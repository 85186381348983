import memoize from 'memoize-one'
import { findFieldByPublicId } from '../utils/form-lot'

export const getAssessmentInfo = memoize((assessmentGroup, headsCount) => {
  let percentageDone = 0
  let avgWeight = 0
  let avgRating = 0
  const formElement = findFieldByPublicId(assessmentGroup, 'individualAssessment')
  if (formElement) {
    const { values } = formElement
    let weights = []
    let ratings = []
    let progress = 0
    for (let fieldset of values) { // eslint-disable-line
      let weight = findFieldByPublicId(fieldset, 'weightLive')
      let rating = findFieldByPublicId(fieldset, 'fatScore')
      if (weight && rating && weight.value && rating.value) {
        progress++
      }
      if (weight && weight.value) {
        weights.push(weight.value)
      }
      if (rating && rating.value) {
        ratings.push(rating.value)
      }
    }
    percentageDone = Math.floor(progress / headsCount * 100)
    avgWeight = weights.reduce((sum, w) => sum + +w, 0) / weights.length || 0
    avgWeight = parseFloat(avgWeight.toFixed(2))
    avgRating = ratings.reduce((sum, r) => sum + +r, 0) / ratings.length || 0
    avgRating = parseFloat(avgRating.toFixed(2))
  }
  return {
    percentageDone,
    avgWeight,
    avgRating
  }
})

export const resetForm = (fieldset) => {
  return fieldset && fieldset.map(field => {
    let newField = JSON.parse(JSON.stringify(field))
    newField.value = getDefaultFieldValue(newField)
    return newField
  })
}

export const getDefaultFieldValue = (field) => {
  let value
  switch (field.type) {
    case 'text':
    case 'textarea':
    case 'date':
    case 'dropdown':
    case 'email':
    case 'number':
    case 'phone':
    case 'user':
    case 'tradingName':
    case 'picNumber':
      // text fields - default value is empty string
      value = ''
      break
    case 'bool':
    case 'checkbox':
      // boolean fields - default value is boolean
      value = false
      break
    case 'weight':
      // numeric fields - default value is numeric
      value = 0
      break
    case 'rating':
      // rating field - default value is 0
      value = 0
      break
    case 'select':
      // select field - if it has defaultValue prop, set it as default, otherwise an empty string
      if (field.defaultValue) {
        value = field.defaultValue
      } else {
        value = ''
      }
      break
    case 'multiDropdown':
      // multidropdown field - default value is empty array
      value = []
      break
    default:
      break
  }
  return value
}
