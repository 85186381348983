import React, { Component } from 'react'
import debounce from 'lodash/debounce'
import config from '../../../config'
import DebounceSelectField from '../fields/DebounceSelectField'

export default class AdjustWeightSection extends Component {
  constructor (props) {
    super(props)
    this.state = {
      adjustWeight: this.props.adjustWeight,
      weightGain: this.props.weightGain
    }
    this.inputProps = { min: config.lot.minAdjustWeight, max: config.lot.maxAdjustWeight, step: '1' }
    this.debounced = null
  }

  onChange = ({ value }) => {
    value = parseFloat(value) || 0
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(() => this.props.onChange(value), 300)
    this.setState({ adjustWeight: value }, this.debounced)
  }

  onChangeWeightGain = ({ value }) => {
    value = parseFloat(value) || 0
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(() => this.props.onChangeWeightGain(value), 300)
    this.setState({ weightGain: value }, this.debounced)
  }

  getDropdownOptions = () => {
    let options = []
    let WholeNumbers = 100
    let minToWholeNumbers = config.lot.minAdjustWeight * WholeNumbers
    let maxToWholeNumbers = config.lot.maxAdjustWeight * WholeNumbers
    let intervalToWholeNumbers = 0.25 * WholeNumbers
    for (let i = minToWholeNumbers; i <= maxToWholeNumbers; i += intervalToWholeNumbers) {
      options.push(i / WholeNumbers)
    }
    if(this.props.kind === 'sheep-abblamb'){
      const additionalValues = [-3.9, -3.8, -3.7, -3.6, -3.4, -3.3, -3.2, -3.1, -2.9, -2.8, -2.7, -2.6, -2.4, -2.3, -2.2, -2.1, -1.9, -1.8, -1.7, -1.6, -1.4, -1.3, -1.2, -1.1];
      options = [...options, ...additionalValues];
      options.sort((a, b) => a - b);
    }

    return options
  }

  getGainDropdownOptions = (kind) => {
    const cattleOptions = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]
    const sheepOptions = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5]
    return (kind === 'sheep') ? sheepOptions : cattleOptions
  }

  render () {
    let {
      assessmentInfo,
      depth,
      adjustWeight,
      weightGain,
      kind,
      assessmentDate,
      earliestDeliveryDate,
      optiweighAllowed,
      optiweighWeight
    } = this.props
    /* if (adjustWeight !== this.state.adjustWeight && !optiweighAllowed) {
      adjustWeight = this.state.adjustWeight
    } */
    let { avgWeight } = assessmentInfo
    if (optiweighAllowed && optiweighWeight) {
      avgWeight = optiweighWeight
    }
    let newWeight = avgWeight
    if (assessmentDate && earliestDeliveryDate && !optiweighAllowed) {
      assessmentDate = new Date(assessmentDate)
      earliestDeliveryDate = new Date(earliestDeliveryDate)
      const timeDiff = Math.abs(earliestDeliveryDate - assessmentDate)
      const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
      newWeight += weightGain * daysDiff
    }
    let curfewWeight = (((100 + +adjustWeight) * +avgWeight) / 100)
    if (newWeight) {
      curfewWeight = (((100 + +adjustWeight) * +newWeight) / 100)
    }
    return (
      <>
        <div className={`depth-${depth % 3}`}>
          <h3 className='dropdown-title'>{optiweighAllowed ? 'Full Weight' : 'Assessment Weight'}</h3>
          <h3 className='table-value'>{avgWeight.toFixed(2)} Kg</h3>
        </div>
        <div className={`depth-${depth % 3}`}>
          <h3 className='dropdown-title'>Weight Gain Adjustment</h3>
          <DebounceSelectField
            onChange={this.onChangeWeightGain}
            value={weightGain}
            options={this.getGainDropdownOptions(kind)}
            disabled={optiweighAllowed}
          />
        </div>
        {!optiweighAllowed && <div className={`depth-${depth % 3}`}>
          <h3 className='dropdown-title'>New Assessment Weight</h3>
          <h3 className='table-value'>{newWeight.toFixed(2)} Kg</h3>
        </div>}
        <div className={`depth-${depth % 3}`}>
          <h3 className='dropdown-title'>Adjustment</h3>
          <DebounceSelectField
            onChange={this.onChange}
            value={adjustWeight}
            options={this.getDropdownOptions()}
          />
        </div>
        <div className={`depth-${depth % 3}`}>
          <h3 className='dropdown-title'>Curfew Weight</h3>
          <h3 className='table-value'>{curfewWeight.toFixed(2)} Kg</h3>
        </div>
      </>
    )
  }
}
