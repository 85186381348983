import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  SaveButton,
  UPDATE,
  showNotification
} from 'react-admin'
import { push } from 'react-router-redux'
import { DeleteButtonComponent } from '../DeleteButtonComponent'
import { validateDraftLot } from './validate'

import { dataProvider } from '../../App'

class LotEditActions extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isRequest: false
    }
    this.isUnmounted = false
  }

  componentWillUnmount () {
    this.isUnmounted = true
  }

  render () {
    let {
      reduxForm,
      push,
      showNotification,
      chosenUser
    } = this.props
    let initialData = reduxForm.getValues()
    return (
      <div className='custom-kind-actions'>
        <SaveButton
          label='Save And Show'
          submitOnEnter={false}
          className='save-button'
          disabled={this.state.isRequest}
          onClick={() => {
            let data = reduxForm.getValues()
            const validateForm = validateDraftLot(data, { draft: data.draft })
            if (data.kindData.type === 'sheep') {
              delete data.optiweigh
              delete data.optiweighAllowed
            }
            if (!validateForm || validateForm.valid) {
              this.props.showSpinner()
              if (this.state.isRequest) {
                return
              }
              this.setState({
                isRequest: true
              }, () => {
                return dataProvider(UPDATE, `auctions/${data.auction}/lots`, { data, id: data._id, type: 'lot', chosenUser: chosenUser || null })
                  .then((response) => {
                    push(`/lots/${response.data._id}/show`)
                  })
                  .catch(error => {
                    showNotification(error.message, 'warning')
                  })
                  .then(() => {
                    if (!this.isUnmounted) {
                      this.setState({
                        isRequest: false
                      })
                      this.props.showSpinner()
                    }
                  })
              })
            } else {
              this.props.showValidationError(validateForm.message)
            }
          }}
        />
        <DeleteButtonComponent
          label='Delete'
          className='delete-button'
          resource='lots'
          record={initialData}
          redirect='/lots'
          type='deleteLot'
          push={push}
          showNotification={showNotification}
        />
      </div>
    )
  }
}

export default connect(null, {
  push,
  showNotification
})(LotEditActions)
