import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import debounce from 'lodash/debounce'
import { RATING_VALIDATION_MAX, RATING_VALIDATION_MIN } from '../../../constants/index'

export default class RatingField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: this.convertValue(this.props.value)
    }

    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.debounced = null
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.value !== this.props.value && this.state.value !== this.props.value) {
      this.setState({ value: this.convertValue(this.props.value) })
    }
  }

  convertValue (value) {
    if (this.props.type === 'number' && value !== null) {
      const maxDecimalDigits = this.props.maxDecimalDigits !== undefined ? this.props.maxDecimalDigits : 0
      value = parseFloat(value).toFixed(maxDecimalDigits)
    }
    return value
  }

  onBlur ({ target: { value } }) {
    this.updateValue(value)
  }

  onChange ({ target: { value } }) {
    this.setState({ value: +value })
  }

  updateValue (value) {
    let newValue = parseFloat(value).toFixed(0)
    if (value > RATING_VALIDATION_MAX) {
      newValue = 5
    } else if (!value || value < RATING_VALIDATION_MIN) {
      newValue = 1
    }

    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(() => this.props.onChange({
      value: +newValue,
      indexes: this.props.newIndexes,
      field: this.props.field
    }), 300)
    this.setState({ value: newValue }, this.debounced)
  }

  render () {
    const { label } = this.props
    const { value } = this.state
    return (
      <>
        <TextField
          disabled={this.props.disabled}
          onChange={this.onChange}
          value={value === null ? '' : value}
          label={label}
          type='number'
          onBlur={this.onBlur}
        />
      </>
    )
  }
}
