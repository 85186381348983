import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import Slide from '@material-ui/core/Slide'
import { DeleteButton } from 'react-admin'
import axios from 'axios'

function Transition (props) {
  return <Slide direction='up' {...props} />
}

export class DeleteButtonComponent extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false
    }

    this.handleClose = this.handleClose.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
  }

  handleOpen () {
    this.setState({
      open: true
    })
  }

  handleClose () {
    this.setState({
      open: false
    })
  }

  deleteLot = () => {
    axios.delete(`/lots/${this.props.record._id}?deleteFromAdmin=true`).then(() => {
      this.props.push(`/lots`)
      this.props.showNotification('Lot was successfully deleted', 'success')
    })
  }

  render () {
    const { type } = this.props
    return ([
      <Button
        key={1}
        variant='contained'
        color='secondary'
        className='delete-button'
        resource={this.props.resource}
        record={this.props.record}
        onClick={this.handleOpen}
      >
        Delete
        <DeleteIcon />
      </Button>,
      <Dialog
        key={2}
        open={this.state.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {'Are you sure?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            Please confirm you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {type === 'deleteLot' && <Button onClick={this.deleteLot}>Delete</Button>}
          {type !== 'deleteLot' && <DeleteButton
            resource={this.props.resource}
            record={this.props.record}
            label='Yes'
            redirect={`/${this.props.resource}`}
          />}
          <Button onClick={this.handleClose} color='primary'>
            No
          </Button>
        </DialogActions>
      </Dialog>
    ])
  }
}
