import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { Collapse } from 'react-collapse'
import update from 'immutability-helper'
import {
  EditButton,
  List,
 // Datagrid,
  TextField,
} from 'react-admin'

export class Cattle extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpened: false,
    }

    this.renderCattle = this.renderCattle.bind(this)
    this.changeCollapse = this.changeCollapse.bind(this)
  }


  renderCattle() {
    const { ...props } = this.props;
    return (
      <div className='datagrid-wrapper home-datagrid-wrapper'>
        <List {...props} >
          <>
            <TextField />
            <TextField />
            <EditButton />
          </>
        </List>

      </div>
    )
  }

  collapsible({ title, isOpened, changeCollapse, publicId, body }) {
    return (
      <div className='collapse-wrapper'>
        <div
          className='header'
          onClick={isOpened ? () => (changeCollapse(publicId, false)) : () => (changeCollapse(publicId, true))}
        >
          <p className='title'>
            {title}
          </p>
          <div
            className='button'
          >
            <FontAwesomeIcon icon={isOpened ? faAngleUp : faAngleDown} />
            <span>{isOpened ? 'Hide' : 'Show'}</span>
          </div>
        </div>
        <Collapse isOpened={isOpened}>
          <div className='body'>
            {body}
          </div>
        </Collapse>
      </div>
    )
  }

  changeCollapse() {
    this.setState({
      isOpened: update(this.state.isOpened, {
        $set: !this.state.isOpened
      })
    })
  }

  render() {
    return (
      <div>
        <React.Fragment>
          {this.collapsible({
            title: 'Cattle',
            isOpened: this.state.isOpened,
            changeCollapse: this.changeCollapse,
            body: this.renderCattle()
          })}
        </React.Fragment>
      </div>
    )
  }
}