import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  FileInput,
  ImageField,
  LongTextInput,
  SelectArrayInput,
  SelectInput, BooleanInput, FormDataConsumer
} from 'react-admin'
import moment from 'moment'
import { Field } from 'redux-form'
import * as Datetime from 'react-datetime'

import { AuctionTitle } from '../model'
import EditToolbar from '../../../components/EditToolbar'
import axios from 'axios'
import { AuctionType } from '../create/model'
import { StatesAndRegions } from '../create/model'
import auctionCreateService from '../auctionService'
import { TimeFormatInput } from './TimeFormatInput'
import { AuctionTags } from '../../../components/auction/AuctionTags/AuctionTags'
import { UsefulLinks } from '../../../components/auction/UsefulLinks/UsefulLinks'
import { AdditionDateInformation } from '../../../components/auction/AdditionDateInformation/AdditionDateInformation'
import { approvedOptions } from '../../lots/options'
import UserFetch from '../create/userFetch'
import { AuctionTabs } from '../../../components/auction/AuctionTabs/AuctionTabs'

const locations = auctionCreateService.locations
// const reminderLocations = auctionCreateService.reminderLocations
const auctionPublic = auctionCreateService.auctionPublic
const statuses = auctionCreateService.statuses
const userOperations = auctionCreateService.userOperations
const selectAllUserOperations = auctionCreateService.selectAllUserOperations
const selectAllStatus = auctionCreateService.selectAllStatus
// const selectAllStates = auctionCreateService.selectAllStates

const DateTime = (params) => {
  let { input, label, meta: { touched, error } } = params
  input.value = moment(input.value)
  return (
    <div>
      <Datetime
        timeFormat
        dateFormat='DD/MM/YYYY'
        label={label}
        error={Boolean(touched && error)}
        helperText={touched && error}
        inputProps={{ placeholder: 'Live At' }}
        {...input}
      />
      {input.value._d && <p style={{ maxWidth: '250px' }} >{`${moment(input.value._d).tz('Australia/Sydney').format('ddd, DD MMMM, h:mm A')} (SYD): `} <span style={{ fontSize: '14px' }}>{`${moment(input.value._d).tz('Australia/Perth').format('h:mm A')} WA -- ${moment(input.value._d).tz('Australia/Darwin').format('h:mm A')} NT -- ${moment(input.value._d).tz('Australia/Queensland').format('h:mm A')} QLD -- ${moment(input.value._d).tz('Australia/Adelaide').format('h:mm A')} SA -- ${moment(input.value._d).tz('Australia/Sydney').format('h:mm A')} NSW -- ${moment(input.value._d).tz('Australia/Victoria').format('h:mm A')} VIC -- ${moment(input.value._d).tz('Australia/Tasmania').format('h:mm A')} TAS`}</span></p>}
    </div>
  )
}
const validateAuctionEdit = (values) => {
  const errors = {}
  if (!values.title) {
    errors.title = ['The title is required']
  }
  if (!values.location || !values.location.state || values.location.state.length === 0) {
    errors.location = {}
    errors.location.state = ['The Location State field is required']
  }
  if (!values.lotOpeningSeconds) {
    errors.lotOpeningSeconds = ['The lotOpeningSeconds is required']
  }
  if (!values.auctionMaxSeconds) {
    errors.auctionMaxSeconds = ['The Auction Max Seconds is required']
  }
  if (!values.kindId) {
    errors.kindId = ['Category field is required']
  }
  if (!values.liveAt) {
    errors.liveAt = ['Live At field is required']
  }
  if (!values.auctionType) {
    errors.auctionType = ['Auction Type field is required']
  }
  if (!values.logo) {
    errors.logo = ['Logo for Web field is required']
  }
  if (!values.logoIos) {
    errors.logoIos = ['Logo for iOs field is required']
  }
  return errors
}

export const AuctionEdit = (props) => {
  let [isPrivateSale, setIsPrivateSale] = useState(null)
  let [isStudSale, setIsStudSale] = useState(false)
  let [isClosed, setIsCloed] = useState(false)
  let [allowAutoBid, setAllowAutoBid] = useState(false)
  let [allowUpcomingBid, setAllowUpcomingBid] = useState(false)
  let [sendReminderAsSMS, setSendReminderAsSMS] = useState(false)
  let [isRequiresTabs, setIsRequiresTabs] = useState(false)
  let [regions,setRegions] = useState(null);
  let [addNewLotToSpecificAgents, setAddNewLotToSpecificAgents] = useState(false)
  const [chosenUsers, setChosenUsers] = useState(null)
  const [availableAgentIds, setAvailableAgentIds] = useState([])
  const { users } = UserFetch()

  if (isPrivateSale === null) {
    axios.get(props.location.pathname).then((response) => {
      if (response.data.auction) {
        const privateSale = response.data.auction.privateSale ? response.data.auction.privateSale : false
        const isStud = response.data.auction.studSale ? response.data.auction.studSale : false
        const isAddNewLot = response.data.auction.addNewLotToSpecificAgents ? response.data.auction.addNewLotToSpecificAgents : false
        const isClosed = response.data.auction.state === 'closed'
        const allowUpcoming = response.data.auction.allowUpcomingBid
        const allowAutoBid = response.data.auction.allowAutoBid
        const requiresTabs = response.data.auction.requiresTabs ?? false
        const specificAgents = response.data.auction.specificAgents
        setIsCloed(isClosed)
        setIsPrivateSale(privateSale)
        setAddNewLotToSpecificAgents(isAddNewLot)
        setIsStudSale(isStud)
        setAllowUpcomingBid(allowUpcoming)
        setAllowAutoBid(allowAutoBid)
        setIsRequiresTabs(requiresTabs)
        setAvailableAgentIds(specificAgents)
      } else {
        setIsPrivateSale(false)
      }
    })
  }

  const onChangePrivateSale = () => {
    let privateSale = !isPrivateSale
    setIsPrivateSale(privateSale)
    if (!privateSale && !isStudSale) {
      setAddNewLotToSpecificAgents(false)
    }
  }
  const onChangeStudSale = () => {
    let studSale = !isStudSale
    setIsStudSale(studSale)
    if (!isPrivateSale && !studSale) {
      setAddNewLotToSpecificAgents(false)
    }
  }
  const onChangeAutoBid = () => {
    setAllowAutoBid(!allowAutoBid)
  }
  const onChangeUpcomingBid = () => {
    setAllowUpcomingBid(!allowUpcomingBid)
  }
  const onChangeSendReminderAsSMS = () => {
    setSendReminderAsSMS(!sendReminderAsSMS)
  }
  const onChangeRequiresTabs = () => {
    setIsRequiresTabs(!isRequiresTabs)
  }
  const onChangeAddNewLotToSpecificAgents = () => {
    setAddNewLotToSpecificAgents(!addNewLotToSpecificAgents)
  }

  const handleChange = (selected) => {
    setChosenUsers(selected)
  }

  const UserStatuses = () => {
    return (
      <FormDataConsumer>
        {({ formData }) => {
          if (formData && formData.smsReminders && formData.smsReminders.userStatus && formData.smsReminders.userStatus.length > 0 && formData.smsReminders.userStatus.includes('All')) {
            formData.smsReminders.userStatus = selectAllStatus
          }
          return (
            <SelectArrayInput source='smsReminders.userStatus' label='SMS Reminder Status ' choices={statuses} />
          )
        }}

      </FormDataConsumer>
    )
  }
  const UserProfessions = () => {
    return (
      <FormDataConsumer>
        {({ formData }) => {
          if (formData && formData.smsReminders && formData.smsReminders.userTypes && formData.smsReminders.userTypes.length > 0 && formData.smsReminders.userTypes.includes('All')) {
            formData.smsReminders.userTypes = selectAllUserOperations
          }
          return (
            <SelectArrayInput source='smsReminders.userTypes' label='SMS Reminder Professions ' choices={userOperations} />
          )
        }}

      </FormDataConsumer>
    )
  }

  const ListingFeeForSpecialPrice = () => {
    return (
      <FormDataConsumer>
        {({ formData }) => {
          if (formData && formData.kindData.type === 'cattle') {
            return <NumberInput source='specialPriceFeeInDollars' label='Listing fee(dollars) Bulls' step={1} />
          } else if (formData && formData.kindData.type === 'sheep') {
            return <NumberInput source='specialPriceFeeInDollars' label='Listing fee(dollars) Rams' step={1} />
          } else if (formData && formData.kindData.type === 'goat') {
            return <NumberInput source='specialPriceFeeInDollars' label='Listing fee(dollars) Bucks' step={1} />
          } else {
            return null
          }
        }}
      </FormDataConsumer>
    )
  }
  const getPostcodeData = async () => {
    let url = '/postcodes'
    const res = await axios.get(url);
    let data = [];
    if(res && res.data) {
      res.data.postcodesData.forEach(element => {
        data.push(`${element.region} (${element.state})`)
    });
    }
    data = Array.from(new Set(data));
    data =  data.map ((d)=>{
     return {id : d, name : d}
    })
    setRegions(data);
  }
 
  useEffect(() => {
     getPostcodeData();
   
    if (users.length === 0) { return }
    if (availableAgentIds.length > 0) {
      let availableAgents = users.filter(person => availableAgentIds.includes(person.value))
      setChosenUsers(availableAgents)
    } else {
      setChosenUsers([])
    }
  }, [users,availableAgentIds])  // warning fixes

  return (
    <Edit {...props} title={<AuctionTitle />}>
      <SimpleForm validate={validateAuctionEdit} toolbar={<EditToolbar auction specificAgents={chosenUsers ? chosenUsers.map(e => e.value) : []} />}>
        <TextInput source='title' />
        <Field disabled={isClosed} name='localLiveAtISO' source='localLiveAtISO' label='Live At' component={DateTime} />
        <AuctionType source='auctionType' />
        <SelectInput disabled={isClosed} source='approved' label='Approved' choices={approvedOptions} />
        <SelectArrayInput source='location.state' label='Location State' choices={locations} />
        <BooleanInput disabled={isClosed} source='showLotNumbers' label='Show lot number' />
        <NumberInput source='viewDetailsDays' label='View details days' defaultValue={10} />
        <BooleanInput disabled={isClosed} source='sendReminderAsSMS' label='Send Reminder as SMS?'
          onChange={onChangeSendReminderAsSMS} />
        <StatesAndRegions regions = {regions ? regions : [] } />
        <UserStatuses />
        <UserProfessions />
        <SelectInput disabled={isClosed} source='public' choices={auctionPublic} />
        <TimeFormatInput source='auctionOpenTimer' label='Auction Open Timer' />
        <TimeFormatInput source='addLotOpenTimer' label='Add lot Open Timer' />
        <TimeFormatInput source='addLotCloseTimer' label='Add Lot Close Timer' />
        <TimeFormatInput disabled={isClosed} source='lotOpeningSeconds' label='Lot Opening Timer' />
        <TimeFormatInput disabled={isClosed} source='bidAddedSeconds' label='Bid Added Timer' />
        <TimeFormatInput disabled={isClosed} source='lotMaxSeconds' label='Lot Max Timer' />
        <FormDataConsumer>
          {({ formData, record }) => {
            return (
              <>
                {formData && formData.auctionType !== 'sequential' && formData && formData.auctionType !== 'synchronous' &&
                  <TimeFormatInput record={record} disabled={isClosed} source='standbyTimer' label='Standby Timer' />}
              </>
            )
          }}
        </FormDataConsumer>
        {/* <FormDataConsumer>
          {({ formData, record }) => {
            return (
              <>
                {formData && formData.auctionType !== 'sequential' && formData.auctionType !== 'synchronous' && formData.auctionType !== 'simultaneous' && formData.auctionType !== 'sequentialOffline' &&
                  <TimeFormatInput record={record} disabled={isClosed} source='auctionMaxSeconds' label='Auction Max Timer' />}
              </>
            )
          }}
        </FormDataConsumer> */}
        <BooleanInput disabled={isClosed} source='privateSale' label='Is this a private sale?'
          onChange={onChangePrivateSale} />
        <BooleanInput disabled={isClosed} source='studSale' label='Is this a Stud Sale' onChange={onChangeStudSale} />
        <BooleanInput source='allowAutoBid' label='Allow Auto-bids?' onChange={onChangeAutoBid} />
        <BooleanInput source='allowUpcomingBid' label='Allow bids on upcoming Lot?' onChange={onChangeUpcomingBid} />
        <BooleanInput disabled={isClosed} source='requiresTabs' label='Auction Requires Tabs?'
          onChange={onChangeRequiresTabs} />
        {isRequiresTabs && <AuctionTabs/> }
        {(isPrivateSale || isStudSale) && <BooleanInput source='addNewLotToSpecificAgents' label='show add new lot to specific users' defaultValue={addNewLotToSpecificAgents}
          onChange={onChangeAddNewLotToSpecificAgents} />}
        {addNewLotToSpecificAgents && chosenUsers && <Select defaultValue={chosenUsers.length > 0 ? chosenUsers : []} options={users} placeholder='User/Users' isMulti onChange={handleChange} />}
        <NumberInput source='listingFeeInDollars' label='Listing fee(dollars)' step={1} />
        <ListingFeeForSpecialPrice />
        {isPrivateSale &&
          <NumberInput source='auctionSetUpFeeInDollars' label='Auction set up fee' step={1} defaultValue={0} />}
        {isPrivateSale && <NumberInput source='auctionDiscount' label='Auction Discount(%)' step={1} defaultValue={0} />}
        <AuctionTags />
        <UsefulLinks />
        <BooleanInput source='showCalculator' label='Show Stock Co. calculator in catalogue?' />
        {isPrivateSale && isStudSale && <Field name='additionalDateInfo' source='additionalDateInfo' label='' component={AdditionDateInformation} />}
        <LongTextInput label='Auction Overview Description' source='descriptionShort' />
        <LongTextInput label='In Catalogue Description' source='description' />
        <LongTextInput label='Admin Notes' source='adminNotes' />
        <FileInput disabled={isClosed} source='image' label='Image' accept='.jpg,.jpeg,.png'
          placeholder={<p>Drop your file here</p>}>
          <ImageField source='url' />
        </FileInput>
        <FileInput disabled={isClosed} source='logo' label='Logo for Web' accept='.jpg,.jpeg,.png'
          placeholder={<p>Drop your file here</p>}>
          <ImageField source='url' />
        </FileInput>
        <FileInput disabled={isClosed} source='logoIos' label='Logo for iOs' accept='.jpg,.jpeg,.png'
          placeholder={<p>Drop your file here</p>}>
          <ImageField source='url' />
        </FileInput>
      </SimpleForm>
    </Edit>
  )
}
