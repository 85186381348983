export const roleOptions = [
  { id: 'admin', name: 'Admin' },
  { id: 'user', name: 'User' }
]
export const tradeThroughCompanyOptions = [  
  { id: false, name: 'No' },
  { id: true, name: 'Yes' }
]
export const statusOptions = [
  { id: 'registered', name: 'Registered' },
  { id: 'registered-updated', name: 'Registered: Updated' },
  { id: 'approved-buyer', name: 'Approved Buyer' },
  { id: 'approved-buyer-updated', name: 'Approved Buyer: Updated' },
  { id: 'approved-seller', name: 'Approved Seller' },
  { id: 'approved-buyer-seller', name: 'Approved Buyer & Seller' },
  { id: 'declined', name: 'Declined' },
  { id: 'removed', name: 'Removed' }
]
export const typeOptions = [
  { id: 'guest', name: 'Guest' },
  { id: 'buyer', name: 'Buyer' },
  { id: 'seller', name: 'Seller' }
]
/*
export const sellerTypeOptions = [
  { id: 'independent', name: 'Independent' },
  { id: 'agency', name: 'Agency' }
]
*/
export const states = [
  { id: 'NSW', name: 'NSW' },
  { id: 'VIC', name: 'VIC' },
  { id: 'QLD', name: 'QLD' },
  { id: 'WA', name: 'WA' },
  { id: 'SA', name: 'SA' },
  { id: 'TAS', name: 'TAS' },
  { id: 'NT', name: 'NT' }
]
export const discountOptions = [
  { id: null, name: 'Nil' },
  { id: 1, name: '1%' },
  { id: 2, name: '2%' },
  { id: 3, name: '3%' },
  { id: 4, name: '4%' },
  { id: 5, name: '5%' },
  { id: 6, name: '6%' },
  { id: 7, name: '7%' },
  { id: 8, name: '8%' },
  { id: 9, name: '9%' },
  { id: 10, name: '10%' },
  { id: 15, name: '15%' },
  { id: 20, name: '20%' },
  { id: 25, name: '25%' },
  { id: 30, name: '30%' },
  { id: 35, name: '35%' },
  { id: 40, name: '40%' },
  { id: 45, name: '45%' },
  { id: 50, name: '50%' },
  { id: 55, name: '55%' },
  { id: 60, name: '60%' },
  { id: 65, name: '65%' },
  { id: 70, name: '70%' },
  { id: 75, name: '75%' },
  { id: 80, name: '80%' },
  { id: 85, name: '85%' },
  { id: 90, name: '90%' },
  { id: 95, name: '95%' },
  { id: 100, name: '100%' }
]
