import React, { Component } from 'react'

import DebounceSelectField from './DebounceSelectField'
import compareArrays from '../../../helpers/compareArrays'
import formatTitleForType from '../../../helpers/formatTitleForType'

export default class FormTypeField extends Component {
  constructor (props) {
    super(props)

    this.onChange = props.onChange
  }

  shouldComponentUpdate (nextProps) {
    return this.props.type !== nextProps.type || compareArrays(this.props.newIndexes, nextProps.newIndexes)
  }

  render () {
    return this.props.isMarked || this.props.newIndexes.length === 1
      ? (
        <div className='type type-no-select'>{formatTitleForType(this.props.type)}</div>
      )
      : (
        <DebounceSelectField
          onChange={this.onChange}
          value={this.props.type}
          newIndexes={this.props.newIndexes}
          field='type'
        />
      )
  }
}
