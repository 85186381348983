import React from 'react'
import { Form } from 'redux-form'
import * as Datetime from 'react-datetime'
import './InvoiceDate.scss'
import moment from 'moment'

export class InvoiceDate extends Form {
  constructor(props, context) {
    super(props, context)

    this.state = {
      selectedDate: null,
      error: null
    }

    this.reduxForm = context._reduxForm
    this.formValues = this.reduxForm.getValues()
  }

  componentDidMount = async () => {
    let sourceName = this.props.source
    if (this.formValues && this.formValues[sourceName]) {
      this.setState({ selectedDate: moment(this.formValues[sourceName]) }, () => {
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.meta && nextProps.meta.error && nextProps.meta.submitFailed) {
      this.setState({ error: nextProps.meta.error })
    } else {
      this.setState({ error: null })
    }
  }

  dateHandler = (date) => {
    this.setState({ selectedDate: date }, this.updateReduxForm)
  }

  updateReduxForm = () => {
    this.reduxForm.dispatch(this.reduxForm.change(`${this.props.source}`, this.state.selectedDate))
  }

  dateValidation = (date) => {
    var dateYesterday = new Date();
    dateYesterday.setDate(dateYesterday.getDate() - 1);
    return date.isAfter(moment(dateYesterday));
  }

  render() {
    return (
      <div className="date-info-container" style={{ marginTop: "10px" }}>
        {this.props.spacing && (
          <label className={"spacing-label"}>{''}</label>
        )}
        {this.state.selectedDate && (
          <label className={"floating-label"}>{this.props.label}</label>
        )}
        <Datetime
          timeFormat={0}
          className="date-field"
          dateFormat="DD/MM/YYYY"
          label={this.props.label}
          inputProps={{ placeholder: this.props.label }}
          value={this.state.selectedDate}
          onChange={this.dateHandler}
          isValidDate={this.dateValidation}
        />
        {this.state.error && (
          <p className="error-message">{this.state.error}</p>
        )}
      </div>
    );
  }
}
