import React from 'react'
import { Form } from 'redux-form'
import TextField from '@material-ui/core/TextField'
import * as Datetime from 'react-datetime'
import './AdditionDateInformation.scss'
import moment from 'moment'

export class AdditionDateInformation extends Form {
  constructor (props, context) {
    super(props, context)

    this.state = {
      title: '',
      date: null,
      error: null
    }

    this.reduxForm = context._reduxForm
    this.formValues = this.reduxForm.getValues()
    this.additionalDateInfo = this.formValues.additionalDateInfo
  }

  componentDidMount = async () => {
    if (this.additionalDateInfo && this.additionalDateInfo.title && this.additionalDateInfo.date) {
      this.setState({ title: this.additionalDateInfo.title, date: moment(this.additionalDateInfo.date).format('DD/MM/YYYY HH:mm a') })
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps && nextProps.meta && nextProps.meta.error && nextProps.meta.submitFailed) {
      this.setState({ error: nextProps.meta.error })
    } else {
      this.setState({ error: null })
    }
  }

  dateHandler = (date) => {
    this.setState({ date }, this.updateReduxForm)
  }

  titleHandler = ({ target: { value } }) => {
    this.setState({ title: value }, this.updateReduxForm)
  }

  updateReduxForm = () => {
    this.reduxForm.dispatch(this.reduxForm.change('additionalDateInfo', { title: this.state.title, date: this.state.date }))
  }

  render () {
    return (
      <div className='additional-date-info-container' style={{ marginTop: '10px' }}>
        <label className={'main-label'}>
        Additional Date/Time Information
        </label>
        <TextField
          className='title'
          onChange={this.titleHandler}
          label={'Title'}
          value={this.state.title}
          type='text'
          InputProps={{ inputProps: { min: 0 } }}
        />
        <Datetime
          timeFormat
          className='date-description'
          dateFormat='DD/MM/YYYY'
          label={'Date'}
          inputProps={{ placeholder: 'Date' }}
          value={this.state.date}
          onChange={this.dateHandler}
        />
        {this.state.error && <p className='error-message'>{this.state.error}</p>}
      </div>
    )
  }
}
