import React, { Component } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import debounce from 'lodash/debounce'

import formatTitleForType from '../../../helpers/formatTitleForType'

export default class DebounceSelectField extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: props.value,
      options: props.options
    }

    this.isLabeledOptions = props.options.some(option => option.hasOwnProperty('label'))
    this.onChange = this.onChange.bind(this)
    this.onReset = this.onReset.bind(this)
    this.debounced = null
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.value !== this.props.value && this.state.value !== this.props.value) {
      this.setState({ value: this.props.value })
    }
  }

  onChange ({ target: { value } }) {
    this.updateValue(value)
  }

  onReset () {
    this.updateValue(this.props.defaultValue !== undefined ? this.props.defaultValue : null)
  }

  updateValue (value) {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(() => this.props.onChange({
      value,
      indexes: this.props.newIndexes,
      field: this.props.field,
      publicId: this.props.publicId ? this.props.publicId : null
    }), 300)
    this.setState({ value }, this.debounced)
  }

  render () {
    let { options } = this.props
    return (
      <div className={this.props.tags ? 'margin' : ''}>
        <div className='type lot-edit-field'>
          <Select
            id={this.props.id}
            disabled={this.props.disabled}
            onChange={this.onChange}
            value={this.state.value === null ? this.props.multiple ? [] : '' : this.state.value}
            multiple={this.props.multiple}
            renderValue={value => {
              let renderValue
              if (this.props.multiple) {
                if (this.props.tags) {
                  renderValue = value.map(e => { return typeof e === 'object' ? e.title : e }).join(', ')
                } else {
                  renderValue = value.join(' / ')
                }
              } else if (this.isLabeledOptions) {
                renderValue = value ? options.find(option => option.value === value).label : value
              } else {
                renderValue = value
              }
              return (
                <div className='input'>
                  {formatTitleForType(renderValue)}
                </div>
              )
            }}
          >
            {options.map((option, index) => {
              if (this.isLabeledOptions) {
                return <MenuItem key={index} value={option.value}>{formatTitleForType(option.label)}</MenuItem>
              } else if (this.props.tags) {
                return <MenuItem key={index} value={option.title}>{formatTitleForType(option.title)}</MenuItem>
              } else {
                return <MenuItem key={index} value={option}>{formatTitleForType(option)}</MenuItem>
              }
            })}
          </Select>
        </div>
        <span className='fg-field-reset' onClick={this.props.onReset ? this.props.onReset : this.onReset}>X</span>
      </div>
    )
  }
}

DebounceSelectField.defaultProps = {
  multiple: false
}
