import React, { Component } from 'react'
import { OwnPagination } from '../../OwnPagination'
import { dataProvider } from '../../../App'
import update from 'immutability-helper'
import { GET_LIST, Datagrid, TextField } from 'react-admin'
import MaterialTextField from '@material-ui/core/TextField'
import config from '../../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse } from 'react-collapse'
import debounce from 'lodash/debounce'

export class VendorsTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userVendors: {
        data: {},
        isOpened: false,
        page: 1,
        perPage: 10,
        total: 0,
        field: '_id',
        order: 'ASC',
        distincted: 0
      },
      searchUserVendors: ''
    }
    this.loadUserVendors()
  }

  loadUserVendors = async () => {
    dataProvider(GET_LIST, `/auctions/${this.props.auctionId}/vendors`, {
      filter: this.filter,
      pagination: {
        page: this.state.userVendors.page,
        perPage: this.state.userVendors.perPage
      },
      sort: {
        field: this.state.userVendors.field,
        order: this.state.userVendors.order
      },
      search: this.state.searchUserVendors,
      isAuctionVendors: true,
      auction: true
    })
      .then(({ data, total, distincted }) => {
        let newids = []
        let newdata = {}
        for (let i = 0; i < data.length; i++) {
          let id = data[i]._id
          newids.push(id)
          newdata[id] = {
            ...data[i]
          }
        }
        this.setState({
          userVendors: update(this.state.userVendors, {
            data: {
              $set: newdata
            },
            total: {
              $set: total
            },
            distincted: {
              $set: distincted
            }
          })
        })
      })
  }

  changeCollapse = (state) => {
    this.setState({
      userVendors: update(this.state.userVendors, {
        isOpened: { $set: state }
      })
    })
  }

  setPerPageVendors = (perPage) => {
    this.setState({
      userVendors: update(this.state.userVendors, {
        perPage: { $set: perPage }
      })
    }, this.loadUserVendors)
  }

  setSearchUserVendorsString = ({ target: { value } }) => {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.loadUserVendors, 300)
    this.setState({
      searchUserVendors: value
    }, this.debounced)
  }

  setPageVendors = (page) => {
    this.setState({
      userVendors: update(this.state.userVendors, {
        page: { $set: page }
      })
    }, this.loadUserVendors)
  }

  renderUserVendors = () => {
    let { data } = this.state.userVendors
    let ids = Object.keys(data)

    const UserField = ({ record = {} }) => {
      if (!record.createdBy) {
        return 'User Not Found'
      }
      return (
        <a href={`/#/users/${record.createdBy._id}/show`}>{record.createdBy.firstName} {record.createdBy.lastName}</a>
      )
    }
    const LotIdField = ({ record = {} }) => {
      return (
        <span>
          {record.number &&
          <a href={`/#/lots/${record._id}/show`} className='vendor-link'>{record.number}</a>
          }
        </span>
      )
    }
    const LotSearchNumberField = ({ record = {} }) => {
      return (
        <span>
          {record.searchNumericId &&
          <a href={`/#/lots/${record._id}/show`} className='vendor-link'>{record.searchNumericId}</a>
          }
        </span>
      )
    }
    const LotTitleField = ({ record = {} }) => {
      return (
        <span>
          {record.publicDetails && record.publicDetails.title &&
          <a href={`/#/lots/${record._id}/show`} className='vendor-link'>{record.publicDetails.title}</a>
          }
        </span>
      )
    }
    return (
      <div className='info-table-div-wrapper'>
        <MaterialTextField
          label='Search'
          placeholder='Search'
          className='search-text'
          onChange={this.setSearchUserVendorsString}
        />
        <Datagrid
          ids={ids}
          data={data}
          currentSort={{}}
        >
          <UserField label='Name' sortable={false} />
          <TextField source='createdBy.phone' label='Phone' sortable={false} />
          <TextField source='createdBy.connectivity.lastPlatform' label='App' sortable={false} />
          <LotSearchNumberField label='Lot ID' sortable={false} />
          <LotIdField label='Lot No' sortable={false} />
          <LotTitleField label='Title' sortable={false} />
          <TextField source='_startPrice' label='Start Price' sortable={false} />
          <TextField source='_reservePrice' label='Reserve Price' sortable={false} />
          <TextField source='bidding' label='Bid type' sortable={false} />
        </Datagrid>
        <OwnPagination
          page={this.state.userVendors.page}
          perPage={this.state.userVendors.perPage}
          total={this.state.userVendors.total}
          setPerPage={this.setPerPageVendors}
          setPage={this.setPageVendors}
        />
      </div>
    )
  }

  collapsible = ({ title, exportLink, isOpened, body }) => {
    return (
      <div className='collapse-wrapper'>
        <div
          className='header'
          onClick={isOpened ? () => (this.changeCollapse(false)) : () => (this.changeCollapse(true))}
        >
          {title}
          <div
            className='button'
          >
            <FontAwesomeIcon icon={isOpened ? 'angle-up' : 'angle-down'} />
            <span>{isOpened ? 'Hide' : 'Show'}</span>
          </div>
        </div>
        <Collapse isOpened={isOpened}>
          {exportLink ? <a className='export-button' href={exportLink} target='_blank' rel='noopener noreferrer'>Export to CSV</a> : null}
          <div className='body'>
            {body}
          </div>
        </Collapse>
      </div>
    )
  }

  render () {
    return (
      <div id='collapse-content-buyers'>
        {this.collapsible({
          title: `User Vendors, ${this.state.userVendors.distincted || 1} Vendor${this.state.userVendors.distincted > 1 ? 's' : ''}`,
          exportLink: `${config.rootUrl}exports/auctions/${this.props.auctionId}/vendors?${config.adminAccess}=true`,
          isOpened: this.state.userVendors.isOpened,
          changeCollapse: this.changeCollapse,
          body: this.renderUserVendors()
        })}
      </div>
    )
  }
}
