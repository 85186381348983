export const auctionDefaultProps = [
  'basePath',
  'hasCreate',
  'hasEdit',
  'hasList',
  'hasShow',
  'history',
  'id',
  'location',
  'match',
  'options',
  'permissions',
  'resource'
]

export const lotDefaultProps = [
  'basePath',
  'hasCreate',
  'hasEdit',
  'hasList',
  'hasShow',
  'history',
  'id',
  'location',
  'match',
  'options',
  'permissions',
  'resource'
]

export const transtionProps = [
  'appear',
  'children',
  'in',
  'role',
  'tabIndex',
  'timeout'
]
