import React from 'react'
import { connect } from 'react-redux'
import { showNotification } from 'react-admin'
import Button from '@material-ui/core/Button'
import { push } from 'react-router-redux'

import handleError from '../providers/handleError'

export default connect(null, {
  push,
  showNotification
})((props) => {
  const { color, variant, disabled } = props
  const buttonProps = { color, variant, disabled }
  if (props.disabledField && props.record && props.record[props.disabledField]) {
    buttonProps.disabled = true
    buttonProps.variant = 'contained'
  }
  return (
    <Button {...buttonProps} onClick={() => handleClick(props)}>
      {props.children}
    </Button>
  )
})

const handleClick = async (props) => {
  try {
    await props.onClick(props).catch(handleError)
  } catch (err) {
    props.showNotification(err.message, 'warning')
  }
}
