import React, { useState } from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  LongTextInput,
  SelectInput,
  required,
  CheckboxGroupInput
} from 'react-admin'

import {
  roleOptions,
  statusOptions,
  states
} from '../options'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { professionOther } from '../../../constants'
import { getUserTypeByStatus } from '../../../utils'

const validateUserCreation = (values) => {
  const errors = {}
  if (values.phone && values.phone.search(/^\d*$/) === -1 && values.phone.length > 0) {
    errors.phone = ['Phone number must contain only digits']
  }
  if (values.secondaryPhone && values.secondaryPhone.search(/^\d*$/) === -1 && values.secondaryPhone.length > 0) {
    errors.secondaryPhone = ['Phone number must contain only digits']
  }
  if (values.type === 'buyer' && !values.PIC) {
    errors.PIC = ['Required']
  }
  return errors
}

const PicField = ({ record = {} }) => {
  if (record.type === 'buyer') {
    return <TextInput source='PIC' label='PIC' validate={required()} />
  } else {
    return <TextInput source='PIC' label='PIC' />
  }
}

export const UserCreate = (props) => {
  const [userType, setUserType] = useState(null)
  let [showSelect, setShowSelect] = useState(true)
  let [userTags, setUserTags] = useState([])
  let [userTagsSet, setUserTagsSet] = useState(false)
  let [profession, setProfession] = useState(null)
  let [professionOptions, setProfessionOptions] = useState([])
  let [professionOptionsLoaded, setProfessionOptionsLoaded] = useState(null)
  let [kindOptions, setKindOptions] = useState([])
  let [kindOptionsLoaded, setKindOptionsLoaded] = useState(null)

  if (!userTagsSet) {
    axios.get('/user-tags').then((response) => {
      setUserTags(response.data['user-tags'])
      setUserTagsSet(true)
    })
  }

  if (!professionOptionsLoaded) {
    axios.get('/professions').then((response) => {
      let arrayProfessions = response.data.professions.map((document, index) => {
        document.id = document.name
        return document
      })
      setProfessionOptions(arrayProfessions)
      setProfessionOptionsLoaded(true)
    })
  }
  if (!kindOptionsLoaded) {
    axios.get('/kinds').then((response) => {
      setKindOptions(response.data.kinds)
      setKindOptionsLoaded(true)
    })
  }

  const UserStatus = ({ record }) => {
    return (
      <SelectInput onChange={(e, value) => { onChangeStatus(e, value, record) }} source='status' label='Status' choices={statusOptions} validate={required()} />
    )
  }

  function onChangeStatus(e, value, record) {
    setUserType(getUserTypeByStatus(value, record.type))
    console.log('Type=', userType);
  }

  const ProfessionFields = ({ record }) => {
    if (profession === null) {
      setProfession(record.profession)
    }
    return (
      <div>
        <SelectInput onChange={onChangeProfession} source='profession' label='Profession' choices={professionOptions} validate={required()} />
        {profession === professionOther && <TextInput source='otherProfession' label='Other Profession' validate={required()} />}
      </div>
    )
  }

  function onChangeProfession(e, value) {
    setProfession(value)
  }

  const ApprovedSellingCategories = () => {
    return (
      <CheckboxGroupInput source='approvedStockTypes' label='Approved Selling Categories' choices={kindOptions.filter(item => item.sellingCategory === true)} optionValue="title" optionText="title" />
    )
  }

  return (
    <Create {...props}>
      <SimpleForm validate={validateUserCreation} className='user-create-form' redirect='show'>
        <TextInput source='firstName' label='First Name' validate={required()} />
        <TextInput source='lastName' label='Last Name' validate={required()} />
        <TextInput source='email' label='Email' type='email' validate={required()} />
        <TextInput source='password' label='Password' type='password' validate={required()} />
        <SelectInput ressetable source='role' label='Role' choices={roleOptions} validate={required()} />
        <UserStatus />
        {/*
        <TextInput source='type' disabled defaultValue={userType ? capitalize(userType) : ''} label='Type' />
        <SelectInput source='sellerType' label='Seller Type' choices={sellerTypeOptions} validate={required()} />
        */}
        <ProfessionFields />
        <ApprovedSellingCategories />
        {/*
        <TextInput source='saleConditions' label='Sale Conditions' />
        <TextInput source='website' label='Website' />
        <TextInput source='jobTitle' label='Job Title' />
        */}
        <TextInput source='agencyName' label='Agency Name' />
        <TextInput source='tradingName' label='Trading Name' validate={required()} />
        <TextInput source='agentName' label='Agent Name' />
        <TextInput source='agentPhone' label='Agent Number' />
        <TextInput source='agentTradingName' label='Agent Trading Name' />
        <PicField />
        <TextInput source='ABN' label='ABN' />
        <TextInput source='phone' label='Phone' validate={required()} />
        {showSelect && <SelectInput source='userTag' choices={userTags} optionText='title' optionValue='title' />}
        {!showSelect && <TextInput source='userTag' label='New Tag' />}
        <FontAwesomeIcon
          className='add-button'
          icon='plus-square'
          onClick={() => setShowSelect(!showSelect)}
        />
        <TextInput source='secondaryPhone' label='Secondary Phone' />
        <div className='address-block'>
          <p className='title'>Office Address:</p>
          <div className='inputs'>
            <TextInput source='officeAddress.address1' label='Address 1' />
            <TextInput source='officeAddress.address2' label='Address 2' />
            <TextInput source='officeAddress.town' label='Town' validate={required()} />
            <TextInput source='officeAddress.postcode' label='Postcode' validate={required()} />
            <SelectInput source='officeAddress.state' choices={states} label='State' validate={required()} />
          </div>
        </div>
        <div className='address-block'>
          <p className='title'>Postal Address:</p>
          <div className='inputs'>
            <TextInput source='postalAddress.address1' label='Address 1' />
            <TextInput source='postalAddress.address2' label='Address 2' />
            <TextInput source='postalAddress.town' label='Town' validate={required()} />
            <TextInput source='postalAddress.postcode' label='Postcode' validate={required()} />
            <SelectInput source='postalAddress.state' choices={states} label='State' validate={required()} />
          </div>
        </div>
        <LongTextInput source='adminNotes' />
      </SimpleForm>
    </Create>
  )
}
