import React, { Component } from 'react'
import {
  GET_LIST,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  NumberField,
  CreateButton,
  CardActions,
  BooleanField
} from 'react-admin'
import MaterialTextField from '@material-ui/core/TextField'
import debounce from 'lodash/debounce'
import moment from 'moment'
import update from 'immutability-helper'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons/index'
import { Collapse } from 'react-collapse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import { OwnPagination } from '../../components/OwnPagination'
import './styles.css'
import config from '../../config'
import { dataProvider } from '../../App'
import socketService from '../../utils/socketConnection'
import { TimeField } from '../../utils/fields'

const OPEN = 'open'
const UPCOMING = 'upcoming'
const CLOSED = 'closed'

export class LotListComponent extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ids: [],
      data: {},
      total: 0,
      page: 1,
      perPage: config.perPage,
      field: 'liveAt',
      order: 'DESC',
      timerEnabled: true,
      search: ''
    }
    this.debounced = null

    this.timerId = null
    this.loadData = this.loadData.bind(this)
    this.setPerPage = this.setPerPage.bind(this)
    this.setPage = this.setPage.bind(this)
    this.setSort = this.setSort.bind(this)
    this.tickTock = this.tickTock.bind(this)
    this.clearTimer = this.clearTimer.bind(this)
    this.lotUpdate = this.lotUpdate.bind(this)
    this.setSearchString = this.setSearchString.bind(this)
    this.renderLotListActions = this.renderLotListActions.bind(this)

    if (this.props.type === 'live') {
      this.label = 'Live Lots'
      this.filter = { state: { $in: ['live', 'open'] } }
    } else if (this.props.type === 'upcoming') {
      this.label = 'Upcoming Lots'
      this.filter = { state: { $in: ['future'] } }
    } else if (this.props.type === 'closed') {
      this.label = 'Closed Lots'
      this.filter = { state: { $in: ['closed'] } }
    }
    if (this.props.type) {
      this.loadData()
    }
  }

  componentDidMount () {
    socketService.sockets['bids'].on('lot-update', this.lotUpdate)
  }

  async componentWillUnmount () {
    this.clearTimer()
    let data = Object.keys(this.state.data).map(auction => {
      return { auction }
    })
    this.leaveAuction(data)
  }

  clearTimer (cb) {
    this.setState({
      timerEnabled: false
    }, () => {
      clearTimeout(this.timerId)
      if (cb) {
        cb()
      }
    })
  }

  async tickTock () {
    if (this.state.timerEnabled) {
      await new Promise((resolve) => {
        this.timerId = setTimeout(() => {
          this.timerId = null
          resolve()
        }, 1000)
      })
      if (!this.state.timerEnabled || this.timerId !== null) {
        return
      }
      let data = {}
      Object.keys(this.state.data).forEach(key => {
        data[key] = {
          ...this.state.data[key],
          _leftTime: this.state.data[key]._leftTime - 1000
        }
      })
      this.setState({
        data
      }, this.tickTock)
    }
  }

  async leaveAuction (data) {
    try {
      let promisesArray = data
        .map(el => el.auction)
        .filter((el, index, arr) => arr.indexOf(el) === index)
        .map(auction => {
          return new Promise((resolve, reject) => {
            socketService.sockets.emit('bids', 'leave-auction', { _id: auction }, (err) => {
              if (err) {
                reject(err)
              } else {
                resolve()
              }
            })
          })
        })
      await Promise.all(promisesArray)
    } catch (error) {
      console.warn(error)
    }
  }

  lotUpdate (data) {
    if (!data || !data.doc) {
      return
    }
    if (this.state.data[data.doc._id]) {
      this.setState({
        data: update(this.state.data, {
          [data.doc._id]: {
            _leftTime: { $set: moment(data.doc.finishAt).diff() }
          }
        })
      })
    }
  }

  loadData () {
    let field
    if (this.state.field === '_currentBid') {
      field = 'currentBidCents'
    } else if (this.state.field === '_leftTime') {
      field = 'liveAt'
    } else if (this.state.field === '_reoffered') {
      field = 'reofferAttempts'
    } else if (this.state.field[0] === '_' && this.state.field !== '_id') {
      field = this.state.field.substring(1)
    } else {
      field = this.state.field
    }
    dataProvider(GET_LIST, 'admin-lots', {
      filter: this.filter,
      pagination: {
        page: this.state.page,
        perPage: this.state.perPage
      },
      sort: {
        field,
        order: this.state.order
      },
      search: this.state.search
    })
      .then(async ({ data, total }) => {
        if (this.props.type === 'live') {
          this.leaveAuction(data)
        }
        let newids = []
        let newdata = {}
        if (this.props.type === 'live') {
          try {
            let promisesArray = data
              .map(el => el.auction)
              .filter((el, index, arr) => arr.indexOf(el) === index)
              .map(auction => {
                return new Promise((resolve, reject) => {
                  socketService.sockets.emit('bids', 'join-auction', { _id: auction }, (err) => {
                    if (err) {
                      reject(err)
                    } else {
                      resolve()
                    }
                  })
                })
              })
            await Promise.all(promisesArray)
          } catch (error) {
            console.warn(error)
          }
        }
        for (let i = 0; i < data.length; i++) {
          let id = data[i].id
          newids.push(id)
          newdata[id] = {
            ...data[i],
            leftTime: data[i].leftTime
          }
        }
        this.clearTimer(() => {
          this.setState({
            ids: newids,
            data: newdata,
            total,
            timerEnabled: true
          }, () => {
            if (this.props.type === 'live') {
              this.tickTock()
            }
          })
        })
      })
  }

  setPerPage (perPage) {
    this.setState({
      perPage
    }, this.loadData)
  }

  setPage (page) {
    this.setState({
      page
    }, this.loadData)
  }

  setSort (field) {
    let order = 'ASC'
    if (this.state.field === field) {
      order = this.state.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      field,
      order
    }, this.loadData)
  }

  setSearchString ({ target: { value } }) {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.loadData, 300)
    this.setState({
      search: value
    }, this.debounced)
  }

  renderLotListActions ({ basePath }) {
    let total = this.state.total
    return (
      <CardActions>
        <div className='card-actions'>
          <b>{this.label}</b>
          <span>, {total || 0} {total === 1 ? 'lot' : 'lots'}</span>
        </div>
        <CreateButton basePath={basePath} className='hidden-create-button' />
        <MaterialTextField
          label='Search'
          placeholder='Search'
          className='search-text'
          onChange={this.setSearchString}
        />
      </CardActions>
    )
  }

  render () {
    const {
      type,
      basePath
    } = this.props
    const {
      ids,
      data
    } = this.state
    let filteredProps = { ...this.props }
    delete filteredProps.hasList
    delete filteredProps.hasShow
    delete filteredProps.hasEdit
    delete filteredProps.hasCreate
    const AuctionId = ({ source, record = {} }) => <a href={`/#/auctions/${record.auction}/show`} className='self-link'>{record.auctionData.searchNumber}</a>
    const LotId = ({ source, record = {} }) => <a href={`/#/lots/${record._id}/show`} className='self-link'>{record.searchNumericId}</a>
    const LotNumber = ({ source, record = {} }) => <a href={`/#/lots/${record._id}/show`} className='self-link'>{record.number}</a>
    const DescriptionField = ({ source, record = {} }) => <a href={`/#/lots/${record._id}/show`} className='self-link'>{record.description}</a>
    const CategoryField = ({ source, record = {} }) => <a href={`/#/kinds/${record.kind}/show`} className='vendor-link'>{record.kindData.title}</a>
    const AuctionDateField = ({ source, record = {} }) => <span>{record.auctionData.liveAt ? moment(record.auctionData.liveAt).format('D/M/YYYY') : ''}</span>
    const AuctionTimeField = ({ source, record = {} }) => <span>{record.auctionData.liveAt ? new Date(record.auctionData.liveAt).toLocaleTimeString() : ''}</span>
    const SellerField = ({ source, record = {} }) => <a href={`/#/users/${record.createdBy}/show`} className='vendor-link'>{`${record.creatorData.firstName} ${record.creatorData.lastName}`}</a>
    const WinnerField = ({ record = {} }) => {
      return <span className='vendor-link'>{record.winnerData && record.winnerData.firstName ? `${record.winnerData.firstName} ${record.winnerData.lastName}` : ''}</span>
    }
    const LotStatusField = ({ record }) => {
      const highlight = classNames('status-field', {
        green: record.reserveStatus === 'onMarket',
        white: record.reserveStatus === 'notNear' || record.status === 'awaitingBids',
        lime: record.reserveStatus === 'nearReserve'
      })
      return (
        <span className={highlight}>{record.reserveStatus.match(/[A-Z]*[^A-Z]+/g).map(word => word[0].toUpperCase() + word.slice(1)).join(' ')}</span>
      )
    }
    const LotClosedStatusField = ({ record }) => {
      const highlight = classNames('status-field', {
        sold: record.status === 'sold',
        passed: record.status === 'passed',
        noBid: record.status === 'noBid',
        withdrawn: record.status === 'withdrawn'
      })
      let statu = record.status
      if (statu === 'soldAfterAuction') {
        statu = 'Sold AA'
      }
      return (
        <span className={highlight}>{statu}</span>
      )
    }
    return (
      <div className='datagrid-wrapper lots'>
        {this.renderLotListActions({
          basePath: basePath
        })}
        <Datagrid
          ids={ids}
          data={data}
          currentSort={{ field: this.state.field, order: this.state.order }}
          setSort={this.setSort}
          {...filteredProps}
        >
          <LotId source='searchNumericId' label='Id' />
          <LotNumber source='number' label='Lot Number' />
          {type !== 'closed' && <DescriptionField source='description' label='Lot Description' sortable />}
          {type !== 'closed' && <AuctionId source='auctionData.id' label='Auction ID' />}
          {type === 'closed' && <TextField source='publicDetails.title' label='Title' sortable />}
          {type !== 'closed' && <AuctionLinkField source='auctionData.title' label='Auction' />}
          {(type === 'closed' || type === 'upcoming') && <AuctionDateField source='auctionData.liveAt' label='Auction Date' />}
          {(type === 'closed' || type === 'upcoming') && <AuctionTimeField source='auctionData.liveAt' label='Auction Time' />}
          {type === 'closed' && <CategoryField source='publicDetails.title' label='Category' sortable />}
          {type === 'closed' && <TextField source='count' label='Head' sortable />}
          <NumberField source='_startPrice' label='Start Price' />
          <NumberField source='_reserveCents' label='Reserve Price' />
          {((type === 'closed' || type === 'live') && type !== 'closed') && <LotStatusField source='status' />}
          {(type !== 'upcoming' && type !== 'closed') && <NumberField source='_currentBid' label='Current Price' />}
          {type === 'live' && <TimeField label='Time Left' source='_leftTime' sortable />}
          <TextField source='bidding' label='Bid Type' sortable />
          <NumberField source='_bidIncrementCents' label='Bid Increment' />
          {type === 'live' && <SellerField source='creatorData' label='Seller' sortable />}
          {type === 'upcoming' && <BooleanField source='_pending' sortable />}
          {type === 'closed' && <BooleanField source='draft' sortable />}
          {(type === 'upcoming' || type === 'closed') && <BooleanField source='approved' sortable />}
          {(type === 'upcoming' || type === 'closed') && <BooleanField label='Reoffered' source='reoffered' sortable />}
          {(type === 'upcoming' || type === 'closed') && <SellerField source='creatorData' label='Seller' sortable />}
          {type === 'closed' && <LotClosedStatusField label='Closed Status' source='status' />}
          {type === 'closed' && <TextField label='Closed Price' source='_closedPrice' />}
          {type === 'closed' && <WinnerField source='winnerData' label='Buyer/Losing bidder(passed lots)' />}
          <EditButton />
          <ShowButton />
        </Datagrid>
        <OwnPagination
          page={this.state.page}
          rowsPerPage={this.state.perPage}
          perPage={this.state.perPage}
          total={this.state.total}
          setPerPage={this.setPerPage}
          setPage={this.setPage}
        />
      </div>
    )
  }
}

export class LotList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: {
        isOpened: false
      },
      upcoming: {
        isOpened: false
      },
      closed: {
        isOpened: false
      }
    }

    this.collapsible = this.collapsible.bind(this)
    this.changeCollapse = this.changeCollapse.bind(this)
  }

  collapsible ({ title, isOpened, changeCollapse, publicId, body }) {
    return (
      <div className='collapse-wrapper'>
        <div
          className='header'
          onClick={isOpened ? () => (changeCollapse(publicId, false)) : () => (changeCollapse(publicId, true))}
        >
          <p className='title'>
            {title}
          </p>
          <div
            className='button'
          >
            <FontAwesomeIcon icon={isOpened ? faAngleUp : faAngleDown} />
            <span>{isOpened ? 'Hide' : 'Show'}</span>
          </div>
        </div>
        <Collapse isOpened={isOpened}>
          <div className='body'>
            {body}
          </div>
        </Collapse>
      </div>
    )
  }

  changeCollapse (name, state) {
    switch (name) {
      case OPEN:
        this.setState({
          open: update(this.state.open, {
            isOpened: { $set: state }
          })
        })
        break
      case UPCOMING:
        this.setState({
          upcoming: update(this.state.upcoming, {
            isOpened: { $set: state }
          })
        })
        break
      case CLOSED:
        this.setState({
          closed: update(this.state.closed, {
            isOpened: { $set: state }
          })
        })
        break
      default:
        break
    }
  }

  render () {
    return (
      <div className='lists-wrapper'>
        <p className='page-title'>Lots</p>
        <React.Fragment>
          {this.collapsible({
            title: 'Live Lots',
            isOpened: this.state.open.isOpened,
            changeCollapse: this.changeCollapse,
            publicId: OPEN,
            body: <LotListComponent {...this.props} type='live' resource='lots' />

          })}
        </React.Fragment>
        <React.Fragment>
          {this.collapsible({
            title: 'Upcoming Lots',
            isOpened: this.state.upcoming.isOpened,
            changeCollapse: this.changeCollapse,
            publicId: UPCOMING,
            body: <LotListComponent {...this.props} type='upcoming' resource='lots' />
          })}
        </React.Fragment>
        <React.Fragment>
          {this.collapsible({
            title: 'Closed Lots',
            isOpened: this.state.closed.isOpened,
            changeCollapse: this.changeCollapse,
            publicId: CLOSED,
            body: <LotListComponent {...this.props} type='closed' resource='lots' />
          })}
        </React.Fragment>
      </div>
    )
  }
}

const AuctionLinkField = ({ record = {} }) => <a href={`/#/auctions/${record.auction}/show`} className='vendor-link'>{record.auctionData.title}</a>
