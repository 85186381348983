import React, { useEffect, useState, useCallback } from 'react'
import Select, { components } from 'react-select'
import axios from 'axios'
import debounce from 'lodash/debounce'
import DeleteIcon from '@material-ui/icons/ArrowDropDown'
import MaterialTextField from '@material-ui/core/TextField'

import './styles.scss'

const Input = (props) => {
  return (
    <MaterialTextField
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onChange={props.onChange}
      value={props.value}
      label='User' />
  )
}

export const UserAutoCompleteField = ({ saveUser, chosenUser }) => {
  const [users, setUsers] = useState(null)
  const [filter, setFilter] = useState('')
  const [userLoading, setUserLoading] = useState(false)
  const [inputDefaultValue, setInputDefaultValue] = useState(chosenUser?.label || '')

  useEffect(() => {
    if (users?.length && chosenUser) {
      setInputDefaultValue(chosenUser.label)
    }
  }, [chosenUser, users])

  const updateUsers = useCallback(
    debounce((filter) => {
      let url = `/users?limit=25&page=1&sortDirection=-1&sortField=id`
      if (filter) {
        url = url + `&filters=${encodeURIComponent(`{ "q":"${filter}`)}&search=${encodeURIComponent(filter)}`
      }
      setUserLoading(true)
      axios.get(url).then((res) => {
        setUserLoading(false)
        let newUsers = res.data.users.map(
          (user) => ({
            value: user._id,
            label: `U${user.numericId} ${user.firstName} ${user.lastName}`
          })
        )
        setUsers(newUsers)
      })
    }, 300),
    []
  )

  useEffect(() => {
    updateUsers(filter)
  }, [filter, updateUsers])

  const onSelectChange = (newValue) => {
    saveUser(newValue)
  }

  const onInputChange = (newValue) => {
    setFilter(newValue || null)
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <DeleteIcon />
      </components.DropdownIndicator>
    )
  }

  const Placeholder = props => {
    return <components.Placeholder {...props} />
  }

  return (
    <Select
      components={{ DropdownIndicator, Input, Placeholder }}
      placeholder={false}
      onChange={onSelectChange}
      onInputChange={onInputChange}
      defaultInputValue={inputDefaultValue}
      className='user-auto-complete '
      classNamePrefix='user-select'
      isLoading={userLoading}
      isClearable
      isSearchable
      name='color'
      options={users}
    />
  )
}
