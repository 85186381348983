import React, { Component } from 'react'
import {
  GET_LIST,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  ReferenceField,
  NumberField,
  CreateButton,
  CardActions
} from 'react-admin'
import MaterialTextField from '@material-ui/core/TextField'
import debounce from 'lodash/debounce'
import moment from 'moment'
import update from 'immutability-helper'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons/index'
import { Collapse } from 'react-collapse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { OwnPagination } from '../../components/OwnPagination'
import config from '../../config'
import './styles.css'
import { dataProvider } from '../../App'

export const AuctionTitle = ({ record = {} }) => {
  return <span>Auction {record ? `'${record.searchNumber}'` : ''}</span>
}

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const OPEN = 'open'
const UPCOMING = 'upcoming'
const CLOSED = 'closed'

class AuctionListComponent extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ids: [],
      data: {},
      total: 0,
      page: 1,
      perPage: config.perPage,
      field: 'liveAt',
      order: 'ASC',
      search: ''
    }
    this.debounced = null

    this.loadData = this.loadData.bind(this)
    this.setPerPage = this.setPerPage.bind(this)
    this.setPage = this.setPage.bind(this)
    this.setSort = this.setSort.bind(this)
    this.setSearchString = this.setSearchString.bind(this)
    this.renderAuctionsListActions = this.renderAuctionsListActions.bind(this)

    this.type = ''

    if (this.props.type === 'live') {
      this.label = 'Open/Live Auctions'
      this.type = ['open', 'live']
    } else if (this.props.type === 'future') {
      this.label = 'Upcoming Auctions'
      this.type = 'future'
    } else if (this.props.type === 'closed') {
      this.label = 'Closed Auctions'
      this.type = 'closed'
    }
    if (this.props.type) {
      this.loadData()
    }
  }

  async loadData () {
    let field
    if (this.state.field === 'openTime') {
      field = 'openAt'
    } else if (this.state.field === 'liveTime') {
      field = 'liveAt'
    } else {
      field = this.state.field
    }
    if (this.type === 'closed') {
      field = 'closedAt'
    }
    dataProvider(GET_LIST, this.props.resource, {
      pagination: {
        page: this.state.page,
        perPage: this.state.perPage
      },
      sort: {
        field,
        order: this.type === 'closed' ? 'DESC' : this.state.order
      },
      state: this.type,
      search: this.state.search,
      isAdminSite: true
    })
      .then(({ data, total }) => {
        let newids = []
        let newdata = {}
        for (let i = 0; i < data.length; i++) {
          let id = data[i].id
          newids.push(id)
          newdata[id] = {
            ...data[i],
            leftTime: data[i].leftTime
          }
        }
        this.setState({
          ids: newids,
          data: newdata,
          total
        })
      })
  }

  setPerPage (perPage) {
    this.setState({
      perPage
    }, this.loadData)
  }

  setPage (page) {
    this.setState({
      page
    }, this.loadData)
  }

  setSort (field) {
    let order = 'ASC'
    if (this.state.field === field) {
      order = this.state.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      field,
      order
    }, this.loadData)
  }

  setSearchString ({ target: { value } }) {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.loadData, 300)
    this.setState({
      search: value
    }, this.debounced)
  }

  renderAuctionsListActions ({ basePath }) {
    return (
      <CardActions>
        <div className='card-actions'>
          <b>{this.label}</b>
        </div>
        <MaterialTextField
          label='Search'
          placeholder='Search'
          className='search-text'
          onChange={this.setSearchString}
        />
        <CreateButton basePath={basePath} />
      </CardActions>
    )
  }

  render () {
    const {
      type,
      basePath
    } = this.props
    const {
      ids,
      data
    } = this.state
    let filteredProps = { ...this.props }
    delete filteredProps.hasList
    delete filteredProps.hasShow
    delete filteredProps.hasEdit
    const AuctionSwap = ({ source, record = {} }) => {
      return (
        <div>
          {
            record.auctions.map((auction, index) => {
              return <div key={index}><a href={`/#/auctions/${auction._id}/show`}>{auction.title}</a></div>
            })
          }
        </div>
      )
    }
    const AuctionId = ({ source, record = {} }) => {
      let isCancelled = record.cancelled
      return (
        <a href={`/#/auctions/${record._id}/show`} className={'self-link'.concat(isCancelled ? ' cancelled' : '')}>
          {record.searchNumber}
        </a>
      )
    }
    const KindField = ({ source, record = {} }) => {
      return (
        <a href={`/#/kinds/${record.kind}/show`} className='vendor-link'>
          {record.kindData.title}
        </a>
      )
    }
    const DateFieldWithDay = ({ source, record = {} }) => {
      return (
        <div>
          <p>{days[new Date(record[source]).getDay()]}</p>
          <p>{moment(record[source]).format('D/MM/YYYY, hh:mm:ss A')}</p>
        </div>
      )
    }
    const AuctionTitleLink = ({ record = {} }) => <a href={`/#/auctions/${record._id}/show`} className='self-link'>{record.title}</a>
    return (
      <div className='datagrid-wrapper auctions'>
        {this.renderAuctionsListActions({
          basePath: basePath
        })}
        <Datagrid
          ids={ids}
          data={data}
          currentSort={{ field: this.state.field, order: this.state.order }}
          setSort={this.setSort}
          {...filteredProps}
        >
          <AuctionId source='searchNumber' label='Auction ID' sortable />
          <AuctionTitleLink source='title' label='Title' sortable />
          <KindField source='kindData.title' label='Category' />
          {type === 'live' && <TextField source='_state' label='Status' sortable />}
          <DateFieldWithDay source='liveAt' label='Live At' showTime sortable />
          <NumberField source='lotCount' label='Approved Lot Count' sortable />
          {type === 'live' && <NumberField source='lotsOpen' label='Lots Open' sortable />}
          {type === 'live' && <NumberField source='lotsClosed' label='Lots Closed' sortable />}
          {(type === 'live' || type === 'future') && <DateFieldWithDay source='openAt' label='Open At' showTime sortable />}
          {type === 'live' && <TextField source='openTime' label='Open Time' sortable />}
          {type === 'closed' && <NumberField source='resultTable.total.numberOfLots.sold' label='Lots Sold' sortable />}
          {type === 'closed' && <NumberField source='resultTable.total.numberOfLots.passed' label='Lots Passed in' sortable />}
          {type === 'closed' && <NumberField source='resultTable.total.numberOfLots.noBid' label='No Bid' sortable />}
          {type === 'closed' && <NumberField source='resultTable.total.numberOfLots.withdrawn' label='Withdrawn' sortable />}
          {type === 'closed' && <NumberField source='resultTable.total.numberOfLots.reoffered.total' label='Reoffered' sortable />}
          {type === 'future' &&
          <ReferenceField source='tag' reference='tags' label='Auction Swap' linkType={false}>
            <AuctionSwap />
          </ReferenceField>
          }
          {type === 'live' && <TextField source='liveTime' label='Live Time' sortable />}
          <EditButton />
          <ShowButton />
        </Datagrid>
        <OwnPagination
          page={this.state.page}
          rowsPerPage={this.state.perPage}
          perPage={this.state.perPage}
          total={this.state.total}
          setPerPage={this.setPerPage}
          setPage={this.setPage}
        />
      </div>
    )
  }
}

/* ** */

export class AuctionList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: {
        isOpened: false
      },
      upcoming: {
        isOpened: false
      },
      closed: {
        isOpened: false
      }
    }

    this.collapsible = this.collapsible.bind(this)
    this.changeCollapse = this.changeCollapse.bind(this)
  }

  collapsible ({ title, isOpened, changeCollapse, publicId, body }) {
    return (
      <div className='collapse-wrapper'>
        <div
          className='header'
          onClick={isOpened ? () => (changeCollapse(publicId, false)) : () => (changeCollapse(publicId, true))}
        >
          <p className='title'>
            {title}
          </p>
          <div
            className='button'
          >
            <FontAwesomeIcon icon={isOpened ? faAngleUp : faAngleDown} />
            <span>{isOpened ? 'Hide' : 'Show'}</span>
          </div>
        </div>
        <Collapse isOpened={isOpened}>
          <div className='body'>
            {body}
          </div>
        </Collapse>
      </div>
    )
  }

  changeCollapse (name, state) {
    switch (name) {
      case OPEN:
        this.setState({
          open: update(this.state.open, {
            isOpened: { $set: state }
          })
        })
        break
      case UPCOMING:
        this.setState({
          upcoming: update(this.state.upcoming, {
            isOpened: { $set: state }
          })
        })
        break
      case CLOSED:
        this.setState({
          closed: update(this.state.closed, {
            isOpened: { $set: state }
          })
        })
        break
      default:
        break
    }
  }

  render () {
    return (
      <div className='lists-wrapper'>
        <p className='page-title'>Auctions</p>
        <React.Fragment>
          {this.collapsible({
            title: 'Open/Live Auctions',
            isOpened: this.state.open.isOpened,
            changeCollapse: this.changeCollapse,
            publicId: OPEN,
            body: <AuctionListComponent {...this.props} type='live' resource='auctions' />

          })}
        </React.Fragment>
        <React.Fragment>
          {this.collapsible({
            title: 'Upcoming Auctions',
            isOpened: this.state.upcoming.isOpened,
            changeCollapse: this.changeCollapse,
            publicId: UPCOMING,
            body: <AuctionListComponent {...this.props} type='future' resource='auctions' />

          })}
        </React.Fragment>
        <React.Fragment>
          {this.collapsible({
            title: 'Closed Auctions',
            isOpened: this.state.closed.isOpened,
            changeCollapse: this.changeCollapse,
            publicId: CLOSED,
            body: <AuctionListComponent {...this.props} type='closed' resource='auctions' />

          })}
        </React.Fragment>
      </div>
    )
  }
}
