import React, { Component } from 'react'
import classNames from 'classnames'
import { makePrice } from '../../../../utils'
import { GET_LIST, Datagrid, TextField, NumberField, BooleanField, ReferenceField } from 'react-admin'
import update from 'immutability-helper'
import { dataProvider } from '../../../../App'
import socketService from '../../../../utils/socketConnection'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import NewWindow from 'react-new-window'

import './ActiveLotTable.styles.scss'
import '../../../../models/auctions/show/showStyles.scss'

export class ActiveLotTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      lots: {
        data: {},
        isOpen: false,
        field: '_id'
      },
      lastNumber: 1,
      warningLastLot: false,
      activeLot: null,
      notification: props.auction.notification || '',
      price: null,
      messageOptions: [],
      messageNotificationBlock: props.auction.notification ? 'The notification is activated' : '',
      auctionState: props._state,
      consecutiveLots: []
    }
    this.debounced = null
    this.loadMessageOptions()
  }

  componentDidMount = () => {
    this.connect()
    if (!this.state.loaded) {
      this.setState({ loaded: true })
      this.loadData()
    }
  }

  componentDidUpdate = () => {
    if (!this.state.loaded) {
      this.setState({ loaded: true })
      this.loadData()
    }
  }

  connect = () => {
    if (document.visibilityState === 'visible') {
      this.isSubscribed = true
      socketService.sockets['bids'].on('lot-update', this.lotBySocket)
      socketService.sockets['bids'].on('change-live', this.changeActiveLot)
      socketService.sockets['catalog'].on('auction', this.auctionUpdate)
    } else {
      setTimeout(this.connect, 2000)
    }
  }

  loadMessageOptions = async () => {
    axios.get('/notification/config').then((res) => {
      this.setState({
        ...this.state,
        messageOptions: res.data.notifications.map(not => ({ name: not.comment, value: not.comment }))
      })
    })
  }

  loadData = async () => {
    socketService.sockets.emit('bids', 'join-auction', { _id: this.props.auction.id }, (err) => {
      if (err) {
        setTimeout(this.loadData, 2000)
        console.warn(err)
      } else {
        console.log('join-auction was sent')
      }
    })
    dataProvider(GET_LIST, `auctions/${this.props.auction.id}/lots`, {
      filter: this.filter,
      pagination: {
        page: this.state.lots.page,
        perPage: this.state.lots.perPage
      },
      sort: {
        field: this.state.field,
        order: this.state.order
      },
      search: this.state.search,
      auction: true,
      auctionLots: true,
      fromAdmin: true
    })
      .then(({ data, total }) => {
        this.setConsecutiveLots(data)
        let newIds = []
        let newData = {}
        let lot = data.find(data => data.state === 'live')
        let lastNumber = Math.max(...data.map(lot => lot.number), 0)
        if (!lot) {
          console.log('No active lots')
        } else {
          newIds.push(lot.id)
          newData[lot.id] = {
            ...lot,
            leftTime: lot.leftTime
          }
          this.setState({
            lots: update(this.state.lots, {
              data: {
                $set: newData
              }
            }),
            activeLot: lot,
            lastNumber: lastNumber,
            warningLastLot: lot.number === lastNumber
          })
        }
      }).catch(err => {
        console.warn(err)
      })
  }

  getConsecutiveLots = () => {
    dataProvider(GET_LIST, `auctions/${this.props.auction.id}/lots`, {
      filter: this.filter,
      pagination: {
        page: this.state.lots.page,
        perPage: this.state.lots.perPage
      },
      sort: {
        field: this.state.field,
        order: this.state.order
      },
      search: this.state.search,
      auction: true,
      auctionLots: true,
      fromAdmin: true
    })
      .then(({ data, total }) => {
        this.setConsecutiveLots(data)
      }).catch(err => {
        console.warn(err)
      })
  }

  setConsecutiveLots = (data) => {
    let liveLot = data.find(data => data.state === 'live')
    if (liveLot) {
      this.setState({
        activeLot: liveLot,
        consecutiveLots: data.filter(item => (item.number === liveLot.number - 1 || item.number === liveLot.number + 1))
      })
    }
  }

  lotBySocket = (data) => {
    if (!data || !data.doc) {
      this.getConsecutiveLots()
      return
    }
    if (this.state.activeLot && this.state.activeLot._id === data.doc._id) {
      let lot = Object.assign({}, this.state.activeLot)
      if (data) {
        if (data.doc.currentBidCents) {
          lot.currentBidCents = data.doc.currentBidCents
          lot._currentPrice = makePrice(data.doc.currentBidCents, { bidding: lot.bidding })
        }
        if (data.doc.reserveStatus) {
          lot.reserveStatus = data.doc.reserveStatus
        }
        if (data.doc.state) {
          lot.state = data.doc.state
        }
        if (data.user) {
          if (lot.winnerData) {
            lot.winnerData.winner = data.user
          } else {
            lot.winnerUser = data.user
          }
        }
        if (data.doc.startPrice) {
          lot.startPrice = data.doc.startPrice
        }
        if (data.doc.reserveCents) {
          lot.reserveCents = data.doc.reserveCents
        }
        if (data.doc.hasOwnProperty('pausedTime')) {
          lot.pausedTime = data.doc.pausedTime
        }
        if (data.doc.hasOwnProperty('frozenTime')) {
          lot.frozenTime = data.doc.frozenTime
        }
      }
      this.setState({
        activeLot: lot
      })
      this.getConsecutiveLots()
      let newData = {}
      newData[this.state.activeLot.id] = {
        ...lot,
        leftTime: data.doc.leftTime
      }
      this.setState({
        lots: update(this.state.lots, {
          data: {
            $set: newData
          }
        })
      })
    }
  }

  changeActiveLot = async (data) => {
    if (data.doc) {
      if (data.doc.winner && !(data.doc.winnerUser || data.doc.winnerData.winner)) {
        const response = await axios.get(`users/${data.doc.winner}`)
        if (response.data.user) {
          data.doc.winnerData.winner = response.data.user
        }
      }
      let newIds = []
      let newData = {}
      newIds.push(data.doc._id)
      newData[data.doc._id] = {
        ...data.doc,
        leftTime: data.doc.leftTime
      }
      this.setState({
        lots: update(this.state.lots, {
          data: {
            $set: newData
          }
        }),
        activeLot: data.doc,
        warningLastLot: data.doc.number === this.state.lastNumber
      })
      this.getConsecutiveLots()
    }
  }

  auctionUpdate = async (data) => {
    this.setState({
      auctionState: data.doc.state
    })
  }

  getBidButton = (x, lot) => {
    return (
      <Button key={`button-bid-${x}`} variant='outlined' color='primary'
        className={'bid-positive'} disabled={lot.frozenTime || (Math.sign(x) === -1 && lot.highestAutoBidder && lot.highestAutoBidder.autoBidsCount > 0)}
        onClick={() => {
          this.placeBid(this.state.activeLot.currentBidCents + x * lot.bidIncrementCents)
        }}>
        <p>{makePrice(x * lot.bidIncrementCents, { bidding: lot.bidding || 'head' })}</p>
        <p>{makePrice(lot.currentBidCents + x * lot.bidIncrementCents, { bidding: lot.bidding || 'head' })}</p>
      </Button>
    )
  }

  placeBid = (amount) => {
    socketService.sockets.emit('bids', 'bid', {
      bid: {
        lot: this.state.activeLot._id,
        cents: amount,
        isAdminBid: true,
        shouldAllowLowerPrice: true,
        userId: window.sessionStorage.getItem('userId')
      }
    }, (callBack) => {
      if (callBack === true) {
        // this.loadData()
      }

    })
  }

  handleNotification = ({ target: { value } }) => {
    this.setState({
      notification: value
    })
  }

  handlePrice = ({ target: { value } }) => {
    this.setState({
      price: value
    })
  }

  async publishNotification (lot) {
    let { notification } = this.state
    if (lot && notification) {
      await axios(`auctions/${lot.auction}/publish-notification`, {
        method: 'put',
        data: {
          notification: notification
        }
      }).then(() => {
        this.setState({ messageNotificationBlock: 'The notification is activated' })
      })
    }
  }

  async stopNotification (lot) {
    if (lot) {
      await axios(`auctions/${lot.auction}/stop-notification`, {
        method: 'put'
      }).then(() => {
        this.setState({ notification: '', messageNotificationBlock: 'The notification is stopped' })
      })
    }
  }

  async changePrice (lot, amount) {
    if (!lot) {
      lot = this.state.activeLot
    }
    if (lot && amount) {
      await axios.put(`lots/${lot._id}/changePrice`, {
        price: amount
      })
    }
  }

  lotsRender () {
    let { data } = this.state.lots
    let { activeLot, price, warningLastLot } = this.state
    let ids = Object.keys(data)
    const rowStyle = (record) => ({
      backgroundColor: record.removed ? '#e8553db8' : ''
    })
    let winner = activeLot ? ((activeLot.winnerUser && activeLot.winnerUser.role === 'admin') || (activeLot.winnerData && activeLot.winnerData.winner && activeLot.winnerData.winner.role === 'admin')) ? 'admin' : ((activeLot.winnerUser && activeLot.winnerUser.role === 'user') || (activeLot.winnerData && activeLot.winnerData.winner && activeLot.winnerData.winner.role === 'user')) ? 'user' : 'no-bid' : ''

    const LotClosedStatusField = ({ record }) => {
      const highlight = classNames('status-field', {
        sold: record.status === 'sold',
        passed: record.status === 'passed',
        noBid: record.status === 'noBid',
        withdrawn: record.status === 'withdrawn'
      })
      let status = record.status
      if (status === 'soldAfterAuction') {
        status = 'Sold AA'
      }
      return (
        <span className={highlight}>{status}</span>
      )
    }
    const ReserveStatusField = ({ source, record = {} }) => {
      switch (record.state) {
        case 'future':
          return (
            <span> Upcoming </span>
          )
        case 'futureInLive':
          return (
            <span> Upcoming </span>
          )
        case 'open':
          return (
            <span> Upcoming </span>
          )
        case 'live':
          return (
            <span> Live </span>
          )
        default:
          return null
      }
    }
    const LotId = ({ record = {} }) => <p>{record.searchNumericId}</p>
    const AuctionLinkField = ({ record = {} }) => <p>{record.auctionData.title}</p>
    const DescriptionLinkField = ({ record = {} }) => <p>{record.publicDetails.title}</p>
    const NumberLink = ({ record = {} }) => <p>{record.number}</p>
    const SellerField = ({ record = {} }) => <p>{record.creatorData.firstName} {record.creatorData.lastName}</p>
    const WinnerField = ({ record = {} }) => {
      return (record &&
        <p>{`${record.firstName} ${record.lastName}`}</p>
      )
    }
    const ReofferedField = ({ record = {} }) => {
      record._reoffered = record.reoffered || !!record.copyFrom
      return (
        <BooleanField record={record} source='_reoffered' sortable />
      )
    }
    const BidIncrementField = ({ record = {} }) => {
      return (
        <p>{makePrice(record.bidIncrementCents, { bidding: record.bidding })}</p>
      )
    }
    const StatusField = ({ record = {} }) => {
      if (record.auctionData.closedAt) {
        return <LotClosedStatusField record={record} />
      } else {
        return <ReserveStatusField record={record} />
      }
    }
    const BuyerField = ({ record = {} }) => {
      return (
        <ReferenceField record={record} source='winnerData.winner._id' reference='users' label='Buyer/ Losing bidder(passed lots)' basePath='users' allowEmpty linkType={false} sortable={false}>
          <WinnerField />
        </ReferenceField>
      )
    }
    const StartPrice = ({ record = {} }) => {
      return makePrice(this.state.activeLot.startPrice, { bidding: this.state.activeLot.bidding || 'head' })
    }

    const BidButtons = (lot) => [-10, -5, -2, -1, 1, 2, 5, 10].map(x => this.getBidButton(x, lot.lot))

    const closeOnClick = () => {
      this.props.changeWindowModal({ showActiveLot: false })
    }
    
    return (
      <div className='auction-details'>
        {warningLastLot && <div className={'current-bidder-user'}>
          <span className='current-bidder'>{('This is the last lot in the auction')}</span>
        </div>}
        {activeLot && <div>
          <div className='row-buttons'>
            <Button className={'liveLotButton'} disabled={activeLot.number === 1 } onClick={async () => { 
              let lotNumber = activeLot.number
              let auctionId = activeLot.auction
              let data = {
                lotNumber,
                auctionId
              }
              if(activeLot.number === 1) {
                  console.log('There is no Previous Lot');
              } else {
                 await axios(`lots/${activeLot._id}/openPreviousLot`, { method: 'put', data })
                 .then((res) => {
                  if(res.status === 200) {
                    this.getConsecutiveLots()
                    let prevLot = res.data.previousLot
                    let prevLotData = {} 
                    prevLotData[prevLot.id] = {
                      ...prevLot,
                      leftTime: prevLot.leftTime
                    }
                    this.setState({
                      lots: update(this.state.lots, {
                        data: {
                          $set: prevLotData
                        }
                      }),
                      activeLot: prevLot,
                      warningLastLot: res.data.lastLot
                  })
                  } 
                 }).catch((error) => {
                  window.alert('Previous lot was withdrawn, Kindly reopen manually!', 'error') 
                 })
              }
            }}>Open Previous Lot</Button>

            <Button className={activeLot.pausedTime ? 'liveLotButton1' : 'liveLotButton'} onClick={async () => {
              await axios(`lots/${activeLot._id}/pause`, { method: 'put' })
            }}>Pause lot</Button>
            <Button className={activeLot.frozenTime ? 'liveLotButton1' : 'liveLotButton'} onClick={async () => {
              await axios(`lots/${activeLot._id}/freeze`, {
                method: 'put'
              })
            }}>Freeze lot</Button>
            <Button variant='outlined' className='liveLotButton' onClick={async () => {
              await axios(`lots/${activeLot._id}/resume`, {
                method: 'put'
              })
            }}>Resume lot</Button>
            <Button variant='outlined' className='liveLotButton' disabled={activeLot.pausedTime || activeLot.frozenTime} onClick={async () => {
              if (activeLot.pausedTime || activeLot.frozenTime) {
                console.log('noooooo')
                // window.alert('It is not possible to close paused or frozen lot. Please resume and then close the lot.')
              } else {
                await axios.put(`/lots/${activeLot._id}/closePassed`)
                .then((res) => {
                  if(res.status === 200) {
                    this.getConsecutiveLots()
                    let nextLot = res.data.upcomingLot
                    let nextLotData = {} 
                    nextLotData[nextLot.id] = {
                      ...nextLot,
                      leftTime: nextLot.leftTime
                    }
                    this.setState({
                      lots: update(this.state.lots, {
                        data: {
                          $set: nextLotData
                        }
                      }),
                      activeLot: nextLot,
                      warningLastLot: res.data.lastLot
                  })
                  }
                }).catch((err) => {
                  console.log(err, 'error');
                })
              }
            }}>Close Passed In</Button>
            <Button variant='outlined' className='liveLotButton' disabled={activeLot.pausedTime || activeLot.frozenTime} onClick={async () => {
              if (activeLot.pausedTime || activeLot.frozenTime) {
                console.log('noooooo')
                // window.alert('It is not possible to close paused or frozen lot. Please resume and then close the lot.')
              } else {
                await axios.put(`/lots/${activeLot._id}/closeSold`)
                .then((res) => {
                  if(res.status === 200) {
                    this.getConsecutiveLots()
                    let nextLot = res.data.upcomingLot
                    let nextLotData = {} 
                    nextLotData[nextLot.id] = {
                      ...nextLot,
                      leftTime: nextLot.leftTime
                    }
                    this.setState({
                      lots: update(this.state.lots, {
                        data: {
                          $set: nextLotData
                        }
                      }),
                      activeLot: nextLot,
                      warningLastLot: res.data.lastLot
                  })
                  }
                }).catch((err) => {
                  console.log(err, 'error');
                })
              }
            }}>Close Sold</Button>
          </div>
          <div className='lotInfoSpan'>
            <span>{'Lot ' + activeLot.number}</span>
            <span>{activeLot.searchNumericId}</span>
          </div>
          <div className='lotInfoSpan'>
            <span>{activeLot.description}</span>
          </div>
          <div className='table-margin' style={{ marginLeft: '5px', marginRight: '5px' }}>
            <div className='auction-info-wrapper info-table-div-wrapper'>
              <Datagrid
                ids={ids}
                data={data}
                currentSort={{}}
                rowStyle={rowStyle}
              >
                <AuctionLinkField source='auctionData.title' label='Auction' sortable={false} />
                <LotId source='_id' label='Lot ID' sortable={false} />
                <NumberLink source='number' label='Lot No' sortable={false} />
                <DescriptionLinkField source='description' label='Lot Description' sortable={false} />
                {!activeLot && <TextField source='_startPrice' label='Start Price' sortable={false} />}
                {activeLot && <StartPrice label='Start Price' />}
                <NumberField source='bidding' label='Bid Type' sortable={false} />
                <BidIncrementField source='bidIncrementCents' label='Bid Increment' sortable={false} />
                <ReofferedField label='Reoffered' sortable />
                <SellerField label='Seller' sortable={false} />
                <StatusField label='Status' />
                {this.state.auctionState !== 'closed' &&
                  <TextField source='_currentPrice' label='Current Price' sortable={false} />}
                <TextField source='_closedPrice' label='Closed Price' sortable={false} />
                <BuyerField label='Buyer/ Losing bidder(passed lots)' />
              </Datagrid>
            </div>
          </div>
          <div className='price'>
            <span className='current-bid'>{makePrice(activeLot.currentBidCents, { bidding: activeLot.bidding || 'head' })}</span>
          </div>
          <div className='bid-btns'>
            <BidButtons lot={activeLot} />
          </div>
          <div className={winner === 'admin' ? 'current-bidder-admin' : winner === 'user' ? 'current-bidder-user' : 'no-bid'}>
            <span className='current-bidder'>{(activeLot.winnerData && activeLot.winnerData.winner) ? activeLot.winnerData.winner.firstName.concat(' ', activeLot.winnerData.winner.lastName) : (activeLot.winnerUser ? activeLot.winnerUser.firstName.concat(' ', activeLot.winnerUser.lastName) : 'No bid')}</span>
          </div>
          <InputLabel id='form-control-message-select-select-label' style={{ marginLeft: '5px' }}>Price</InputLabel>
          <div className='set-bid'>
            <input className='bid-div1' type='number' onChange={this.handlePrice} />
            <div style={{ width: '25%', marginRight: '5px', marginLeft: '5px' }} />
            <Button className='bid-div3' disabled={activeLot.frozenTime} onClick={() => { this.changePrice(activeLot, price) }}>Set Price</Button>
          </div>
          <InputLabel id='form-control-message-select-select-label' style={{ marginLeft: '5px' }}>Select message</InputLabel>
          <div className='set-bid message'>
            <FormControl className='form-control-message-select'>
              <Select
                inputProps={{ className: 'message-select-input' }}
                MenuProps={{ id: 'message-select-menu', classes: { paper: 'message-select-paper' } }}
                id='message-select'
                className='message-select'
                onChange={this.handleNotification}
                value={this.state.notification}
                style={{ borderBottom: '1px solid' }}
                labelId='message-select'
              >
                {this.state.messageOptions.map((option, index) => {
                  return (
                    <MenuItem className='message-select-item' key={index} value={option.value}>{option.name}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>

            <Button className={`bid-div2 start-notification ${this.state.messageNotificationBlock === 'The notification is activated' ? 'active' : ''}`} onClick={() => this.publishNotification(activeLot)} >Start Notification</Button>
            <Button className='bid-div3' onClick={() => this.stopNotification(activeLot)} >Stop Notification</Button>
          </div>
          {this.state.messageNotificationBlock &&
            <div className={`message-notification ${this.state.messageNotificationBlock === 'The notification is activated' ? 'green' : 'red'}`}>
              {this.state.messageNotificationBlock}
            </div>
          }
          <div className='autoBidSpan'>
            <span>{'Number of Autobids Set: ' + (activeLot.highestAutoBidder && activeLot.highestAutoBidder.autoBidsCount ? activeLot.highestAutoBidder.autoBidsCount : 0)}</span>
           {/* <span>{'Highest Autobid: ' + `${(activeLot.highestAutoBidder && activeLot.highestAutoBidder.cents ? makePrice(activeLot.highestAutoBidder.cents, { bidding: activeLot.bidding }) + (' ' + activeLot.highestAutoBidder.firstName + ' ' + activeLot.highestAutoBidder.lastName) : 0)}`}</span> */}
           {/* warning fixes */}
           <span>{'Highest Autobid: ' + (activeLot.highestAutoBidder && activeLot.highestAutoBidder.cents ? makePrice(activeLot.highestAutoBidder.cents, { bidding: activeLot.bidding }) + (' ' + activeLot.highestAutoBidder.firstName  + ' ' + activeLot.highestAutoBidder.lastName) : 0)}</span>  

          </div>
          {this.state.consecutiveLots.length > 0 &&
            <div className='table-margin' style={{ marginLeft: '5px', marginRight: '5px' }}>
              <div className='auction-info-wrapper info-table-div-wrapper'>
                <Datagrid
                  ids={Object.keys(this.state.consecutiveLots)}
                  data={this.state.consecutiveLots}
                  currentSort={{}}
                  rowStyle={rowStyle}
                >
                  <AuctionLinkField source='auctionData.title' label='Auction' sortable={false} />
                  <LotId source='_id' label='Lot ID' sortable={false} />
                  <NumberLink source='number' label='Lot No' sortable={false} />
                  <DescriptionLinkField source='description' label='Lot Description' sortable={false} />
                  {!activeLot && <TextField source='_startPrice' label='Start Price' sortable={false} />}
                  {activeLot && <StartPrice label='Start Price' />}
                  <NumberField source='bidding' label='Bid Type' sortable={false} />
                  <BidIncrementField source='bidIncrementCents' label='Bid Increment' sortable={false} />
                  <ReofferedField label='Reoffered' sortable />
                  <SellerField label='Seller' sortable={false} />
                  <StatusField label='Status' />
                  {this.state.auctionState !== 'closed' &&
                    <TextField source='_currentPrice' label='Current Price' sortable={false} />}
                  <TextField source='_closedPrice' label='Closed Price' sortable={false} />
                  <BuyerField label='Buyer/ Losing bidder(passed lots)' />
                </Datagrid>
              </div>
            </div>}
        </div>}
        {this.state.auctionState === 'closed' &&
          <div className='close-overlay'>
            <button className="close-icon" onClick={closeOnClick} >
              X
            </button>
            <div className='text-container'>
              <h3>AUCTION NO. {this.props.auction.number} CLOSED</h3>
            </div>
          </div>
        }
      </div>
    )
  }

  setLotsPage = (page) => {
    this.setState({
      lots: update(this.state.lots, {
        page: { $set: page }
      })
    }, this.loadData)
  }

  changeCollapse = (state) => {
    this.setState({
      lots: update(this.state.lots, {
        isOpen: { $set: state }
      })
    })
  }

  render () {
    return (
      <NewWindow features={{ width: 1500, height: 800 }} title='Active Lot' onUnload={() => { this.props.changeWindowModal({ show: false, auction: {} }) }}>
        <div id='active-lot-window-wrapper'>
          <div>
            { this.lotsRender()}
          </div>
        </div>
      </NewWindow>
    )
  }
}

export default ActiveLotTable
