/**
 * This function finds an average in the segment then finds an average of all segments
 * @param {{average: number,numberOfHeads: number }[]}  arrayOfAverageWeights
 * @returns {number}
 */
export function findAverageFromArray (arrayOfAverageWeights) {
  const sumOfHead = arrayOfAverageWeights.reduce((sum, item) => {
    return sum + item.numberOfHeads
  }, 0)
  const sumAverage = arrayOfAverageWeights.reduce((sum, item) => {
    return sum + (item.average * item.numberOfHeads)
  }, 0)
  const averageResult = sumAverage / sumOfHead
  // Round to hundredths
  return Math.round(averageResult * 100) / 100
}
