/* global sessionStorage */

import React from 'react'
import { AppBar } from 'react-admin'

import '../styles/header.css'

const Header = props => (
  <div className='header-wrapper'>
    <div className='logo' />
    <div className='full-name'>{sessionStorage.getItem('userFullName')}</div>
    <AppBar {...props} className='app-bar' />
  </div>
)

export default Header
