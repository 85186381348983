export default function filterKindFormReference (reference) {
  if (reference.forms) {
    reference.forms = reference.forms.map(form => {
      delete form._shortId
      if (form.forms || form.options) {
        filterKindFormReference(form)
      }
      return form
    })
  }
  if ((reference.type === 'dropdown' || reference.type === 'multiDropdown') &&
    reference.options) {
    reference.options = reference.options.map(option => option.label)
  }
  if (reference.type === 'select' && reference.options) {
    reference.options.forEach(option => {
      delete option._shortId
    })
  }
}
