import { LotListComponent } from '../../models/lots/model'
import config from '../../config'
import { dataProvider } from '../../App'
import { OwnPagination } from '../OwnPagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { Collapse } from 'react-collapse'
import update from 'immutability-helper'
import debounce from 'lodash/debounce'
import React from 'react'
import { GET_LIST, Datagrid, TextField, ShowButton, EditButton, BooleanField } from 'react-admin'
import MaterialTextField from '@material-ui/core/TextField'
import moment from 'moment'

const AuctionLinkField = ({ record = {} }) => <a href={`/#/auctions/${record.auction}/show`} className='vendor-link'>{record.auctionData.title}</a>
const LotIdLinkField = ({ record = {} }) => <a href={`/#/lots/${record._id}/show`} className='self-link'>{record.searchNumericId}</a>
const CreatorLinkField = ({ record = {} }) => <a href={`/#/users/${record.createdBy}/show`} className='vendor-link'>{record.creatorData ? `${record.creatorData.firstName} ${record.creatorData.lastName}` : ''}</a>
const LotTitleLinkField = ({ record = {} }) => <a href={`/#/lots/${record._id}/show`} className='self-link'>{record.publicDetails.title}</a>

export class HomeLotsComponent extends LotListComponent {
  constructor (props) {
    super(props)

    this.state = {
      ids: [],
      data: {},
      total: 0,
      page: 1,
      perPage: config.perPage,
      field: 'createdAt',
      order: 'DESC',
      timerEnabled: true,
      isOpened: false,
      search: ''
    }
    this.debounced = null

    this.filter = { approved: false, draft: false }
    this.loadLotData = this.loadLotData.bind(this)
    this.setPage = this.setPage.bind(this)
    this.setPerPage = this.setPerPage.bind(this)
    this.setSort = this.setSort.bind(this)
    this.renderLots = this.renderLots.bind(this)
    this.changeCollapse = this.changeCollapse.bind(this)
    this.setSearchString = this.setSearchString.bind(this)

    this.loadLotData()
  }

  loadLotData () {
    let field
    if (this.state.field[0] === '_') {
      field = this.state.field.substring(1)
    } else {
      field = this.state.field
    }
    dataProvider(GET_LIST, 'admin-lots', {
      filter: { ...this.filter },
      pagination: {
        page: this.state.page,
        perPage: this.state.perPage
      },
      sort: {
        field,
        order: this.state.order
      },
      search: this.state.search
    })
      .then(({ data, total }) => {
        let newids = []
        let newdata = {}
        for (let i = 0; i < data.length; i++) {
          let id = data[i].id
          newids.push(id)
          newdata[id] = {
            ...data[i],
            leftTime: data[i].leftTime
          }
        }
        this.clearTimer(() => {
          this.setState({
            ids: newids,
            data: newdata,
            total,
            timerEnabled: true
          })
        })
      })
  }

  setPerPage (perPage) {
    this.setState({
      perPage
    }, this.loadLotData)
  }

  setPage (page) {
    this.setState({
      page
    }, this.loadLotData)
  }

  setSort (field) {
    let order = 'ASC'
    if (this.state.field === field) {
      order = this.state.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      field,
      order
    }, this.loadLotData)
  }

  renderLots () {
    const { ids, data } = this.state
    const LotEditButton = ({ record }) => <EditButton to={`/lots/${record._id}`} />
    const LotShowButton = ({ record }) => <ShowButton to={`/lots/${record._id}/show`} />
    const AuctionDateField = ({ source, record = {} }) => <span>{record.auctionData.liveAt ? moment(record.auctionData.liveAt).format('D/M/YYYY') : ''}</span>
    let filteredProps = { ...this.props }
    delete filteredProps.hasList
    delete filteredProps.hasShow
    delete filteredProps.hasEdit
    return (
      <div className='datagrid-wrapper home-datagrid-wrapper'>
        <b className='table-header'>Pending Lots, {this.state.total} lots</b>
        <MaterialTextField
          label='Search'
          placeholder='Search'
          className='home-search-text'
          onChange={this.setSearchString}
        />
        <Datagrid
          ids={ids}
          data={data}
          currentSort={{ field: this.state.field, order: this.state.order }}
          setSort={this.setSort}
          {...filteredProps}
        >
          <LotIdLinkField source='numericId' label='ID' sortable />
          <TextField source='createdAt' label='Date Added' sortable />
          <LotTitleLinkField source='publicDetails.title' label='Lot Title' sortable />
          <AuctionLinkField source='auctionData' label='Auction' sortable />
          <AuctionDateField source='auctionData.liveAt' label='Auction Date' />
          <TextField source='_startPrice' label='Start Price' sortable />
          <TextField source='_reserveCents' label='Reserve Price' sortable />
          <TextField source='bidding' label='Bid Type' sortable />
          <TextField source='_bidIncrementCents' label='Bid Increment' sortable />
          <BooleanField source='_approved' label='Pending' />
          <CreatorLinkField source='creatorData.firstName' label='Seller' sortable />
          <LotEditButton />
          <LotShowButton />
        </Datagrid>
        <OwnPagination
          page={this.state.page}
          rowsPerPage={this.state.perPage}
          perPage={this.state.perPage}
          total={this.state.total}
          setPerPage={this.setPerPage}
          setPage={this.setPage}
        />
      </div>
    )
  }

  collapsible ({ title, isOpened, changeCollapse, publicId, body }) {
    return (
      <div className='collapse-wrapper'>
        <div
          className='header'
          onClick={isOpened ? () => (changeCollapse(publicId, false)) : () => (changeCollapse(publicId, true))}
        >
          <p className='title'>
            {title}
          </p>
          <div
            className='button'
          >
            <FontAwesomeIcon icon={isOpened ? faAngleUp : faAngleDown} />
            <span>{isOpened ? 'Hide' : 'Show'}</span>
          </div>
        </div>
        <Collapse isOpened={isOpened}>
          <div className='body'>
            {body}
          </div>
        </Collapse>
      </div>
    )
  }

  changeCollapse () {
    this.setState({
      isOpened: update(this.state.isOpened, {
        $set: !this.state.isOpened
      })
    })
  }

  setSearchString ({ target: { value } }) {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.loadLotData, 300)
    this.setState({
      search: value
    }, this.debounced)
  }

  render () {
    return (
      <div>
        <React.Fragment>
          {this.collapsible({
            title: 'Lots',
            isOpened: this.state.isOpened,
            changeCollapse: this.changeCollapse,
            body: this.renderLots()
          })}
        </React.Fragment>
      </div>
    )
  }
}
