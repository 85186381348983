import React from 'react'
import { Form } from 'redux-form'
import { get, setWith, clone, uniq } from 'lodash'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import generatePathForLot from '../../helpers/generatePathForLot'
import LotEditActions from './LotEditActions'
import FormTextField from './fields/FormTextField'
import FormNumberField from './fields/FormNumberField'
import PhoneNumberField from './fields/PhoneNumberField'
import FormDateField from './fields/FormDateField'
import AdjustWeightSection from './fields/AdjustWeightSection'
import { HeadsCountSection } from './fields/HeadsCountSection'
import formatTitleForType from '../../helpers/formatTitleForType'
import { getAssessmentInfo, resetForm } from '../../helpers/lotForm'
import DebounceSelectField from './fields/DebounceSelectField'
import CheckboxField from './fields/CheckboxField'
import { findFieldByPublicId } from '../../utils/form-lot'
import { lotHiddenFields, requiredForOptiWeighFields } from '../../constants'
import {LotDetailsModify} from './LotAssessmentFormModificationBeforeImport'
import './AssessmentFormStyles.css'
import RatingField from './fields/RatingField'
import WeightField from './fields/WeightField'
import { Circle } from 'better-react-spinkit'
import LotCreateActions from './LotCreateActions'
import MobEntryWeight from './fields/MobEntryWeight'
import moment from 'moment'
import kindApiService from '../../providers/kinds/kindApiService'

// find paths to all 'repeatForNumber' fields
function findRFNPaths (details, indexes = [], type) {
  let rfnPaths = []
  if (type === 'create') {
    details.forms.forEach((detail, index) => {
      let newIndexes = indexes.concat(index)
      if (detail.type === 'group') {
        details.values = details.forms
        rfnPaths = rfnPaths.concat(findRFNPaths(detail, newIndexes, type))
      } else if (detail.type === 'repeatForNumber') {
        detail.values = [detail.forms]
        rfnPaths.push(generatePathForLot(newIndexes))
      }
    })
  } else {
    details.values.forEach((detail, index) => {
      let newIndexes = indexes.concat(index)
      if (detail.type === 'group') {
        rfnPaths = rfnPaths.concat(findRFNPaths(detail, newIndexes))
      } else if (detail.type === 'repeatForNumber') {
        rfnPaths.push(generatePathForLot(newIndexes))
      }
    })
  }
  return rfnPaths
}

async function getStockCategories () {
  let stockCategories = await axios(`/stock-categories`, {
    method: 'get'
  })
  return stockCategories.data.stockCategories
}

async function getStateCategories () {
  let stateCategories = await axios('/states', {
    method: 'get'
  })
  return stateCategories.data.states
}

export default class LotAssessmentFormEdit extends Form {
  constructor (props, context) {
    super(props, context)

    this.state = {}
    this.reduxForm = context._reduxForm
    let reduxFormValues = {}
    let details = {}
    this.kindData = {}
    if (this.props.type === 'create') {
      reduxFormValues = this.props.kindFull.form
      details = reduxFormValues
      this.kindData = this.props.kindFull
    } else {
      reduxFormValues = this.reduxForm.getValues()
      details = reduxFormValues.details
      if (reduxFormValues.kindData) {
        this.kindData = reduxFormValues.kindData
      } else {
        this.kindData = this.props.kindFull
      }
    }

    const kind = this.props.kindFull ? this.props.kindFull.type : this.props.record.kindData.type

    if (this.props.dataFromJson) {
      details = this.props.dataFromJson.details
      this.reduxForm.dispatch(this.reduxForm.change('details', this.props.dataFromJson.details))
      const assesmentGroupIndex = details.values.findIndex(item => item.publicId === 'assessmentGroup')
      if (assesmentGroupIndex !== -1) {
        const individualAssesmentIndex = details.values[assesmentGroupIndex].values.findIndex(x => x.publicId === 'individualAssessment')
        if (individualAssesmentIndex !== -1) {
          const count = details.values[assesmentGroupIndex].values[individualAssesmentIndex].values.length
          if (count && kind.includes('cattle')) {
            this.reduxForm.dispatch(this.reduxForm.change('count', count))
            this.setState({ newCount: this.props.dataFromJson.count })
          } else if (count && kind.includes('sheep')) {
            this.reduxForm.dispatch(this.reduxForm.change('countWeighed', count))
            this.reduxForm.dispatch(this.reduxForm.change('count', this.props.dataFromJson.count))
            this.setState({ newCount: this.props.dataFromJson.count })
            this.setState({ newCountWeighed: this.props.dataFromJson.countWeighed })
          } else if (kind.includes('goat')) {
            this.reduxForm.dispatch(this.reduxForm.change('countWeighed', this.props.dataFromJson.countWeighed))
            this.reduxForm.dispatch(this.reduxForm.change('count', this.props.dataFromJson.count))
            this.state.newCount = this.props.dataFromJson.count
            this.state.newCountWeighed = this.props.dataFromJson.countWeighed
          }
        }
      }
    }

    let weightsChanged = null

    if (this.props.weights) {
      if(this.reduxForm.getValues().kind === 'goat') {
        details =  this.props.kindFull.form
      }else {
        details = this.reduxForm.getValues().details || this.props.kindFull.form
      }
      const assessmentGroupIndex = details.values.findIndex(item => item.publicId === 'assessmentGroup')
      if (assessmentGroupIndex !== -1) {
        const individualAssessmentIndex = details.values[assessmentGroupIndex].values.findIndex(x => x.publicId === 'individualAssessment')
        if (individualAssessmentIndex !== -1) {
          details.values[assessmentGroupIndex].values[individualAssessmentIndex].values = this.props.weights
          this.reduxForm.dispatch(this.reduxForm.change('details', details))
          weightsChanged = this.props.weights.length
        }
      }
    }

    this.state.details = details
    this.state.page = 0
    this.state.rowsPerPage = 1
    this.state.paginator = {}
    this.state.stockCategories = []
    this.state.stateCategories = []
    this.state.stockCategoryOptions = []
    this.state.districtOptions = []
    this.state.spinner = false
    this.state.adjustWeight = this.reduxForm.getValues().adjustWeight || 0
    this.state.weightGain = this.reduxForm.getValues().weightGain || 0
    this.state.showValidationMessage = false
    this.state.isLotClosed = reduxFormValues.state === 'closed'
    this.state.avgWeight = 0
    this.state.optiweighAllowed = this.reduxForm.getValues().optiweighAllowed || false
    this.state.dataFromJsonSaved = false
    this.state.kindsList = null

    this.stockCategoryIndexes = null
    this.districtIndexes = null

    // init paginators
    this.RFNpaths = findRFNPaths(details, [], this.props.type)
    this.RFNpaths.forEach(path => {
      this.state.paginator[path] = 0
    })

    if (weightsChanged) {
      this.reduxForm.dispatch(this.reduxForm.change('count', this.props.weights.length))
      if (kind === 'sheep') {
        this.reduxForm.dispatch(this.reduxForm.change('countWeighed', this.props.weights.length))
      }
    }

    if (this.props.adjustWeight) {
      this.setState({
        adjustWeight: this.props.adjustWeight
      })
      this.onChangeAdjustWeight(this.props.adjustWeight)
    }
    if (this.props.weightGain) {
      this.setState({
        weightGain: this.props.weightGain
      })
      this.reduxForm.dispatch(this.reduxForm.change('weightGain', this.props.weightGain))
    }

    this.initKinds()

    this.onChange = this.onChange.bind(this)
    this.onChangeAdjustWeight = this.onChangeAdjustWeight.bind(this)
    this.onChangeWeightGain = this.onChangeWeightGain.bind(this)
    this.onChangeCount = this.onChangeCount.bind(this)
    this.onChangeMobEntryWeight = this.onChangeMobEntryWeight.bind(this)
  }

  initKinds = async () => {
    const kindsList = await kindApiService.getKinds()
    this.setState({ kindsList })
  }

  /**
   * We should hide assessment form in case lot type = goat and countWeighed = 0
   * @param {Object} detail
   * @param {Object} kindData lot kind data
   * @param {int} countWeighed
   * @returns {boolean}
   */
  isAssessmentFormShown (detail, kindData, countWeighed) {
    return !(this.reduxForm.getValues().optiweighAllowed && detail.publicId === 'assessmentGroup') &&
      !(detail.publicId === 'assessmentGroup' && kindData.type === 'goat' && !countWeighed)
  }

  componentDidUpdate () {
    let changedWeights = null
    if (this.props.optiweigh && this.props.optiweigh !== this.reduxForm.getValues().optiweigh) {
      this.reduxForm.dispatch(this.reduxForm.change('optiweigh', this.props.optiweigh))
    }
    if (this.props.assessmentOptiweigh && this.props.assessmentOptiweigh !== this.reduxForm.getValues().assessmentOptiweigh) {
      this.onOptiweighAssessmentUploaded()
    }
    if (this.props.averageOptiweigh && this.props.averageOptiweigh !== this.reduxForm.getValues().averageOptiweigh) {
      this.onOptiweighAverageUploaded()
    }
    if (this.props.optiweighAllowed !== this.state.optiweighAllowed) {
      this.reduxForm.dispatch(this.reduxForm.change('optiweighAllowed', this.props.optiweighAllowed))
      this.setState({ optiweighAllowed: this.props.optiweighAllowed })
      if (this.props.optiweighAllowed) {
        this.createOldDetailsAndChangeForm()
        if (this.props.assessmentOptiweigh) {
          this.onOptiweighAssessmentUploaded()
        }
        if (this.props.averageOptiweigh) {
          this.onOptiweighAverageUploaded()
        }
      } else if (this.props.optiweighAllowed === false && !!this.reduxForm.getValues().oldDetails) {
        this.clearOldDetailsAndRestoreForm()
      }
    }
    if (this.state.details && this.state.details.values) {
      const assessmentGroupIndex = this.state.details.values.findIndex(item => item.publicId === 'assessmentGroup')
      if (assessmentGroupIndex !== -1) {
        const individualAssessmentIndex = this.state.details.values[assessmentGroupIndex].values.findIndex(x => x.publicId === 'individualAssessment')
        if (individualAssessmentIndex !== -1) {
          changedWeights = this.state.details.values[assessmentGroupIndex].values[individualAssessmentIndex].values
        }
      }
    }
    if (this.props.weights && (this.props.weights === changedWeights)) {
      this.changeAssessment()
    }
    if (this.props.optiweighAllowed && this.props.dataFromJson && this.state.dataFromJsonSaved === false) {
      if (this.props.dataFromJson.oldDetails) {
        this.reduxForm.dispatch(this.reduxForm.change('oldDetails', this.props.dataFromJson.oldDetails))
      }
      if (this.props.dataFromJson.oldWeightGain || this.props.dataFromJson.oldWeightGain === 0) {
        this.reduxForm.dispatch(this.reduxForm.change('oldWeightGain', this.props.dataFromJson.oldWeightGain))
      }
      this.setState({
        dataFromJsonSaved: true
      })
    }
  }

  removeRequired (details, requiredArray) {
    if (typeof details === 'object' && Array.isArray(details) === false) {
      if (details.hasOwnProperty('isRequired') && !(requiredArray.includes(details.title) || requiredArray.includes(details.publicId))) {
        details.isRequired = false
        return null
      } else if (details.hasOwnProperty('isRequired') && requiredArray.includes(details.publicId)) {
        details.isRequired = true
        return null
      } else {
        if (details.values) {
          details.values.forEach(item => {
            this.removeRequired(item, requiredArray)
          })
        } else {
          return null
        }
      }
    } else if (Array.isArray(details) === true) {
      details.forEach(item => {
        this.removeRequired(item, requiredArray)
      })
    }
  }

  changeAssessment (setEmpty = false) {
    const kind = this.props.kindFull ? this.props.kindFull.type : this.props.record.kindData.type
    const details = this.reduxForm.getValues().details || this.props.kindFull.form
    if (details.values) {
      this.removeRequired(details, requiredForOptiWeighFields)
      const assessmentGroupIndex = details.values.findIndex(item => item.publicId === 'assessmentGroup')
      if (assessmentGroupIndex !== -1) {
        const individualAssessmentIndex = details.values[assessmentGroupIndex].values.findIndex(x => x.publicId === 'individualAssessment')
        if (individualAssessmentIndex !== -1) {
          if (setEmpty) {
            details.values[assessmentGroupIndex].values[individualAssessmentIndex].values.forEach(item => {
              item.forEach(prop => {
                prop.value = ''
              })
            })
            this.setState({
              details: details
            })
            this.reduxForm.dispatch(this.reduxForm.change('details', details))
          } else {
            if (this.props.weights !== details.values[assessmentGroupIndex].values[individualAssessmentIndex].values) {
              details.values[assessmentGroupIndex].values[individualAssessmentIndex].values = this.props.weights
              this.reduxForm.dispatch(this.reduxForm.change('details', details))
              this.reduxForm.dispatch(this.reduxForm.change('oldDetails', details))
              this.reduxForm.dispatch(this.reduxForm.change('count', this.props.weights.length))
              if (kind === 'sheep') {
                this.reduxForm.dispatch(this.reduxForm.change('countWeighed', this.props.weights.length))
              }
            }
          }
        }
      }
    }
  }

  calculateCount (counts) {
    let sum = 0
    counts.forEach(item => {
      sum += item.count
    })
    return sum
  }

  createOldDetailsAndChangeForm () {
    const values = this.reduxForm.getValues()
    if (values.details) {
      const detailsCopy = JSON.parse(JSON.stringify(values.details))
      this.reduxForm.dispatch(this.reduxForm.change('oldDetails', detailsCopy))
    }
    this.changeAssessment(true)
    if (values.weightGain || values.weightGain === 0) {
      const oldWeightGain = JSON.parse(JSON.stringify(values.weightGain))
      this.reduxForm.dispatch(this.reduxForm.change('oldWeightGain', oldWeightGain))
      this.onChangeWeightGain(0)
    }
  }

  async clearOldDetailsAndRestoreForm () {
    const values = this.reduxForm.getValues()
    if (values.oldDetails) {
      const oldDetailsCopy = JSON.parse(JSON.stringify(values.oldDetails))
      const oldCount = oldDetailsCopy.values[1].values[0].values.length
      this.onChangeCount({ value: oldCount, field: 'count' })
      this.reduxForm.dispatch(this.reduxForm.change('details', oldDetailsCopy))
      this.reduxForm.dispatch(this.reduxForm.change('oldDetails', null))
      this.setState({
        details: oldDetailsCopy,
        newCount: oldCount
      })
    }
    if (values.oldWeightGain || values.oldWeightGain === 0) {
      const oldWeightGain = JSON.parse(JSON.stringify(values.oldWeightGain))
      this.onChangeWeightGain(oldWeightGain)
      this.reduxForm.dispatch(this.reduxForm.change('oldWeightGain', null))
    }
  }

  async onOptiweighAssessmentUploaded () {
    if (this.props.optiweighAllowed) {
      const sum = this.calculateCount(this.props.assessmentOptiweigh.counts)
      this.onChangeCount({ value: sum, field: 'count' })
      this.setState({ newCount: sum })
    }
    this.reduxForm.dispatch(this.reduxForm.change('assessmentOptiweigh', this.props.assessmentOptiweigh))
  }

  onOptiweighAverageUploaded () {
    this.reduxForm.dispatch(this.reduxForm.change('averageOptiweigh', this.props.averageOptiweigh))
    const lastColumn = this.props.averageOptiweigh[this.props.averageOptiweigh.length - 1]
    const weightGain = lastColumn.averageDailyGain
    const avgWeight = lastColumn.weight
    let assessmentDate = lastColumn.date
    this.setState({
      avgWeight
    })
    assessmentDate = moment(assessmentDate).hours(12).format()
    if (weightGain && avgWeight && assessmentDate) {
      this.onChangeWeightGain(weightGain)
      const details = this.reduxForm.getValues().details || this.props.kindFull.form
      if (details.values) {
        const deliveryGroupIndex = details.values.findIndex(item => item.title === 'Delivery')
        if (deliveryGroupIndex !== -1) {
          const assessmentDateIndex = details.values[deliveryGroupIndex].values.findIndex(x => x.publicId === 'assessmentDate')
          if (assessmentDateIndex !== -1) {
            details.values[deliveryGroupIndex].values[assessmentDateIndex].value = assessmentDate
            this.reduxForm.dispatch(this.reduxForm.change('details', details))
          }
        }
      }
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.record && nextProps.record.details && JSON.stringify(this.state.details) !== JSON.stringify(nextProps.record.details)) {
      let newDetails = this.reduxForm.getValues().details
      this.setState({ details: newDetails })
    }
  }

  componentDidMount () {
    this.loadDynamicData()
  }

  async loadDynamicData () {
    let { kindData, details } = this.reduxForm.getValues()
    if (this.props.type === 'create' || !kindData) {
      kindData = this.props.kindFull
    }
    let stateCategories = await getStateCategories()
    let stockCategories
    let res = await getStockCategories()
    switch (kindData.type) {
      case ('sheep'):
        stockCategories = res.sheep
        break
      case ('cattle'):
        stockCategories = res.cattle
        break
      case ('goat'):
        stockCategories = res.goat
        break
      case ('sheep-abblamb'):
        stockCategories = res.abblambsheep
        break
      default:
        stockCategories = res.cattle
    }
    let updateObj = {
      stateCategories, stockCategories
    }
    let stateField = findFieldByPublicId(details, 'state')
    if (stateField && stateField.value && stateCategories[stateField.value]) {
      updateObj.districtOptions = stateCategories[stateField.value] || []
    }
    let sexField = findFieldByPublicId(details, 'sex')
    if (sexField && sexField.value && stockCategories[sexField.value]) {
      updateObj.stockCategoryOptions = stockCategories[sexField.value] || []
    }
    this.setState(updateObj)
  }

  assessmentInfo () {
    let { count = 1, countWeighed = 0, details } = this.reduxForm.getValues()
    let headsCount
    if (this.kindData.type !== 'cattle') {
      headsCount = countWeighed
    } else {
      headsCount = count
    }
    if (this.props.type === 'create' || this.props.type === 'createImport') {
      details = this.state.details
    }
    let assessmentGroup = findFieldByPublicId(details, 'assessmentGroup')
    return getAssessmentInfo(assessmentGroup, headsCount)
  }

  assessmentDeliveryDates () {
    let { details } = this.reduxForm.getValues()
    if (this.props.type === 'create' || this.props.type === 'createImport') {
      details = this.state.details
    }
    let earliestDelivery = findFieldByPublicId(details, 'earliestDelivery')
    let assessmentDate = findFieldByPublicId(details, 'assessmentDate')
    return { assessmentDate, earliestDelivery }
  }

  onChangeAdjustWeight (value) {
    this.setState({
      adjustWeight: value
    })
    this.reduxForm.dispatch(this.reduxForm.change('adjustWeight', value))
  }

  onChangeWeightGain (value) {
    this.setState({
      weightGain: value
    })
    this.reduxForm.dispatch(this.reduxForm.change('weightGain', value))
  }

  onChange ({ value, indexes, field, publicId = null, isNested = false }) {
    if (value === 'NaN') {
      value = ''
    }
    if (publicId === 'sex') {
      if (this.stockCategoryIndexes) {
        let path = generatePathForLot(this.stockCategoryIndexes, field)
        let stockCategoryValue = []
        let details = setWith(clone(this.state.details), path, stockCategoryValue, clone)

        this.setState({ details }, () => {
          this.reduxForm.dispatch(this.reduxForm.change(`details${path[0] === '[' ? path : '.' + path}`, stockCategoryValue))
        })
      }
      this.setState({ stockCategoryOptions: this.state.stockCategories[value] || [] })
    }
    if (publicId === 'state') {
      if (this.districtIndexes) {
        let path = generatePathForLot(this.districtIndexes, field)
        let districtValue = ''
        let details = setWith(clone(this.state.details), path, districtValue, clone)

        this.setState({ details }, () => {
          this.reduxForm.dispatch(this.reduxForm.change(`details${path[0] === '[' ? path : '.' + path}`, districtValue))
        })
      }
      this.setState({ districtOptions: this.state.stateCategories[value] || [] })
    }

    let path
    if (isNested) {
      field = 'array'
      path = generatePathForLot(indexes, field)
    } else {
      path = generatePathForLot(indexes, field)
    }
    let details = setWith(clone(this.state.details), path, value, clone)
    this.setState({ details }, () => {
      this.reduxForm.dispatch(this.reduxForm.change(`details${path[0] === '[' ? path : '.' + path}`, value))
    })
    if (publicId === 'animalTeeth') {
      const teethPublicIds = [
        { publicId: 'teeth0', value: '' },
        { publicId: 'teeth12', value: '' },
        { publicId: 'teeth34', value: '' },
        { publicId: 'teeth56', value: '' },
        { publicId: 'teeth7', value: '' },
        { publicId: 'teeth8', value: '' }]
      let mousedAccordion = findFieldByPublicId(details, 'mouthedAccordion')
      mousedAccordion.value = true

      this.setState({ details }, () => {
        this.reduxForm.dispatch(this.reduxForm.change(`details`, details))
      })

      let individualAssessments = findFieldByPublicId(this.state.details, 'individualAssessment')
      let animalTeeth = []
      individualAssessments.values.forEach(individualAssessment => {
        individualAssessment.forEach(item => {
          if (item.publicId === 'animalTeeth') {
            animalTeeth.push(item)
          }
        })
      })

      //  count teeth status for animals
      animalTeeth.forEach(teeth => {
        teeth.options.forEach(option => {
          if (teeth.value === option.value) {
            teethPublicIds.forEach(teethStatus => {
              if (teethStatus.publicId === option.id) {
                teethStatus.value = Number(teethStatus.value) + 1
                teethStatus.value = teethStatus.value.toString()
              }
            })
          }
        })
      })
      teethPublicIds.forEach(localTeethStatus => {
        let teethStatus = findFieldByPublicId(mousedAccordion, localTeethStatus.publicId)
        if (teethStatus) {
          teethStatus.value = localTeethStatus.value
        }
      })

      this.setState({ details }, () => {
        this.reduxForm.dispatch(this.reduxForm.change(`details`, details))
      })
    }

    if (publicId === 'animalHorn') {
      const hornPublicIds = [
        { publicId: 'polled', value: '' },
        { publicId: 'tipped', value: '' },
        { publicId: 'horned', value: '' },
        { publicId: 'dehorned', value: '' }]
      const sheepHornPublicIds = [{ publicId: 'polled', value: '' }, { publicId: 'horned', value: '' }, { publicId: 'pp', value: '' }, { publicId: 'ph', value: '' }, { publicId: 'hh', value: '' }, { publicId: 'scur', value: '' }]
      let kindData = this.kindData
      let individualAssessments = findFieldByPublicId(this.state.details, 'individualAssessment')
      let animalHorn = []
      individualAssessments.values.forEach(individualAssessment => {
        individualAssessment.forEach(item => {
          if (item.publicId === 'animalHorn') {
            animalHorn.push(item)
          }
        })
      })

      //  count horn status for animals
      animalHorn.forEach(horn => {
        horn.options.forEach(option => {
          if (horn.value === option.value) {
            if (kindData.type === 'sheep') {
              sheepHornPublicIds.forEach(hornStatus => {
                if (hornStatus.publicId === option.id) {
                  hornStatus.value = Number(hornStatus.value) + 1
                  hornStatus.value = hornStatus.value.toString()
                }
              })
            } else {
              hornPublicIds.forEach(hornStatus => {
                if (hornStatus.publicId === option.id) {
                  hornStatus.value = Number(hornStatus.value) + 1
                  hornStatus.value = hornStatus.value.toString()
                }
              })
            }
          }
        })
      })

      // update form
      let hornStatusGroup = findFieldByPublicId(details, 'hornStatusGroup')
      if (kindData.type === 'sheep') {
        let addHornDetails = findFieldByPublicId(details, 'addHornDetails')
        addHornDetails.value = true
        sheepHornPublicIds.forEach(localHornStatus => {
          let hornStatus = findFieldByPublicId(hornStatusGroup, localHornStatus.publicId, { ignoreRFNumber: false })
          hornStatus.value = localHornStatus.value
        })
      } else {
        hornPublicIds.forEach(localHornStatus => {
          let hornStatus = findFieldByPublicId(hornStatusGroup, localHornStatus.publicId)
          hornStatus.value = localHornStatus.value
        })
      }
      this.setState({ details }, () => {
        this.reduxForm.dispatch(this.reduxForm.change(`details`, details))
      })
    }

    if (publicId === 'animalBlemishes') {
      let individualAssessments = findFieldByPublicId(this.state.details, 'individualAssessment')
      let blemishesTextArea = findFieldByPublicId(details, 'blemishesTextArea', {
        ignoreRFNumber: false,
        ignoreClosedRFBool: false
      })
      let animalBlemishes = []

      individualAssessments.values.forEach(individualAssessment => {
        individualAssessment.forEach(item => {
          if (item.publicId === 'animalBlemishes' && item.value && item.value.length) {
            animalBlemishes.push(item.value)
          }
        })
      })

      animalBlemishes = animalBlemishes.join(',')
      let uniqueAnimalBlemishes = uniq(animalBlemishes.split(','))
      animalBlemishes = uniqueAnimalBlemishes.join(',')
      blemishesTextArea.value = animalBlemishes
      this.setState({ details }, () => {
        this.reduxForm.dispatch(this.reduxForm.change(`details`, details))
      })
    }
  }

  getDropdownOptions (detail) {
    let options
    if (detail.publicId === 'sex') {
      options = Object.keys(this.state.stockCategories)
    } else if (detail.publicId === 'stockCategory') {
      options = this.state.stockCategoryOptions
    } else if (detail.publicId === 'state') {
      options = Object.keys(this.state.stateCategories)
    } else if (detail.publicId === 'district') {
      options = this.state.districtOptions
    } else {
      options = detail.options.sort()
    }
    return options
  }

  setIndexToDistrict (indexes) {
    if (!this.districtIndexes) {
      this.districtIndexes = indexes
    }
  }

  setIndexToStockCategory (indexes) {
    if (!this.stockCategoryIndexes) {
      this.stockCategoryIndexes = indexes
    }
  }

  renderGroup ({ details, depth = 0, indexes = [], isNested = false, count, countWeighed, kindData }) {
    let changeEvent = this.onChange
    return details && details.map((detail, index) => {
      if (detail.forms) {
        detail.values = detail.forms
        delete detail.forms
      }
      let nodes = []
      let isHidden = lotHiddenFields.indexOf(detail.publicId) !== -1
      if (isHidden) {
        return nodes
      }
      let newIndexes = indexes.concat(index)
      if (isNested) {
        changeEvent = (obj) => {
          obj.isNested = isNested
          return this.onChange(obj)
        }
      }
      if (detail.publicId === 'stockCategory') {
        this.setIndexToStockCategory(newIndexes)
      }
      if (detail.publicId === 'district') {
        this.setIndexToDistrict(newIndexes)
      }
      if (detail.length > -1) {
        nodes.push(this.renderGroup({
          details: detail,
          depth: depth + 1,
          indexes: newIndexes,
          isNested,
          count,
          countWeighed,
          kindData
        }))
      } else if (detail.publicId === 'vendorName' || detail.publicId === 'vendorPic') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <FormTextField
             // disabled={this.state.isLotClosed}
              newIndexes={newIndexes}
              onChange={changeEvent}
              value={detail.value}
              title={detail.title}
              field='value'
              publicId={detail.publicId || ''}
            />
          </div>)
      } else if (detail.type === 'group') {
        if (this.isAssessmentFormShown(detail, kindData, countWeighed)) {
          nodes.push(
            <div className={`depth-${depth % 3}`} key={detail.title + index}>
              {LotDetailsModify(detail)}
              <h3>{detail.title}</h3>
              <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
              <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
                icon='asterisk' />
              <h4>{formatTitleForType(detail.type)}</h4>
              {this.renderGroup({
                details: detail.values,
                depth: depth + 1,
                indexes: newIndexes,
                count,
                countWeighed,
                kindData
              })}
            </div>)
        }
      } else if (detail.type === 'dropdown') {
        nodes.push(
          <div className={`depth-${depth % 3} lot-edit-dropdown`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <DebounceSelectField
             // disabled={this.state.isLotClosed}
              newIndexes={newIndexes}
              onChange={changeEvent}
              value={detail.value}
              publicId={detail.publicId}
              field='value'
              options={this.getDropdownOptions(detail, newIndexes)}
            />
          </div>)
      } else if (detail.type === 'multiDropdown') {
        nodes.push(
          <div className={`depth-${depth % 3} lot-edit-dropdown`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <DebounceSelectField
             // disabled={this.state.isLotClosed}
              multiple
              newIndexes={newIndexes}
              onChange={changeEvent}
              publicId={detail.publicId}
              value={detail.value || []}
              field='value'
              options={this.getDropdownOptions(detail, newIndexes)}
            />
          </div>)
      } else if (detail.type === 'select') {
        nodes.push(
          <div className={`depth-${depth % 3} lot-edit-dropdown`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <DebounceSelectField
            //  disabled={this.state.isLotClosed}
              newIndexes={newIndexes}
              onChange={changeEvent}
              value={detail.value}
              publicId={detail.publicId}
              defaultValue={detail.defaultValue}
              field='value'
              options={detail.options}
            />
          </div>)
      } else if (detail.type === 'text' || detail.type === 'tradingName' || detail.type === 'email' || detail.type === 'user' || detail.type === 'picNumber') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>

            <FormTextField
              multiline
             // disabled={this.state.isLotClosed}
              newIndexes={newIndexes}
              onChange={changeEvent}
              value={detail.value ? detail.value : (detail.publicId === 'agencyName' && this.props.agencyName ? this.props.agencyName : '')}
              title={detail.title}
              field='value'
            />
          </div>)
      } else if (detail.type === 'textarea') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <FormTextField
            //  disabled={this.state.isLotClosed}
              multiline
              newIndexes={newIndexes}
              onChange={changeEvent}
              value={detail.value}
              title={detail.title}
              field='value'
            />
          </div>)
      } else if (detail.type === 'phone') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <PhoneNumberField
             // disabled={this.state.isLotClosed}
              onChange={changeEvent}
              value={detail.value}
              newIndexes={newIndexes}
              title={detail.title}
              validation={detail.type}
              field='value'
            />
          </div>)
      } else if (detail.type === 'number') {
        nodes.push(<div className={`depth-${depth % 3}`} key={detail.title + index}>
          <h3 className='dropdown-title'>{detail.title}</h3>
          <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
          <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
            icon='asterisk' />
          <h4>{formatTitleForType(detail.type)}</h4>
          <FormNumberField
          //  disabled={this.state.isLotClosed}
            onChange={changeEvent}
            value={detail.value}
            newIndexes={newIndexes}
            title={detail.title}
            validation={detail.type}
            field='value'
            maxDecimalDigits={detail.publicId === 'lowAge' || detail.publicId === 'highAge' || detail.title === 'Micron Tested (Micron)' ? 2 : undefined}
            publicId={detail.title === 'Micron Tested (Micron)' ? 'micronTested' : detail.publicId}
          />
        </div>)
      } else if (detail.type === 'weight') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <WeightField
             // disabled={this.state.isLotClosed}
              onChange={changeEvent}
              value={detail.value}
              newIndexes={newIndexes}
              title={detail.title}
              field='weight'
              publicId='weight'
            />
          </div>)
      } else if (detail.type === 'rating') {
        nodes.push(<div className={`depth-${depth % 3}`} key={detail.title + index}>
          <h3 className='dropdown-title'>{detail.title}</h3>
          <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
          <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
            icon='asterisk' />
          <h4>{formatTitleForType(detail.type)}</h4>
          <RatingField
          //  disabled={this.state.isLotClosed}
            onChange={changeEvent}
            value={detail.value}
            newIndexes={newIndexes}
            title={detail.title}
          />
        </div>)
      } else if (detail.type === 'date') {
       // let optiweighAllowed = this.reduxForm.getValues().optiweighAllowed || false
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <FormDateField
            //  disabled={(this.state.isLotClosed && (detail.publicId !== 'earliestDelivery' && detail.publicId !== 'latestDelivery')) || (optiweighAllowed && detail.publicId === 'assessmentDate')}
              onChange={changeEvent}
              value={detail.value || null}
              newIndexes={newIndexes}
              title={detail.title}
              field='value'
              required={detail.publicId === 'assessmentDate' || detail.publicId === 'earliestDelivery'}
            />
          </div>)
      } else if (detail.type === 'bool' || detail.type === 'checkbox') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <h3 className='table-value'>{detail.value}</h3>
            <CheckboxField
              //disabled={this.state.isLotClosed}
              onChange={changeEvent}
              value={Boolean(detail.value)}
              title={detail.title}
              newIndexes={newIndexes}
              field='value'
            />
          </div>)
      } else if (detail.type === 'repeatForBool') {
        if (this.props.type === 'create' && detail.values && detail.values[0] && detail.values[0].constructor.toString().indexOf('Array') === -1) {
          detail.values = [detail.values]
          detail.value = false
        }
        let isTogglerender = detail.publicId === 'grazingConditions' && (this.props.record && this.props.record.kindData.type === 'sheep-abblamb') ? false : true
        if(!isTogglerender) {
          detail.value = true
        }
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <h3 className='table-value'>{detail.value}</h3>
            {isTogglerender &&  
            <CheckboxField
              //disabled={this.state.isLotClosed}
              onChange={changeEvent}
              value={Boolean(detail.value)}
              title={detail.title}
              newIndexes={newIndexes}
              field='value'
            /> }
           
            {detail.value && this.renderGroup({
              details: detail.values,
              depth: depth + 1,
              indexes: newIndexes,
              isNested: true,
              count,
              countWeighed,
              kindData
            })}
          </div>)
      } else if (detail.type === 'repeatForNumber' && !this.reduxForm.getValues().optiweighAllowed) {
        let path = generatePathForLot(newIndexes)
        if (this.props.type === 'create' && detail.values && detail.values[0] && detail.values[0].publicId === 'weightLive') {
          detail.values = [detail.values]
        }
        let repeatedIndexes = newIndexes.concat(this.state.paginator[path])
        if (!(kindData && kindData.type === 'goat' && !countWeighed)) {
          nodes.push(
            <div className={`depth-${depth % 3}`} key={detail.title + index}>
              <h3>{detail.title}</h3>
              <h4>{formatTitleForType(detail.type)}</h4>
              <React.Fragment key={this.state.paginator[path]}>
                {this.renderGroup(
                  {
                    details: detail.values[this.state.paginator[path]],
                    depth: 0,
                    indexes: repeatedIndexes,
                    isNested: true,
                    count,
                    countWeighed,
                    kindData
                  })
                }
              </React.Fragment>
              <div className='pagination'>
                <h5 className='total-heads'>Total heads: {this.state.paginator[path] + 1} / {detail.values.length} </h5>
                <Button
                  variant='outlined'
                  color='primary'
                  disabled={detail.values.length <= 1}
                  onClick={() => {
                    let { count, countWeighed } = this.reduxForm.getValues()
                    let headsCount = countWeighed || count
                    if (countWeighed) {
                      this.onChangeCount({
                        index: this.state.paginator[path], field: 'countWeighed', value: countWeighed - 1
                      })
                    } else {
                      this.onChangeCount({ index: this.state.paginator[path], field: 'count', value: headsCount - 1 })
                    }
                  }}>
                  Clear this one
                </Button>
                <Button
                  variant='outlined'
                  color='primary'
                  disabled={!this.state.paginator[path] > 0}
                  onClick={() => {
                    if (this.state.paginator[path] > 0) {
                      const newPaginator = Object.assign({}, this.state.paginator)
                      newPaginator[path] = 0
                      this.setState({ paginator: newPaginator })
                    }
                  }}>
                  First Assessment
                </Button>
                <Button
                  variant='outlined'
                  color='primary'
                  disabled={!this.state.paginator[path] > 0}
                  onClick={() => {
                    if (this.state.paginator[path] > 0) {
                      --this.state.paginator[path]
                      this.setState({ paginator: this.state.paginator })
                    }
                  }}>
                  Prev
                </Button>
                <Button
                  variant='outlined'
                  color='primary'
                  disabled={this.state.paginator[path] + 1 === detail.values.length}
                  onClick={() => {
                    if (detail.values[this.state.paginator[path] + 1]) {
                      ++this.state.paginator[path]
                      this.setState({ paginator: this.state.paginator })
                    }
                  }}>
                  Next
                </Button>
                <Button
                  variant='outlined'
                  color='primary'
                  disabled={this.state.paginator[path] + 1 === detail.values.length}
                  onClick={() => {
                    if (detail.values[this.state.paginator[path] + 1]) {
                      const newPaginator = Object.assign({}, this.state.paginator)
                      newPaginator[path] = detail.values.length - 1
                      this.setState({ paginator: newPaginator })
                    }
                  }}>
                  Last Assessment
                </Button>
              </div>
            </div>)
        }
      }

      if (detail.publicId === 'assessmentDate') {
        const adj = this.state.adjustWeight || this.props.adjustWeight
        const weightGain = this.state.weightGain || this.props.weightGain
        const { assessmentDate, earliestDelivery } = this.assessmentDeliveryDates()
        const kind = this.props.kindFull ? this.props.kindFull.type : this.props.record.kindData.type
        nodes.push(<AdjustWeightSection
          key='adjust-weight-section'
          assessmentInfo={this.assessmentInfo()}
          adjustWeight={adj || 0}
          depth={depth}
          assessmentDate={assessmentDate ? assessmentDate.value : null}
          earliestDeliveryDate={earliestDelivery ? earliestDelivery.value : null}
          weightGain={weightGain || 0}
          optiweighAllowed={this.reduxForm.getValues().optiweighAllowed || false}
          optiweighWeight={this.state.avgWeight || 0}
          kind={kind || 'cattle'}
          onChange={this.onChangeAdjustWeight}
          onChangeWeightGain={this.onChangeWeightGain}
        />)
      }
      return nodes
    })
  }

  onChangeMobEntryWeight (value) {
    this.reduxForm.dispatch(this.reduxForm.change('mobEntryWeight', value))
  }

  onChangeCount ({ value, field, index }) {
    let { count, countWeighed } = this.reduxForm.getValues()
    count = field === 'count' ? value : count
    countWeighed = field === 'countWeighed' ? value : countWeighed
    let trueCount = countWeighed || count

    if ((this.kindData.type === 'goat' || this.kindData.type === 'sheep')) {
      trueCount = countWeighed
    } else if (this.kindData.type === 'cattle' || this.kindData.type === 'sheep-abblamb') {
      trueCount = count
    }
    // find types
    let { details, paginator } = this.state
    this.RFNpaths.forEach((path) => {
      let formElement = get(this.state.details, path)
      let oldLength = formElement.values.length
      if (trueCount > oldLength) {
        const currentKind = this.state.kindsList.find(kind => kind.type === this.kindData.type)
        if (currentKind.form.forms && currentKind.form.forms.length) {
          const assessmentGroup = findFieldByPublicId(currentKind.form.forms, 'assessmentGroup').forms[0]
          for (let i = 0; i < trueCount - oldLength; i++) {
            formElement.values.push(resetForm(assessmentGroup.forms))
          }
        }

      } else if (oldLength > trueCount) {
        // make sure to leave at least one element
        if (index >= 0) {
          formElement.values.splice(index, 1)
        } else {
          formElement.values.splice(Math.max(trueCount, 0))
        }
      }
      details = setWith(clone(this.state.details), path, formElement, clone)
      // fix paginators after element length change
      if (paginator[path] + 1 > formElement.values.length) {
        paginator[path] = Math.max(formElement.values.length - 1, 0)
      }
      this.reduxForm.dispatch(this.reduxForm.change(`details${path[0] === '[' ? path : '.' + path}`, formElement))
    })

    this.setState({ details }, () => {
      this.reduxForm.dispatch(this.reduxForm.change(field, value))
      if (index >= 0 && field === 'countWeighed') {
        this.reduxForm.dispatch(this.reduxForm.change('count', value))
      }
    })
  }

  render () {
    const { details } = this.state
    let { count, countWeighed, optiweighAllowed, mobEntryWeight } = this.reduxForm.getValues()
    return (
      <div className='assesstment-wrapper editable'>
        {this.state.spinner && <div className='spinner-wrapper'>
          <div className='spinner'>
            <Circle size={100} />
          </div>
        </div>}
        {optiweighAllowed && <MobEntryWeight
          count={mobEntryWeight}
          onChange={this.onChangeMobEntryWeight}
        />}
        <HeadsCountSection
          kindData={this.kindData}
          defaultCount={count}
          defaultCountWeighed={countWeighed}
          newCount={this.state.newCount}
          newCountWeighed={this.state.newCountWeighed}
          onChange={this.onChangeCount}
        />
        {details && this.state.stockCategories && this.renderGroup({
          details: details.values,
          count,
          countWeighed,
          kindData: this.kindData
        })}
        {(this.props.type === 'create' || this.props.type === 'createImport') &&
        <LotCreateActions userId={this.props.userId}
          showValidationError={(message) => { this.props.showValidationError(message) }}
          showSpinner={this.props.toggleSpinner} reduxForm={this.reduxForm}
          details={this.state.details} />}
        {(this.props.type !== 'create' && this.props.type !== 'createImport') &&
        <LotEditActions showValidationError={(message) => { this.props.showValidationError(message) }}
          showSpinner={this.props.toggleSpinner} reduxForm={this.reduxForm}
          chosenUser={this.props.chosenUser || null} />}
      </div>)
  }
}
