import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeWindowModal } from '../../../../actions'
import EditAdminBidModal from './editAdminBidModal'

const mapStateToProps = (data) => {
  return {
    auction: data.mainCustomData.windowPopup.auction
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ changeWindowModal }, dispatch)
  }
}

const EditAdminBidModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAdminBidModal)

export default EditAdminBidModalContainer
