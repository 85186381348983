import React, { Component } from 'react'
import { dataProvider } from '../../App'
import { GET_LIST } from 'react-admin'
import { OwnPagination } from '../OwnPagination'
import moment from 'moment'
import debounce from 'lodash/debounce'
import MaterialTextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ReactTable from 'react-table'
import config from '../../config'
import './styles.css'
import { makePrice } from '../../utils'
import axios from 'axios'
import * as Datetime from 'react-datetime'

export class InvoicesSentReportTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: [],
      defaultPageSize: 27,
      search: '',
      field: 'paymentDueDate',
      order: 'DESC',
      total: 100,
      page: 1,
      perPage: 10,
      dateFrom: '',
      dateTo: ''
    }

    this.loadData = this.loadData.bind(this)
    this.linkToUser = this.linkToUser.bind(this)
    this.downloadCsv = this.downloadCsv.bind(this)
  }

  loadData = async () => {
    let { page, perPage, field, order, search, dateFrom, dateTo } = this.state
    if (dateFrom) {
      dateFrom = moment(dateFrom).tz(config.timeZone)
    }
    if (dateTo) {
      dateTo = moment(dateTo).tz(config.timeZone)
    }
    try {
      let data = await dataProvider(GET_LIST, 'lot-invoice-report', {
        filter: this.filter,
        pagination: {
          page,
          perPage
        },
        sort: {
          field,
          order
        },
        search,
        range: {
          rangeFrom: dateFrom,
          rangeTo: dateTo
        },
        fromXero: true
      })
      let newData = []
      let items = data.data.filter(item => item.invoice !== null).sort((a, b) => a.invoiceNo - b.invoiceNo)
      items.forEach(item => {
        if (item.invoice.data.auction.privateSale) {
          item.discount = item.invoice.data.auction.auctionDiscount ? item.invoice.data.auction.auctionDiscount : 0
        } else {
          item.discount = item.invoice.data.user.discount ? item.invoice.data.user.discount : 0
        }
        if (item.invoice && item.invoice.data.auction.searchNumber) {
          item.searchNumber = item.invoice.data.auction.searchNumber
        } else {
          let letters = ''
          if (item.stockType) {
            letters = item.stockType.substring(0, 1)
          }
          if (item.invoice) {
            if (item.invoice.data.auction.privateSale) {
              letters = 'P'.concat(letters)
            } else {
              letters = 'A'.concat(letters)
            }
            if (item.invoice.data.auction.number) {
              letters = letters.concat(item.invoice.data.auction.number)
            }
          }
          item.searchNumber = letters
        }
        if (!item.lotNo) {
          let lotNo = item.invoice.data.lots.findIndex(x => x.id === item.lotId)
          item.lotNo = lotNo + 1
        }
      })
      items.forEach((item, index, array) => {
        if (newData.findIndex(x => x[0].invoiceNo === item.invoiceNo) === -1) {
          const push = array.filter(x => x.invoiceNo === item.invoiceNo)
          newData.push(push)
        }
      })
      let resultArray = []
      newData.forEach(array => {
        if (array[0].invoice.data.auction.privateSale) {
          let copy = JSON.parse(JSON.stringify(array[0]))
          copy.role = 'setUpFee'
          array.unshift(copy)
        }
        if ((array[0].invoice.data.auction.privateSale && array[0].invoice.data.auction.auctionDiscount) ||
          (array[0].invoice.data.user && array[0].invoice.data.user.discount)) {
          let copy = JSON.parse(JSON.stringify(array[0]))
          copy.role = 'discount'
          array.push(copy)
        }
        resultArray.push(...array)
      })
      this.setState({ data: resultArray.reverse(), total: data.total })
    } catch (error) {
      console.log(error)
    }
  }

  setSearchString = ({ target: { value } }) => {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.loadData, 300)
    this.setState({
      search: value
    }, this.debounced)
  }

  setSort = (field) => {
    let order = 'ASC'
    if (this.state.field === field) {
      order = this.state.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      field,
      order
    }, this.loadData)
  }

  getClearance (total, sold) {
    let clearance = 0
    if (total && sold) {
      clearance = sold / (total / 100)
    }
    return clearance.toFixed(0)
  }

  setPerPage = (perPage) => {
    this.setState({
      perPage
    }, this.loadData)
  }

  setPage = (page) => {
    this.setState({
      page
    }, this.loadData)
  }

  makeUserId (userId, type) {
    let userType = ''
    if (type) {
      userType = 'U'
    }
    return `${userType}${userId}`
  }

  linkToUser = (userId) => {
    this.props.history.push(`users/${userId}/show`)
  }

  async downloadCsv () {
    let { field, order, search, dateFrom, dateTo } = this.state

    if (dateFrom) {
      dateFrom = moment(dateFrom).tz(config.timeZone)
    }
    if (dateTo) {
      dateTo = moment(dateTo).tz(config.timeZone)
    }
    let response = await axios({
      url: `print/invoice-send-report?rangeFrom=${dateFrom}&rangeTo=${dateTo}&search=${search}&sortField=${field}&sortDirection=${order}`,
      method: 'GET',
      responseType: 'blob'
    })

    const blob = new window.Blob([response.data], { type: response.data.type })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    const contentDisposition = response.headers['content-disposition']
    let fileName = 'xero-report'
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length === 2) { fileName = fileNameMatch[1] }
    }
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
  }

  dateHandler = (date, field) => {
    this.setState({ [field]: date })
  }

  clearHandle = () => {
    this.setState({ dateFrom: '', dateTo: '' },
      () => {
        this.loadData()
      })
  }

  calculateDiscount = (invoice) => {
    let discountFee = 0
    let totalExcGST = 0
   // const listingFee = invoice.data.auction.listingFee ? invoice.data.auction.listingFee : invoice.priceRate
    invoice.data.lots.forEach(lot => {
      lot.lotDiscount = lot.lotDiscount ? lot.lotDiscount : 0
      if (lot.reofferAttempts === 0) {
        totalExcGST += lot.lotFee * ((100 - lot.lotDiscount) / 100)
      }
    })
    if (invoice.data.auction.auctionSetUpFee && invoice.data.auction.privateSale) {
      totalExcGST += invoice.data.auction.auctionSetUpFee
    }
    if (invoice.data.auction.privateSale) {
      const auctionDiscount = invoice.data.auction.auctionDiscount ? invoice.data.auction.auctionDiscount : 0
      discountFee = totalExcGST * auctionDiscount / 100
      return discountFee
    } else {
      const userDiscount = invoice.data.user.discount ? invoice.data.user.discount : 0
      discountFee = totalExcGST * userDiscount / 100
      return Math.round(discountFee)
    }
  }

  applyRangeHandle = () => {
    if (!this.state.dateFrom || !this.state.dateTo) {
      window.alert('Dates from and to should be filled')
    } else if (this.state.dateFrom > this.state.dateTo) {
      window.alert('Please select valid date')
    } else {
      this.loadData()
    }
  }

  render () {
    return (
      <div id='invoice-accounter-report-table'>
        <div className='reports-search-wrapper'>
          <Datetime
            timeFormat={0}
            dateFormat='DD/MM/YYYY'
            label={'label'}
            helperText={'some help text'}
            inputProps={{ placeholder: 'From' }}
            value={this.state.dateFrom}
            onChange={(date) => { this.dateHandler(date, 'dateFrom') }}
          />

          <Datetime
            timeFormat={false}
            dateFormat='DD/MM/YYYY'
            label={'label'}
            helperText={'some help text'}
            inputProps={{ placeholder: 'To' }}
            value={this.state.dateTo}
            onChange={(date) => { this.dateHandler(date, 'dateTo') }}
          />
          <Button className='range-button' onClick={this.applyRangeHandle}>apply range</Button>

          <Button className='range-button clearButton' onClick={this.clearHandle}>clear range</Button>

          <Button
            onClick={this.downloadCsv}
            color='primary'
            className='download-csv'
            variant='contained'>
            Xero report
          </Button>
          <MaterialTextField
            label='Search'
            placeholder='Search'
            className='search-text'
            onChange={this.setSearchString}
          />
        </div>
        <ReactTable
          resizable
          minRows={0}
          data={this.state.data}
          showPagination={this.props.isOpened}
          sortable={false}
          PaginationComponent={() => {
            return (
              <OwnPagination
                page={this.state.page}
                rowsPerPage={this.state.perPage}
                perPage={this.state.perPage}
                total={this.state.total}
                setPerPage={this.setPerPage}
                setPage={this.setPage} />
            )
          }}
          columns={[
            {
              id: '*ContactName',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('agentTradingName')
                  }}>*ContactName</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.agentTradingName + ' - ' + this.makeUserId(original.userId, original.userType)}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.agentTradingName + ' - ' + this.makeUserId(original.userId, original.userType)}</p>
              },
              width: 200
            },
            {
              id: 'EmailAddress',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('agentEmailAddress')
                  }}>EmailAddress</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.agentEmailAddress}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.agentEmailAddress}</p>
              },
              width: 200
            },
            {
              id: 'POAddressLine1',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('agentAddress')
                  }}>POAddressLine1</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.agentAddress}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.agentAddress}</p>
              },
              width: 200
            },
            {
              id: 'POAddressLine2',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('agentAddressLine2')
                  }}>POAddressLine2</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.agentAddressLine2}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.agentAddressLine2}</p>
              },
              width: 200
            },
            {
              id: 'POAddressLine3',
              Header: () => {
                return (
                  <div>POAddressLine3</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p />
                    </React.Fragment>
                  )
                }
                return <p />
              },
              width: 200
            },
            {
              id: 'POAddressLine4',
              Header: () => {
                return (
                  <div>POAddressLine4</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p />
                    </React.Fragment>
                  )
                }
                return <p />
              },
              width: 200
            },
            {
              id: 'POCity',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('town')
                  }}>POCity</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.town}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.town}</p>
              },
              width: 200
            },
            {
              id: 'PORegion',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('state')
                  }}>PORegion</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.state}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.state}</p>
              },
              width: 200
            },
            {
              id: 'POPostalCode',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('postcode')
                  }}>POPostalCode</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.postcode}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.postcode}</p>
              },
              width: 200
            },
            {
              id: 'POCountry',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('postcode')
                  }}>POCountry</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>Australia</p>
                    </React.Fragment>
                  )
                }
                return <p>Australia</p>
              },
              width: 200
            },
            {
              id: '*InvoiceNumber',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('invoiceNo')
                  }}>*InvoiceNumber</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{`FGI${original.invoiceNo}`}</p>
                    </React.Fragment>
                  )
                }
                return (
                  <p>{`FGI ${original.invoiceNo}`}</p>
                )
              },
              width: 200
            },
            {
              id: 'Reference',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('userId')
                  }}>Reference</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!original.role) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.searchNumber + ' - Lot ' + original.lotNo}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.searchNumber}</p>
              },
              width: 200
            },
            {
              id: '*InvoiceDate',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('dateOfIssue')
                  }}>*InvoiceDate</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{moment(original.dateOfIssue).tz(config.timeZone).format('DD/MM/YYYY')}</p>
                    </React.Fragment>
                  )
                }
                return (
                  <p>{moment(original.dateOfIssue).tz(config.timeZone).format('DD/MM/YYYY')}</p>
                )
              },
              width: 200
            },
            {
              id: '*DueDate',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('paymentDueDate')
                  }}>*DueDate</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.paymentDueDate ? moment(original.paymentDueDate).tz(config.timeZone).format('DD/MM/YYYY') : null}</p>
                    </React.Fragment>
                  )
                }
                return (
                  <p>{original.paymentDueDate ? moment(original.paymentDueDate).tz(config.timeZone).format('DD/MM/YYYY') : null}</p>
                )
              },
              width: 200
            },
            {
              id: 'InventoryItemCode',
              Header: () => {
                return (
                  <div>InventoryItemCode</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p />
                    </React.Fragment>
                  )
                }
                return <p />
              },
              width: 200
            },
            {
              id: '*Description',
              Header: () => {
                return (
                  <div>*Description</div>
                )
              },
              Cell: ({ original, index }) => {
                if (original.role === 'setUpFee') {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>Private Sale Set Up Fee</p>
                    </React.Fragment>
                  )
                }
                if (original.role === 'discount') {
                  if (original.invoice.data.auction.privateSale && original.invoice.data.auction.auctionDiscount) {
                    return (
                      <React.Fragment>
                        <div className='subHeader' />
                        <p>Discount - Auction Discount</p>
                      </React.Fragment>
                    )
                  } else {
                    return (
                      <React.Fragment>
                        <div className='subHeader' />
                        <p>Discount - User Discount</p>
                      </React.Fragment>
                    )
                  }
                } else {
                  if (!original.lotDiscount) {
                    return <p>Listing fees</p>
                  } else {
                    return <p>Listing fees - Lot Discount</p>
                  }
                }
              },
              width: 200
            },
            {
              id: '*Quantity',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('lotNoHead')
                  }}>*Quantity</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!original.role) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.lotNoHead}</p>
                    </React.Fragment>
                  )
                } else {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{'1'}</p>
                    </React.Fragment>
                  )
                }
              },
              width: 200
            },
            {
              id: '*UnitAmount',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('feeperHead')
                  }}>*UnitAmount</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!original.role) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{makePrice(original.feeperHead)}</p>
                    </React.Fragment>
                  )
                } else {
                  if (original.role === 'setUpFee') {
                    return (
                      <React.Fragment>
                        <div className='subHeader' />
                        <p>{makePrice(original.invoice.data.auction.auctionSetUpFee || 0)}</p>
                      </React.Fragment>
                    )
                  } else {
                    return (
                      <React.Fragment>
                        <div className='subHeader' />
                        <p>{makePrice(this.calculateDiscount(original.invoice)) ? `-${makePrice(this.calculateDiscount(original.invoice))}` : 0}</p>
                      </React.Fragment>
                    )
                  }
                }
              },
              width: 200
            },
            {
              id: 'Discount',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('agentDiscount')
                  }}>Discount</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!original.role) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.lotDiscount ? `${original.lotDiscount}%` : ''}</p>
                    </React.Fragment>
                  )
                } 
                // else {
                //   if (original.role === 'discount') {
                //     return (
                //       <React.Fragment>
                //         <div className='subHeader' />
                //         <p>{original.discount ? `${original.discount}%` : ''}</p>
                //       </React.Fragment>
                //     )
                //   }
                // }
                return <p>{''}</p>
              },
              width: 200
            },
            {
              id: '*AccountCode',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('stockType')
                  }}>*AccountCode</div>
                )
              },
              Cell: ({ original, index }) => {
                let code = '41100'

                if (original.stockType === 'Cattle') {
                  code = '41100'
                  if (original.studSale) {
                    code = '41200'
                  }
                } else if (original.stockType === 'Sheep') {
                  const tags = original.auctionTags.map(a => a.title)
                  if (tags.includes('PSG')) {
                    code = '41300'
                  } else {
                    code = '41400'
                  }
                  if (original.studSale) {
                    code = '41500'
                  }
                } else if (original.stockType === 'Goat') {
                  code = '41300'
                  if (original.studSale) {
                    code = '41600'
                  }
                } else if (original.stockType === 'Wool') {
                  code = '41700'
                }
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{code}</p>
                    </React.Fragment>
                  )
                }
                return <p>{code}</p>
              },
              width: 200
            },
            {
              id: '*TaxType',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('stockType')
                  }}>*TaxType</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.amountGST ? 'GST on Income' : 'GST Free Income'}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.amountGST ? 'GST on Income' : 'GST Free Income'}</p>
              },
              width: 200
            },
            {
              id: 'TrackingName1',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('state')
                  }}>TrackingName1</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{'State'}</p>
                    </React.Fragment>
                  )
                }
                return <p>{'State'}</p>
              },
              width: 200
            },
            {
              id: 'TrackingOption1',
              Header: () => {
                return (
                  <div>TrackingOption1</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.state}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.state}</p>
              },
              width: 200
            },
            {
              id: 'TrackingName2',
              Header: () => {
                return (
                  <div>TrackingName2</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>UVCode</p>
                    </React.Fragment>
                  )
                }
                return <p>UVCode</p>
              },
              width: 200
            },
            {
              id: 'TrackingOption2',
              Header: () => {
                return (
                  <div>TrackingOption2</div>
                )
              },
              Cell: ({ original, index }) => {
                return (
                  <React.Fragment>
                    <div className='subHeader' />
                    <p>{this.makeUserId(original.userId, original.userType)}</p>
                  </React.Fragment>
                )
              },
              width: 200
            },
            {
              id: 'Currency',
              Header: () => {
                return (
                  <div>Currency</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p />
                    </React.Fragment>
                  )
                }
                return <p />
              },
              width: 200
            },
            {
              id: 'BrandingTheme',
              Header: () => {
                return (
                  <div>BrandingTheme</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p />
                    </React.Fragment>
                  )
                }
                return <p />
              },
              width: 200
            },
            {
              id: 'Tags',
              Header: () => {
                return (
                  <div>Tags</div>
                )
              },
              Cell: ({ original }) => {
                if (original.invoice && original.invoice.data.auction.auctionTags) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.invoice.data.auction.auctionTags ? original.invoice.data.auction.auctionTags.map(e => e.title).join(', ') : ''}</p>
                    </React.Fragment>
                  )
                } else {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p />
                    </React.Fragment>
                  )
                }
              },
              width: 200
            }
          ]}
          defaultPageSize={this.state.defaultPageSize}
        />
      </div>
    )
  }
}
