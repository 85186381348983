import React from 'react'
import { MenuItemLink, getResources } from 'react-admin'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import ModalContainer from '../components/Modal/ModalContainer'
import '../styles/menu.css'

const Menu = ({ resources, onMenuClick }) => (
  <div className='menu'>
    {resources.map((resource, ind) => (
      <div className='menu-item' key={ind}>
        {resource.options.icon && <FontAwesomeIcon icon={resource.options.icon} className='menu-icon' />}
        <MenuItemLink to={`/${resource.name}`} activeClassName='active' primaryText={resource.options.label} onClick={onMenuClick} />
      </div>
    ))}
    {/* <ModalContainer /> */}
  </div>
)

const mapStateToProps = state => ({
  resources: getResources(state)
})

export default connect(mapStateToProps)(Menu)
