import React, { Component } from 'react'

import DebounceField from './DebounceField'

export default class FormNumberField extends Component {
  onChange = (obj) => {
    if (this.props.convertToNumber) {
      obj.value = Number(obj.value)
    }
    this.props.onChange(obj)
  }
  render () {
    const { value, newIndexes, maxDecimalDigits, validation, publicId } = this.props
    return (
      <DebounceField
        disabled={this.props.disabled}
        onChange={this.onChange}
        value={value}
        newIndexes={newIndexes}
        maxDecimalDigits={maxDecimalDigits}
        validation={validation}
        field='value'
        type='number'
        publicId={publicId}
      />
    )
  }
}
