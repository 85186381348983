import React from "react";
import { Form } from "redux-form";
import TextField from "@material-ui/core/TextField";
import IconPlus from "@material-ui/icons/Add";
import IconRemove from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import "./AuctionTabs.scss";

export class AuctionTabs extends Form {
  constructor(props, context) {
    super(props, context);

    this.state = {
      auctionTabs: [{ tabName: "", description: "" }],
    };

    this.reduxForm = context._reduxForm;
    this.formValues = this.reduxForm.getValues();
    this.auctionTabs = this.formValues.auctionTabs;
  }

  componentDidMount = async () => {
    if (this.auctionTabs && this.auctionTabs.length) {
      this.setState({ auctionTabs: this.auctionTabs });
    }
  };

  onAddClick = () => {
    this.setState({
      auctionTabs: [
        ...this.state.auctionTabs,
        { tabName: "", description: "" },
      ],
    });
  };

  onRemoveClick = (index) => {
    if (this.state.auctionTabs.length <= 1) {
      this.setState(
        { auctionTabs: [{ tabName: "", description: "" }] },
        this.updateReduxForm
      );
    } else {
      const copyOfTabs = Object.assign([], this.state.auctionTabs);
      copyOfTabs.splice(index, 1);
      this.setState({ auctionTabs: copyOfTabs }, this.updateReduxForm);
    }
  };

  onChangeName = (index, value) => {
    const copyOfTabs = Object.assign([], this.state.auctionTabs);
    copyOfTabs[index].tabName = value;
    this.setState({ auctionTabs: copyOfTabs }, this.updateReduxForm);
  };

  onChangeDesc = (index, value) => {
    const copyOfTabs = Object.assign([], this.state.auctionTabs);
    copyOfTabs[index].description = value;
    this.setState({ auctionTabs: copyOfTabs }, this.updateReduxForm);
  };

  updateReduxForm = () => {
    this.reduxForm.dispatch(
      this.reduxForm.change("auctionTabs", this.state.auctionTabs)
    );
  };

  render() {
    return (
      <div className="auction-tabs-container" style={{ marginTop: "10px" }}>
        {this.state.auctionTabs.map((link, index) => {
          return (
            <>
              <TextField
                className="auction-tabs-text-name"
                name="name"
                style={{ width: "85px" }}
                onChange={({ target: { value } }) => {
                  this.onChangeName(index, value);
                }}
                label={`Tab ${index + 1}`}
                value={link.tabName}
                type="text"
                InputProps={{ inputProps: { min: 0 } }}
              />
              <TextField
                className="auction-tabs-text-description"
                name="description"
                style={{ width: "85px" }}
                onChange={({ target: { value } }) => {
                  this.onChangeDesc(index, value);
                }}
                onBlur={this.onBlur}
                label={"Description"}
                value={link.description}
                type="text"
                InputProps={{ inputProps: { min: 0, max: 59 } }}
              />
              <label
                className="icon-button-file-remove"
                htmlFor="icon-button-file-remove"
              >
                <IconButton
                  id={"icon-button-file-remove"}
                  onClick={() => {
                    this.onRemoveClick(index);
                  }}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <IconRemove />
                </IconButton>
              </label>
              {index === this.state.auctionTabs.length - 1 && (
                <label
                  className="icon-button-file-plus"
                  htmlFor="icon-button-file-plus"
                >
                  <IconButton
                    id={"icon-button-file-remove"}
                    onClick={this.onAddClick}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <IconPlus />
                  </IconButton>
                </label>
              )}
            </>
          );
        })}
      </div>
    );
  }
}
