import React, { Component } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import debounce from 'lodash/debounce'

import formatTitleForType, { selectableTypes } from '../../../helpers/formatTitleForType'

export default class DebounceSelectField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: props.value
    }

    this.updateValue = this.updateValue.bind(this)
    this.debounced = null
  }

  updateValue ({ target: { value } }) {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(() => this.props.onChange({
      value,
      indexes: this.props.newIndexes,
      field: this.props.field
    }), 300)
    this.setState({ value }, this.debounced)
  }

  render () {
    let options = selectableTypes
    if (this.props.newIndexes.length > 1) {
      options = options.filter(el => el !== 'group')
    }
    if (this.props.newIndexes.length > 2) {
      options = options.filter(el => el !== 'repeatForBool' && el !== 'repeatForNumber')
    }
    return (
      <div className='type'>
        <Select
          onChange={this.updateValue}
          value={this.state.value}
          renderValue={value => {
            return (
              <div className='input'>
                {formatTitleForType(value)}
              </div>
            )
          }}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>{formatTitleForType(option)}</MenuItem>
          ))}
        </Select>
      </div>
    )
  }
}
