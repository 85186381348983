import React from 'react'
import { Collapse } from 'react-collapse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function collapsible ({ title, exportLink, isOpened, changeCollapse, body }) {
  function hendleCollapsClick () {
    if (isOpened) {
      changeCollapse(false)
    } else {
      changeCollapse(true)
    }
  }

  return (
    <div className='collapse-wrapper'>
      <div
        className='header'
        onClick={hendleCollapsClick}>
        {title}
        <div
          className='button'
        >
          <FontAwesomeIcon icon={isOpened ? 'angle-up' : 'angle-down'} />
          <span>{isOpened ? 'Hide' : 'Show'}</span>
        </div>
      </div>
      <Collapse isOpened={isOpened}>
        {exportLink ? <a className='export-button' href={exportLink} target='_blank' rel='noopener noreferrer'>Export to CSV</a> : null}
        <div className='body'>
          {body}
        </div>
      </Collapse>
    </div>
  )
}
