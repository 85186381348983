import { rootUrl } from './config/index'

export default {
  exceptedStates: ['NSW', 'QLD'],
  requiredStatuses: ['approved-buyer', 'approved-buyer-updated', 'approved-seller', 'approved-buyer-seller'],
  timeZone: 'Australia/Sydney',
  taxPerHead: {
    sheep: 90,
    cattle: 700,
    goat: 90
  },
  lot: {
    maxHeads: 20000,
    maxAdjustWeight: 20,
    minAdjustWeight: -20,
    maxMediaLimit: 20,
    maxDocumentLimit: 6
  },
  perPage: 10,
  rowsPerPageOptions: [10, 25, 50],
  adminAccess: 'farmgate-admin-access',
  rootUrl
}
