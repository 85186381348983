import React, { Component } from 'react'
import {
  GET_ONE,
  SimpleForm,
  Toolbar,
  TextInput,
  UPDATE
} from 'react-admin'
import update from 'immutability-helper'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'
import SaveIcon from '@material-ui/icons/Save'

import { dataProvider } from '../../App'
import './styles.css'

function Transition (props) {
  return <Slide direction='up' {...props} />
}

class ContactUsListComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      data: {}
    }
    this.loadContacts = this.loadContacts.bind(this)
    this.updateContacts = this.updateContacts.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.changePhone = this.changePhone.bind(this)
    this.changeText = this.changeText.bind(this)
    this.changeEmail = this.changeEmail.bind(this)
    this.changeSecondaryEmail = this.changeSecondaryEmail.bind(this)
    this.loadContacts()
  }

  async loadContacts () {
    let contacts = await dataProvider(GET_ONE, 'contacts/main', {})
    if (contacts) {
      this.setState({
        data: contacts.data
      })
    }
  }

  handleOpen () {
    this.setState({
      open: true
    })
  }

  async updateContacts () {
    await dataProvider(UPDATE, 'contacts/main', { data: this.state.data }).then(this.handleClose)
  }

  handleClose () {
    this.setState({
      open: false
    })
  }

  changePhone (event) {
    this.setState({
      data: update(this.state.data, {
        callPhone: {
          $set: event.target.value
        }
      })
    })
  }

  changeText (event) {
    this.setState({
      data: update(this.state.data, {
        textPhone: {
          $set: event.target.value
        }
      })
    })
  }

  changeEmail (event) {
    this.setState({
      data: update(this.state.data, {
        email: {
          $set: event.target.value
        }
      })
    })
  }

  changeSecondaryEmail (event) {
    this.setState({
      data: update(this.state.data, {
        secondaryEmail: {
          $set: event.target.value
        }
      })
    })
  }

  render () {
    const SaveButton = () => {
      return (
        <Button
          onClick={this.handleOpen}
          className='save-button'
        >
          <SaveIcon />
          Save
        </Button>
      )
    }
    const EditToolbar = props => {
      return (
        <Toolbar {...props} >
          <SaveButton />
        </Toolbar>
      )
    }
    return (
      <div>
        {Object.keys(this.state.data).length &&
        <div>
          <p className='page-title'>Edit Contact Us</p>
          <div className='edit-contact-us'>
            <SimpleForm toolbar={<EditToolbar />} record={this.state.data}>
              <TextInput source='email' onChange={this.changeEmail} />
              <TextInput source='secondaryEmail' onChange={this.changeSecondaryEmail} />
              <TextInput source='callPhone' onChange={this.changePhone} />
              <TextInput source='textPhone' onChange={this.changeText} />
            </SimpleForm>
          </div>
          <Dialog
            open={this.state.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby='alert-dialog-slide-title'
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle id='alert-dialog-slide-title'>
              {'Are you sure?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                Please confirm you want to edit your contact details?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.updateContacts} color='primary'>
                Yes
              </Button>
              <Button onClick={this.handleClose} color='primary'>
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        }
      </div>
    )
  }
}

export const ContactUsList = (props) => {
  const { basePath, ...newProps } = props
  return (
    <ContactUsListComponent {...newProps} />
  )
}
