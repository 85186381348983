import io from 'socket.io-client'
import config from '../config'

let sockets = {}

function connection () {
  const bidsNamespace = 'bids'
  const catalogNamespace = 'catalog'

  const socketNamespaces = [bidsNamespace, catalogNamespace]
  sockets.emit = (namespace, ...leftArguments) => {
    sockets[namespace].emit.apply(sockets[namespace], leftArguments)
  }

  socketNamespaces.forEach(namespace => {
    const connect = function () {
      sockets[namespace].emit('login', {}, (err) => {
        if (err) {
          console.log('socket login error', err)
        } else {
          console.log(`${namespace}: connected`)
        }
      })
    }
    sockets[namespace] = io(`${config.rootUrl}${namespace}`, {
      jsonp: false,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionDelayMax: 2500,
      transports: ['websocket']
    })
    sockets[namespace].on('connect', connect)
    sockets[namespace].on('reconnect', connect)
  })
}

export default {
  connection,
  sockets
}
