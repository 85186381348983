import {
  CREATE,
  UPDATE,
  DELETE,
  fetchUtils
} from 'react-admin'
import { pick } from 'lodash'
import handleError from './handleError'
import fileUploader from './fileUploader'
import draftToHtml from 'draftjs-to-html'
import { convertToRaw } from 'draft-js'
import convertDataRequestToHTTP from './providerService'
import convertHTTPResponse from './convertHTTPResponse'
import auctionMiddlewareService from './auction/auctionMiddlewareService'
import lotMiddlewareService from './lot/lotMiddlewareService'
const updateKindFields = [
  'title',
  'form',
  'listingPriceCents',
  'specialPriceCents'
]

const updateUserFields = [
  'apiKey',
  'type',
  'role',
  'settings',
  'firstName',
  'lastName',
  'email',
  'phone',
  'secondaryPhone',
  'PIC',
  'anotherStatePIC',
  'ABN',
  'agentName',
  'agentPhone',
  'agentTradingName',
  'tradingName',
  'profession',
  'otherProfession',
  'stockTypes',
  'approvedStockTypes',
  'officeAddress.address1',
  'officeAddress.address2',
  'officeAddress.postcode',
  'officeAddress.state',
  'officeAddress.town',
  'referee1.firstName',
  'referee1.lastName',
  'referee1.tradingName',
  'referee1.email',
  'referee1.phone',
  'referee1.relationship',
  'referee2.firstName',
  'referee2.lastName',
  'referee2.tradingName',
  'referee2.email',
  'referee2.phone',
  'referee2.relationship',
  'yearsInIndustry',
  'postalAddress.address1',
  'postalAddress.address2',
  'postalAddress.postcode',
  'postalAddress.state',
  'postalAddress.town',
  'agencyName',
  'status',
  'approved',
  'adminNotes',
  'adminComments',
  'disputeStrikes',
  'discount',
  'additionalState',
  'userTag',
  'registeredForGST',
  'areYouUsingAgent',
  'isRequestedAsBuyer',
  'isRequestedAsSeller',
  'agentName',
  /*'bankDetails.accountName',
  'bankDetails.BSB',
  'bankDetails.accountNumber',*/
  'doYouTradeThroughCompany',
  'customerDetails.companyName',
  'customerDetails.legalNameOfTradingEntity',
  'customerDetails.ACN',
  'customerDetails.givenName',
  'customerDetails.lastName'
]

export const restProvider = function (httpClient = fetchUtils.fetchJson) {
  return (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE || type === CREATE) {
      if (params.data.icon) {
        params.icon = params.data.icon
      }
      if (params.data.image) {
        params.image = params.data.image
      }
      if (params.data.logo) {
        params.logo = params.data.logo
      }
      if (params.data.logoIos) {
        params.logoIos = params.data.logoIos
      }
      /*
      if (params.data.policyFile) {
        params.policyFile = params.data.policyFile
      }
      if (params.data.licenseFile) {
        params.licenseFile = params.data.licenseFile
      }
      */
      if (params.data.documents) {
        params.documents = params.data.documents
      }
      if (params.data.adminAttachments) {
        params.adminAttachments = params.data.adminAttachments
      }
      if (params.data.media) {
        params.media = params.data.media
      }
    }
    if (type === UPDATE) {
      let url = resource
      if (params.type === 'lot') {
        if (params.data.countWeighed === null) {
          params.data.countWeighed = 0;
        }
        return lotMiddlewareService.updateLot(params, type, resource, httpClient, url)
      }
      if (resource === 'faqs') {
        params.data.questions = params.data.questions.map(question => {
          let newQuestion = Object.assign({}, question)
          if (typeof newQuestion.answer !== 'string') {
            let htmlValue = draftToHtml(convertToRaw(newQuestion.answer.getCurrentContent()))
            newQuestion.answer = htmlValue
          }
          return newQuestion
        })
      }
      if (resource === 'auctions') {
        return auctionMiddlewareService.updateAuction(params, type, resource, httpClient)
      }
      if (resource === 'users') {
        params.data = { user: pick(params.data, updateUserFields) }
        if (type === 'UPDATE') {
          params.type = 'UPDATE'
        }
      }
      return fileUploader(resource, params)
        .then(() => {
          if (resource === 'kinds') {
            params.data = { kind: pick(params.data, updateKindFields) }
          }
          if (resource === 'contacts/main') {
            return httpClient(url, {
              method: 'PUT',
              data: { contact: params.data }
            })
          } else {
            if (resource.indexOf('emails/config') !== -1) {
              return httpClient(url, {
                method: 'PUT',
                data: params.data
              })
            } else {
              if (params.chosenUser) {
                return httpClient(url + '/' + params['id'] + '/soldAA/' + params.chosenUser, {
                  method: 'PUT',
                  data: params.data
                })
              } else {
                return httpClient(url + '/' + params['id'], {
                  method: 'PUT',
                  data: params.data
                })
              }
            }
          }
        })
        .then((response) => {
          if (resource === 'contacts/main') {
            return
          }
          if (resource.indexOf('emails/config') !== -1) {
            return
          }
          const one = response.data[resource.slice(0, resource.length - 1)] || response.data[params.type]
          one.id = one['_id']
          return {
            data: one
          }
        })
        .catch(handleError)
    }
    if (type === CREATE) {
      let url = resource
      if (resource === 'auctions') {
        return auctionMiddlewareService.createAuction(params, resource, httpClient)
      }
      if (resource === 'users') {
        params.data = { user: {...params.data, type: params.data.type ? params.data.type.toLowerCase() : 'guest'} }
      }
      return httpClient(url, {
        method: 'POST',
        data: params.data
      })
        .then(async response => {
          if (resource === 'invoice/template') {
            return {
              data: response
            }
          } else {
            const one = response.data[resource.slice(0, resource.length - 1)]
            if (one) {
              one.id = one['_id']
              if (resource === 'auctions') {
                params.id = one['_id']
                await fileUploader(resource, params)
              }
              return {
                data: one
              }
            } else {
              return {
                data: response
              }
            }
          }
        })
        .catch(handleError)
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE) {
      if (params.ids) {
        return Promise.all(params.ids.map(function (id) {
          return httpClient(resource + '/' + id, {
            method: 'DELETE'
          })
        }))
          .then((responses) => {
            return {
              data: responses.map(function (response) {
                return response.json
              })
            }
          })
          .catch(handleError)
      } else if (params.id) {
        if (resource === 'home') {
          return httpClient('users/deny-user-upgrade-request/' + params.id, {
            method: 'DELETE',
            data: { requestType: 'userAccessRequestDeny' }
          })
            .then((response) => {
              if (response.status === 200) {
                window.location.reload()
              }
              return {
                data: response.json || { id: params.id }
              }
            })
            .catch(handleError)
        } else {
          return httpClient(resource + '/' + params.id, {
            method: 'DELETE'
          })
            .then((response) => {
              return {
                data: response.json || { id: params.id }
              }
            })
            .catch(handleError)
        }
      }
    }
    let { url, options } = convertDataRequestToHTTP(type, resource, params)
    if (resource === 'home') {
      return
    }
    return httpClient(url, options)
      .then((response) => {
        return convertHTTPResponse(response, type, resource, params)
      })
      .catch(handleError)
  }
}
