import React from 'react'
import { Form } from 'redux-form'
import { setWith, clone, get, unset } from 'lodash'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import './AssessmentFormStyles.css'
import hasMarked from '../../helpers/hasMarked'
import generatePathForKind from '../../helpers/generatePathForKind'
import { generateShortIdsInReference, generateShortId } from '../../helpers/generateShortIds'
import FormTextField from './fields/FormTextField'
import FormIsPublicField from './fields/FormIsPublicField'
import FormIsRequiredField from './fields/FormIsRequiredField'
import FormRemoveButton from './fields/FormRemoveButton'
import FormAddButton from './fields/FormAddButton'
import FormOptionsField from './fields/FormOptionsField'
import FormTypeField from './fields/FormTypeField'
import FormDefaultValueSwitchField from './fields/FormDefaultValueSwitchField'
import KindEditActions from './KindEditActions'

export default class AssessmentFormEdit extends Form {
  constructor (props, context) {
    super(props, context)

    this.reduxForm = context._reduxForm

    let form = this.reduxForm.getValues().form
    generateShortIdsInReference(form)
    this.state = {
      form
    }

    this.renderGroup = this.renderGroup.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.addSection = this.addSection.bind(this)
    this.removeSection = this.removeSection.bind(this)
  }

  onChange ({ value, indexes, field, lastArrayName }) {
    let path = generatePathForKind(indexes, field, lastArrayName)
    let form = setWith(clone(this.state.form), path, value, clone)
    if (field === 'type') {
      let pathTypeObject = generatePathForKind(indexes)
      const formsPath = pathTypeObject + '.forms'
      const prevForms = get(form, formsPath)
      const optionsPath = pathTypeObject + '.options'
      const prevOptions = get(form, optionsPath)
      if (
        value === 'repeatForBool' ||
        value === 'repeatForNumber' ||
        value === 'group'
      ) {
        unset(form, `${pathTypeObject}.isPublic`)
        unset(form, `${pathTypeObject}.isRequired`)
        form = setWith(clone(form), pathTypeObject, get(form, pathTypeObject), clone)
        if (!prevForms) {
          form = setWith(clone(form), formsPath, [], clone)
        }
        if (prevOptions) {
          form = setWith(clone(form), optionsPath, [], clone)
        }
      } else if (value === 'dropdown' ||
        value === 'multiDropdown' ||
        value === 'select') {
        if (prevForms) {
          form = setWith(clone(form), formsPath, [], clone)
        }
        if (!prevOptions) {
          form = setWith(clone(form), optionsPath, [], clone)
        }
        if (!get(form, pathTypeObject).isPublic) {
          get(form, pathTypeObject).isPublic = true
          unset(form, `${pathTypeObject}.forms`)
          form = setWith(clone(form), pathTypeObject, get(form, pathTypeObject), clone)
        }
        if (!get(form, pathTypeObject).isRequired) {
          get(form, pathTypeObject).isRequired = false
          unset(form, `${pathTypeObject}.forms`)
          form = setWith(clone(form), pathTypeObject, get(form, pathTypeObject), clone)
        }
      } else {
        if (!get(form, pathTypeObject).isPublic) {
          get(form, pathTypeObject).isPublic = true
          form = setWith(clone(form), pathTypeObject, get(form, pathTypeObject), clone)
        }
        if (!get(form, pathTypeObject).isRequired) {
          get(form, pathTypeObject).isRequired = false
          form = setWith(clone(form), pathTypeObject, get(form, pathTypeObject), clone)
        }
        if (prevForms) {
          form = setWith(clone(form), formsPath, [], clone)
        }
        if (prevOptions) {
          form = setWith(clone(form), optionsPath, [], clone)
        }
      }
    }
    this.setState({ form }, () => {
      this.reduxForm.dispatch(this.reduxForm.change(`form${path[0] === '[' ? path : '.' + path}`, value))
    })
  }
  onDragEnd (result) {
    if (!result.destination) {
      return
    }
    let destinationId = result.destination.droppableId
    let sourceId = result.source.droppableId
    let destinationIndex = result.destination.index
    let sourceIndex = result.source.index
    let type = result.type
    let sourceIndexes = []
    this.state.form.forms.find((form, formIndex) => {
      if (form._shortId === sourceId) {
        sourceIndexes.push(formIndex)
        return true
      }
      return form.forms && !!form.forms.find((nestedForm, nestedIndex) => {
        if (nestedForm._shortId === sourceId) {
          sourceIndexes.push(formIndex)
          sourceIndexes.push(nestedIndex)
          return true
        }
        return nestedForm.forms && !!nestedForm.forms.find((childForm, childIndex) => {
          if (childForm._shortId === sourceId) {
            sourceIndexes.push(formIndex)
            sourceIndexes.push(nestedIndex)
            sourceIndexes.push(childIndex)
            return true
          } else {
            return false
          }
        })
      })
    })

    let destinationPath = ''
    let sourcePath = ''
    let destinationObject = {}
    let sourceObject = {}
    if (destinationId !== sourceId) { // if different tables
      let destinationIndexes = []
      this.state.form.forms.find((form, formIndex) => {
        if (form._shortId === destinationId) {
          destinationIndexes.push(formIndex)
          return true
        }
        return form.forms && !!form.forms.find((nestedForm, nestedIndex) => {
          if (nestedForm._shortId === destinationId) {
            destinationIndexes.push(formIndex)
            destinationIndexes.push(nestedIndex)
            return true
          }
          return nestedForm.forms && !!nestedForm.forms.find((childForm, childIndex) => {
            if (childForm._shortId === destinationId) {
              destinationIndexes.push(formIndex)
              destinationIndexes.push(nestedIndex)
              destinationIndexes.push(childIndex)
              return true
            } else {
              return false
            }
          })
        })
      })
      sourcePath = generatePathForKind(sourceIndexes)
      sourceObject = sourcePath ? get(this.state.form, sourcePath) : this.state.form
      destinationPath = generatePathForKind(destinationIndexes)
      destinationObject = destinationPath ? get(this.state.form, destinationPath) : this.state.form
    } else {
      sourcePath = destinationPath = generatePathForKind(sourceIndexes)
      sourceObject = destinationObject = sourcePath ? get(this.state.form, sourcePath) : this.state.form
    }
    // firstly remove, then add
    let removed = sourceObject[type === 'options' ? 'options' : 'forms'].splice(sourceIndex, 1)
    destinationObject[type === 'options' ? 'options' : 'forms'].splice(destinationIndex, 0, removed[0])

    let form = setWith(clone(this.state.form), sourcePath, sourceObject, clone)
    this.setState({ form }, () => {
      this.reduxForm.dispatch(this.reduxForm.change(sourcePath, sourceObject))
      if (destinationId !== sourceId) { // if different tables
        form = setWith(clone(this.state.form), destinationPath, destinationObject, clone)
        this.setState({ form }, () => {
          this.reduxForm.dispatch(this.reduxForm.change(destinationPath, destinationObject))
        })
      }
    })
  }
  addSection ({ indexes, field }) {
    let path = ''
    if (indexes.length > 0) {
      path = generatePathForKind(indexes)
    }
    let object = path.length > 0 ? get(this.state.form, path) : this.state.form
    let newObject = {
      _shortId: generateShortId()
    }
    if (field === 'options') {
      newObject.label = ''
    } else {
      newObject.title = ''
      newObject.isMarked = false
      if (indexes.length === 0) {
        newObject.type = 'group'
        newObject.forms = []
      } else {
        newObject.type = 'text'
        newObject.isPublic = true
        newObject.isRequired = false
      }
    }
    object[`${field || 'forms'}`].push(newObject)
    let form = path.length > 0 ? setWith(clone(this.state.form), path, object, clone) : object
    this.setState({ form }, () => {
      this.reduxForm.dispatch(this.reduxForm.change(path, object))
    })
  }
  removeSection ({ indexes, index, field }) {
    let path = ''
    if (indexes.length > 0) {
      path = generatePathForKind(indexes)
    }
    let object = path.length > 0 ? get(this.state.form, path) : this.state.form
    if (object.forms && hasMarked(object.forms[index])) {
      window.alert('Can\'t remove this because group has marked field(s)')
      return
    }
    object[`${field || 'forms'}`].splice(index, 1)
    let form = path.length > 0 ? setWith(clone(this.state.form), path, object, clone) : object
    this.setState({ form }, () => {
      this.reduxForm.dispatch(this.reduxForm.change(path, object))
    })
  }

  renderGroup (forms, indexes = []) {
    return forms.map((form, index) => {
      if (!form._shortId) {
        return null
      }
      let depth = indexes.length % 3
      let newIndexes = indexes.concat([index])
      if (
        form.type === 'group' ||
        form.type === 'repeatForNumber' ||
        form.type === 'repeatForBool'
      ) {
        return (
          <Draggable
            key={form._shortId}
            draggableId={form._shortId}
            index={index}
          >
            {(draggableProvided) => (
              <div
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
              >
                <Droppable
                  droppableId={form._shortId}
                  type={form.type}
                >
                  {(droppableProvided) => (
                    <div
                      className={`depth-${depth} ${form.isMarked ? 'marked' : ''}`}
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {!form.isMarked && <FormRemoveButton
                        removeSection={this.removeSection}
                        indexes={indexes}
                        index={index}
                      />}
                      <FormTextField
                        title={form.title}
                        newIndexes={newIndexes}
                        onChange={this.onChange}
                      />
                      <FormTypeField
                        type={form.type}
                        newIndexes={newIndexes}
                        onChange={this.onChange}
                        isMarked={form.isMarked}
                      />
                      {this.renderGroup(form.forms, newIndexes)}
                      <FormAddButton
                        addSection={this.addSection}
                        indexes={newIndexes}
                      />
                    </div>
                  )}
                </Droppable>
              </div>
            )}
          </Draggable>
        )
      } else if (form.type === 'dropdown' || form.type === 'multiDropdown') {
        return (
          <Draggable
            key={form._shortId}
            draggableId={form._shortId}
            index={index}
          >
            {(draggableProvided) => (
              <div
                className={`depth-${depth} ${form.isMarked ? 'marked' : ''}`}
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
              >
                {!form.isMarked && <FormRemoveButton
                  removeSection={this.removeSection}
                  indexes={indexes}
                  index={index}
                />}
                <FormTextField
                  title={form.title}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                />
                <FormIsPublicField
                  isPublic={form.isPublic}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                />
                <FormIsRequiredField
                  isRequired={form.isRequired}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                />
                <FormTypeField
                  type={form.type}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                  isMarked={form.isMarked}
                />
                <h5>Options:</h5>
                <Droppable
                  droppableId={form._shortId}
                  type='options'
                >
                  {(droppableProvided) => (
                    <div
                      className='dropdown-options'
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {form.options.map((option, ind) => (
                        <Draggable
                          key={option._shortId}
                          draggableId={option._shortId}
                          index={ind}
                        >
                          {(draggableProvided) => (
                            <div
                              className={`element depth-${(indexes.length + 1) % 3}`}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                            >
                              <FormRemoveButton
                                removeSection={this.removeSection}
                                indexes={newIndexes}
                                index={ind}
                                field='options'
                              />
                              <FormOptionsField
                                onChange={this.onChange}
                                option={option}
                                newIndexes={newIndexes.concat(ind)}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
                <FormAddButton
                  addSection={this.addSection}
                  indexes={newIndexes}
                  field='options'
                />
              </div>
            )}
          </Draggable>
        )
      } else if (form.type === 'select') {
        return (
          <Draggable
            key={form._shortId}
            draggableId={form._shortId}
            index={index}
          >
            {(draggableProvided) => (
              <div
                className={`depth-${depth} marked`}
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
              >
                <FormTextField
                  title={form.title}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                />
                <FormIsPublicField
                  isPublic={form.isPublic}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                />
                <FormIsRequiredField
                  isRequired={form.isRequired}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                />
                <FormTypeField
                  type={form.type}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                  isMarked
                />
                <h5>Options:</h5>
                <Droppable
                  droppableId={form._shortId}
                  type='options'
                >
                  {(droppableProvided) => (
                    <div
                      className='dropdown-options'
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {form.options.map((option, ind) => (
                        <Draggable
                          key={option._shortId}
                          draggableId={option._shortId}
                          index={ind}
                        >
                          {(draggableProvided) => (
                            <div
                              className={`element depth-${(indexes.length + 1) % 3}`}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                            >
                              <FormOptionsField
                                onChange={this.onChange}
                                option={option}
                                newIndexes={newIndexes.concat(ind)}
                              />
                              {
                                form.defaultValue &&
                                (
                                  <FormDefaultValueSwitchField
                                    defaultValue={form.defaultValue}
                                    optionValue={option.value}
                                    newIndexes={newIndexes}
                                    onChange={this.onChange}
                                  />
                                )
                              }
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </div>
            )}
          </Draggable>
        )
      } else if ( // bool
        form.type === 'bool' ||
        form.type === 'checkbox'
      ) {
        return (
          <Draggable
            key={form._shortId}
            draggableId={form._shortId}
            index={index}
          >
            {(draggableProvided) => (
              <div
                className={`depth-${depth} ${form.isMarked ? 'marked' : ''}`}
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
              >
                {!form.isMarked && <FormRemoveButton
                  removeSection={this.removeSection}
                  indexes={indexes}
                  index={index}
                />}
                <FormTextField
                  title={form.title}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                />
                <FormIsPublicField
                  isPublic={form.isPublic}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                />
                <FormIsRequiredField
                  isRequired={form.isRequired}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                />
                <FormTypeField
                  type={form.type}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                  isMarked={form.isMarked}
                />
              </div>
            )}
          </Draggable>
        )
      } else if ( // number
        form.type === 'rating' ||
        form.type === 'weight'
      ) {
        return (
          <Draggable
            key={form._shortId}
            draggableId={form._shortId}
            index={index}
          >
            {(draggableProvided) => (
              <div
                className={`depth-${depth} ${form.isMarked ? 'marked' : ''}`}
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
              >
                {!form.isMarked && <FormRemoveButton
                  removeSection={this.removeSection}
                  indexes={indexes}
                  index={index}
                />}
                <FormTextField
                  title={form.title}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                />
                <FormIsPublicField
                  isPublic={form.isPublic}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                />
                <FormIsRequiredField
                  isRequired={form.isRequired}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                />
                <FormTypeField
                  type={form.type}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                  isMarked={form.isMarked}
                />
              </div>
            )}
          </Draggable>
        )
      } else if ( // string
        form.type === 'text' ||
        form.type === 'textarea' ||
        form.type === 'date' ||
        form.type === 'number' ||
        form.type === 'user' ||
        form.type === 'phone' ||
        form.type === 'email' ||
        form.type === 'picNumber' ||
        form.type === 'tradingName'
      ) {
        return (
          <Draggable
            key={form._shortId}
            draggableId={form._shortId}
            index={index}
          >
            {(draggableProvided) => (
              <div
                className={`depth-${depth} ${form.isMarked ? 'marked' : ''}`}
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
              >
                {!form.isMarked && <FormRemoveButton
                  removeSection={this.removeSection}
                  indexes={indexes}
                  index={index}
                />}
                <FormTextField
                  title={form.title}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                />
                <FormIsPublicField
                  isPublic={form.isPublic}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                />
                <FormIsRequiredField
                  isRequired={form.isRequired}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                />
                <FormTypeField
                  type={form.type}
                  newIndexes={newIndexes}
                  onChange={this.onChange}
                  isMarked={form.isMarked}
                />
              </div>
            )}
          </Draggable>
        )
      } else {
        return null
      }
    })
  }

  render () {
    let { form } = this.state
    return (
      <div className='assesstment-wrapper editable'>
        <FormTextField
          title={form.title}
          newIndexes={[]}
          onChange={this.onChange}
        />
        <DragDropContext
          onDragEnd={this.onDragEnd}
        >
          <Droppable
            droppableId='root'
            type='root'
          >
            {(droppableProvided) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {form.forms && this.renderGroup(form.forms, [])}
                <FormAddButton
                  addSection={this.addSection}
                  indexes={[]}
                />
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <KindEditActions reduxForm={this.reduxForm} form={form} />
      </div>
    )
  }
}
