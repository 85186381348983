import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import debounce from 'lodash/debounce'
import { RATING_VALIDATION_MAX, RATING_VALIDATION_MIN } from '../../../constants/index'

export default class DebounceField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: this.convertValue(this.props.value)
    }

    if (this.props.validation) {
      switch (this.props.validation) {
        case 'rating':
          this.isValid = this.ratingValidation
          break
        default:
          this.isValid = null
      }
    }

    this.onChange = this.onChange.bind(this)
    this.onReset = this.onReset.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.debounced = null
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.value !== this.props.value && this.state.value !== this.props.value) {
      this.setState({ value: this.convertValue(this.props.value) })
    }
  }

  ratingValidation (value) {
    return value <= RATING_VALIDATION_MAX && value >= RATING_VALIDATION_MIN
  }

  convertValue (value) {
    if (this.props.type === 'number' && value !== null) {
      const maxDecimalDigits = this.props.maxDecimalDigits !== undefined ? this.props.maxDecimalDigits : 0
      value = parseFloat(value).toFixed(maxDecimalDigits)
    }
    if (value && this.props.publicId === 'vendorPic') {
      return value.toUpperCase()
    } else if (value && (this.props.publicId === 'lowAge' || this.props.publicId === 'highAge' || this.props.publicId === 'micronTested')) {
      return value.replace(/\.00$/, '')
    } else {
      return value
    }
  }

  onBlur () {
    this.setState({ value: this.convertValue(this.state.value) })
  }

  onChange ({ target: { value } }) {
    this.updateValue(value)
  }

  onReset () {
    this.updateValue(null)
  }

  updateValue (value) {
    if (!this.isValid || this.isValid(value)) {
      if (this.debounced && this.debounced.cancel) {
        this.debounced.cancel()
      }
      this.debounced = debounce(() => this.props.onChange({
        value: this.convertValue(value),
        indexes: this.props.newIndexes,
        field: this.props.field
      }), 300)
      if (this.props.publicId === 'vendorPic') {
        this.setState({ value: value.toUpperCase() }, this.debounced)
      } else {
        this.setState({ value }, this.debounced)
      }
    }
  }

  render () {
    const { type, label } = this.props
    const { value } = this.state
    return (
      <>
        <TextField
          disabled={this.props.disabled}
          multiline={this.props.multiline}
          onChange={this.onChange}
          value={value === null || value === 'NaN' ? '' : value}
          label={label}
          type={type === 'phone' ? 'number' : type}
          onBlur={this.onBlur}
        />
        <span className='fg-field-reset' onClick={this.onReset}>X</span>
      </>
    )
  }
}
