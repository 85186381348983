import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'

function Transition (props) {
  return <Slide direction='up' {...props} />
}

const modalTypes = {
  sms: 'sms',
  cancel: 'cancel',
  closeAuction: 'close-auction'
}

export const ConfirmationDialog = ({
  confirmationModalOpen,
  sendSms,
  modalType,
  timesToConfirmCancel,
  handleConfirmCancel,
  handleClose,
  handleConfirmClose,
  auction
}) => {
  const dialogText = () => {
    let state = auction.state
    switch (modalType) {
      case modalTypes.sms :
        return 'SMS'
      case modalTypes.cancel :
        if (state === 'future') {
          return 'Do you really want to cancel the auction?'
        } else {
          return 'Auction can only be cancelled in \'future\' state'
        }
      case modalTypes.closeAuction:
        if (state === 'open' || state === 'live') {
          return 'Do you really want to close all lots and the auction?'
        } else {
          return 'Auction can only be closed in \'open\' and \'live\' states'
        }
      default:
        return ''
    }
  }

  const DialogActionsContent = () => {
    const state = auction.state
    switch (modalType) {
      case modalTypes.sms:
        return (
          <>
            <Button onClick={sendSms} color='primary'>
              Yes
            </Button>
            <Button onClick={handleClose} color='primary'>
              No
            </Button>
          </>
        )
      case modalTypes.cancel:
        if (state === 'future') {
          return (
            <>
              <Button
                onClick={handleConfirmCancel}
                color='primary'>
                Yes
              </Button>
              <Button onClick={handleClose} color='primary'>
                No
              </Button>
            </>
          )
        } else {
          return (
            <>
              <Button onClick={handleClose} color='primary'>
                Close
              </Button>
            </>
          )
        }
      case modalTypes.closeAuction:
        if (state === 'open' || state === 'live') {
          return (
            <>
              <Button
                onClick={handleConfirmClose}
                color='primary'>
                Yes
              </Button>
              <Button onClick={handleClose} color='primary'>
                No
              </Button>
            </>
          )
        } else {
          return (
            <>
              <Button onClick={handleClose} color='primary'>
                Close
              </Button>
            </>
          )
        }
      default:
        return (
          <>
            <Button onClick={handleClose} color='primary'>
              Close
            </Button>
          </>
        )
    }
  }

  const ContentText = () => {
    const state = auction.state
    switch (modalType) {
      case modalTypes.sms :
        return 'Please confirm you want to send SMS?'
      case modalTypes.cancel :
        if (state === 'future') {
          return `${timesToConfirmCancel} times to ask left`
        } else {
          return ''
        }
      case modalTypes.closeAuction:
        if (state === 'live' || state === 'open') {
          return `${timesToConfirmCancel} times to ask left`
        } else {
          return ''
        }
      default:
        return ''
    }
  }

  return (
    <>
      {auction &&
      <Dialog
        open={confirmationModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {dialogText()}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <ContentText />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogActionsContent />
        </DialogActions>
      </Dialog>
      }
    </>
  )
}
