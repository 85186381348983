/* eslint camelcase: 0 */
import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import debounce from 'lodash/debounce'

export default class MobEntryWeight extends Component {
  render () {
    const { count } = this.props
    return (
      <>
        <div className='depth-0'>
          <h3 className='dropdown-title'>Mob Entry Weight</h3>
          <HeadsInput
            {...this.props}
            value={count || ''}
            field='mobEntryWeight'
          />
        </div>
      </>
    )
  }
}

class HeadsInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: this.props.value
    }
    this.inputProps = { min: 0, step: '1' }
    this.debounced = null
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value })
    }
  }

  onChange = ({ target: { value } }) => {
    value = Number(value) || 0
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.setState({ value }, this.debounced)
  }

  onBlur = ({ target: { value } }) => {
    const { field } = this.props
    value = Number(value) || 0
    switch (field) {
      case 'mobEntryWeight':
        if (value < 0) {
          value = 0
        }
        break
      default:
        break
    }
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(() => this.props.onChange(value, 300))
    this.setState({ value }, this.debounced)
  }

  render () {
    const { value } = this.state
    return (
      <TextField
        onChange={this.onChange}
        onBlur={this.onBlur}
        defaultValue={value || ''}
        type='number'
        inputProps={this.inputProps}
      />
    )
  }
}
