import {
  GET_LIST
} from 'react-admin'
import React, { Component } from 'react'
import { OwnPagination } from './OwnPagination'

import { dataProvider } from '../App'
import config from '../config'

export class BaseListComponent extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ids: [],
      data: {},
      total: 0,
      page: 1,
      perPage: config.perPage,
      field: '_id',
      order: 'ASC',
      timerEnabled: true
    }

    this.timerId = null

    this.loadData = this.loadData.bind(this)
    this.setPerPage = this.setPerPage.bind(this)
    this.setPage = this.setPage.bind(this)
    this.setSort = this.setSort.bind(this)

    this.loadData()
    this.filter = (data) => { return data }
  }

  loadData () {
    let params = {
      filter: { ...this.filter },
      pagination: {
        page: this.state.page,
        perPage: this.state.perPage
      },
      sort: {
        field: this.state.field,
        order: this.state.order
      }
    }
    if (this.props.resource === 'invoices') {
      params.status = this.props.type
    }
    dataProvider(GET_LIST, this.props.resource, params)
      .then(({ data, total }) => {
        let newids = []
        data = data.filter(this.filter)
        let newdata = {}
        for (let i = 0; i < data.length; i++) {
          let id = data[i].id
          newids.push(id)
          newdata[id] = {
            ...data[i],
            leftTime: data[i].leftTime
          }
        }
        this.setState({
          ids: newids,
          data: newdata,
          total
        })
      })
  }

  setPerPage (perPage) {
    this.setState({
      perPage
    }, this.loadData)
  }

  setPage (page) {
    this.setState({
      page
    }, this.loadData)
  }

  setSort (field) {
    if (field[0] === '_') {
      field = field.substring(1)
    }
    let order = 'ASC'
    if (this.state.field === field) {
      order = this.state.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({ field, order }, () => {
      this.loadData()
    })
  }

  showPagination () {
    return (
      <OwnPagination
        page={this.state.page}
        rowsPerPage={this.state.perPage}
        perPage={this.state.perPage}
        total={this.state.total}
        setPerPage={this.setPerPage}
        setPage={this.setPage}
      />
    )
  }

  render () {
    return (null)
  }
}
