import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import compareArrays from '../../../helpers/compareArrays'

export default class FormIsPublicField extends Component {
  constructor (props) {
    super(props)

    this.onChange = props.onChange

    this.state = {
      value: this.props.isPublic
    }

    this.toggle = this.toggle.bind(this)
  }

  shouldComponentUpdate (nextProps, nextState) {
    return this.state.value !== nextState.value || compareArrays(this.props.newIndexes, nextProps.newIndexes)
  }

  toggle () {
    this.setState({
      value: !this.state.value
    }, () => {
      this.onChange({ value: this.state.value, indexes: this.props.newIndexes, field: 'isPublic' })
    })
  }

  render () {
    return (
      <FontAwesomeIcon
        className='is-public'
        icon={this.state.value ? 'eye' : 'eye-slash'}
        onClick={this.toggle}
      />
    )
  }
}
