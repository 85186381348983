import axios from 'axios'

const locations = [
  { id: 'NSW', name: 'NSW' },
  { id: 'NT', name: 'NT' },
  { id: 'QLD', name: 'QLD' },
  { id: 'SA', name: 'SA' },
  { id: 'TAS', name: 'TAS' },
  { id: 'VIC', name: 'VIC' },
  { id: 'WA', name: 'WA' }
]

const reminderLocations = [
  { id: 'All', name: 'All' },
  { id: 'disable', name: 'Disable' },
  { id: 'Select Region', name: 'Select Region' },
  { id: 'NSW', name: 'NSW' },
  { id: 'NT', name: 'NT' },
  { id: 'QLD', name: 'QLD' },
  { id: 'SA', name: 'SA' },
  { id: 'TAS', name: 'TAS' },
  { id: 'VIC', name: 'VIC' },
  { id: 'WA', name: 'WA' }
]

const auctionTypes = [
  { id: 'simultaneous', name: 'Timed Auctions' },
  { id: 'sequential', name: 'Sequential(SEQ)' },
  { id: 'sequentialOffline', name: 'On Property (Single Sequential)' },
  { id: 'synchronous', name: 'Synchronous Sale' }
]

const auctionPublic = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' }
]

const statuses = [
  {id : 'All',  name : 'All' },
  {id : 'registered', name : 'registered' },
  {id : 'registered-updated', name : 'registered-updated' },
  {id : 'approved-buyer', name : 'approved-buyer' },
  {id : 'approved-buyer-updated', name : 'approved-buyer-updated' },
  {id : 'approved-seller',  name : 'approved-seller' },
  {id : 'approved-buyer-seller',  name : 'approved-buyer-seller' }, 
]
const userOperations = [
  {id : 'All',  name : 'All' },
  {id : 'Livestock Agent', name : 'Livestock Agent' },
  {id : 'Media', name : 'Media' },
  {id : 'Producer', name : 'Producer' },
  {id : 'Processor', name : 'Processor' },
  {id : 'Feedlotter',  name : 'Feedlotter' },
  {id : 'Other (Specify)',  name : 'Other (Specify)' }
]
const selectAllUserOperations = [
  'Livestock Agent',
  'Media',
  'Producer',
  'Processor',
  'Feedlotter',
  'Other (Specify)',
  'All'
]

const selectAllStatus = [
  'registered',
  'registered-updated',
  'approved-buyer',
  'approved-buyer-updated',
  'approved-seller',
  'approved-buyer-seller',
  'All'
]

const selectAllStates = [
  'NSW',
  'NT',
  'QLD',
  'SA',
  'TAS',
  'VIC',
  'WA',
  'All'
]



const getDefaultTimers = async () => {
  const defaultTimersData = await axios.get('auctions/auction-default-timers')
  return defaultTimersData.data.defaultTimers
}

export default {
  locations,
  reminderLocations,
  auctionTypes,
  auctionPublic,
  statuses,
  userOperations,
  selectAllUserOperations,
  selectAllStatus,
  selectAllStates,
  getDefaultTimers
}
