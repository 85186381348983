
import React, {  useEffect, useState } from 'react'
import {
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  NumberInput,
  TextInput,
  SelectInput
} from 'react-admin'
import '../../models/invoices/styles.css'
import axios from 'axios'
import EditToolbar from '../../components/EditToolbar'
import './styles.scss'


// export const PostcodeEdit = (props) =>{
//   let [regionData, setRegiondata] = useState(null);
//   let [state, setState] = useState(null);

//   const getPostcodeData = async () => {
//     let selectedState =  window.location.href.split('/')[6];
//     setState(selectedState);
//     let arrayData = [];
//     let newData = [];
//     let url = '/postcodes'
//     const res = await axios.get(url);

//     if(res && res.data){
//       res.data.postcodesData.forEach((code)=>{
//         if(selectedState == 'ALL'){
//           newData.push(code.region);
//         }
//        if(code.state == selectedState){
//         newData.push(code.region);
//        }
//       })
//     }
//     if(newData){
//       newData = [...new Set(newData)];
//       arrayData = newData.map((ele)=>{
//         return {id: ele, name : ele}
//       })
//     }

//    setRegiondata(arrayData);
//   }
//   useEffect(()=>{
//     getPostcodeData()
//   },[])
//   console.log(regionData,"regionData")
//   return(
//     <Edit title='Edit FAQ' {...props}>
//       <SimpleForm className='edit-faq' toolbar={<EditToolbar />}>
//         <TextInput  source='state' label='State' />
//         {/* <TextInput  source='region' label='Region' /> */}
//         {regionData && <SelectInput source='region' choices={regionData} />}
//         <TextInput  source='town'  label='Town' />
//         <NumberInput  source='postcode' label='Postcode' />
  
//       </SimpleForm>
//     </Edit>
//   )
// } 
export const PostcodeEdit = (props) =>
(
  <Edit title='Edit FAQ' {...props}>
    <SimpleForm className='edit-faq' toolbar={<EditToolbar />}>
      <TextInput  source='state' label='State' />
      <TextInput  source='region' label='Region' />
      <TextInput  source='town'  label='Town' />
      <NumberInput  source='postcode' label='Postcode' />

    </SimpleForm>
  </Edit>
)
export const PostcodeCreate = (props) => {

  let [regionData, setRegiondata] = useState(null);
  let [state, setState] = useState(null);

  const getPostcodeData = async () => {
    let selectedState = window.location.href.split('/')[6];
    setState(selectedState);
    let arrayData = [];
    let newData = [];
    let url = '/postcodes'
    const res = await axios.get(url);

    if (res && res.data) {
      res.data.postcodesData.forEach((code) => {
        if (selectedState === 'ALL') {
          newData.push(code.region);
        }
        if (code.state === selectedState) {
          newData.push(code.region);
        }
      })
    }
    if (newData) {
      newData = [...new Set(newData)];
      arrayData = newData.map((ele) => {
        return { id: ele, name: ele }
      })
    }

    setRegiondata(arrayData);
  }
  useEffect(() => {
    getPostcodeData()
  }, [])

  return (

    <Create {...props}>
      <SimpleForm redirect='list'>
        <TextInput source='state' defaultValue={state} />
        {regionData && <SelectInput source="region" choices={regionData} />}
        <TextInput source='town' />
        <NumberInput source='postcode' />

      </SimpleForm>
    </Create>
  )
}

export const StateList = (props) => {

  let [statedata, setStatedata] = useState(null);

  const getPostcodeData = async () => {
    let newData = {};
    let url = '/postcodes'
    const res = await axios.get(url);

    if(res && res.data){

      res.data.postcodesData.forEach((code)=>{
        newData[code.state] = {id :code._id, state: code.state}
      })
    }
   newData["ALL"] = {id :'ALL', state: 'ALL'}
   setStatedata(newData);
  }
  useEffect(()=>{
    getPostcodeData()
  },[])

  if(statedata){
    var ids = Object.keys(statedata)
  }
  const StateLink = ({ record = {} }) => <a href={`/#/custom?state=${record.state}`} className='self-link'>{record.state}</a>
  return (
    <div className='lists-wrapper'>
      <div className='datagrid-wrapper  postcodes'  >
        <Datagrid
          ids={ids}
          data={statedata}
          currentSort={{}}
          className='invoices-wrapper'
        >
           <StateLink source='state' label='State'  />
        </Datagrid>
      </div>
    </div>
  )

}

