import React, { Component } from 'react'
import './styles.css'
import { dataProvider } from '../../App'
import { GET_LIST } from 'react-admin'
import { OwnPagination } from '../OwnPagination'
import moment from 'moment'
import debounce from 'lodash/debounce'
import MaterialTextField from '@material-ui/core/TextField'
import ReactTable from 'react-table'
import config from '../../config'
import '../KpiReportTable/styles.scss'

export class AuctionReportTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: [], defaultPageSize: 20, search: '', field: '', order: 'ASC', total: 100, page: 1, perPage: config.perPage
    }

    this.loadData = this.loadData.bind(this)
  }

  loadData = async () => {
    let { page, perPage, field, order, search } = this.state
    try {
      let data = await dataProvider(GET_LIST, '/lots/reports', {
        filter: this.filter,
        pagination: {
          page, perPage
        },
        sort: {
          field, order
        },
        search
      })
      this.setState({ data: data.data, total: data.total })
    } catch (error) {
      console.log(error)
    }
  }

  setSearchString = ({ target: { value } }) => {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.loadData, 300)
    this.setState({
      page: 1, search: value
    }, this.debounced)
  }

  setSort = (field) => {
    let order = 'ASC'
    if (this.state.field === field) {
      order = this.state.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      field, order
    }, this.loadData)
  }

  getClearance (total, sold) {
    let clearance = 0
    if (total && sold) {
      clearance = sold / (total / 100)
    }
    return clearance.toFixed(0)
  }

  setPerPage = (perPage) => {
    this.setState({
      perPage
    }, this.loadData)
  }

  setPage = (page) => {
    this.setState({
      page
    }, this.loadData)
  }

  getAgeString = (inlineDetails, age) => {
    let ageString = age
    if (inlineDetails && inlineDetails.ageUnit === 'year') {
      ageString = `${age / 12} yrs`
    }
    if (inlineDetails && inlineDetails.ageUnit === 'month') {
      ageString = `${age} mths`
    }
    return ageString
  }

  getTaxPerHead (count, kindType) {
    let tax = 0
    if (kindType === 'sheep') {
      tax = config.taxPerHead.sheep * count
    }
    if (kindType === 'cattle') {
      tax = config.taxPerHead.cattle * count
    }
    return tax
  }

  cutOutMajorBreed (majorBreed, title) {
    return title.replace(majorBreed, '')
  }

  render () {
    return (<div id='auction-report-table'>
      <div className='reports-search-wrapper'>
        <MaterialTextField
          label='Search'
          placeholder='Search'
          className='search-text'
          onChange={this.setSearchString}
        />
      </div>
      <ReactTable
        resizable
        minRows={0}
        data={this.state.data}
        showPagination={this.props.isOpened}
        sortable={false}
        PaginationComponent={() => {
          return (<OwnPagination
            page={this.state.page}
            rowsPerPage={this.state.perPage}
            perPage={this.state.perPage}
            total={this.state.total}
            setPerPage={this.setPerPage}
            setPage={this.setPage} />)
        }}
        columns={[{
          id: 'AuctionDate',
          Header: () => {
            return (<div onClick={() => { this.setSort('auctionData.liveAt') }}>Auction Date</div>)
          },
          Cell: ({ original, index }) => {
            if (!index) {
              return (<React.Fragment>
                <div className='subHeader' />
                <p>{moment(original.auctionData.liveAt).format('DD/MM/YYYY')}</p>
              </React.Fragment>)
            }
            return <p>{moment(original.auctionData.liveAt).format('DD/MM/YYYY')}</p>
          },
          width: 175
        }, {
          id: 'AuctionNo',
          Header: () => {
            return (<div onClick={() => { this.setSort('auctionData.number') }}>Auction No.</div>)
          },
          Cell: ({ original, index }) => {
            if (!index) {
              return (<React.Fragment>
                <div className='subHeader' />
                <p>{original.auctionData.searchNumber}</p>
              </React.Fragment>)
            }
            return <p>{original.auctionData.searchNumber}</p>
          }
        }, {
          id: 'LotNo',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('numericId')
            }}>Lot ID</div>)
          },
          Cell: ({ original, index }) => {
            if (!index) {
              return (<React.Fragment>
                <div className='subHeader' />
                <p>{original.searchNumericId}</p>
              </React.Fragment>)
            }
            return <p>{original.searchNumericId}</p>
          }
        }, {
          id: 'Cattle/Sheep',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('kindDatatitleAndDate')
            }}>Cattle/Sheep</div>)
          },
          Cell: ({ original, index }) => {
            if (!index) {
              return (<React.Fragment>
                <div className='subHeader' />
                <p>{original.kindData.title}</p>
              </React.Fragment>)
            }
            return <p>{original.kindData.title}</p>
          },
          width: 125
        }, {
          id: 'Lot No',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('number')
            }}>Lot No</div>)
          },
          Cell: ({ original, index }) => {
            if (!index) {
              return (<React.Fragment>
                <div className='subHeader' />
                <p>{original.number}</p>
              </React.Fragment>)
            }
            return <p>{original.number}</p>
          },
          width: 125
        }, {
          id: 'Details',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('publicDetails.title')
            }}>Details</div>)
          },
          Cell: ({ original, index }) => {
            if (!index) {
              return (<React.Fragment>
                <div className='subHeader' />
                <p>{this.cutOutMajorBreed(original.publicDetails.majorBreed, original.publicDetails.title)}</p>
              </React.Fragment>)
            }
            return <p>{this.cutOutMajorBreed(original.publicDetails.majorBreed, original.publicDetails.title)}</p>
          }
        }, {
          id: 'MajorityBreed',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('publicDetails.majorBreed')
            }}>Majority Breed</div>)
          },
          Cell: ({ original, index }) => {
            if (!index) {
              return (<React.Fragment>
                <div className='subHeader' />
                <p>{original.publicDetails.majorBreed}</p>
              </React.Fragment>)
            }
            return <p>{original.publicDetails.majorBreed}</p>
          },
          width: 125
        }, {
          id: 'Age',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('publicDetails.age.average')
            }}>Age</div>)
          },
          Cell: ({ original, index }) => {
            if (!index) {
              return (<React.Fragment>
                <div className='subHeader' />
                <p>{`max: ${this.getAgeString(original.inlineDetails, original.publicDetails.age.max)}, average: ${this.getAgeString(original.inlineDetails, original.publicDetails.age.average)}, min: ${this.getAgeString(original.inlineDetails, original.publicDetails.age.min)}`}</p>
              </React.Fragment>)
            }
            return (<div>
              <p>{`max: ${this.getAgeString(original.inlineDetails, original.publicDetails.age.max)}, average: ${this.getAgeString(original.inlineDetails, original.publicDetails.age.average)}, min: ${this.getAgeString(original.inlineDetails, original.publicDetails.age.min)}`}</p>
            </div>)
          },
          width: 250
        }, {
          id: 'Assw',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('publicDetails.weight.average')
            }}>Ass/w</div>)
          },
          Cell: ({ original, index }) => {
            if (!index) {
              return (<React.Fragment>
                <div className='subHeader' />
                <p>{`${original.publicDetails.weight.average} kg`}</p>
              </React.Fragment>)
            }
            return (<p>{`${original.publicDetails.weight.average} kg`}</p>)
          }
        }, {
          id: 'Curw',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('publicDetails.weight.curfew')
            }}>Cur/w</div>)
          },
          Cell: ({ original, index }) => {
            if (!index) {
              return (<React.Fragment>
                <div className='subHeader' />
                <p>{`${original.publicDetails.weight.curfew} kg`}</p>
              </React.Fragment>)
            }
            return (<p>{`${original.publicDetails.weight.curfew} kg`}</p>)
          }
        }, {
          id: 'Town',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('publicDetails.location.town')
            }}>Town</div>)
          },
          Cell: ({ original, index }) => {
            if (!index) {
              return (<React.Fragment>
                <div className='subHeader' />
                <p>{original.publicDetails.location.town}</p>
              </React.Fragment>)
            }
            return (<p>{original.publicDetails.location.town}</p>)
          }
        }, {
          id: 'State',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('publicDetails.location.state')
            }}>State</div>)
          },
          Cell: ({ original, index }) => {
            if (!index) {
              return (<React.Fragment>
                <div className='subHeader' />
                <p>{original.publicDetails.location.state}</p>
              </React.Fragment>)
            }
            return (<p>{original.publicDetails.location.state}</p>)
          }
        }, {
          id: 'Status',
          Header: () => {
            return (<div onClick={() => {
              // TODO make castom sort
              this.setSort('status')
            }}>Status</div>)
          },
          Cell: ({ original, index }) => {
            let pirce = ''
            if (original.status === 'passed' || original.status === 'sold' || original.status === 'soldAfterAuction') {
              pirce = `( $${original.closedPrice / 100} )`
            }
            let statusText = original.status
            if (original.status === 'soldAfterAuction') {
              statusText = 'sold AA'
            }
            if (!index) {
              return (<React.Fragment>
                <div className='subHeader' />
                <p>{`${statusText} ${pirce}`}</p>
              </React.Fragment>)
            }
            return (<p>{`${statusText} ${pirce}`}</p>)
          }
        }, {
          id: 'SoldPrice',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('currentBidCents')
            }}>Sold Price</div>)
          },
          Cell: ({ original, index }) => {
            let pirce = ''
            if (original.status === 'passed' || original.status === 'sold' || original.status === 'soldAfterAuction') {
              pirce = `$${original.closedPrice / 100}`
            }
            if (!index) {
              return (<React.Fragment>
                <div className='subHeader' />
                <p>{pirce}</p>
              </React.Fragment>)
            }
            return (<p>{pirce}</p>)
          }
        }, {
          id: 'Seller Name',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('createdBy')
            }}>Seller Name</div>)
          },
          Cell: ({ original, index }) => {
            let creatorData = original.creatorData
            let firstName = creatorData ? creatorData.firstName : ''
            let lastName = creatorData ? creatorData.lastName : ''
            let tradingName = creatorData ? creatorData.tradingName : ''
            let phone = creatorData ? creatorData.phone : ''
            let PIC = creatorData ? creatorData.PIC : ''
            if (!index) {
              return (<React.Fragment>
                <div className='quarterCell left'>
                  <div className='subHeader'>
                    Name
                  </div>
                  <p>{`${firstName} ${lastName}`}</p>
                </div>
                <div className='quarterCell left'>
                  <div className='subHeader'>
                    Trading Name
                  </div>
                  <p>{`${tradingName}`}</p>
                </div>
                <div className='quarterCell left'>
                  <div className='subHeader'>
                    Mobile
                  </div>
                  <p>{`${phone}`}</p>
                </div>
                <div className='quarterCell left'>
                  <div className='subHeader'>
                    Vendor PIC
                  </div>
                  <p>{`${PIC}`}</p>
                </div>
              </React.Fragment>)
            }
            return (<React.Fragment>
              <div className='quarterCell left'>
                <p>{`${firstName} ${lastName}`}</p>
              </div>
              <div className='quarterCell left'>
                <p>{`${tradingName}`}</p>
              </div>
              <div className='quarterCell left'>
                <p>{`${phone}`}</p>
              </div>
              <div className='quarterCell left'>
                <p>{`${PIC}`}</p>
              </div>
            </React.Fragment>)
          },
          width: 500
        }, {
          id: 'Buyer Name',
          Header: () => {
            return (<div className='type-header' onClick={() => {
              this.setSort('winner')
            }}>Buyer Name</div>)
          },
          Cell: ({ original, index }) => {
            let winner = original.winner
            let firstName = winner ? winner.firstName : ''
            let lastName = winner ? winner.lastName : ''

            if (!index) {
              return (<React.Fragment>
                <div className='thirdCell left'>
                  <div className='subHeader'>Name
                  </div>
                  <p>{(original.winner && original.status === 'sold') ? `${firstName} ${lastName}` : ''}</p>
                </div>
                <div className='thirdCell left'>
                  <div className='subHeader'>Trading Name
                  </div>
                  <p>{(original.winner && original.status === 'sold') ? original.winner.tradingName : ''}</p>
                </div>
                <div className='thirdCell left'>
                  <div className='subHeader'>Mobile
                  </div>
                  <p>{(original.winner && original.status === 'sold') ? original.winner.phone : ''}</p>
                </div>
              </React.Fragment>)
            }
            return (<React.Fragment>
              <div className='thirdCell left'>
                <p>{(original.winner && original.status === 'sold') ? `${firstName} ${lastName}` : ''}</p>
              </div>
              <div className='thirdCell left'>
                <p>{(original.winner && original.status === 'sold') ? original.winner.tradingName : ''}</p>
              </div>
              <div className='thirdCell left'>
                <p>{(original.winner && original.status === 'sold') ? original.winner.phone : ''}</p>
              </div>
            </React.Fragment>)
          },
          width: 400
        },
        {
          id: 'Auction Tag',
          Header: () => {
            return (<div>Auction Tag</div>)
          },
          Cell: ({ original, index }) => {
            return (<React.Fragment>
              <p>{original.auctionData.auctionTags ? original.auctionData.auctionTags.map(e => e.title).join(', ') : ''}</p>
            </React.Fragment>)
          }
        }
        ]}
        defaultPageSize={this.state.defaultPageSize}
      />
    </div>)
  }
}
