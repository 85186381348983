import React, { Component } from 'react'

import compareArrays from '../../../helpers/compareArrays'

export default class FormDefaultValueSwitchField extends Component {
  constructor (props) {
    super(props)

    this.onChange = props.onChange

    this.toggle = this.toggle.bind(this)
  }

  shouldComponentUpdate (nextProps, nextState) {
    return this.props.defaultValue !== nextProps.defaultValue || compareArrays(this.props.newIndexes, nextProps.newIndexes)
  }

  toggle () {
    if (this.props.defaultValue !== this.props.optionValue) {
      this.onChange({ value: this.props.optionValue, indexes: this.props.newIndexes, field: 'defaultValue' })
    }
  }

  render () {
    return (
      <div className='default-value-switch'>
        <label>
          <input type='checkbox' checked={this.props.defaultValue === this.props.optionValue} onChange={this.toggle} />
          Default
        </label>
      </div>
    )
  }
}
