import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import moment from 'moment'
import './editAdminBidModal.css'
import axios from 'axios'
import { splitString } from '../../../../utils/string-number-helper'
import { makePrice } from '../../../../utils'
import socketService from '../../../../utils/socketConnection'
import NewWindow from 'react-new-window'

require('moment-timezone')

class EditAdminBidModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        show: false,
        valid: true
      },
      userId: '',
      currentLotNumber: '',
      newBid: 0,
      autoBidValid: true,
      currentLot: {
        title: '',
        show: false,
        valid: true
      },
      successNote: false,
      errorNote: { show: false, text: '' },
      increment: 0,
      bidding: 'head',
      loaded: false
    }
    this.isSubscribed = false
  }

  componentDidMount = () => {
    this.connect()
    if (!this.state.loaded) {
      this.setState({ loaded: true })
      this.joinAuction()
    }
  }

  componentDidUpdate = () => {
    if (!this.state.loaded) {
      this.setState({ loaded: true })
      this.joinAuction()
    }
  }

  componentWillUnmount() {
    socketService.sockets['bids'].off('lot-update', this.lotUpdate)
  }

  connect = () => {
    if (document.visibilityState === 'visible') {
      this.isSubscribed = true
      socketService.sockets['bids'].on('lot-update', this.lotUpdate)
    } else {
      setTimeout(this.connect, 2000)
    }
  }

  joinAuction = async () => {
    socketService.sockets.emit('bids', 'join-auction', { _id: this.props.auction._id }, (err) => {
      if (err) {
        setTimeout(this.joinAuction, 2000)
        console.warn(err)
      } else {
        console.log('join-auction was sent')
      }
    })
  }

  lotUpdate = (data) => {
    if (!data || !data.doc) {
      return
    }
    let currentLot = Object.assign({}, this.state.currentLot)
    if (data.doc._id === currentLot._id) {
      currentLot.bid = makePrice(data.doc.currentBidCents, { bidding: data.doc.bidding })
      currentLot.currentBidCents = data.doc.currentBidCents
      this.setState({
        currentLot,
        newBid: currentLot.bidding === 'head' ? (data.doc.currentBidCents + this.state.increment) / 100 : data.doc.currentBidCents + this.state.increment,
        bidding: currentLot.bidding
      })
    }
  }

  checkUser = () => {
    axios.get(`/users/check/${splitString(this.state.userId)}`)
      .then(res => {
        let user = res.data
        user.show = true
        user.valid = true
        this.setState({
          user
        })
      })
      .catch(() => {
        this.setState({
          user: {
            show: false,
            valid: false
          }
        })
      })
  }

  checkLot = () => {
    axios.get(`/lots/check/${this.state.currentLotNumber}/${this.props.auction._id}`)
      .then(res => {
        let currentLot = res.data
        currentLot.show = true
        currentLot.valid = true
        currentLot.bid = makePrice(currentLot.currentBidCents, { bidding: currentLot.bidding })
        this.setState({
          currentLot,
          newBid: currentLot.bidding === 'head' ? (currentLot.currentBidCents + currentLot.bidIncrementCents) / 100 : currentLot.currentBidCents + currentLot.bidIncrementCents,
          increment: currentLot.bidIncrementCents || 1000,
          bidding: currentLot.bidding
        })
      })
      .catch(() => {
        this.setState({
          currentLot: {
            show: false,
            valid: false
          }
        })
      })
  }

  setAutoBid = () => {
    if (this.state.currentLot.currentBidCents >= (this.state.currentLot.bidding === 'head' ? +this.state.newBid * 100 : this.state.newBid)) {
      this.setState({
        autoBidValid: false
      })
      return
    }
    if (this.state.user.valid && this.state.currentLot.valid) {
      axios.put(`/users/${this.state.user._id}/autobids`, {
        autobid: {
          isAdminAutoBid: true,
          lot: this.state.currentLot._id,
          cents: this.state.currentLot.bidding === 'head' ? +this.state.newBid * 100 : +this.state.newBid
        }
      }).then(() => {
        this.setState({ successNote: true }, () => {
          setTimeout(() => {
            this.setState({ successNote: false })
          }, 5000)
        })
      }, (err) => {
        let text = 'bid error'
        if (err.response && err.response.data.error && err.response.data.error.errors[0] && err.response.data.error.errors[0].message) {
          text = err.response.data.error.errors[0].message
        }
        this.setState({ errorNote: { show: true, text: text } }, () => {
          setTimeout(() => {
            this.setState({ errorNote: { show: false, text: '' } })
          }, 5000)
        })
      })
    }
  }

  setBid = () => {
    if (this.state.user.valid && this.state.currentLot.valid) {
      socketService.sockets.emit('bids', 'bid', {
        bid: {
          lot: this.state.currentLot._id,
          cents: this.state.currentLot.currentBidCents + this.state.increment,
          isAdminBid: true,
          userId: this.state.user._id
        }
      }, (confirmation) => {
        if (!confirmation.error) {
          this.setState({ successNote: true }, () => {
            setTimeout(() => {
              this.setState({ successNote: false })
            }, 5000)
          })
        } else {
          let text = ''
          confirmation.error && confirmation.error.message ? text = confirmation.error.message : text = 'Your bid has not been placed'
          this.setState({ errorNote: { show: true, text: text } }, () => {
            setTimeout(() => {
              this.setState({ errorNote: { show: false, text: '' } })
            }, 5000)
          })
        }
      })
    }
  }

  render () {
    const { user, currentLot } = this.state
    const { auction } = this.props

    return (
      <NewWindow onUnload={() => { this.props.changeWindowModal({ show: false, auction: {} }) }}>
        <div id='window-wrapper'>
          <div id='alert-dialog-slide-title'>
            <div className='modal-auction-title'>
              {auction.title}
            </div>
            <div>
              Auction No. {auction.number}, {moment(auction.liveAt).tz('Australia/Sydney').format('DD/MM/YYYY hh:mm A')}
            </div>
          </div>
          <div className='check-block'>
            <span> User ID: </span>
            <span className='user-input'>
              <Input
                placeholder='User ID'
                name='User ID'
                error={!this.state.user.valid}
                onChange={({ target: { value } }) => { this.setState({ userId: value }) }}
              />
              {!this.state.user.valid &&
              <div className='error-input'>
                Invalid User ID
              </div>
              }
            </span>
            <span>
              <Button onClick={this.checkUser} color='secondary'>
                   Check
              </Button>
            </span>
          </div>
          {user.show &&
          <div className='user-check-info'>
            <div className='user-name'>{`${user.firstName} ${user.lastName}`}</div>
            <div className='user-phone-email'>{`${user.phone}, ${user.email}`}</div>
          </div>
          }
          <hr />
          <div className='check-block'>
            <span>Lot Number:</span>
            <span className='lot-number-input'>
              <Input
                placeholder='Lot Number'
                name='Lot Number'
                error={!currentLot.valid}
                onChange={({ target: { value } }) => { this.setState({ currentLotNumber: value }) }}
              />
              {!currentLot.valid &&
              <div className='error-input'>
                Invalid Lot Number
              </div>
              }
            </span>
            <span>
              <Button onClick={this.checkLot} color='secondary'>Check</Button>
            </span>
          </div>
          {currentLot.show &&
          <div className='user-check-info'>
            <div className='user-name'>{`${currentLot.publicDetails.title}`}</div>
            <div className=''>Current Bid: {`${currentLot.bid}`}</div>
          </div>
          }
          <hr />
          <div className='check-block new-bid'>
            <span className='input-text'>New Bid:</span>
            <Button onClick={this.setBid} color='primary' variant='contained'>
              {this.state.increment ? `Bid ${makePrice(this.state.increment, { bidding: this.state.bidding })}` : 'Bid'}
            </Button>
          </div>
          <div className='check-block new-bid'>
            <span>New Auto-Bid:</span>
            <span className='new-bid-input'>
              <Input
                placeholder='New Auto-Bid'
                name='New Bid'
                error={!this.state.autoBidValid}
                value={this.state.newBid}
                onChange={({ target: { value } }) => { this.setState({ newBid: value, autoBidValid: true }) }}
              />
              {!this.state.autoBidValid &&
              <div className='error-input'>
                Invalid Bid
              </div>
              }
            </span>
            <span>
              <Button color='primary' variant='contained' onClick={this.setAutoBid} className='auto-bid-button'>
                Auto-Bid
              </Button>
            </span>
            {this.state.successNote &&
            <p className='notification success'>Your new bid is successfully placed</p>
            }
            {this.state.errorNote.show &&
            <p className='notification error'>{this.state.errorNote.text}</p>
            }
          </div>
        </div>
      </NewWindow>
    )
  }
}

export default EditAdminBidModal
