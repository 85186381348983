
import React from 'react'
import {
  Datagrid,
  TextField,
  EditButton,
  // CreateButton,
  ArrayField
} from 'react-admin'
import '../../models/invoices/styles.css'
import Button from '@material-ui/core/Button'
import Papa from 'papaparse'
import { Circle } from 'better-react-spinkit'
import { BaseListComponent } from '../../components/BaseList'
import axios from 'axios'
import './styles.scss'
import config from '../../config'

export class PostcodeList extends BaseListComponent {
    constructor (props) {
      super(props)
      this.state = {
        ids: [],
        data: {},
        total: 0,
        spinner : false,
        page: 1,
        perPage: config.perPage,
        field: 'createdAt',
        order: 'DESC',
        timerEnabled: true,
        search: null
      }
      this.hiddenCSVInput = React.createRef()
    }
  
    async componentDidMount () {
     let selectedState =  window.location.href.split('?state=')[1];
      let newData = {};
      let filteredData = [];
      let temp_val_region ;
      let temp_val_id;
      let temp_val_state;
      let townData = []
      let url = '/postcodes'
      const res = await axios.get(url);

      if(res && res.data){
        filteredData =  res.data.postcodesData.filter((ele)=>{
          if(selectedState === 'ALL'){
            return ele
          }
           return ele.state === selectedState
        })
      }
      
     if(filteredData){
      filteredData.forEach((code,index)=>{
  
        if(!temp_val_region ) {
          temp_val_region = code.region;
          temp_val_id = code._id ;
          temp_val_state = code.state ;
        } 
          if(code.region === temp_val_region ) {
            townData.push({ _id : code._id,town : code.town, postcode: code.postcode}) 
    
            if(index === (filteredData.length - 1)){
              newData[temp_val_id] = { _id : temp_val_id, state : temp_val_state, title : temp_val_region, towns:[...townData] }
            }
          }else{
            newData[temp_val_id] = { _id : temp_val_id,  state : temp_val_state,  title : temp_val_region, towns:[...townData] }
            temp_val_region = code.region;
            temp_val_id = code._id ;
            temp_val_state = code.state;
            townData.splice(0,townData.length);
            townData.push({_id : code._id,town : code.town, postcode: code.postcode});
  
            if(index === (filteredData.length - 1)){
              newData[temp_val_id] = { _id : temp_val_id, state : temp_val_state, title : temp_val_region, towns:[...townData] }
            }
          }
    
         
        }) ;
     }
      this.setState({
        data: newData
      })
    }
  
    // loadData () {
    //   dataProvider(GET_LIST, 'postcodes', {
    //     pagination: {
    //       page: this.state.page,
    //       perPage: this.state.perPage
    //     },
    //     sort: {
    //       field: this.state.field === '_id' ? 'createdAt' : this.state.field,
    //       order: this.state.field === '_id' ? 'DESC' : this.state.order
    //     },
    //     status: this.props.type,
    //     search: this.props.search || null
    //   })
    //     .then(( data) => {
    //       console.log(data,"data");
    //       if (data) {
           
    //         this.setState({
    //           data:data
    //         })
    //       }
    //     })
    // }

   importPostcodes = (event) => {
    //event.preventDefault();
    this.setState({
      spinner: true
    })
   let selectedState =  window.location.href.split('?state=')[1];
    let file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        error: () => {
          console.log('Error parsing CSV')
        },
        complete: async(results) => {
          if(results.data.length > 0)
          results.data.shift();
          results.data.pop();
          var objData = results.data.map(x => {
            if(x[0] === ''){
              x[0] = selectedState
            }
           return  ({ 
            state: x[0], 
            region: x[1],
            town: x[2],
            postcode : x[3]
          })
          });
          await axios({
            method: 'POST',
            url:   `${config.rootUrl}postcodes?${config.adminAccess}=true&selectedState=${selectedState}`,
            data: objData
          }).then ((res) => {
            if (res.status === 200) {
             if (window.confirm("File Imported Successfully..!!")) {
              this.setState({
                spinner: false
              })
              window.location.reload(); // Reload the page
            }
            }
          }).catch((error) =>{
            throw new Error (error)
            })
          }
        })
    }
    }
  
    render () {
      let selectedState =  window.location.href.split('?state=')[1];
      let { data } = this.state
      let ids = Object.keys(data)
      // const PostcodeCreateButton = (props) =>{
      //   return <CreateButton to={`/postcodes/create/${props.selectedState}`} />
      // }
     const PostcodeEditButton = ({ record,selectedState }) => <EditButton to={`/postcodes/${record._id}/${selectedState}`} />
      
     const handleCSVClick = event => {
      this.hiddenCSVInput.current.click()
     }
  
      return (
        <>
      {this.state.spinner && <div className='spinner-wrapper'>
          <div className='spinner'>
            <Circle size={100} />
          </div>
        </div>}

        <div className='lists-wrapper'>
          <div className='postcode-import-export'>
          { <Button onClick={handleCSVClick} color='primary' variant='outlined' >Import Postcodes</Button>}
           <a href={`${config.rootUrl}exports/postcodes?${config.adminAccess}=true&selectedState=${selectedState}`}>Export Postcode CSV</a>
           {/* <Button onClick={handleDownloadCSVClick(timezone)} color='primary' variant='outlined' >Export Postcode CSV</Button> */}
          </div>
          <div className='datagrid-wrapper  postcodes'  >
         {/* {selectedState !== 'ALL' && <PostcodeCreateButton selectedState = {selectedState} />}  */}

            <Datagrid
              ids={ids}
              data={data}
              currentSort={{}}
              className='invoices-wrapper'
            >
               <TextField source='state' label='State'  />
                <TextField source='title' label='Region'  />
             <ArrayField source='towns' className='invoices-lots'>
               <Datagrid>
                  <TextField source='town' label='Town' />
                  <TextField source='postcode' label='Postcode' />
                  {selectedState !== 'ALL' && <PostcodeEditButton selectedState = {selectedState} />}
                  </Datagrid>
               </ArrayField>
                    <input
                      style={{ display: 'none' }}
                      type='file'
                      accept='.csv'
                      ref={this.hiddenCSVInput}
                      onChange={async (event) => this.importPostcodes(event)}
                      title={'Import Postcodes'}
                    />
                   
            </Datagrid>
         
          </div>
        </div>
        </>
       
      )
    }
  }