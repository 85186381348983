import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import debounce from 'lodash/debounce'

export default class DebounceField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: props.value
    }

    this.updateValue = this.updateValue.bind(this)
    this.debounced = null
  }

  updateValue ({ target: { value } }) {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(() => this.props.onChange({
      value: this.props.passObject ? { // for 'options'
        ...this.props.passObject,
        label: value
      } : value,
      indexes: this.props.newIndexes,
      field: this.props.field,
      lastArrayName: this.props.lastArrayName
    }), 300)
    this.setState({ value }, this.debounced)
  }

  render () {
    return (
      <TextField
        onChange={this.updateValue}
        value={this.state.value}
        type={this.props.type}
        label={this.props.label}
        className='wide-text-input'
      />
    )
  }
}
