const font = 'Lato'

const wrapper = {
  width: '600px',
  margin: '0 auto',
  fontFamily: 'Lato'
}
const header = {
  height: '175px',
  background: 'url(\'https://storage.googleapis.com/farmgate-live-media/cows-new.png\')',
  backgroundPosition: 'bottom',
  backgroundSize: 'cover',
  marginLeft: '-10px',
  marginRight: '-10px',
  marginTop: '-10px',
  fontFamily: font
}
const headerPrivate = {
  height: '175px',
  background: 'url(\'https://storage.googleapis.com/farmgate-live-media/new_cows_private.png\')',
  backgroundPosition: 'bottom',
  backgroundSize: 'cover',
  marginLeft: '-10px',
  marginRight: '-10px',
  marginTop: '-10px',
  fontFamily: font
}
const logo = {
  width: '280px',
  background: 'url(\'https://storage.googleapis.com/farmgate-live-media/farmgate-logo.png\')',
  display: 'inline-block',
  height: '80px',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: '50px'
}
const contactInfo = {
  width: '290px',
  paddingTop: '50px',
  fontWeight: 'bold',
  display: 'inline-block',
  fontSize: '12px',
  fontFamily: font
}
const contactInfoLine = {
  textAlign: 'end',
  color: '#fff',
  fontFamily: font
}
const subHeader = {
  background: '#D1D2D4',
  height: '21px',
  textTransform: 'uppercase',
  paddingTop: '9px',
  paddingLeft: '36px',
  fontSize: '12px',
  marginLeft: '-10px',
  marginRight: '-10px',
  fontFamily: font
}
const setUpFeeField = {
  height: '21px',
  paddingTop: '9px',
  paddingLeft: '36px',
  fontSize: '12px',
  marginLeft: '-10px',
  marginRight: '-10px',
  fontFamily: font
}
const fieldsWrapper = {
  width: '230px',
  paddingLeft: '120px',
  marginTop: '20px',
  display: 'inline-block',
  fontFamily: font,
  marginBottom: '15px'
}
const userInfoWrapper = {
  float: 'left',
  paddingTop: '20px',
  fontSize: '13px',
  paddingLeft: '35px',
  maxWidth: '165px',
  minWidth: '165px',
  fontFamily: font
}
const userInfoField = {
  marginBottom: '10px',
  wordBreak: 'break-word',
  fontFamily: font
}
const userField = {
  marginBottom: '8px',
  borderBottom: '1px solid black',
  fontSize: '11px',
  fontWeight: '700',
  position: 'relative',
  fontFamily: font
}
const userValue = {
  fontWeight: 'normal',
  position: 'absolute',
  right: 0,
  maxWidth: '140px',
  fontFamily: font
}
const listingFeesTable = {
  margin: '15px auto 0',
  fontFamily: font
}
const tableHeader = {
  textTransform: 'uppercase',
  fontSize: '8px',
  textAlign: 'left',
  width: '69px',
  wordSpacing: '150px',
  paddingLeft: '5px',
  fontFamily: font
}
const tableHeaderVendorName = {
  textTransform: 'uppercase',
  fontSize: '8px',
  textAlign: 'left',
  width: '200px',
  wordSpacing: '150px',
  paddingLeft: '5px',
  fontFamily: font
}
const underline = {
  margin: '0',
  backgroundColor: '#D1D2D4',
  height: '0.5px',
  outline: 'none',
  border: 'none',
  fontFamily: font
}
const lotsRowCell = {
  padding: '5px',
  fontSize: '9px',
  fontFamily: font
}
const setUpFee = {
  padding: '5px',
  fontSize: '9px',
  fontFamily: font,
  textAlign: 'left',
  margin: 'auto'
}
const setUpFeeNumber = {
  padding: '5px',
  fontSize: '9px',
  fontFamily: font,
  position: 'absolute',
  right: '30px'
}
const input = {
  outline: 'none',
  border: 'none',
  maxWidth: '40px',
  fontSize: '9px',
  fontFamily: font
}
const uppercaseCell = {
  padding: '5px',
  fontSize: '9px',
  textTransform: 'uppercase',
  fontFamily: font
}
const footer = {
  background: '#232020',
  color: 'white',
  height: '20px',
  alignItems: 'center',
  paddingTop: '10px',
  fontFamily: font
}
const pageFooter = {
  marginLeft: '-10px',
  marginRight: '-10px',
  fontFamily: font
}
const termsDay = {
  marginRight: '30px',
  textTransform: 'uppercase',
  fontSize: '10px',
  textAlign: 'end',
  fontFamily: font
}
const info = {
  height: '80px',
  paddingTop: '20px',
  position: 'relative',
  fontFamily: font
}
const directDeposit = {
  float: 'left',
  marginLeft: '40px',
  fontFamily: font
}
const cheque = {
  position: 'absolute',
  right: '30px',
  fontFamily: font
}
const directDepositContent = {
  fontSize: '10px',
  fontFamily: font
}
const chequeContent = {
  fontSize: '10px',
  marginLeft: '60px',
  fontFamily: font
}
const directContent = {
  fontSize: '10px',
  marginLeft: '58px',
  fontFamily: font
}
const chequeLogoBlock = {
  float: 'left'
}
const chequeLogo = {
  width: '54px',
  height: '33px',
  background: 'url(\'https://storage.googleapis.com/farmgate-live-media/Envelope.png\')',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}
const directLogo = {
  width: '52px',
  height: '33px',
  background: 'url(\'https://storage.googleapis.com/farmgate-live-media/EFT-Logo.jpg\')',
  backgroundSize: 'cover'
}
const bold = {
  fontWeight: 'bold',
  fontFamily: font
}
const mainBlock = {
  minHeight: '507px',
  maxHeight: '507px',
  fontFamily: font
}
const page = {
  background: '#242020',
  color: 'white',
  height: '23px',
  paddingTop: '10px',
  fontFamily: font
}
const pageNumber = {
  fontSize: '12px',
  marginRight: '30px',
  textAlign: 'end',
  fontFamily: font
}

export default {
  pageNumber,
  page,
  mainBlock,
  bold,
  directLogo,
  chequeLogo,
  chequeLogoBlock,
  directContent,
  chequeContent,
  directDepositContent,
  cheque,
  directDeposit,
  info,
  termsDay,
  pageFooter,
  footer,
  uppercaseCell,
  input,
  setUpFeeNumber,
  setUpFee,
  lotsRowCell,
  underline,
  tableHeaderVendorName,
  tableHeader,
  listingFeesTable,
  userValue,
  userField,
  userInfoField,
  userInfoWrapper,
  fieldsWrapper,
  setUpFeeField,
  subHeader,
  contactInfoLine,
  contactInfo,
  logo,
  headerPrivate,
  header,
  wrapper
}
