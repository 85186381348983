import React, { Component } from 'react'
import { dataProvider } from '../../App'
import { GET_LIST } from 'react-admin'
import { OwnPagination } from '../OwnPagination'
import moment from 'moment'
import MaterialTextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ReactTable from 'react-table'
import config from '../../config'
import debounce from 'lodash/debounce'
import axios from 'axios'

export class LotWatchlist extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: [],
      defaultPageSize: 20,
      search: '',
      field: 'auctionDate',
      order: 'DESC',
      total: 100,
      page: 1,
      perPage: config.perPage
    }

    this.loadData = this.loadData.bind(this)
  }

  loadData = async () => {
    let { page, perPage, field, order, search } = this.state
    try {
      let data = await dataProvider(GET_LIST, '/view-lot-report', {
       viewedReport: true,
       getLotpageViewMoreReport: false,
        filter: this.filter,
        pagination: {
          page,
          perPage
        },
        sort: {
          field,
          order
        },
        search
      })

      this.setState({ data: data.data, total: data.total })
    } catch (error) {
      console.log(error)
    }
  }

  setSearchString = ({ target: { value } }) => {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.loadData, 300)
    this.setState({
      search: value
    }, this.debounced)
  }

  setSort = (field) => {
    let order = 'ASC'
    if (this.state.field === field) {
      order = this.state.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      field,
      order
    }, this.loadData)
  }

  setPerPage = (perPage) => {
    this.setState({
      perPage
    }, this.loadData)
  }

  setPage = (page) => {
    this.setState({
      page
    }, this.loadData)
  }

  getClearance (total, sold) {
    let clearance = 0
    if (total && sold) {
      clearance = sold / (total / 100)
    }
    return clearance.toFixed(0)
  }

  downloadCsv = async () => {
    let { field, order, search } = this.state
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    let response = await axios({
      url: `print/lots-viewed-report?search=${search}&sortField=${field}&sortDirection=${order}&timezone=${timezone}`,
      method: 'GET',
      responseType: 'blob'
    })

    const blob = new window.Blob([response.data], { type: response.data.type })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    const contentDisposition = response.headers['content-disposition']
    let fileName = 'lots_added_to_watchlist_report'
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length === 2) { fileName = fileNameMatch[1] }
    }
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
  }

  render () {
    return (
      <div id='kpi-report-table'>
        <div className='reports-search-wrapper'>
          <Button
            onClick={this.downloadCsv}
            color='primary'
            className='download-csv'
            variant='contained'>
            Download CSV
          </Button>
          <MaterialTextField
            label='Search'
            placeholder='Search'
            className='search-text'
            onChange={this.setSearchString}
          />
        </div>
        <ReactTable
          resizable
          minRows={0}
          data={this.state.data}
          showPagination={this.props.isOpened}
          sortable={false}
          PaginationComponent={() => {
            return (
              <OwnPagination
                page={this.state.page}
                rowsPerPage={this.state.perPage}
                perPage={this.state.perPage}
                total={this.state.total}
                setPerPage={this.setPerPage}
                setPage={this.setPage} />
            )
          }}
          columns={[
            {
              id: 'AuctionNo',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('auctionNumber')
                  }}>Auction No.</div>
                )
              },
              Cell: ({ original }) => {
                return original._isFirst ? <p>{original.auctionSearchNumber}</p> : null
              }
            },
            {
              id: 'Auction Name',
              Header: () => {
                return (
                  <div>Auction</div>
                )
              },
              Cell: ({ original }) => {
                return original._isFirst ? <p>{original.auctionTitle}</p> : null
              }
            },
            {
              id: 'AuctionDate',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('auctionDate')
                  }}>Auction Date</div>
                )
              },
              Cell: ({ original }) => {
                return original._isFirst ? <p>{moment(original.auctionDate).format('DD/MM/YYYY')}</p> : null
              },
              width: 100
            },
            {
              id: 'Lot Number',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('lotNumber')
                  }}>Lot Number</div>
                )
              },
              Cell: ({ original }) => {
                return original._isFirst ? <p>{original.lotNumber}</p> : null
              },
              width: 80
            },
            {
              id: 'Lot Description',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('lotId.publicDetails.titleLive')
                  }}>Lot Description</div>
                )
              },
              Cell: ({ original }) => {
                return original._isFirst ? <p>{original.lotId ? original.lotId.publicDetails.title : null}</p> : null
              },
              width: 200
            },
            {
              id: 'Stock Type',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('stockType')
                  }}>Stock Type</div>
                )
              },
              Cell: ({ original }) => {
                return original._isFirst ? <p>{original.stockType}</p> : null
              }
            },
            {
              id: 'Auction Tag',
              Header: () => {
                return (<div>Auction Tag</div>)
              },
              Cell: ({ original, index }) => {
                return original._isFirst ? (<React.Fragment>
                  <p>{original.auctionId ? original.auctionId.auctionTags ? original.auctionId.auctionTags.map(e => e.title).join(', ') : '' : ''}</p>
                </React.Fragment>) : null
              }
            },
            // {
            //   id: 'Viewed by User ',
            //   Header: () => {
            //     return (
            //       <div onClick={() => {
            //         this.setSort('timesViewThatLotbyUser')
            //       }}>Viewed by User </div>
            //     )
            //   },
            //   Cell: ({ original }) => {
            //     return <p>{original.timesViewThatLotbyUser}</p>
            //   },
            //   width: 100
            // },
            {
              id: 'First Name',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('firstName')
                  }}>First Name</div>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.firstName}</p>
              }
            },
            {
              id: 'Last Name',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('lastName')
                  }}>Last Name</div>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.lastName}</p>
              }
            },
            {
              id: 'Trading Name',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('tradingName')
                  }}>Trading Name</div>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.tradingName}</p>
              },
              width: 180
            },
            {
              id: 'Town',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('town')
                  }}>Town</div>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.town}</p>
              }
            },
            {
              id: 'State',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('state')
                  }}>State</div>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.state}</p>
              },
              width: 80
            },
            {
              id: 'createdAt',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('createdAt')
                  }}>Time & Date</div>
                )
              },
              Cell: ({ original }) => {
                return original.createdAt ? <p>{moment(original.createdAt).format('DD/MM/YYYY, hh:mm A')}</p> : null
              },
              width: 200
            }
          ]}
          defaultPageSize={this.state.defaultPageSize}
        />
      </div>
    )
  }
}
