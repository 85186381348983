import React, { Component } from 'react'
import './styles.css'

import { generateStatusString } from '../../../utils/fields'
import moment from 'moment'
import axios from 'axios'
import { getFeePerHead, makePrice } from '../../../utils'
import { dataProvider } from '../../../App'
import { CREATE } from 'react-admin'
import Button from '@material-ui/core/Button'
import { gstTax } from '../../../constants'
import Spinner from '../../../components/SpinnerComponent/Spinner'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import pdfStyles from './styles'

function Transition (props) {
  return <Slide direction='up' {...props} />
}

class PdfModel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      invoice: this.props.record,
      pagesCount: 1,
      hasLotDiscountField: false,
      kind: this.props.record.data.auction.kindData ? this.props.record.data.auction.kindData.type : '',
      lots: this.props.record.data.lots,
      invoiceTotal: this.invoiceTotal(this.props.record.totalPrice, this.props.record.data.user.discount),
      gstAmount: this.calculateGst(this.props.record.totalPrice, this.props.record.data.user.discount),
      totalOwing: this.calculateTotalOwing(this.props.record.totalPrice, this.props.record.data.user.discount),
      canEdit: this.props.record.status !== 'paid',
      spinner: false,
      deliveredEmail: false,
      discount: this.props.record.data.user.discount
    }
    this.countPages = this.countPages.bind(this)
    this.updatePrices = this.updatePrices.bind(this)
    this.calculateTotalOwing = this.calculateTotalOwing.bind(this)
    this.calculateTotal = this.calculateTotal.bind(this)
    this.convertValue = this.convertValue.bind(this)
    this.downloadPdf = this.downloadPdf.bind(this)
    this.sendPdf = this.sendPdf.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.sendOverdueEmail = this.sendOverdueEmail.bind(this)
    this.invoiceTotal = this.invoiceTotal.bind(this)
  }

  componentDidMount () {
    this.countPages()
    let lots = Object.assign([], this.state.lots)
    lots.forEach(lot => {
      lot.lotDiscount = lot.lotDiscount ? lot.lotDiscount : 0
      if (lot.lotDiscount > 0 && !this.state.hasLotDiscountField) {
        this.setState({
          hasLotDiscountField: true
        })
      }
      lot.priceRate = getFeePerHead(lot)
      lot.subTotal = lot.reoffered && !lot.takeFeeForReoffered ? 0 : (lot.priceRate * lot.count * (100 - lot.lotDiscount) / 100)
      if (lot.reoffered && !lot.takeFeeForReoffered) {
        lot.priceRateText = '0.00'
        lot.subTotalText = '0.00'
        lot.priceRate = 0
      } else {
        lot.priceRateText = this.state.kind === 'sheep' ? lot.priceRate : (lot.priceRate / 100).toFixed(2)
        lot.subTotalText = (lot.subTotal / 100).toFixed(2)
      }
      lot.countText = lot.count
    })
    this.setState({
      lots
    })
  }

  invoiceTotal (totalPrice, discount) {
    return totalPrice - (totalPrice / 100 * discount)
  }

  calculateTotal (lots) {
    const setUpFee = this.props.record.data.auction.auctionSetUpFee ? this.props.record.data.auction.auctionSetUpFee : 0
    this.props.record.data.auction.privateSale = this.props.record.data.auction.privateSale ? this.props.record.data.auction.privateSale : false
    this.props.record.data.auction.auctionDiscount = (this.props.record.data.auction.auctionDiscount && this.props.record.data.auction.privateSale)
      ? this.props.record.data.auction.auctionDiscount : 0
    let total = 0
    lots.forEach(item => {
      if (!item.reoffered || item.takeFeeForReoffered) {
        if (item.count && item.priceRate) {
          item.lotDiscount = item.lotDiscount ? item.lotDiscount : 0
          total += item.count * item.priceRate * ((100 - item.lotDiscount) / 100)
        }
      }
    })
    return total + setUpFee
  }

  calculateTotalOwing (value, discount) {
    let valueWithDiscount = 0
    if (!this.props.record.data.auction.privateSale) {
      discount = this.props.record.data.user.discount ? this.props.record.data.user.discount : 0
    }
    if (discount > 0) {
      valueWithDiscount = value - (value * discount / 100)
    } else {
      valueWithDiscount = value
    }
    let gstRate = gstTax
    if (this.props.record.hasOwnProperty('gst')) {
      gstRate = this.props.record.gst
    }
    return ((valueWithDiscount * gstRate) / 100) + valueWithDiscount
  }

  calculateGst (value, discount) {
    let valueWithDiscount = 0
    if (!this.props.record.data.auction.privateSale) {
      discount = this.props.record.data.user.discount ? this.props.record.data.user.discount : 0
    }
    if (discount > 0) {
      valueWithDiscount = value - (value * discount / 100)
    } else {
      valueWithDiscount = value
    }
    let gstRate = gstTax
    if (this.props.record.hasOwnProperty('gst')) {
      gstRate = this.props.record.gst
    }
    return (valueWithDiscount * gstRate) / 100
  }

  countPages () {
    let numberOfLots = (!this.props.record.data.auction.auctionSetUpFee) ? 6 : 4
    if (this.props.record.data.auction.auctionDiscount && this.props.record.data.auction.auctionDiscount > 0) {
      numberOfLots -= 1
    }
    let pagesCount = Math.ceil(this.state.invoice.data.lots.length / numberOfLots) || 1
    if (pagesCount > 1) {
      let otherPages = Math.ceil((this.state.invoice.data.lots.length - numberOfLots) / 14) || 1
      pagesCount = otherPages + 1
    }
    this.setState({
      pagesCount
    })
  }

  convertValue (value) {
    return this.state.kind === 'sheep' ? value : (value / 100)
  }

  sendPdf () {
    this.setState({
      spinner: true
    })
    dataProvider(CREATE, `invoice/template`, {
      data: {
        html: document.getElementById('pdf-template').innerHTML,
        amount: document.getElementById('amountValue').innerHTML,
        dueOn: document.getElementById('due-on').innerText,
        invoice: this.props.record
      }
    })
      .then(() => {
        this.setState({
          open: true,
          spinner: false,
          deliveredEmail: true
        })
      })
  }

  sendOverdueEmail () {
    this.setState({
      spinner: true
    })
    dataProvider(CREATE, `invoice/overdue`, {
      data: {
        html: document.getElementById('pdf-template').innerHTML,
        amount: document.getElementById('amountValue').value,
        dueOn: document.getElementById('due-on').innerText,
        invoice: this.props.record
      }
    })
      .then(() => {
        this.setState({
          open: true,
          spinner: false,
          deliveredEmail: true
        })
      })
  }

  handleClose () {
    this.setState({
      deliveredEmail: false
    })
  }

  async downloadPdf () {
    this.setState({
      spinner: true
    })
    let response = await axios({
      url: `invoice/invoice-pdf`,
      method: 'POST',
      data: {
        html: document.getElementById('pdf-template').innerHTML
      },
      responseType: 'blob'
    })

    const blob = new window.Blob([response.data], { type: response.data.type })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    let fileName = `FarmGate Auctions Invoice FGI${this.state.invoice.number}_${this.state.invoice.data.user.firstName} ${this.state.invoice.data.user.lastName}`
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
    this.setState({
      spinner: false
    })
  }

  changeRate (e, index) {
    if (this.state.canEdit) {
      if (e.target.value === '') {
        let lots = Object.assign([], this.state.lots)
        lots[index].priceRate = 0
        lots[index].priceRateText = ''
        lots[index].subTotal = (lots[index].priceRate * lots[index].count * ((100 - lots[index].lotDiscount) / 100))
        lots[index].subTotalText = 0
        this.updatePrices(lots)
        this.setState({
          lots
        })
        return
      }
      if (e.target.value.search(/^[^a-zA-Z]+$/)) {
        return
      }
      let lots = Object.assign([], this.state.lots)
      lots[index].priceRate = this.state.kind === 'sheep' ? e.target.value : Number((e.target.value * 100).toFixed(0))
      lots[index].priceRateText = e.target.value
      lots[index].subTotal = (lots[index].priceRate * lots[index].count * ((100 - lots[index].lotDiscount) / 100))
      lots[index].subTotalText = /* this.state.kind === 'sheep' ? lots[index].subTotal : */ (lots[index].subTotal / 100).toFixed(2)
      if (e.target.value.search(/^0[0-9]/) !== -1) {
        lots[index].priceRateText = lots[index].priceRateText.substr(1)
      }
      this.updatePrices(lots)
      this.setState({
        lots
      })
    }
  }

  changeSubTotal (e, index) {
    if (this.state.canEdit) {
      let lots = Object.assign([], this.state.lots)
      if (e.target.value === '') {
        lots[index].subTotal = 0
        lots[index].subTotalText = ''
        this.updatePrices(lots)
        this.setState({
          lots
        })
        return
      }
      if (e.target.value.search(/^[^a-zA-Z]+$/)) {
        return
      }
      lots[index].subTotal = /* this.state.kind === 'sheep' ? Number(e.target.value) : */Number((e.target.value * 100).toFixed(0))
      lots[index].subTotalText = e.target.value
      if (e.target.value.search(/^0[0-9]/) !== -1) {
        lots[index].subTotalText = lots[index].subTotalText.substr(1)
      }
      this.updatePrices(lots)
      this.setState({
        lots
      })
    }
  }

  updatePrices (lots) {
    let invoiceTotal = 0
    const auctionDiscount = this.props.record.data.auction.auctionDiscount ? this.props.record.data.auction.auctionDiscount : 0
    const setUpFee = this.props.record.data.auction.auctionSetUpFee ? this.props.record.data.auction.auctionSetUpFee : 0
    lots.forEach(lot => {
      invoiceTotal += lot.subTotal
    })
    invoiceTotal = (invoiceTotal + setUpFee) * ((100 - auctionDiscount) / 100)
    let gstAmount = this.calculateGst(invoiceTotal, this.state.discount)
    let totalOwing = this.calculateTotalOwing(invoiceTotal, this.state.discount)
    this.props.setCount(totalOwing)
    this.setState({
      invoiceTotal,
      gstAmount,
      totalOwing
    })
  }

  calculateDiscount (lot) {
    if (lot.reoffered) return makePrice(0)
    const cents = lot.count * lot.priceRate * lot.lotDiscount / 100
    return makePrice(cents)
  }

  changeCount (e, index) {
    if (this.state.canEdit) {
      if (e.target.value === '') {
        let lots = Object.assign([], this.state.lots)
        lots[index].count = 0
        lots[index].countText = ''
        lots[index].subTotal = (lots[index].priceRate * lots[index].count * ((100 - lots[index].lotDiscount) / 100))
        lots[index].subTotalText = '0.00'
        this.updatePrices(lots)
        this.setState({
          lots
        })
        return
      }
      if (e.target.value.search(/^[^a-zA-Z]+$/)) {
        return
      }
      let lots = Object.assign([], this.state.lots)
      lots[index].count = e.target.value
      lots[index].countText = e.target.value
      lots[index].subTotal = (lots[index].priceRate * lots[index].count * ((100 - lots[index].lotDiscount) / 100))
      lots[index].subTotalText = /* this.state.kind === 'sheep' ? lots[index].subTotal : */(lots[index].subTotal / 100).toFixed(2)
      if (e.target.value.search(/^0[0-9]/) !== -1) {
        lots[index].countText = lots[index].countText.substr(1)
      }
      this.updatePrices(lots)
      this.setState({
        lots
      })
    }
  }

  render () {
    const { invoice, pagesCount, kind, lots } = this.state
    const user = invoice.data.user
    const Header = () => {
      if (this.props.record.data.auction.privateSale) {
        return (
          <div style={pdfStyles.headerPrivate}>
            <div style={pdfStyles.logo} />
            <div style={pdfStyles.contactInfo}>
              <div style={pdfStyles.contactInfoLine}>FarmGate Auctions Pty Ltd</div>
              <div style={pdfStyles.contactInfoLine}>A.C.N 604 541 433</div>
              <div style={pdfStyles.contactInfoLine}>Level 5, 10 Bridge Street,</div>
              <div style={pdfStyles.contactInfoLine}>Sydney NSW 2000</div>
              <div style={pdfStyles.contactInfoLine}>accounts@farmgateauctions.com.au</div>
              <div style={pdfStyles.contactInfoLine}>+61 (0) 2 82526840</div>
            </div>
          </div>
        )
      } else {
        return (
          <div style={pdfStyles.header}>
            <div style={pdfStyles.logo} />
            <div style={pdfStyles.contactInfo}>
              <div style={pdfStyles.contactInfoLine}>FarmGate Auctions Pty Ltd</div>
              <div style={pdfStyles.contactInfoLine}>A.C.N 604 541 433</div>
              <div style={pdfStyles.contactInfoLine}>Level 5, 10 Bridge Street,</div>
              <div style={pdfStyles.contactInfoLine}>Sydney NSW 2000</div>
              <div style={pdfStyles.contactInfoLine}>accounts@farmgateauctions.com.au</div>
              <div style={pdfStyles.contactInfoLine}>+61 (0) 2 82526840</div>
            </div>
          </div>
        )
      }
    }
    const InvoiceTo = () => {
      return (
        <div>
          <div style={pdfStyles.subHeader}>Invoice To</div>
          <div style={pdfStyles.userInfoWrapper}>
            <div style={pdfStyles.userInfoField}>
              {`${user.firstName} ${user.lastName}`}
            </div>
            <div style={pdfStyles.userInfoField}>
              {user.tradingName}
            </div>
            <div style={pdfStyles.userInfoField}>
              {user.officeAddress && user.officeAddress.address1}
            </div>
            <div style={pdfStyles.userInfoField}>
              {user.officeAddress && user.officeAddress.town}, {user.officeAddress && user.officeAddress.state}, {user.officeAddress && user.officeAddress.postcode}
            </div>
          </div>
          <div style={pdfStyles.fieldsWrapper}>
            <div style={pdfStyles.userField}>
              <span>Invoice No: </span>
              <span style={pdfStyles.userValue}>FGI{invoice.number}</span>
            </div>
            <div style={pdfStyles.userField}>
              <span style={pdfStyles.bold}>Date of Issue: </span>
              <span style={pdfStyles.userValue}>{moment(invoice.createdAt).format('DD/MM/YYYY')}</span>
            </div>
            <div style={pdfStyles.userField}>
              <span>Payment Due Date: </span>
              <span style={pdfStyles.userValue}>{moment(invoice.dueOn).format('DD/MM/YYYY')}</span>
            </div>
            {this.props.record.data.auction.auctionDiscount > 0 &&
            <div style={pdfStyles.userField}>
              <span>Total: </span>
              <span style={pdfStyles.userValue}>{makePrice(this.calculateTotal(lots))}</span>
            </div>}
            {this.props.record.data.auction.auctionDiscount > 0 &&
            <div style={pdfStyles.userField}>
              <span>Less Discount: </span>
              <span style={pdfStyles.userValue}>{`${invoice.data.auction.auctionDiscount}%`}</span>
            </div>}
            <div style={pdfStyles.userField}>
              <span>Total (excluding GST): </span>
              <span style={pdfStyles.userValue}>{makePrice(this.invoiceTotal(this.calculateTotal(lots), invoice.data.auction.auctionDiscount))}</span>
            </div>
            <div style={pdfStyles.userField}>
              <span>GST amount: (Included)</span>
              <span style={pdfStyles.userValue}>{makePrice(this.calculateGst(this.calculateTotal(lots), invoice.data.auction.auctionDiscount))}</span>
            </div>
            <div style={pdfStyles.userField}>
              <span>Total Amount Owing: </span>
              <span style={pdfStyles.userValue}>{makePrice(this.calculateTotalOwing(this.calculateTotal(lots), invoice.data.auction.auctionDiscount))}</span>
            </div>
          </div>
          {this.props.record.data.auction.auctionSetUpFee > 0 &&
          <div>
            <div style={pdfStyles.subHeader}>Auction Set Up Fee</div>
            <div style={pdfStyles.setUpFeeField}>
              <span style={pdfStyles.etUpFee}>Set Up Fee</span>
              <span style={pdfStyles.setUpFeeNumber}>{makePrice(this.props.record.data.auction.auctionSetUpFee)}</span>
            </div>
          </div>}
        </div>
      )
    }
    const Footer = (props) => {
      return (
        <div style={pdfStyles.pageFooter}>
          <div>
            <div style={pdfStyles.footer}>
              <div style={pdfStyles.termsDay}>Terms 7 Days</div>
            </div>
            <div style={pdfStyles.info}>
              <div style={pdfStyles.directDeposit}>
                <div style={pdfStyles.directDepositContent}>
                  <div style={pdfStyles.chequeLogoBlock}>
                    <div style={pdfStyles.directLogo} />
                  </div>
                  <div style={pdfStyles.directContent}>
                    <div style={pdfStyles.bold}>EFT | Direct Deposit</div>
                    <div>Reference No: FGI{invoice.number}</div>
                    <div>Bank: NAB</div>
                    <div>A/c Name: FarmGate Auctions Pty Ltd</div>
                    <div>BSB: 082-140 A/c No: 78-465-2663</div>
                  </div>
                </div>
              </div>
              <div style={pdfStyles.cheque}>
                <div style={pdfStyles.chequeLogoBlock}>
                  <div style={pdfStyles.chequeLogo} />
                </div>
                <div style={pdfStyles.chequeContent}>
                  <div style={pdfStyles.bold}>Cheque</div>
                  <div>Payable to: FarmGate Auctions</div>
                  <div>Mail to: Level 5, 10 Bridge Street,</div>
                  <div>Sydney, NSW 2000</div>
                </div>
              </div>
            </div>
            <div style={pdfStyles.page}>
              <div style={pdfStyles.pageNumber}>
                {props.page}-{this.state.pagesCount}
              </div>
            </div>
          </div>
        </div>
      )
    }
    const pages = []
    const totalLots = [...invoice.data.lots]
    let numberOfLots = (!this.props.record.data.auction.auctionSetUpFee) ? 6 : 4
    if (this.props.record.data.auction.auctionDiscount && this.props.record.data.auction.auctionDiscount > 0) {
      numberOfLots -= 1
    }
    for (let i = 0; i < pagesCount; i++) {
      let lots
      if (i === 0) {
        lots = totalLots.splice(0, numberOfLots)
      } else {
        lots = totalLots.splice(0, 14)
      }
      pages.push(<React.Fragment>
        <Header />
        <div style={pdfStyles.mainBlock}>
          {i === 0 && <InvoiceTo />}
          <div className='listing-fees'>
            <div style={pdfStyles.subHeader}>Listing Fees</div>
            <table style={pdfStyles.listingFeesTable}>
              <tr>
                <th style={pdfStyles.tableHeader}>Auction Date</th>
                <th style={pdfStyles.tableHeaderVendorName}>Vendor Name</th>
                <th style={pdfStyles.tableHeader}>Vendor PIC.</th>
                <th style={pdfStyles.tableHeader}>Lot No.</th>
                <th style={pdfStyles.tableHeader}>Closed Status</th>
                <th style={pdfStyles.tableHeader}>No. Head</th>
                <th style={pdfStyles.tableHeader}>Stock Type</th>
                <th style={pdfStyles.tableHeader}>Per Head</th>
                {this.state.hasLotDiscountField &&
                <th style={pdfStyles.tableHeader}>Lot Fee</th>
                }
                {this.state.hasLotDiscountField &&
                <th style={pdfStyles.tableHeader}>Lot Discount</th>
                }
                <th style={pdfStyles.tableHeader}>Fee Total</th>
              </tr>
              <tr>
                <td colSpan='12'>
                  <hr style={pdfStyles.underline} />
                </td>
              </tr>
              {lots.map((lot, index) => {
                let trueIndex = index + i * numberOfLots
                return (
                  <React.Fragment>
                    <tr>
                      <td style={pdfStyles.lotsRowCell}>{moment(invoice.data.auction.liveAt).format('DD/MM/YYYY')}</td>
                      <td style={pdfStyles.lotsRowCell}>{lot.publicDetails.vendorName}</td>
                      <td style={pdfStyles.lotsRowCell}>{lot.inlineDetails && lot.inlineDetails.vendorPic}</td>
                      <td style={pdfStyles.lotsRowCell}>{lot.number}</td>
                      <td style={pdfStyles.uppercaseCell}>{generateStatusString(lot.status)}</td>
                      <td style={pdfStyles.lotsRowCell}>
                        <input
                          style={pdfStyles.input}
                          type='text'
                          value={this.state.lots[trueIndex].countText}
                          onChange={(e) => { this.changeCount(e, i > 0 ? trueIndex : index) }}
                        />
                      </td>
                      <td
                        style={pdfStyles.uppercaseCell}>{invoice.data.auction.kindData && invoice.data.auction.kindData.title}</td>
                      <td style={pdfStyles.lotsRowCell}>
                        <span>
                          {kind === 'sheep' ? '¢' : '$'}
                        </span>
                        <span>
                          <input
                            style={pdfStyles.input}
                            type='text'
                            value={this.state.lots[trueIndex].priceRateText}
                            onChange={(e) => { this.changeRate(e, i > 0 ? trueIndex : index) }}
                          />
                        </span>
                      </td>
                      {this.state.hasLotDiscountField &&
                      <td
                        style={pdfStyles.uppercaseCell}>{makePrice(this.state.lots[trueIndex].priceRate * this.state.lots[trueIndex].count)}</td>
                      }
                      {this.state.hasLotDiscountField &&
                      <td style={pdfStyles.uppercaseCell}>{this.calculateDiscount(this.state.lots[trueIndex])}</td>
                      }
                      <td style={pdfStyles.lotsRowCell}>
                        <span>
                          {'$'}
                        </span>
                        <span>
                          <input
                            style={pdfStyles.input}
                            type='text'
                            value={this.state.lots[trueIndex].subTotalText}
                            onChange={(e) => { this.changeSubTotal(e, i > 0 ? trueIndex : index) }}
                          />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan='12'>
                        <hr style={pdfStyles.underline} />
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </table>
          </div>
        </div>
        <Footer page={i + 1} />
      </React.Fragment>)
    }
    return (
      <React.Fragment>
        <div style={pdfStyles.wrapper} id='pdf-template'>
          {this.state.spinner && <Spinner />}
          <Dialog
            open={this.state.deliveredEmail}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby='alert-dialog-slide-title'
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle id='alert-dialog-slide-title'>
              {'Email Delivered'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                Email was successfully sent and will be delivered shortly
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color='primary'>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          {pages}
        </div>
        <div className='buttons'>
          <Button disabled={this.props.disabled} onClick={this.sendPdf} variant='contained' color='primary' className='send-email'>
            Send Email
          </Button>
          <Button disabled={this.props.disabled} onClick={this.downloadPdf} variant='contained' color='primary' className='send-email'>
            Download
          </Button>
          <Button disabled={this.props.disabled} onClick={this.sendOverdueEmail} variant='contained' color='primary' className='send-email'>
            Overdue
          </Button>
        </div>
      </React.Fragment>
    )
  }
}

export default PdfModel
