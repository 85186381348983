import axios from 'axios'

// handles for ActionButton

export const withdrawLot = async (props) => {
  await axios(`lots/${props.data.id}/withdraw`, {
    method: 'put'
  })
  props.showNotification('Lot was successfully withdrawn', 'success')
  props.data.refreshView()
}

export const add10Seconds = async (props, { withoutRefresh = false } = {}) => {
  await axios(`lots/${props.data.id}/add-time?seconds=10`, {
    method: 'put'
  })
  props.showNotification('Time was successfully added', 'success')
  if (!withoutRefresh) {
    props.data.refreshView()
  }
}

export const addMinute = async (props, { withoutRefresh = false } = {}) => {
  await axios(`lots/${props.data.id}/add-time?seconds=60`, {
    method: 'put'
  })
  props.showNotification('Time was successfully added', 'success')
  if (!withoutRefresh) {
    props.data.refreshView()
  }
}

export const freezeLot = async (props) => {
  await axios(`lots/${props.data.id}/freeze`, {
    method: 'put'
  })
  props.showNotification('Lot was successfully frozen', 'success')
  props.data.refreshView()
}

export const pauseLot = async (props) => {
  await axios(`lots/${props.data.id}/pause`, {
    method: 'put'
  })
  props.showNotification('Lot was successfully paused', 'success')
  props.data.refreshView()
}

export const resumeLot = async (props) => {
  await axios(`lots/${props.data.id}/resume`, {
    method: 'put'
  })
  props.showNotification('Lot was successfully resumed', 'success')
  props.data.refreshView()
}

export const closeSequentialAuctionLot = async (props) => {
  await axios(`lots/${props.data.id}/close`, {
    method: 'post'
  })
  props.showNotification('Lot was successfully closed', 'success')
  props.data.refreshView()
}

export const moveToAcution = async (props) => {
  let { basePath, id } = props.data
  props.push(`${basePath}/${id}`)
}

export const handleOpenLot = async (props) => {
  let lot_id = props.data.id
      let data = {
        lot_id
      }
  await axios(`lots/${props.data.id}/reopen`, {
    method: 'post',
    data
  })
  props.showNotification('Lot was successfully opened', 'success')
  if(props.isComingfromLotTable){
    window.location.reload()
  }else{
    props.data.refreshView()
  }
  
}