import React from 'react'
import DebounceSelectField from '../LotAssessmentForm/fields/DebounceSelectField'
import { Form } from 'redux-form'

export default class MultipleSelect extends Form {
  constructor (props, context) {
    super(props, context)

    this.reduxForm = context._reduxForm
    this.state = {
      value: props.record.auctionTags || [],
      options: props.options
    }
    this.dispatchForm = this.dispatchForm.bind(this)
    this.clearTags = this.clearTags.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.options && nextProps.options.length) {
      this.setState({ options: nextProps.options })
    }
  }

  render () {
    return <DebounceSelectField id={this.props.id} options={this.state.options} tags='true' value={this.state.value} field='title'
      multiple onChange={(value) => this.dispatchForm(value)} onReset={this.clearTags} />
  }

  dispatchForm (newValue) {
    if (newValue && newValue.value) {
      this.reduxForm.dispatch(this.reduxForm.change('auctionTags', newValue.value))
      this.setState({ value: newValue.value })
    }
  }

  clearTags () {
    this.reduxForm.dispatch(this.reduxForm.change('auctionTags', []))
    this.setState({ value: [] })
  }
}
