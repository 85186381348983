import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import compareArrays from '../../../helpers/compareArrays'

export default class FormRemoveButton extends Component {
  constructor (props) {
    super(props)

    this.onClick = props.removeSection
  }

  shouldComponentUpdate (nextProps) {
    return this.props.index !== nextProps.index || compareArrays(this.props.indexes, nextProps.indexes)
  }

  render () {
    return (
      <FontAwesomeIcon
        className='remove-button'
        icon='minus-square'
        onClick={() => this.onClick({ indexes: this.props.indexes, index: this.props.index, field: this.props.field })}
      />
    )
  }
}
