import handleError from '../handleError'
import { pick } from 'lodash'
import fileUploader from '../fileUploader'

const updateLotFields = [
  'description',
  'count',
  'countWeighed',
  'details',
  'bidding',
  'biddingSwap',
  'draft',
  'withdrawn',
  'startPrice',
  'bidIncrementCents',
  'reserveCents',
  'acceptenceOnTerms',
  'saleConditions',
  'allocatedSection',
  'approved',
  'maxVendorBids',
  'vendorSignedForm',
  'adjustWeight',
  'weightGain',
  'number',
  'soldAfterAuction',
  'soldBeforeAuction',
  'closedPrice',
  'highestBidCents',
  'removed',
  'lotDiscount',
  'optiweigh',
  'optiweighAllowed',
  'oldDetails',
  'oldWeightGain',
  'mobEntryWeight',
  'takeFeeForReoffered',
  'usefulLinks',
  'additionalTitle',
  'headOnNVD',
  'headAccordingToBuyer',
  'adminNotes',
]

const updateLotFieldsForClosedSeqOffAuction = [
  'description',
  'count',
  'countWeighed',
  'details',
  'bidding',
  'biddingSwap',
  'draft',
  'withdrawn',
  'startPrice',
  'bidIncrementCents',
  'reserveCents',
  'acceptenceOnTerms',
  'saleConditions',
  'allocatedSection',
  'approved',
  'maxVendorBids',
  'vendorSignedForm',
  'adjustWeight',
  'weightGain',
  'number',
  'soldAfterAuction',
  'soldBeforeAuction',
  'closedPrice',
  'highestBidCents',
  'removed',
  'lotDiscount',
  'optiweigh',
  'optiweighAllowed',
  'oldDetails',
  'oldWeightGain',
  'mobEntryWeight',
  'takeFeeForReoffered',
  'status',
  'state',
  'currentBidCents',
  'additionalTitle',
  'headOnNVD',
  'headAccordingToBuyer',
  'adminNotes',
]

const updateLot = (params, type, resource, httpClient, url) => {
  let newParams = JSON.parse(JSON.stringify(params))
  if (params.data.state === 'closed' || (params.data.state === 'future' && params.data.soldBeforeAuction)) {
    newParams.data = { lot: pick(params.data, updateLotFieldsForClosedSeqOffAuction) }
    newParams.data.lot.winner = params.chosenUser
    newParams.data.lot.soldAfterAuction = newParams.data.lot.status === 'soldAfterAuction'
  }
  else {
    newParams.data = { lot: pick(params.data, updateLotFields) }
  }

  return fileUploader(resource, params)
    .then((res) => {
      if (res[0] && res[0].data && res[0].data.newPdfFile) {
        const allLinks = res.map(
          (oneLink) => oneLink.data && oneLink.data.newPdfFile ? oneLink.data.newPdfFile : null)
          .filter(Boolean)
          .flat()
        newParams.data.lot.usefulLinks = newParams.data.lot.usefulLinks.concat(allLinks).filter(link => link.url)
      }
      if (newParams.data.lot.state === 'closed' && newParams.data.lot.status !== 'soldAfterAuction') {
        return httpClient(url + '/' + newParams['id'] + '/closed', {
          method: 'PUT',
          data: newParams.data
        })
      } else {
        if (newParams.chosenUser && newParams.data.lot.soldAfterAuction) {
          return httpClient(url + '/' + newParams['id'] + '/soldAA/' + params.chosenUser, {
            method: 'PUT',
            data: newParams.data
          })
        } else {
          return httpClient(url + '/' + newParams['id'], {
            method: 'PUT',
            data: newParams.data
          })
        }
      }
    })
    .then((response) => {
      const one = response.data[resource.slice(0, resource.length - 1)] || response.data[newParams.type]
      one.id = one['_id']
      return {
        data: one
      }
    })
    .catch(handleError)
}

export default { updateLot }
