export let checkNested = (mode, element, findFunc, options = {}) => {
  const { ignoreRFNumber = false, ignoreClosedRFBool = false } = options
  // mode = ['findOne', 'findAll'] enum
  switch (mode) {
    case 'findOne':
      if (!element.values) {
        return
      }
      switch (element.type) {
        case 'group':
          return findField(element.values, findFunc, options)
        case 'repeatForBool':
        case 'repeatForNumber':
          if (element.type === 'repeatForNumber' && ignoreRFNumber) {
            return
          }
          if (element.type === 'repeatForBool' &&
            ignoreClosedRFBool &&
            !element.value) {
            return
          }
          for (let fieldset of element.values) {  // eslint-disable-line
            let findElem = findField(fieldset, findFunc, options)
            if (findElem) {
              return findElem
            }
          }
          break
        default:
          throw new Error(`Unknown field type ${element.type}`)
      }
      break
    case 'findAll':
      if (!element.values) {
        return []
      }
      switch (element.type) {
        case 'group':
          return findAllFields(element.values, findFunc, options)
        case 'repeatForBool':
        case 'repeatForNumber':
          if (element.type === 'repeatForNumber' && ignoreRFNumber) {
            return []
          }
          if (element.type === 'repeatForBool' &&
            ignoreClosedRFBool &&
            !element.value) {
            return []
          }
          let resultArr = []
          for (let fieldset of element.values) {  // eslint-disable-line
            let res = findAllFields(fieldset, findFunc, options)
            if (res.length) {
              resultArr = resultArr.concat(res)
            }
          }
          return resultArr
        default:
          throw new Error(`Unknown field type ${element.type}`)
      }
    default:
      throw new Error('Unknown findField mode')
  }
}

export function findField (element, findFunc, options = {}) {
  if (!element) {
    return
  }
  if (!Array.isArray(element)) {
    if(element.type){
      let findElem = checkNested('findOne', element, findFunc, options)
      if (findElem) {
        return findElem
      }
    }

  } else {
    for (let child of element) {  // eslint-disable-line
      if (findFunc(child)) {
        return child
      }
      let findElem = checkNested('findOne', child, findFunc, options)
      if (findElem) {
        return findElem
      }
    }
  }
}

// same as findField, but returns an array with all matches (recursive)
export function findAllFields (element, findFunc, options = {}) {
  if (!element) {
    return []
  }
  let resultArr = []
  if (!Array.isArray(element)) {
    let res = checkNested('findAll', element, findFunc, options)
    if (res.length) {
      resultArr = resultArr.concat(res)
    }
  } else {
    for (let child of element) {  // eslint-disable-line
      if (findFunc(child)) {
        resultArr = resultArr.concat(child)
      }
      let res = checkNested('findAll', child, findFunc, options)
      if (res.length) {
        resultArr = resultArr.concat(res)
      }
    }
  }
  return resultArr
}

export function findFieldByPublicId (details, publicId, { ignoreRFNumber = true, ignoreClosedRFBool = true } = {}) {
  return findField(details,
    el => el.publicId && el.publicId === publicId,
    { ignoreRFNumber, ignoreClosedRFBool })
}
