import { Layout } from 'react-admin'
import React from 'react'
import { connect } from 'react-redux'
import Header from './header'
import Menu from './menu'
import Spinner from '../components/SpinnerComponent/Spinner'
import EditAdminBidModal from '../components/auction/show/editAdminBidModal/editAdminBidModalContainer'
import ActiveLotTable from '../components/auction/show/ActiveLotModal/ActiveLotModalContainer'

// import MyAppBar from './MyAppBar';
// import MyMenu from './MyMenu';
// import MyNotification from './MyNotification';

const appLayout = (props) => {
  const { isLoading, dispatch, windowPopup, ...newprops } = props

  return (
    <div>
      {isLoading && <Spinner />}
      <Layout
        {...newprops}
        appBar={Header}
        menu={Menu}
      />
      {windowPopup.showAdminBid && <EditAdminBidModal />}
      {windowPopup.showActiveLot && <ActiveLotTable />}
    </div>
  )
}

const mapStateToProps = state => {
  return ({ windowPopup: state.mainCustomData.windowPopup, isLoading: state.admin.loading > 0 })
}

export default connect(mapStateToProps)(appLayout)
