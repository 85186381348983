import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeWindowModal } from '../../../../actions'
import ActiveLotTable from './ActiveLotTable'

const mapStateToProps = (data) => {
  return {
    auction: data.mainCustomData.windowPopup.auction,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ changeWindowModal }, dispatch)
  }
}

const ActiveLotModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveLotTable)

export default ActiveLotModalContainer
