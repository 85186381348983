import React, { Component } from 'react'
import { msToTime, generateUserId } from '../../../utils'
import classNames from 'classnames'
import { OwnPagination } from '../../OwnPagination'
import { dataProvider } from '../../../App'
import update from 'immutability-helper'
import { GET_LIST, Datagrid, TextField, ArrayField } from 'react-admin'
import MaterialTextField from '@material-ui/core/TextField'
import config from '../../../config'
import debounce from 'lodash/debounce'
import { collapsible } from '../../../utils/auction/show/collapsible'

export class UserBuyersDisconnected extends Component {
  constructor (props) {
    super(props)

    this.state = {
      userBuyers: {
        data: {},
        page: 1,
        perPage: 10,
        total: 0,
        field: '_id',
        order: 'ASC',
        isOpened: false
      },
      searchUserBuyers: ''
    }
    this.loadUserBuyers()
  }

  loadUserBuyers = async () => {
    dataProvider(GET_LIST, `auctions/${this.props.auctionId}/buyers`, {
      filter: this.filter,
      pagination: {
        page: this.state.userBuyers.page,
        perPage: this.state.userBuyers.perPage
      },
      sort: {
        field: this.state.userBuyers.field,
        order: this.state.userBuyers.order
      },
      search: this.state.searchUserBuyers,
      isAuctionBuyers: true,
      auction: true
    })
      .then(({ data, total }) => {
        let newids = []
        let newdata = {}
        for (let i = 0; i < data.length; i++) {
          let id = data[i]._id
          newids.push(id)
          newdata[id] = {
            ...data[i]
          }
        }
        this.setState({
          userBuyers: update(this.state.userBuyers, {
            data: {
              $set: newdata
            },
            total: {
              $set: total
            }
          })
        })
      })
  }

  setSearchUserBuyersString = ({ target: { value } }) => {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.loadUserBuyers, 300)
    this.setState({
      searchUserBuyers: value
    }, this.debounced)
  }

  changeCollapse = (state) => {
    this.setState({
      userBuyers: update(this.state.userBuyers, {
        isOpened: { $set: state }
      })
    })
  }

  setPageBuyers = (page) => {
    this.setState({
      userBuyers: update(this.state.userBuyers, {
        page: { $set: page }
      })
    }, this.loadUserBuyers)
  }

  setPerPageBuyers = (perPage) => {
    this.setState({
      userBuyers: update(this.state.userBuyers, {
        perPage: { $set: perPage }
      })
    }, this.loadUserBuyers)
  }

  renderUsers () {
    let { data } = this.state.userBuyers
    let ids = Object.keys(data)

    const UserId = ({ record = {} }) => {
      return (
        <div> {generateUserId(record.user.type, record.user.numericId)} </div>
      )
    }
    const UserField = ({ record = {} }) => {
      if (!(record.user && record.user.connectivity)) {
        return 'User Not Found'
      }
      return <a href={`/#/users/${record.user._id}/show`} className='self-link'>{record.user.firstName} {record.user.lastName}</a>
    }
    const LotLinkField = ({ record = {} }) => {
      if (!(record.lastBidLotId && record.lastBidLotDescription)) {
        return null
      }
      return (
        <span>
          {record.lastBidLotId && record.lastBidLotDescription &&
          <a href={`/#/lots/${record.lastBidLotId}/show`} className='vendor-link'>{record.lastBidLotDescription}</a>
          }
        </span>
      )
    }
    const LotNumber = ({ record = {} }) => {
      if (!(record.user && record.lastBidNumber)) {
        return null
      }
      return (
        <span>
          {record.lastBidNumber &&
          <a href={`/#/lots/${record.lastBidLotId}/show`} className='vendor-link'>{record.lastBidNumber}</a>
          }
        </span>
      )
    }
    const ConnectionTimeStartField = ({ record = {} }) => {
      return <span>{record.timeConnected ? new Date(record.timeConnected).toLocaleTimeString() : '-'}</span>
    }
    const ConnectionTimeEndField = ({ record = {} }) => {
      return <span>{record.timeDisconnected ? new Date(record.timeDisconnected).toLocaleTimeString() : '-'}</span>
    }
    const TimeSinceLastBidField = ({ record = {} }) => {
      if (!record.lastBid) {
        return null
      }
      if (!record.lastBid || !record.lastBid.createdAt) {
        return (
          <span>
            -
          </span>
        )
      }
      let diff = new Date().getTime() - new Date(record.lastBid.createdAt).getTime()
      return (
        <span>
          {msToTime(diff)}
        </span>
      )
    }
    const StatusField = ({ record = {} }) => {
      const highlight = classNames('status', {
        green: record.state === 'ONLINE',
        red: record.state === 'ACTIVE',
        black: record.state === 'OFFLINE'
      })
      return (
        <span className={highlight}>{record.state}</span>
      )
    }
    const LotSearchNumberField = ({ record = {} }) => {
      let lot
      try {
        lot = record.lastBidLotId
      } catch (e) {
        lot = null
      }
      return (
        <span>
          {lot &&
          <a href={`/#/lots/${record.lastBidLotId}/show`} className='vendor-link'>{record.lastBidLotSearchId}</a>
          }
        </span>
      )
    }
    return (
      <div className='info-table-div-wrapper'>
        <MaterialTextField
          label='Search'
          placeholder='Search'
          className='search-text'
          onChange={this.setSearchUserBuyersString}
        />
        <Datagrid
          ids={ids}
          data={data}
          currentSort={{}}
        >
          <UserId source='Id' />
          <UserField label='Name' sortable={false} />
          <TextField source='user.phone' label='Phone' sortable={false} />
          <TextField source='user.email' label='Email' sortable={false} />
          <TextField source='user.officeAddress.town' label='Town' sortable={false} />
          <TextField source='user.officeAddress.state' label='State' sortable={false} />
          <TextField source='user.postalAddress.address1' label='Postal Address Line 1' />
          <TextField source='user.postalAddress.address2' label='Postal Address Line 2' />
          <TextField source='user.tradingName' label='Trading Name' />
          <TextField source='user.connectivity.lastPlatform' label='App' sortable={false} />
          <ArrayField source='_platforms' className='platforms-array'>
            <Datagrid>
              <TextField source='platformName' label='Platform' />
              <StatusField source='state' label='Status' sortable={false} />
              <ConnectionTimeStartField label='Time Connected' sortable={false} />
              <ConnectionTimeEndField label='Time Disconnected' sortable={false} />
            </Datagrid>
          </ArrayField>
          <LotSearchNumberField label='Last Bid Lot ID' sortable={false} />
          <LotNumber label='Last Bid Lot No' sortable={false} />
          <LotLinkField source='user.connectivity.lastBid.lot' label='Last Bid Lot' sortable={false} />
          <TextField source='_price' label='Bid Amount' sortable={false} />
          <TextField source='user.connectivity.ip' label='IP' sortable={false} />
          <TimeSinceLastBidField source='' label='Time Since Last Bid' sortable={false} />
        </Datagrid>
        <OwnPagination
          page={this.state.userBuyers.page}
          rowsPerPage={this.state.userBuyers.perPage}
          perPage={this.state.userBuyers.perPage}
          total={this.state.userBuyers.total}
          setPerPage={this.setPerPageBuyers}
          setPage={this.setPageBuyers}
        />
      </div>
    )
  }

  render () {
    let timeZone
    try {
      timeZone = window.Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (e) {
      timeZone = config.timeZone
    }

    return (
      <div id='collapse-content-buyers'>
        {
          collapsible({
            title: `User Buyers Disconnected, ${this.state.userBuyers.total} Offline`,
            exportLink: `${config.rootUrl}exports/auctions/${this.props.auctionId}/buyers?${config.adminAccess}=true${timeZone ? '&timeZone=' + timeZone : ''}`,
            isOpened: this.state.userBuyers.isOpened,
            body: this.renderUsers(),
            changeCollapse: this.changeCollapse
          })
        }
      </div>
    )
  }
}
