import React, { Component, useState } from 'react'
import {
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  DisabledInput,
  EditButton,
  SelectInput,
  GET_LIST,
  ArrayField,
  LongTextInput
} from 'react-admin'
import moment from 'moment'
import MaterialTextField from '@material-ui/core/TextField'
import './styles.css'
import { BaseListComponent } from '../../components/BaseList'
import EditToolbar from '../../components/EditToolbar'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { Collapse } from 'react-collapse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import update from 'immutability-helper'
import { InvoiceTemplateComponent } from './template/model'
import { gstTax } from '../../constants/index'
import { dataProvider } from '../../App'
import config from '../../config'
import { getFeePerHead, makePrice } from '../../utils'
import debounce from 'lodash/debounce'
import { Field } from 'redux-form'
import { InvoiceDate } from '../../components/InvoiceDate/InvoiceDate'

const PENDING = 'pending'
const PAID = 'paid'

export class InvoiceListComponent extends BaseListComponent {
  constructor (props) {
    super(props)
    this.state = {
      ids: [],
      data: {},
      total: 0,
      page: 1,
      perPage: config.perPage,
      field: 'createdAt',
      order: 'DESC',
      timerEnabled: true,
      search: null
    }
  }

  componentDidUpdate () {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    if (this.state.search !== this.props.search) {
      this.setState({
        search: this.props.search
      }, this.debounced)
      this.debounced = debounce(this.loadData, 1000)
    }
  }

  loadData () {
    dataProvider(GET_LIST, 'invoices', {
      user: this.props.id,
      pagination: {
        page: this.state.page,
        perPage: this.state.perPage
      },
      sort: {
        field: this.state.field === '_id' ? 'createdAt' : this.state.field,
        order: this.state.field === '_id' ? 'DESC' : this.state.order
      },
      status: this.props.type,
      search: this.props.search || null
    })
      .then(({ data, total }) => {
        if (data) {
          let newIds = data.map(invoice => {
            return invoice._id
          })
          data.forEach(invoice => {
            // We need to check if listingFee was setted
            if (invoice.data.auction && invoice.data.auction.listingFee) {
              invoice.data.auction.auctionSetUpFee = invoice.data.auction.auctionSetUpFee ? invoice.data.auction.auctionSetUpFee : 0
              invoice.lots.forEach(lot => {
                lot.priceInDollars = lot.reoffered && !lot.takeFeeForReoffered ? 0 : getFeePerHead(lot)
                lot.price = !lot.reoffered || lot.takeFeeForReoffered ? lot.lotFee : 0
                if (!lot.lotDiscount) {
                  lot.lotDiscount = 0
                }
                lot.discountFee = lot.lotFee * lot.lotDiscount / 100
                lot.totalFee = lot.lotFee - lot.discountFee
              })
            } else {
              invoice.lots.forEach(lot => {
                lot.lotFee = !lot.reoffered || lot.takeFeeForReoffered ? lot.lotFee : 0
                if (!lot.lotDiscount) {
                  lot.lotDiscount = 0
                }
                lot.priceInDollars = lot.reoffered && !lot.takeFeeForReoffered ? 0 : getFeePerHead(lot)
                lot.discountFee = lot.lotFee * lot.lotDiscount / 100
                lot.totalFee = lot.lotFee - lot.discountFee
              })
            }
          })
          data = data.reduce((acc, cur) => ({ ...acc, [cur._id]: cur }), {})
          this.setState({
            data: data, ids: newIds, total: total
          })
        }
      })
  }

  render () {
    const { ids, data } = this.state
    let filteredProps = { ...this.props }
    delete filteredProps.hasList
    delete filteredProps.hasShow
    delete filteredProps.hasEdit
    const InvoiceEditButton = ({ record }) => <EditButton to={`/invoices/${record._id}`} />
    const LotNumberLink = ({ record }) => <a href={`/#/lots/${record._id}/show`}
      className='vendor-link'>{record.number ? record.number : ''}</a>
    const LotDescriptionLink = ({ record }) => <a href={`/#/lots/${record._id}/show`}
      className='vendor-link title-lot'>{record.publicDetails ? record.publicDetails.title : ''}</a>
    const LotStatusField = ({ record }) => {
      if (record.customStatus) {
        let currentStatus = record.customStatus.match(/[A-Z]*[^A-Z]+/g).map(word => word[0].toUpperCase() + word.slice(1)).join(' ')
        if (record.customStatus === 'soldAfterAuction') {
          currentStatus = 'Sold AA'
        }
        return <span>{currentStatus}</span>
      } else {
        return null
      }
    }

    const ReofferField = ({ record = {} }) => {
      return (
        <div className='reoffered-field'>
          <span>{record.status && record.reoffered === true ? 'Yes' : record.status && record.reoffered === false ? 'No' : ''}</span>
          {record.reoffered
            ? <>
              <span className='separator'>/</span>
              <span>{record.status && record.takeFeeForReoffered ? 'Yes' : 'No'}</span>
            </> : null
          }
        </div>
      )
    }

    const EmailStatusField = ({ record }) => {
      let status
      if (record.emailStatus && !record.emailStatus.length) {
        status = 'pending'
      } else if (record.emailStatus === true) {
        status = 'sent'
      } else if (record.emailStatus === false) {
        status = 'pending'
      } else {
        status = record.emailStatus
      }
      return (
        <span className={`upper ${status === 'pending' ? 'pending' : ''}`}>{status}</span>
      )
    }

    const PaymentStatusField = ({ record }) => {
      return (<span
        className={`${record.status.toLowerCase() === 'overdue' && 'red'} ${record.status.toLowerCase() === 'paid' && 'green'}`}>
        {record.status.toLowerCase() === 'pending' || record.status.toLowerCase() === 'sent' ? '' : record.status}
      </span>)
    }

    const TotalOwing = ({ record }) => {
      let totalExcGST = 0
      record.data.lots.forEach(lot => {
        lot.lotDiscount = lot.lotDiscount ? lot.lotDiscount : 0
        if (!lot.reoffered || lot.takeFeeForReoffered) {
          totalExcGST += lot.lotFee * ((100 - lot.lotDiscount) / 100)
        }
      })
      if (record.data.auction.privateSale) {
        if (record.data.auction.auctionSetUpFee) {
          totalExcGST += record.data.auction.auctionSetUpFee
        }
        const auctionDiscount = record.data.auction.auctionDiscount ? record.data.auction.auctionDiscount : 0
        totalExcGST = totalExcGST * (100 - auctionDiscount) / 100
      } else {
        const userDiscount = record.data.user.discount ? record.data.user.discount : 0
        totalExcGST = totalExcGST * (100 - userDiscount) / 100
      }
      let gstRate = gstTax
      if (record.hasOwnProperty('gst')) {
        gstRate = record.gst
      }
      let gst = totalExcGST / 100 * gstRate
      const totalOwing = (totalExcGST + gst) / 100
      return <span>{`$${totalOwing.toFixed(2)}`}</span>
    }

    const GSTincluded = ({ record }) => {
      let totalExcGST = 0
      record.data.lots.forEach(lot => {
        lot.lotDiscount = lot.lotDiscount ? lot.lotDiscount : 0
        if (!lot.reoffered || lot.takeFeeForReoffered) {
          totalExcGST += lot.lotFee * ((100 - lot.lotDiscount) / 100)
        }
      })
      if (record.data.auction.privateSale) {
        if (record.data.auction.auctionSetUpFee) {
          totalExcGST += record.data.auction.auctionSetUpFee
        }
        const auctionDiscount = record.data.auction.auctionDiscount ? record.data.auction.auctionDiscount : 0
        totalExcGST = totalExcGST * (100 - auctionDiscount) / 100
      } else {
        const userDiscount = record.data.user.discount ? record.data.user.discount : 0
        totalExcGST = totalExcGST * (100 - userDiscount) / 100
      }
      let gstRate = gstTax
      if (record.hasOwnProperty('gst')) {
        gstRate = record.gst
      }
      let gst = totalExcGST / 100 * gstRate / 100
      return <span>{`$${gst.toFixed(2)}`}</span>
    }

    const TotalexclGST = ({ record }) => {
      let totalExcGST = 0
      record.data.lots.forEach(lot => {
        lot.lotDiscount = lot.lotDiscount ? lot.lotDiscount : 0
        if (!lot.reoffered || lot.takeFeeForReoffered) {
          totalExcGST += lot.lotFee * ((100 - lot.lotDiscount) / 100)
        }
      })
      if (record.data.auction.privateSale) {
        if (record.data.auction.auctionSetUpFee) {
          totalExcGST += record.data.auction.auctionSetUpFee
        }
        const auctionDiscount = record.data.auction.auctionDiscount ? record.data.auction.auctionDiscount : 0
        totalExcGST = totalExcGST * (100 - auctionDiscount) / 100
      } else {
        const userDiscount = record.data.user.discount ? record.data.user.discount : 0
        totalExcGST = totalExcGST * (100 - userDiscount) / 100
      }
      return <span>{makePrice(totalExcGST)}</span>
    }

    const UserDiscount = ({ record }) => {
      return (<>
        {!record.data.auction.privateSale && record.data.user.discount
          ? <span>{`${record.data.user.discount}%`}</span> : <span>{``}</span>}
      </>)
    }

    const UserIdField = ({ record = {} }) => {
      return (<>
        {record.data.user ? <a href={`/#/users/${record.data.user._id}/show`}
          className='vendor-link'>{`UV${record.data.user.numericId}`}</a> : null}
      </>)
    }

    const DueOn = ({ record = {} }) => <span>{moment(record.dueOn).format('DD/MM/YYYY')}</span>

    const CreatedAt = ({ record = {} }) => <span>{moment(record.createdAt).format('D/MM/YYYY')}</span>

    const TotalField = ({ record }) => {
      let total = 0
      record.data.lots.forEach(lot => {
        if (lot.count > 0 && lot.status && (!lot.reoffered || lot.takeFeeForReoffered)) {
          total += lot.totalFee
        }
      })
      return (<span>{makePrice(total)}</span>)
    }

    const LotFeeField = ({ record = {} }) => {
      if (record.status) {
        return (<span>{makePrice(record.lotFee)}</span>)
      } else {
        return (<span>{''}</span>)
      }
    }

    const RateField = ({ record = {} }) => {
      if (record.status) {
        return (<span>{makePrice(record.priceInDollars)}</span>)
      } else {
        return (<span>{''}</span>)
      }
    }

    const LotDiscountField = ({ record = {} }) => {
      if (record.status) {
        return (<span>{makePrice(record.discountFee)}</span>)
      } else {
        return (<span>{''}</span>)
      }
    }

    const LotFeeTotalField = ({ record = {} }) => {
      if (record.status) {
        return (<span>{makePrice(record.totalFee)}</span>)
      } else {
        return (<span>{''}</span>)
      }
    }

    const AuctionDiscount = ({ record = {} }) => {
      return (<>
        {record.data.auction.auctionDiscount && record.data.auction.privateSale
          ? <span>{`${record.data.auction.auctionDiscount}%`}</span> : <span>{``}</span>}
      </>)
    }

    const AuctionSetUpFee = ({ record = {} }) => {
      return (<>
        {record.data.auction.auctionSetUpFee && record.data.auction.privateSale
          ? <span>{makePrice(record.data.auction.auctionSetUpFee)}</span> : <span>{``}</span>}
      </>)
    }

    return (
      <div>
        <div className='datagrid-wrapper' style={{ display: 'block' }}>
          <Datagrid
            ids={ids}
            data={data}
            currentSort={{ field: this.state.field, order: this.state.order }}
            setSort={this.setSort}
            {...filteredProps}
            className='invoices-wrapper'
          >
            <UserIdField source='data.user.numericId' label='User ID' />
            <UserLinkField source='data.user.firstName' label='User' />
            <AuctionIdLinkField source='data.auction.searchNumber' label='Auction ID' />
            <AuctionLinkField source='data.auction.title' label='Auction' />
            <TextField source='number' label='Invoice No' />
            <CreatedAt source='createdAt' label='Invoice  Date' />
            <DueOn source='dueOn' label='Payment Due Date' />
            <PaymentStatusField source='status' label='Payment Status' />
            <EmailStatusField source='emailStatus' label='Email Status' />
            <ArrayField source='lots' className='invoices-lots'>
              <Datagrid>
                <TextField source='publicDetails.vendorName' label='Vendor Name' />
                <TextField source='inlineDetails.vendorPic' label='Vendor PIC' />
                <LotNumberLink source='customLotNumber' label='Lot Number' />
                <LotDescriptionLink source='publicDetails.title' label='Lot Description' />
                <LotStatusField source='customStatus' label='Closed Status' />
                <ReofferField label='Reoffered/Take Fee For Reoffered' source='reoffered' />
                <TextField source='count' label='No. Head' />
                <RateField source='priceInDollars' label='Listing Fee' />
                <LotFeeField source='lotFee' label='Lot Fee' />
                <LotDiscountField source='lotDiscount' label='Lot Discount' />
                <LotFeeTotalField source='totalFee' label='Fee Total' />
              </Datagrid>
            </ArrayField>
            <TotalField source='totalPrice' label='Total' />
            <AuctionSetUpFee source='totalPrice' label='Set Up Fee' />
            <AuctionDiscount source='data.auction.auctionDiscount' label='Less Auction Discount %' />
            <UserDiscount source='data.user.discount' label='User Discount %' />
            <TotalexclGST source='totalPrice' label='Total excl GST - (Discount)' />
            <GSTincluded source='totalPrice' label='GST' />
            <TotalOwing source='totalOwning' label='Total Amount Owing' />
            <InvoiceEditButton />
          </Datagrid>
        </div>
        {this.showPagination()}
      </div>
    )
  }
}

export const InvoiceEdit = (props) => {
  const [count, setCount] = useState(null)
  return (
    <Edit className='edit-invoice-page' title='Edit Invoice' {...props}>
      <SimpleForm toolbar={<EditToolbar />} validate={validateInvoiceEdit}>
        <DisabledInput label='Id' source='_id' />
        <SelectInput source='status' choices={[{ id: 'paid', name: 'paid' }, { id: 'overdue', name: 'overdue' }]}
          label='Payment Status' />
        <Field name='createdAt' source='createdAt' label='Invoice Date' component={InvoiceDate} />
        <Field name='dueOn' source='dueOn' label='Payment Date' spacing component={InvoiceDate} />
        <LongTextInput className='admin-layout' label='Admin Notes:' source='adminNotes' />
        <InvoiceTemplateComponent
          {...props}
          count={count}
          setCount={setCount}
        />
      </SimpleForm>
    </Edit>
  )

  function validateInvoiceEdit(values) {
    const errors = {}
    if (!values.createdAt) {
      errors.createdAt = ['Invoice Date field is required']
    }
    if (!values.dueOn) {
      errors.dueOn = ['Payment Date field is required']
    }
    if (moment(values.dueOn).isBefore(moment(values.createdAt))) {
      errors.createdAt = ['Invoice date must be before payment date']
    }
    return errors
  }
}

const AuctionLinkField = ({ record = {} }) => {
  return (<a className='auction-link vendor-link'
    href={`/#/auctions/${record.data.auction._id}/show`}>{record.data.auction.title}</a>)
}

const AuctionIdLinkField = ({ record = {} }) => {
  return (<a className='auction-link vendor-link'
    href={`/#/auctions/${record.data.auction._id}/show`}>{record.data.auction.searchNumber}</a>)
}

const UserLinkField = ({ record = {} }) => {
  return (<>
    {record.data.user ? <a href={`/#/users/${record.data.user._id}/show`}
      className='vendor-link'>{record.userName}</a> : null}
  </>)
}

export class InvoiceList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pending: {
        isOpened: false
      },
      paid: {
        isOpened: false
      },
      searchPaid: null,
      searchPending: null
    }
    this.changeCollapse = this.changeCollapse.bind(this)
  }

  handleSearch = (event, type) => {
    if (type === PENDING) {
      this.setState({ searchPending: event.target.value })
    } else {
      this.setState({ searchPaid: event.target.value })
    }
  }

  collapsible = ({ title, isOpened, changeCollapse, publicId, body, filter = false }) => {
    return (
      <div className='collapse-wrapper'>
        <div
          className='header'
          onClick={isOpened ? () => (changeCollapse(publicId, false)) : () => (changeCollapse(publicId, true))}
        >
          <p className='title'>
            {title}
          </p>
          <div
            className='button'
          >
            {filter && isOpened && <div className='invoice-title' style={{ marginBottom: '10px' }}>
              <MaterialTextField
                label='Search'
                placeholder='Search'
                className='search-text-invoice'
                onChange={(event) => this.handleSearch(event, publicId)}
                onClick={(event) => event.stopPropagation()}
              />
            </div>}
            <FontAwesomeIcon icon={isOpened ? faAngleUp : faAngleDown} />
            <span>{isOpened ? 'Hide' : 'Show'}</span>
          </div>
        </div>
        <Collapse isOpened={isOpened}>
          <div className='body'>
            {body}
          </div>
        </Collapse>
      </div>
    )
  }

  changeCollapse (name, state) {
    switch (name) {
      case PENDING:
        this.setState({
          pending: update(this.state.pending, {
            isOpened: { $set: state }
          })
        })
        break
      case PAID:
        this.setState({
          paid: update(this.state.paid, {
            isOpened: { $set: state }
          })
        })
        break
      default:
        break
    }
  }

  render () {
    const { isHomePage, ...props } = this.props
    return (
      <div className='lists-wrapper'>
        {isHomePage
          ? <React.Fragment>
            {this.collapsible({
              title: 'Pending Invoices',
              isOpened: this.state.pending.isOpened,
              changeCollapse: this.changeCollapse,
              publicId: 'pending',
              body: <InvoiceListComponent {...props} type='pending' resource='invoices' />

            })}
          </React.Fragment>
          : <React.Fragment>
            <p className='page-title'>Invoices</p>
            <React.Fragment>
              {this.collapsible({
                title: 'Pending Invoices',
                isOpened: this.state.pending.isOpened,
                changeCollapse: this.changeCollapse,
                publicId: PENDING,
                body: <InvoiceListComponent {...props} type='pending' resource='invoices' search={this.state.searchPending} />,
                filter: true
              })}
            </React.Fragment>
            <React.Fragment>
              {this.collapsible({
                title: 'Paid Invoices',
                isOpened: this.state.paid.isOpened,
                changeCollapse: this.changeCollapse,
                publicId: PAID,
                body: <InvoiceListComponent {...props} type='paid' resource='invoices' search={this.state.searchPaid} />,
                filter: true
              })}
            </React.Fragment>
          </React.Fragment>
        }
      </div>
    )
  }
}
