/* eslint camelcase: 0 */
import React, { Component } from 'react'

export class TickTockField extends Component {
  constructor (props) {
    super(props)
    this.state = {
      action: props.action,
      timerEnabled: false,
      leftTime: props.leftTime
    }
    this.canSetLeftTime = true
    this.timerId = null
    this.tickTock = this.tickTock.bind(this)
    this.clearTimer = this.clearTimer.bind(this)
  }

  componentWillUnmount () {
    this.clearTimer()
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (!nextProps.leftTime) {
      this.setState({ timerEnabled: false })
    }
    if (nextProps.leftTime !== this.state.leftTime) {
      this.setState({ leftTime: nextProps.leftTime })
      if (this.state.leftTime === 0 && nextProps.leftTime > 0) {
        this.setState({ timerEnabled: true }, this.tickTock)
      }
    }
  }

  componentDidMount () {
    if (this.canSetLeftTime && this.state.leftTime) {
      this.canSetLeftTime = false
      this.setState({
        leftTime: this.state.leftTime,
        timerEnabled: true
      }, this.tickTock)
    }
    return true
  }

  clearTimer (cb) {
    this.setState({
      timerEnabled: false
    }, () => {
      clearTimeout(this.timerId)
      if (cb) {
        cb()
      }
    })
  }
  timeFormat (duration) {
    if (duration < 0) {
      return
    }
    let s = Math.floor(duration / 1000)
    let m = Math.floor(duration / 60 / 1000)
    let h = Math.floor(duration / 60 / 60 / 1000)
    let hours = h
    let minutes = m - 60 * h
    let seconds = (s - 60 * m)
    hours = (hours < 10) ? '0' + hours : hours
    minutes = (minutes < 10) ? '0' + minutes : minutes
    seconds = (seconds < 10) ? '0' + seconds : seconds
    let output = ''
    if (hours) {
      output += hours + ':'
    }
    if (minutes) {
      output += minutes + ':'
    }
    return output + seconds
  }
  async tickTock () {
    if (this.state.timerEnabled) {
      await new Promise((resolve) => {
        this.timerId = setTimeout(() => {
          this.timerId = null
          resolve()
        }, 1000)
      })
      if (!this.state.timerEnabled || this.timerId !== null) {
        return
      }
      if (!this.props.stopTick) {
        if (this.state.action && this.state.action === 'add') {
          this.setState({
            leftTime: this.state.leftTime + 1000
          }, this.tickTock)
        } else {
          this.setState({
            leftTime: this.state.leftTime - 1000
          }, this.tickTock)
        }
      } else {
        this.canSetLeftTime = true
        this.setState({
          timerEnabled: false
        })
      }
    }
  }

  render () {
    return (
      <div style={{ width: '60px' }}>{this.timeFormat(this.state.leftTime)}</div>
    )
  }
}
