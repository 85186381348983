import React from 'react'
import ReactDropzone from 'react-dropzone'
import { Form } from 'redux-form'
import './DocumentsInputField.css'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextField from '@material-ui/core/TextField'
import debounce from 'lodash/debounce'
import adobeReaderIcon from '../../assets/img/adobe-reader-icon.jpg'
import documentIcon from '../../assets/img/document.png'
import xlsxImage from '../../assets/img/xlsx.png'
import mswordImage from '../../assets/img/ms-doc-file.png'

const ImagePreview = ({ document }) => {
  if (document.rawFile) {
    if (document.rawFile.type === 'application/pdf') {
      return (<img className='document-image' alt='pdf' src={adobeReaderIcon} style={{ paddingLeft: '10px', width: '50px', display: 'block' }} />)
    } else if (document.rawFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return (<img className='document-image' alt='xml' src={xlsxImage} style={{ paddingLeft: '10px', width: '50px', display: 'block' }} />)
    } else if (document.rawFile.type === 'application/msword') {
      return (<img className='document-image' alt='msword' src={mswordImage} style={{ paddingLeft: '10px', width: '50px', display: 'block' }} />)
    } else if (document.rawFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return (<img className='document-image' alt='msword' src={mswordImage} style={{ paddingLeft: '10px', width: '50px', display: 'block' }} />)
    } else if (document.rawFile.type === 'application/vnd.ms-excel') {
      return (<img className='document-image' alt='msword' src={xlsxImage} style={{ paddingLeft: '10px', width: '50px', display: 'block' }} />)
    } else {
      return (<img className='document-image' alt='preview' src={documentIcon} style={{ paddingLeft: '10px', width: '275px', display: 'block' }} />)
    }
  } else {
    if (document.mimeType === 'application/pdf') {
      return (<img className='document-image' alt='pdf' src={adobeReaderIcon} style={{ paddingLeft: '10px', width: '50px', display: 'block' }} />)
    } else if (document.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return (<img className='document-image' alt='xml' src={xlsxImage} style={{ paddingLeft: '10px', width: '50px', display: 'block' }} />)
    } else if (document.mimeType === 'application/msword') {
      return (<img className='document-image' alt='msword' src={mswordImage} style={{ paddingLeft: '10px', width: '50px', display: 'block' }} />)
    } else if (document.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return (<img className='document-image' alt='msword' src={mswordImage} style={{ paddingLeft: '10px', width: '50px', display: 'block' }} />)
    } else if (document.mimeType === 'application/vnd.ms-excel') {
      return (<img className='document-image' alt='msword' src={xlsxImage} style={{ paddingLeft: '10px', width: '50px', display: 'block' }} />)
    } else {
      return (<img className='document-image' alt='preview' src={documentIcon} style={{ paddingLeft: '10px', width: '275px', display: 'block' }} />)
    }
  }
}

export default class DocumentsInputField extends Form {
  constructor (props, context) {
    super(props, context)
    this.reduxForm = context._reduxForm
    let documents = []
    let title = 'Documents'
    let showImagePlaseHolder = false
    this.field = 'documents'
    this.isOne = props.isOne || false
    if (this.props.sourceField) {
      this.field = this.props.sourceField
      this.props.record[this.field] ? documents = [this.props.record[this.field]] : documents = []
      switch (this.props.sourceField) {
        case 'licenseFile' :
          showImagePlaseHolder = true
          title = 'License uploads*'
          break
        case 'policyFile' :
          showImagePlaseHolder = true
          title = 'Professional Indemnity*'
          break
        case 'adminAttachments':
          title = 'Admin Attachments'
          documents = this.props.record.adminAttachments
          break
        default :
          title = 'Documents'
      }
    } else {
      documents = this.props.record.documents
    }

    this.state = { documents, title, showImagePlaseHolder, isDeleted: false }
    this.debounced = null

    this.deleteItem = this.deleteItem.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.componentDidUpdate = this.componentDidUpdate.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (
      (prevProps.record[this.field] && !this.props.record[this.field]) ||
      (!prevProps.record[this.field] && this.props.record[this.field]) ||
      (prevProps.record[this.field] && this.props.record[this.field] && prevProps.record[this.field].key !== this.props.record[this.field].key)
    ) {
      let newDocument = []
      this.props.record[this.field] ? newDocument = [this.props.record[this.field]] : newDocument = []
      this.setState({ documents: newDocument })
    }
  }

  deleteItem (newDocument) {
    let documents = Object.assign([], this.state.documents)
    documents.forEach((document, index) => {
      if (document.name === newDocument.name) {
        documents.splice(index, 1)
      }
    })
    if (this.isOne) {
      this.reduxForm.dispatch(this.reduxForm.change(this.field, null))
    } else {
      this.reduxForm.dispatch(this.reduxForm.change(this.field, documents))
    }
    this.setState({ documents })
  }

  onDrop (files) {
    if (files.length) {
      let documents = Object.assign([], this.state.documents)
      let isSameName = false
      documents.forEach((document) => {
        if (files[0].name === document.name) {
          isSameName = true
        }
      })
      if (!isSameName) {
        let file = files[0]
        if (!file.type) {
          let arrayName = file.name.split('.')
          let ext = arrayName[arrayName.length - 1]
          let mime = ''
          switch (ext) {
            case 'xls':
              mime = 'application/vnd.ms-excel'
              break
            case 'doc':
              mime = 'application/msword'
              break
            case 'docx':
              mime = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              break
            case 'xlsx':
              mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              break
            default:
              mime = ''
              break
          }
          var newFilef = new window.File([file], file.name, { type: mime, lastModified: new Date() })
          documents.push({ rawFile: newFilef, name: newFilef.name })
          if (this.isOne) {
            documents[0] = { rawFile: newFilef, name: newFilef.name }
            this.reduxForm.dispatch(this.reduxForm.change(this.field, documents[0]))
          } else {
            documents.push({ rawFile: newFilef, name: newFilef.name })
            this.reduxForm.dispatch(this.reduxForm.change(this.field, documents))
          }
          this.setState({ documents })
        } else {
          if (this.isOne) {
            documents[0] = { rawFile: file, name: file.name }
            this.reduxForm.dispatch(this.reduxForm.change(this.field, documents[0]))
          } else {
            documents.push({ rawFile: file, name: file.name })
            this.reduxForm.dispatch(this.reduxForm.change(this.field, documents))
          }
          this.setState({ documents })
        }
      }
    }
  }

  onChangeDescription (event, index) {
    let value = event.target.value
    let documents = Object.assign([], this.state.documents)
    documents[index].description = value
    this.setState({ documents })
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(() => {
      this.reduxForm.dispatch(this.reduxForm.change('documents', documents))
    }, 300)
  }

  render () {
    return (
      <div className='DocumentsInputField'>
        <h1>{this.state.title}</h1>
        <ReactDropzone
          disabled={this.props.record.state === 'closed'}
          accept={'.jpg,.jpeg,.pdf,.png,.doc,.docx,.xlsx,.xls'}
          multiple={false}
          onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => {
            return (
              <div {...getRootProps()}
                className='drop-zone'>
                <input {...getInputProps()} />
                <p>Drop your file here</p>
              </div>
            )
          }}
        </ReactDropzone>
        {this.state.documents && this.state.documents.length
          ? this.state.documents.map((document, index) => {
            return (
              <React.Fragment key={index}>
                <div className='item'>
                  <div className='leftSide'>
                    <FontAwesomeIcon
                      className='delete-btn'
                      onClick={() => { this.deleteItem(document) }}
                      icon={faMinusCircle} />

                    <p>{document.name}</p>
                    <ImagePreview document={document} />
                  </div>
                  {
                    this.state.showImagePlaseHolder
                      ? null
                      : <TextField
                        className='description'
                        placeholder='Description'
                        value={document.description}
                        onChange={(event) => { this.onChangeDescription(event, index) }}
                        type='text' />
                  }
                </div>
                <hr />
              </React.Fragment>
            )
          })
          : null}
      </div>
    )
  }
}
