import shortid from 'shortid'

export function generateShortId () {
  return shortid.generate()
}

export function generateShortIdsInReference (reference) {
  if (reference.forms) {
    reference.forms = reference.forms.map(form => {
      form._shortId = generateShortId()
      if (form.forms || form.options) {
        generateShortIdsInReference(form)
      }
      return form
    })
  }
  if (reference.options) {
    reference.options = reference.options.map(option => {
      if (reference.type === 'dropdown' || reference.type === 'multiDropdown') {
        option = {
          label: option,
          _shortId: generateShortId()
        }
      } else {
        option._shortId = generateShortId()
      }
      return option
    })
  }
}
