import React, { Component } from 'react'
import './styles.css'
import { InvoiceList } from '../invoices/model'
import { HomeUsersComponent } from '../../components/HomeUsersComponent/HomeUsersComponent'
import { HomeLotsComponent } from '../../components/HomeLotsComponent/HomeLotsComponent'

export class HomeComponent extends Component {
  render () {
    return (
      <div className='lists-wrapper'>
        <p className='page-title'>Welcome to Farmgate Administration</p>
        <HomeLotsComponent {...this.props} />
        <HomeUsersComponent {...this.props} />
        <InvoiceList isHomePage {...this.props} />
      </div>
    )
  }
}
