import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  EditButton,
  DeleteButton
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

import { toolbarOptions } from '../../utils/reachTextEditor'
import config from '../../config'
import EditToolbar from '../../components/EditToolbar'

const EmptyPagination = props => <span />

export const AssessList = (props) => (
  <div>
    <p className='page-title'>How To Assess</p>
    <List title='All Assess' bulkActions={false} {...props} pagination={<EmptyPagination />} perPage={config.perPage}>
      <Datagrid>
        <TextField source='_id' />
        <TextField source='categoryName' />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  </div>

)

export const AssessEdit = (props) => (
  <Edit title='Edit Assess' {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <DisabledInput label='Id' source='_id' />
      <TextInput source='categoryName' />
      <RichTextInput source='content' addLabel={false} toolbar={toolbarOptions} />
    </SimpleForm>
  </Edit>
)

export const AssessCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='categoryName' />
      <RichTextInput source='content' addLabel={false} toolbar={toolbarOptions} />
    </SimpleForm>
  </Create>
)
