import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'

export default function UserFetch(query, page) {
  const [users, setUsers] = useState([])
  const [error, setError] = useState(false)

  const getUsers = useCallback(async () => {
    try {
      //let url = '/users/specific-agents?sortDirection=-1&sortField=id&type=seller'
      let url = '/users/specific-agents?sortDirection=-1&sortField=id'
      const res = await axios.get(url)
      let newUsers = res.data.users.map(
        (user) => ({
          value: user._id,
          label: `U${user.numericId} ${user.firstName} ${user.lastName}`
        })
      )
      await setUsers((prev) => {
        return [...new Set([...prev, ...newUsers])]
      })
    } catch (err) {
      setError(err)
    }
  },[]) //warning fixes
  useEffect(() => {
    getUsers()
  }, [query, page,getUsers])  //warning fixes

  return { users, error }
}
