/* global FileReader */
import React, { Component } from 'react'
import {
  Create,
  SimpleForm,
  SelectInput,
  GET_LIST,
  NumberInput,
  LongTextInput,
  Toolbar,
  SaveButton,
  TextInput
} from 'react-admin'
import {
  approvedOptions,
  draftOptions,
  withdrawnOptions,
  acceptenceOnTerms,
  biddingOptions,
  soldAfterAuctionOptions
} from '../options'
import { dataProvider } from '../../../App'
import LotAssessmentFormEdit from '../../../components/LotAssessmentForm/AssessmentFormEdit'
import DocumentsInputField from '../../../components/DocumentsInputField/DocumentsInputField'
import MediaInputField from '../../../components/MediaInputField/MediaInputField'
import axios from 'axios'
import { Circle } from 'better-react-spinkit'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'
import './styles.css'
import Papa from 'papaparse'
import Toggle from 'react-toggle'
import { BillForReoffered } from '../../../components/lots/edit/BillForReoffered'
import { parseQueryString } from '../../../utils'
import { findAverageFromArray } from '../../../utils/lots/lotService'
import { UsefulLinks } from '../../../components/auction/UsefulLinks/UsefulLinks'

const acceptableValues = [null, 1, 2, 3, 4, 5]

function Transition (props) {
  return <Slide direction='up' {...props} />
}

export const LotTitle = () => {
  return <span>Create Lot</span>
}

class Select extends Component {
  render () {
    return (<SelectInput onChange={this.props.onChange} optionText='name' optionValue='auction' label='Auction' source='auction' choices={this.props.options} />)
  }
}

export class LotCreate extends Component {
  constructor (props) {
    super(props)

    const params = parseQueryString(this.props.location.search)
    this.state = {
      auctionOptions: [],
      numberOptions: [],
      isAuctionChanged: false,
      spinner: false,
      showValidationMessage: false,
      validationMessage: '',
      soldAfterAuction: null,
      withdrawnValue: null,
      auction: null,
      empty: false,
      dataFromJson: null,
      weights: null,
      avgWeight: null,
      avgScore: null,
      adjustWeight: null,
      averageData: null,
      assessmentOptiweigh: null,
      optiweighId: null,
      optiweighAllowed: false,
      kind: params.kind,
      userId: params.userId,
      saleConditions: params.saleConditions,
      agencyName: params.agencyName
    }
    this.loadAuctionOptions()
  }

  loadAuctionOptions = () => {
    this.setState({
      spinner: true
    })
    var filter = { 'kindData.type': this.state.kind }
    dataProvider(GET_LIST, 'auctions', {
      pagination: {
        page: this.state.page, perPage: this.state.perPage
      },
      sort: {
        field: this.state.field, order: this.state.order
      },
      state: ['future', 'open'],
      filter: filter,
      isAdminSite: true
    })
      .then(({ data }) => {
        let options = []
        if (!data.length) {
          this.setState({
            empty: true
          })
        }
        data.forEach(async (auction) => {
          await axios.get(`kinds/${auction.kind}`).then(result => {
            auction.kindFull = result.data.kind;
            if (this.props.location && this.state.kind && auction.kindData.type === this.state.kind) {
              options.push({
                id: auction._id,
                auction,
                name: `${auction.searchNumber} / ${auction.title} / ${auction.description ? auction.description : '---'}`,
                kind: auction.kind,
                kindData: auction.kindData,
                auctionTabs : auction.auctionTabs,
              })
              if (!this.state.auction) {
                this.setState({
                  auction,
                  spinner: false
                })
              }
            }
          })
          this.setState({
            auctionOptions: options,
            empty: !options.length
          })
        })
      })
  }

  handleAuctionChange (e, value, record) {
    if (value !== record.auction) {
      this.setState({ isAuctionChanged: true, auction: value })
    } else if (this.state.isAuctionChanged) {
      this.setState({ isAuctionChanged: false, auction: value })
    }
  }

  toggleSpinner = () => {
    this.setState({
      spinner: !this.state.spinner
    })
  }

  showValidationMessage = (validationMessage) => {
    this.setState({
      showValidationMessage: !this.state.showValidationMessage,
      validationMessage
    })
  }

  soldAfterAuctionChange = (e, value) => {
    this.setState({ soldAfterAuction: value })
  }

  withdrawnChange = (e, value) => {
    this.setState({ withdrawnValue: value })
  }

  handleClose = () => {
    this.setState({
      showValidationMessage: false
    })
  }

  // While importing old lots , Modifying the assesment forms based on public id
  updateDraftLotDetails = (draftLot) => {

    /*const deliveryGroupIndex = draftLot.details.values.findIndex(item => item.title === 'Delivery')
    if (deliveryGroupIndex !== -1) {
      const earliestDeliveryIndex = draftLot.details.values[deliveryGroupIndex].values.findIndex(x => x.publicId === 'earliestDelivery')
      const latestDeliveryIndex = draftLot.details.values[deliveryGroupIndex].values.findIndex(x => x.publicId === 'latestDelivery')
      if (earliestDeliveryIndex !== -1) {
        draftLot.details.values[deliveryGroupIndex].values[earliestDeliveryIndex].value = null
      }
      if (latestDeliveryIndex !== -1) {
        draftLot.details.values[deliveryGroupIndex].values[latestDeliveryIndex].value = null
      }
    }*/

    const conditionFrameIndex = draftLot.details.values.findIndex(item => item.title === 'Condition and Frame')
    const conditionFrameIndexGoat = draftLot.details.values.findIndex(item => item.title === 'Condition')
    if (conditionFrameIndex !== -1) {
      draftLot.details.values[conditionFrameIndex].title = 'Frame'
      const conditionIndex = draftLot.details.values[conditionFrameIndex].values.findIndex(x => x.title === 'Condition')
      if (conditionIndex !== -1) {
        draftLot.details.values[conditionFrameIndex].values.splice(conditionIndex, 1)
      }
    }
    if (conditionFrameIndexGoat !== -1) {
      draftLot.details.values[conditionFrameIndexGoat].title = 'Frame'
      const conditionIndex = draftLot.details.values[conditionFrameIndexGoat].values.findIndex(x => x.title === 'Condition')
      if (conditionIndex !== -1) {
        draftLot.details.values[conditionFrameIndexGoat].values.splice(conditionIndex, 1)
      }
    }

    const joiningDetailsIndex = draftLot.details.values.findIndex(item => item.title === 'Joining Details')
    if (joiningDetailsIndex !== -1) {
      const stationMatedIndex = draftLot.details.values[joiningDetailsIndex].values.findIndex(x => x.title === 'Station Mated?')
      if (stationMatedIndex !== -1) {
        draftLot.details.values[joiningDetailsIndex].values.splice(stationMatedIndex, 1)
      }
      const continuouslyMatedIndex = draftLot.details.values[joiningDetailsIndex].values.findIndex(x => x.title === 'Continuously Joined?')
      if (continuouslyMatedIndex !== -1) {
        draftLot.details.values[joiningDetailsIndex].values[continuouslyMatedIndex].title = 'Continuously Mated'
      }
      const setDateIndex = draftLot.details.values[joiningDetailsIndex].values.findIndex(x => x.title === 'Joined at Set Dates?')
      if (setDateIndex !== -1) {
        draftLot.details.values[joiningDetailsIndex].values[setDateIndex].title = 'Joined?'
      }

      draftLot.details.values[joiningDetailsIndex].values.sort((a, b) => {
        const titles = ["Joined?", "A.I", "Continuously Mated", "Access to Bulls/Stags?", "Lot been Preg Tested?"];
        return titles.indexOf(a.title) - titles.indexOf(b.title);
      });
    }

    const breedingQualityIndex = draftLot.details.values.findIndex(item => item.title === 'Breeding Quality')
    if (breedingQualityIndex !== -1) {
      draftLot.details.values.splice(breedingQualityIndex, 1)
    }

    // Sorting Joining Details fields 
    if(joiningDetailsIndex !== -1){
      if (draftLot.kindData.type === "cattle") {
        draftLot.details.values[joiningDetailsIndex].values.sort((a, b) => {
          const titles = ["Joined?", "A.I", "Continuously Mated", "Access to Bulls/Stags?", "Lot been Preg Tested?"];
          return titles.indexOf(a.title) - titles.indexOf(b.title);
        });
      }
      if (draftLot.kindData.type === "sheep") {
        draftLot.details.values[joiningDetailsIndex].values.sort((a, b) => {
          const titles = ["Joined?", "A.I?", "Continuously Mated", "Access to Rams/Bucks/Stags?", "Scanned?", "Additional Joining Details"];
          return titles.indexOf(a.title) - titles.indexOf(b.title);
        });
      }
  
      if (draftLot.kindData.type === "goat") {
        draftLot.details.values[joiningDetailsIndex].values.sort((a, b) => {
          const titles = ["Joined?", "A.I?", "Continuously Mated", "Scanned?", "Additional Joining Details"];
          return titles.indexOf(a.title) - titles.indexOf(b.title);
        });
      }
    }

  }

  adminImportForm = (event, record) => {
    const kind = this.state.kind || 'cattle'
    this.setState({
      spinner: true
    })
    let file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsText(file, 'utf-8')
      reader.onload = (e) => {
        try {
          let draftLot = JSON.parse(e.target.result)
          var importValues = draftLot.details.values
          if (draftLot.details && draftLot.details.values && this.state.auction && this.state.auction.kindFull && this.state.auction.kindFull.form && this.state.auction.kindFull.form.forms && this.state.auction.kindFull.form.forms.length !== draftLot.details.values.length) {
            draftLot.details.values = this.state.auction.kindFull.form.forms
            for (let i = 0; i < draftLot.details.values.length - 1; i++) {
              const objectMatchWithTile = importValues.find((object) => object.title === draftLot.details.values[i].title);
              if (objectMatchWithTile) {
                draftLot.details.values[i] = objectMatchWithTile
              }
            }
          }
          this.updateDraftLotDetails(draftLot)
          if (draftLot['bidding'] && draftLot['bidding'].includes('head')) {
            if (draftLot['startPrice']) {
              draftLot['startPrice'] = draftLot['startPrice'] * 100
            }
            if (draftLot['reserveCents']) {
              draftLot['reserveCents'] = draftLot['reserveCents'] * 100
            }
          }
          let stateObj
          if (kind === 'cattle') {
            stateObj = {
              optiweighAllowed: draftLot.optiweighAllowed,
              adjustWeight: draftLot.adjustWeight,
              dataFromJson: draftLot,
              optiweighId: draftLot.optiweigh ? draftLot.optiweigh._id : null,
              assessmentOptiweigh: draftLot.optiweigh ? draftLot.optiweigh.assessmentData : null,
              averageData: draftLot.optiweigh ? draftLot.optiweigh.averageData : null,
              oldWeightGain: draftLot.oldWeightGain ? draftLot.oldWeightGain : null,
              oldDetails: draftLot.oldDetails ? draftLot.oldDetails : null,
              spinner: false,
              takenFromJson: false
            }
          } else {
            stateObj = {
              adjustWeight: draftLot.adjustWeight,
              dataFromJson: draftLot,
              spinner: false
            }
          }
          this.setState(stateObj)
        } catch (err) {
          console.error(err)
          this.setState({
            spinner: false
          })
        }
      }
      reader.onerror = (err) => {
        console.error(err)
        this.setState({
          spinner: false
        })
      }
    }
  }

  parseFatScore (value) {
    if (acceptableValues.find(el => el === value)) {
      return value
    } else {
      return null
    }
  }

  importWeights (event, record) {
    const kind = this.state.kind || 'cattle'
    let file = event.target.files[0]
    if (file) {
      Papa.parse(file, {
        error: () => {
          console.log('Error parsing CSV')
        },
        complete: (results) => {
          results.data = results.data.filter((elem) => elem.length > 1)
          if (results.data.length > 2000) {
            console.log(`Maximum allowed upload of head weighted is 2000  per lot`)
            return
          }
          if (results.errors.length > 0) {
            console.log('Invalid CSV')
          }
          let newAssessment = []
          let totalWeight = 0
          let totalScore = 0
          let weightAmount = 0
          let scoreAmount = 0
          const teethOptions = [
            {
              value: '0',
              label: '0',
              id: 'teeth0'
            },
            {
              value: '1-2',
              label: '1-2',
              id: 'teeth12'
            },
            {
              value: '3-4',
              label: '3-4',
              id: 'teeth34'
            },
            {
              value: '5-6',
              label: '5-6',
              id: 'teeth56'
            },
            {
              value: '7',
              label: '7',
              id: 'teeth7'
            },
            {
              value: '8',
              label: '8',
              id: 'teeth8'
            }
          ]
          const sheepHornOptions = [
            {
              value: 'Polled',
              label: 'Polled',
              id: 'polled'
            },
            {
              value: 'Horned',
              label: 'Horned',
              id: 'horned'
            },
            {
              value: 'PP',
              label: 'PP',
              id: 'pp'
            },
            {
              value: 'PH',
              label: 'PH',
              id: 'ph'
            },
            {
              value: 'HH',
              label: 'HH',
              id: 'hh'
            },
            {
              value: 'Scur',
              label: 'Scur',
              id: 'scur'
            }
          ]
          const cattleHornOptions = [
            {
              value: 'Polled',
              label: 'Polled',
              id: 'polled'
            },
            {
              value: 'Tipped',
              label: 'Tipped',
              id: 'tipped'
            },
            {
              value: 'Horned',
              label: 'Horned',
              id: 'horned'
            },
            {
              value: 'Dehorned',
              label: 'Dehorned',
              id: 'dehorned'
            }
          ]
          const cattleBlemishesOptions = [
            'Pink Eye (PE)',
            'Pink Eye x2 (PE2)',
            'Blue Eye (BE)',
            'Blue Eye x2 (BE2)',
            'One Eye (OE)',
            'Pop Eye (POP)',
            'Wart (W)',
            'Ringworm (RW)',
            'Needle Lump (NL)',
            'Lice (L)',
            'Tick (T)',
            'Split Hoof (SH)',
            'Long Hooves or Paddle Foot (LH or PF)'
          ]
          if (kind === 'cattle') {
            for (let i = 1; i < results.data.length; i++) {
              let itemValues = []
              for (let [index, category] of results.data[0].entries()) {
                let title
                let publicId
                let type
                let required
                let options = null
                if (category.includes('Ass') || category.includes('Weight')) {
                  title = 'Ass/Weight (Live)'
                  publicId = 'weightLive'
                  type = 'weight'
                  required = true
                } else if (category.includes('Fat')) {
                  title = 'Fat Score'
                  publicId = 'fatScore'
                  type = 'rating'
                  required = true
                } else if (category.includes('Teeth')) {
                  title = 'Teeth'
                  publicId = 'animalTeeth'
                  type = 'select'
                  required = false
                  options = teethOptions
                } else if (category.includes('Horn')) {
                  title = 'Horned'
                  publicId = 'animalHorn'
                  type = 'select'
                  required = false
                  options = kind === 'cattle' ? cattleHornOptions : sheepHornOptions
                } else if (category.includes('Blemishes')) {
                  title = 'Blemishes'
                  publicId = 'animalBlemishes'
                  type = 'multiDropdown'
                  required = false
                  options = cattleBlemishesOptions
                } else if (category.includes('Individual')) {
                  title = 'Individual ID no.'
                  publicId = 'animalIndividualId'
                  type = 'text'
                  required = false
                }
                let newItem
                if (options) {
                  newItem = {
                    isMarked: true,
                    isPublic: true,
                    isRequired: required,
                    publicId: publicId,
                    title: title,
                    type: type,
                    value: (publicId.includes('fat') || publicId.includes('weight')) ? Number(results.data[i][index]) : results.data[i][index],
                    options: options
                  }
                } else {
                  if (publicId.includes('weight') && results.data[i][index]) {
                    totalWeight += results.data[i][index]
                    weightAmount++
                  }
                  if (publicId.includes('fat') && results.data[i][index]) {
                    totalScore += results.data[i][index]
                    scoreAmount++
                  }
                  newItem = {
                    isMarked: true,
                    isPublic: true,
                    isRequired: required,
                    publicId: publicId,
                    title: title,
                    type: type,
                    value: (publicId.includes('fat') || publicId.includes('weight')) ? Number(results.data[i][index]) : results.data[i][index]
                  }
                }
                itemValues.push(newItem)
              }
              const publicIds = ['weightLive', 'fatScore', 'animalTeeth', 'animalHorn', 'animalBlemishes', 'animalIndividualId']
              publicIds.forEach(id => {
                let title
                let publicId
                let type
                let required
                let options = null
                if (id.includes('weightLive')) {
                  title = 'Ass/Weight (Live)'
                  publicId = 'weightLive'
                  type = 'weight'
                  required = true
                } else if (id.includes('fatScore')) {
                  title = 'Fat Score'
                  publicId = 'fatScore'
                  type = 'rating'
                  required = true
                } else if (id.includes('animalTeeth')) {
                  title = 'Teeth'
                  publicId = 'animalTeeth'
                  type = 'select'
                  required = false
                  options = teethOptions
                } else if (id.includes('animalHorn')) {
                  title = 'Horned'
                  publicId = 'animalHorn'
                  type = 'select'
                  required = false
                  options = kind === 'cattle' ? cattleHornOptions : sheepHornOptions
                } else if (id.includes('Blemishes')) {
                  title = 'Blemishes'
                  publicId = 'animalBlemishes'
                  type = 'multiDropdown'
                  required = false
                  options = cattleBlemishesOptions
                } else if (id.includes('animalIndividualId')) {
                  title = 'Individual ID no.'
                  publicId = 'animalIndividualId'
                  type = 'text'
                  required = false
                }
                if ((itemValues.findIndex(item => item.publicId === id)) === -1) {
                  if (options) {
                    const newItem = {
                      isMarked: true,
                      isPublic: true,
                      isRequired: required,
                      publicId: publicId,
                      title: title,
                      type: type,
                      value: '',
                      options: options
                    }
                    itemValues.push(newItem)
                  } else {
                    const newItem = {
                      isMarked: true,
                      isPublic: true,
                      isRequired: required,
                      publicId: publicId,
                      title: title,
                      type: type,
                      value: ''
                    }
                    itemValues.push(newItem)
                  }
                }
              })
              newAssessment.push(itemValues)
            }
          } else if (kind === 'sheep-abblamb') {
            for (let i = 1; i < results.data.length; i++) {
              let itemValues = []
              for (let [index, category] of results.data[0].entries()) {
                let title
                let publicId
                let type
                let required
                let options = null
                if (category.includes('Ass') || category.includes('Weight')) {
                  title = 'Ass/Weight (Live)'
                  publicId = 'weightLive'
                  type = 'weight'
                  required = true
                } else if (category.includes('Individual')) {
                  title = 'Individual ID no.'
                  publicId = 'animalIndividualId'
                  type = 'text'
                  required = false
                } else {
                  title = null
                }
                if (title) {
                  let newItem
                  if (options) {
                    newItem = {
                      isMarked: true,
                      isPublic: true,
                      isRequired: required,
                      publicId: publicId,
                      title: title,
                      type: type,
                      value: (publicId.includes('fat') || publicId.includes('weight')) ? Number(results.data[i][index]) : results.data[i][index],
                      options: options
                    }
                  } else {
                    if (publicId.includes('weight') && results.data[i][index]) {
                      totalWeight += results.data[i][index]
                      weightAmount++
                      results.data[i][index] = Number(results.data[i][index])
                    }
                    if (publicId.includes('fat') && results.data[i][index]) {
                      totalScore += results.data[i][index]
                      scoreAmount++
                      results.data[i][index] = Number(results.data[i][index])
                    }
                    newItem = {
                      isMarked: true,
                      isPublic: true,
                      isRequired: required,
                      publicId: publicId,
                      title: title,
                      type: type,
                      value: (publicId.includes('fat') || publicId.includes('weight')) ? Number(results.data[i][index]) : results.data[i][index]
                    }
                  }
                  itemValues.push(newItem)
                }
              }
              const publicIds = ['weightLive','animalIndividualId']
              publicIds.forEach(id => {
                let title
                let publicId
                let type
                let required
                let options = null
                if (id.includes('weightLive')) {
                  title = 'Ass/Weight (Live)'
                  publicId = 'weightLive'
                  type = 'weight'
                  required = true
                } else if (id.includes('animalIndividualId')) {
                  title = 'Individual ID no.'
                  publicId = 'animalIndividualId'
                  type = 'text'
                  required = false
                }
                if ((itemValues.findIndex(item => item.publicId === id)) === -1) {
                  if (options) {
                    const newItem = {
                      isMarked: true,
                      isPublic: true,
                      isRequired: required,
                      publicId: publicId,
                      title: title,
                      type: type,
                      value: '',
                      options: options
                    }
                    itemValues.push(newItem)
                  } else {
                    const newItem = {
                      isMarked: true,
                      isPublic: true,
                      isRequired: required,
                      publicId: publicId,
                      title: title,
                      type: type,
                      value: ''
                    }
                    itemValues.push(newItem)
                  }
                }
              })
              newAssessment.push(itemValues)
            }

          }
          else {
            for (let i = 1; i < results.data.length; i++) {
              let itemValues = []
              for (let [index, category] of results.data[0].entries()) {
                let title
                let publicId
                let type
                let required
                let options = null
                if (category.includes('Ass') || category.includes('Weight')) {
                  title = 'Ass/Weight (Live)'
                  publicId = 'weightLive'
                  type = 'weight'
                  required = true
                } else if (category.includes('Fat')) {
                  title = 'Fat Score'
                  publicId = 'fatScore'
                  type = 'rating'
                  required = true
                } else if (category.includes('Teeth')) {
                  title = 'Teeth'
                  publicId = 'animalTeeth'
                  type = 'select'
                  required = false
                  options = teethOptions
                } else if (category.includes('Horn')) {
                  title = 'Horned'
                  publicId = 'animalHorn'
                  type = 'select'
                  required = false
                  options = kind === 'cattle' ? cattleHornOptions : sheepHornOptions
                } else if (category.includes('Individual')) {
                  title = 'Individual ID no.'
                  publicId = 'animalIndividualId'
                  type = 'text'
                  required = false
                } else {
                  title = null
                }
                if (title) {
                  let newItem
                  if (options) {
                    newItem = {
                      isMarked: true,
                      isPublic: true,
                      isRequired: required,
                      publicId: publicId,
                      title: title,
                      type: type,
                      value: (publicId.includes('fat') || publicId.includes('weight')) ? Number(results.data[i][index]) : results.data[i][index],
                      options: options
                    }
                  } else {
                    if (publicId.includes('weight') && results.data[i][index]) {
                      totalWeight += results.data[i][index]
                      weightAmount++
                      results.data[i][index] = Number(results.data[i][index])
                    }
                    if (publicId.includes('fat') && results.data[i][index]) {
                      totalScore += results.data[i][index]
                      scoreAmount++
                      results.data[i][index] = Number(results.data[i][index])
                    }
                    newItem = {
                      isMarked: true,
                      isPublic: true,
                      isRequired: required,
                      publicId: publicId,
                      title: title,
                      type: type,
                      value: (publicId.includes('fat') || publicId.includes('weight')) ? Number(results.data[i][index]) : results.data[i][index]
                    }
                  }
                  itemValues.push(newItem)
                }
              }
              const publicIds = ['weightLive', 'fatScore', 'animalTeeth', 'animalHorn', 'animalIndividualId']
              publicIds.forEach(id => {
                let title
                let publicId
                let type
                let required
                let options = null
                if (id.includes('weightLive')) {
                  title = 'Ass/Weight (Live)'
                  publicId = 'weightLive'
                  type = 'weight'
                  required = true
                } else if (id.includes('fatScore')) {
                  title = 'Fat Score'
                  publicId = 'fatScore'
                  type = 'rating'
                  required = true
                } else if (id.includes('animalTeeth')) {
                  title = 'Teeth'
                  publicId = 'animalTeeth'
                  type = 'select'
                  required = false
                  options = teethOptions
                } else if (id.includes('animalHorn')) {
                  title = 'Horned'
                  publicId = 'animalHorn'
                  type = 'select'
                  required = false
                  options = kind === 'cattle' ? cattleHornOptions : sheepHornOptions
                } else if (id.includes('animalIndividualId')) {
                  title = 'Individual ID no.'
                  publicId = 'animalIndividualId'
                  type = 'text'
                  required = false
                }
                if ((itemValues.findIndex(item => item.publicId === id)) === -1) {
                  if (options) {
                    const newItem = {
                      isMarked: true,
                      isPublic: true,
                      isRequired: required,
                      publicId: publicId,
                      title: title,
                      type: type,
                      value: '',
                      options: options
                    }
                    itemValues.push(newItem)
                  } else {
                    const newItem = {
                      isMarked: true,
                      isPublic: true,
                      isRequired: required,
                      publicId: publicId,
                      title: title,
                      type: type,
                      value: ''
                    }
                    itemValues.push(newItem)
                  }
                }
              })
              newAssessment.push(itemValues)
            }
          }
          const avgWeight = totalWeight / weightAmount || 0
          const avgScore = totalScore / scoreAmount || 0
          this.setState({
            weights: newAssessment,
            avgWeight: avgWeight,
            avgScore: avgScore
          })
        }
      })
    }
  }

  parseOptiweighAssessment (event) {
    let file = event.target.files[0]
    if (file) {
      Papa.parse(file, {
        error: () => {
          console.log('Error parsing CSV')
          window.alert('Error parsing CSV')
        },
        complete: async (results) => {
          results.data = results.data.filter((elem) => elem.length > 1)
          if (results.errors.length > 0) {
            console.log(results.errors)
          }
          let counts = []
          let avarageArray = []
          if (results.data[0].some(item => item.includes('Count'))) {
            let min = 0
            let max = 0
            let prev = 0
            for (let i = 1; i < results.data.length; i++) {
              let numbers = results.data[i][0].split(' to ')
              let startWeight = Number(numbers[0])
              let endWeight = Number(numbers[1])
              let count = Number(results.data[i][1])
              if (!min && startWeight && count) {
                min = startWeight
              } else {
                if (startWeight < min) {
                  min = startWeight
                }
              }
              if (endWeight > max && count) {
                max = endWeight
              }
              if (startWeight && endWeight && startWeight >= prev && endWeight > startWeight) {
                counts.push({
                  startWeight, endWeight, count
                })
                avarageArray.push({ average: (startWeight + endWeight) / 2, numberOfHeads: count })
                prev = endWeight
              }
            }

            // Remove sections without head from start
            const firstNotNullIndex = counts.findIndex((item) => item.count > 0)
            if (firstNotNullIndex >= 0) {
              counts = counts.splice(firstNotNullIndex, counts.length)
            }

            //  Remove sections without head from end
            const lastNotNullIndex = counts.lastIndexOf((item) => item.count > 0)
            if (lastNotNullIndex >= 0) {
              counts = counts.splice(0, lastNotNullIndex)
            }

            const averageWeight = findAverageFromArray(avarageArray)
            let resultObj = {
              minWeight: min,
              maxWeight: max,
              averageWeight: averageWeight,
              counts
            }
            if (resultObj.counts.length === 0) {
              window.alert('Not valid amount in CSV')
            } else {
              const url = this.state.optiweighId ? `/optiweigh/${this.state.optiweighId}` : '/optiweigh'
              const resp = this.state.optiweighId ? await axios.put(url, { assessmentData: resultObj }) : await axios.post(url, { assessmentData: resultObj })
              if (resp && resp.data) {
                try {
                  await this.setState({
                    assessmentOptiweigh: resultObj,
                    optiweighId: resp.data.optiweigh._id
                  })
                } catch (e) {
                  console.log(e)
                  window.alert(e)
                }
              }
            }
          } else {
            window.alert('Wrong CSV')
          }
        }
      })
    }
  }

  parseOptiweighAverage (event) {
    let file = event.target.files[0]
    if (file) {
      Papa.parse(file, {
        error: () => {
          console.log('Error parsing CSV')
          window.alert('Error parsing CSV')
        },
        complete: async (results) => {
          results.data = results.data.filter((elem) => elem.length > 1)
          if (results.errors.length > 0) {
            console.log('Invalid CSV')
            window.alert('Invalid CSV')
          }
          let data = []
          if (results.data[0].some(item => item.includes('Date'))) {
            for (let i = 1; i < results.data.length; i++) {
              let date = results.data[i][0]
              let weight = Number(results.data[i][1])
              let averageDailyGain = Number(results.data[i][2])
              if (date && weight && averageDailyGain) {
                data.push({
                  date, weight, averageDailyGain
                })
              }
            }
            const url = this.state.optiweighId ? `/optiweigh/${this.state.optiweighId}` : '/optiweigh'
            const resp = this.state.optiweighId ? await axios.put(url, { averageData: data }) : await axios.post(url, { averageData: data })
            if (resp && resp.data) {
              try {
                await this.setState({
                  averageData: data,
                  optiweighId: resp.data.optiweigh._id
                })
              } catch (e) {
                console.log(e)
                window.alert(e)
              }
            }
          } else {
            window.alert('Wrong CSV')
          }
        }
      })
    }
  }

  render () {
    let { auction, dataFromJson, weights, avgWeight, avgScore, adjustWeight, optiweighId, averageData, assessmentOptiweigh, optiweighAllowed } = this.state
    const EmptyToolbar = props => (<Toolbar {...props} className='hidden-toolbar'>
      <SaveButton label='' submitOnEnter={false} />
    </Toolbar>)
    const BiddingOptions = ({ record } = {}) => {
      if (auction.kindData.type === 'sheep' || auction.kindData.type === 'sheep-abblamb') {
        if (this.state.dataFromJson) {
          return (<SelectInput defaultValue={this.state.dataFromJson.bidding} disabled={auction.state === 'closed'} source='bidding' choices={[{ id: 'head', name: 'Head' }]} />)
        }
        return (<SelectInput disabled={auction.state === 'closed'} source='bidding' choices={[{ id: 'head', name: 'Head' }]} />)
      } else {
        if (this.state.dataFromJson) {
          return (<SelectInput defaultValue={this.state.dataFromJson.bidding} disabled={auction.state === 'closed'} source='bidding' choices={biddingOptions} />)
        }
        return (<SelectInput disabled={auction.state === 'closed'} source='bidding' choices={biddingOptions} />)
      }
    }
    const DocumentField = () => {
      return <DocumentsInputField record={auction} />
    }
    const UploadButtons = ({ record = {} }) => {
      const hiddenCSVInput = React.useRef(null)
      const handleCSVClick = event => {
        hiddenCSVInput.current.click()
      }
      const hiddenFileInput = React.useRef(null)
      const handleClick = () => {
        hiddenFileInput.current.click()
      }
      return (
        <div className='button-group-container'>
          <Button onClick={handleClick} color='primary' variant='outlined'>Import Lot</Button>
          <Button onClick={handleCSVClick} color='primary' variant='outlined'>Import Weights</Button>
          <input
            style={{ display: 'none' }}
            type='file'
            accept='.json'
            ref={hiddenFileInput}
            onChange={(event) => this.adminImportForm(event, record)}
          />
          <input
            style={{ display: 'none' }}
            type='file'
            accept='.csv'
            ref={hiddenCSVInput}
            onChange={async (event) => this.importWeights(event, record)}
            title={'Import Weights'}
          />
        </div>)
    }
    const OptiweighButtons = ({ record = {} }) => {
      const hiddenAverageInput = React.useRef(null)
      const handleAverageClick = event => {
        hiddenAverageInput.current.click()
      }
      const hiddenAssessmentInput = React.useRef(null)
      const handleAssessmentClick = event => {
        hiddenAssessmentInput.current.click()
      }
      if (this.state.kind === 'cattle') {
        return (<div className='button-group-container bottom'>
          <Button disabled={!this.state.optiweighAllowed} onClick={handleAverageClick} color='primary'
            variant={averageData ? 'contained' : 'outlined'}>Average Data</Button>
          <Button disabled={!this.state.optiweighAllowed} onClick={handleAssessmentClick} color='primary'
            variant={assessmentOptiweigh ? 'contained' : 'outlined'}>Assessment Data</Button>
          <input
            disabled={!this.state.optiweighAllowed}
            style={{ display: 'none' }}
            type='file'
            accept='.csv'
            ref={hiddenAverageInput}
            onChange={async (event) => this.parseOptiweighAverage(event)}
            title={'Optiweigh'}
          />
          <input
            disabled={!this.state.optiweighAllowed}
            style={{ display: 'none' }}
            type='file'
            accept='.csv'
            ref={hiddenAssessmentInput}
            onChange={async (event) => this.parseOptiweighAssessment(event)}
            title={'Optiweigh'}
          />
        </div>)
      } else return null
    }
    const AdditionalTitleField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <TextInput source='additionalTitle' label='Additional Title' defaultValue={this.state.dataFromJson.additionalTitle} />
      }
      return <TextInput source='additionalTitle' label='Additional Title' />
    }
    const AuctionSelect = ({ record } = {}) => {
      if (this.state.auctionOptions.length) {
        return <Select onChange={(e, value) => { this.handleAuctionChange(e, value, record) }} record={record} options={this.state.auctionOptions} />
      } else {
        return null
      }
    }
    const AllocatedSection = ({ record } = {}) => {
     let selectedAuction = this.state.auctionOptions.length > 0 && this.state.auction &&  this.state.auctionOptions.find(ele => ele.id === this.state.auction.id);
      let auctionTabsOptions = [];
      if (selectedAuction && selectedAuction.auctionTabs) {
          auctionTabsOptions = selectedAuction.auctionTabs.map((item) => ({
            id: `${item.tabName} - ${item.description}`,
            name: `${item.tabName} - ${item.description}`
          }));
        }
      
      return <SelectInput
        //disabled={record.state === 'closed'}
        source='allocatedSection' label='Allocated Section' defaultValue = 'other lots (not allocated)'
        choices={auctionTabsOptions}
      />
    }
    const ApprovedField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <SelectInput defaultValue={this.state.dataFromJson.approved} disabled={record.state === 'closed'} source='approved' label='Approved'
          choices={approvedOptions} />
      }
      return <SelectInput disabled={record.state === 'closed'} source='approved' label='Approved' choices={approvedOptions} />
    }
    const DraftField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <SelectInput defaultValue={this.state.dataFromJson.draft} disabled={record.state === 'closed'} source='draft' label='In draft' choices={draftOptions} />
      }
      return <SelectInput disabled={record.state === 'closed'} source='draft' label='In draft' choices={draftOptions} />
    }
    const StartPriceField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <NumberInput defaultValue={this.state.dataFromJson.startPrice} source='startPrice' label='Start Price(cents)' step={1} />
      }
      return <NumberInput source='startPrice' label='Start Price(cents)' step={1} />
    }
    const BidIncrementCents = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <NumberInput defaultValue={this.state.dataFromJson.bidIncrementCents} source='bidIncrementCents' label='Bid increment(cents)' step={1} />
      }
      return <NumberInput source='bidIncrementCents' label='Bid increment(cents)' step={1} />
    }
    const ReserveCents = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <NumberInput defaultValue={this.state.dataFromJson.reserveCents} source='reserveCents' label='Reserve price(cents)' step={1} />
      }
      return <NumberInput source='reserveCents' label='Reserve price(cents)' step={1} />
    }
    // const ConditionsOfSaleField = ({ record = {} }) => {
    //   if ((this.state.dataFromJson && this.state.dataFromJson.saleConditions) || this.state.saleConditions) {
    //     return <LongTextInput defaultValue={this.state.dataFromJson && this.state.dataFromJson.saleConditions ? this.state.dataFromJson.saleConditions : this.state.saleConditions} disabled={record.state === 'closed'} source='saleConditions' resettable />
    //   }
    //   return <LongTextInput disabled={record.state === 'closed'} source='saleConditions' resettable />
    // }
    const DescriptionField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <LongTextInput defaultValue={this.state.dataFromJson.description} disabled={record.state === 'closed'} source='description' title='Description'
          resettable />
      }
      return <LongTextInput disabled={record.state === 'closed'} source='description' title='Description' resettable />
    }
    const AcceptenceOnTermsField = ({ record = {} }) => {
      return <SelectInput disabled={record.state === 'closed'} source='acceptenceOnTerms' label='Acceptance on Terms' choices={acceptenceOnTerms} />
    }
    const WithdrawnFiled = ({ record = {} }) => {
      return <SelectInput disabled={record.state === 'closed'} source='withdrawn' label='Is Withdrawn' onChange={this.withdrawnChange} choices={withdrawnOptions} />
    }
    // const MaxVendorBidsField = ({ record = {} }) => {
    //   return <NumberInput disabled={record.state === 'closed'} source='maxVendorBids' label='Max Vendor Bids' step={1} />
    // }
    const SoldAfterAuction = ({ record = {} }) => {
      if (this.state.soldAfterAuction === null) {
        this.setState({ soldAfterAuction: record.soldAfterAuction })
      }
      return (<div>
        {record.state === 'closed' &&
        <SelectInput onChange={this.soldAfterAuctionChange} source='soldAfterAuction' label='Sold after auction' choices={soldAfterAuctionOptions} />}
      </div>)
    }
    // const SoldBeforeAuction = ({ record = {} }) => {
    //   return (
    //   <div>
    //     <SelectInput  source='soldBeforeAuction' label='Mark as Sold before auction' choices={soldAfterAuctionOptions} />
    //   </div>)
    // }
    const CurrentBidCentsField = ({ record = {} }) => {
      return (<div>
        {this.state.soldAfterAuction && <NumberInput source='closedPrice' label='New Price' step={1} />}
      </div>)
    }
    const AssessmentForm = ({ record = {} }) => {
      return (<div>
        {this.state.soldAfterAuction && <NumberInput source='closedPrice' label='New Price' step={1} />}
      </div>)
    }
    const OptiweighAllowed = () => {
      if (this.state.kind === 'cattle') {
        return (
          <div>
            <Toggle
              defaultChecked={optiweighAllowed}
              icons={false}
              onChange={() => {
                this.setState({
                  optiweighAllowed: !optiweighAllowed
                })
              }
              } />
            <span>Allow optiweigh</span>
          </div>)
      } else return null
    }

    const HeadOnNVDField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <NumberInput defaultValue={this.state.dataFromJson.headOnNVD} source='headOnNVD' label='Number of head on NVD' step={1} />
      }
      return <NumberInput source='headOnNVD' label='Number of head on NVD' step={1} />
    }

    const HeadAccordingToBuyerField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <NumberInput defaultValue={this.state.dataFromJson.headAccordingToBuyer} source='headAccordingToBuyer' label='Number of head according to Buyer' step={1} />
      }
      return <NumberInput source='headAccordingToBuyer' label='Number of head according to Buyer' step={1} />
    }

    const AdminNotesField = ({ record = {} }) => {
      if (this.state.dataFromJson) {
        return <TextInput defaultValue={this.state.dataFromJson.adminNotes} source='adminNotes' label='Admin Notes' />
      }
      return <TextInput source='adminNotes' label='Admin Notes' />
    }

    const AdminAttachmentsField = () => {
      return <DocumentsInputField record={auction} sourceField='adminAttachments' />
    }
 
    return (
      <>
        {this.state.spinner && <div className='spinner-wrapper'>
          <div className='spinner'>
            <Circle size={100} />
          </div>
        </div>}
        {this.state.empty && <p className='empty-message'>There are currently no Auctions of this type</p>}
        {!this.state.empty &&
        <Create {...this.props} title={<LotTitle />}>
          <SimpleForm toolbar={<EmptyToolbar />}>
            <>
              <UploadButtons />
              <OptiweighButtons />
            </>
            <AdditionalTitleField/>
            {this.state.auctionOptions.length > 0 && <AuctionSelect />}

            {this.state.auction && <React.Fragment>
              <OptiweighAllowed />
              <BillForReoffered isChoosenAuction={this.state.isAuctionChanged} dataFromJson={dataFromJson} />
              <SoldAfterAuction />
              {/* <SoldBeforeAuction /> */}
              <CurrentBidCentsField />
              <ApprovedField />
              {this.state.auction && this.state.auction.requiresTabs && <AllocatedSection /> }
              <DraftField />
              <HeadOnNVDField />
              <HeadAccordingToBuyerField />
              <AdminNotesField />
              <AdminAttachmentsField />
              <StartPriceField />
              <BidIncrementCents />
              <ReserveCents />
              <BiddingOptions />
              <NumberInput source='lotDiscount' label='Lot Discount(%)' step={1} defaultValue={0} />
              {/* <ConditionsOfSaleField /> */}
              <DescriptionField />
              <AcceptenceOnTermsField />
              <WithdrawnFiled />
              {/* <MaxVendorBidsField /> */}
              <MediaInputField type={'create'} record={auction} toggleSpinner={this.toggleSpinner} onSubmit={() => { /* mock inheritance */ }} />
              <DocumentField />
              <UsefulLinks />
              <AssessmentForm />
              {(!dataFromJson && !weights) &&
              <LotAssessmentFormEdit
                showValidationError={(message) => { this.showValidationMessage(message) }}
                toggleSpinner={this.toggleSpinner}
                onSubmit={() => { /* mock inheritance */ }}
                type='create'
                kindFull={auction.kindFull}
                optiweigh={optiweighId}
                optiweighAllowed={optiweighAllowed}
                assessmentOptiweigh={assessmentOptiweigh}
                averageOptiweigh={averageData}
                userId={this.props.location && this.state.userId}
                agencyName={this.props.location && this.state.agencyName}
              />
              }
              {(!dataFromJson && weights) &&
              <LotAssessmentFormEdit
                showValidationError={(message) => { this.showValidationMessage(message) }}
                toggleSpinner={this.toggleSpinner}
                onSubmit={() => { /* mock inheritance */ }}
                type='createImport'
                weights={weights}
                avgWeight={avgWeight}
                avgScore={avgScore}
                kindFull={auction.kindFull}
                assessmentOptiweigh={assessmentOptiweigh}
                optiweighAllowed={optiweighAllowed}
                averageOptiweigh={averageData}
                optiweigh={optiweighId}
                userId={this.props.location && this.state.userId}
                agencyName={this.props.location && this.state.agencyName}
              />
              }
              {(dataFromJson && weights) &&
              <LotAssessmentFormEdit
                showValidationError={(message) => { this.showValidationMessage(message) }}
                toggleSpinner={this.toggleSpinner}
                onSubmit={() => { /* mock inheritance */ }}
                kindFull={auction.kindFull}
                type='createImport'
                weights={weights}
                avgWeight={avgWeight}
                avgScore={avgScore}
                adjustWeight={adjustWeight}
                dataFromJson={dataFromJson}
                optiweigh={optiweighId}
                optiweighAllowed={optiweighAllowed}
                averageOptiweigh={averageData}
                assessmentOptiweigh={assessmentOptiweigh}
                userId={this.props.location && this.state.userId}
                agencyName={this.props.location && this.state.agencyName}
              />
              }
              {(dataFromJson && !weights) &&
              <LotAssessmentFormEdit
                showValidationError={(message) => { this.showValidationMessage(message) }}
                toggleSpinner={this.toggleSpinner}
                onSubmit={() => { /* mock inheritance */ }}
                kindFull={auction.kindFull}
                type='createImport'
                adjustWeight={adjustWeight}
                dataFromJson={dataFromJson}
                optiweigh={optiweighId}
                optiweighAllowed={optiweighAllowed}
                averageOptiweigh={averageData}
                assessmentOptiweigh={assessmentOptiweigh}
                userId={this.props.location && this.state.userId}
                agencyName={this.props.location && this.state.agencyName}
              />
              }
              {
                this.state.showValidationMessage && <Dialog
                  open={this.state.showValidationMessage}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={this.handleClose}
                  aria-labelledby='alert-dialog-slide-title'
                  aria-describedby='alert-dialog-slide-description'
                  className='spinner-wrapper'
                >
                  <DialogTitle id='alert-dialog-slide-title'>
                    {'Lot Form Validation'}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description'>
                      {this.state.validationMessage}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleClose} color='primary'>
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              }
            </React.Fragment>}
          </SimpleForm>
        </Create>
        }
      </>
    )
  }
}
