export const WINDOW_MODOAL = 'WINDOW_MODOAL'
export const RATING_VALIDATION_MAX = 5
export const RATING_VALIDATION_MIN = 1
export const lotHiddenFields = ['district']
export const bidTypes = { HEAD: 'head', KG: 'kg' }
export const gstTax = 0 // percents
export const reofferMaxAttempts = 1
export const requiredForOptiWeighFields = [
  'sex', 'stockCategory', 'ageUnit', 'Condition', 'vendorBreeded', 'hgpTreated', 'majorBreedPercentage', 'majorBreed',
  'vendorName', 'vendorPic', 'vendorPhone', 'vendorEmail', 'vendorAddress', 'town', 'postcode', 'state', 'earliestDelivery'
]
export const professionOther = "Other (Specify)"
