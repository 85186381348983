import React, {Component} from 'react'
import { Sheep } from './sheep'
import { Cattle } from './cattle'
import { Goats } from './goat'
import Axios from 'axios'

export class ReOfferList extends Component {
    constructor(props){
      super(props)
      this.state = {
        data: {}
      }
    }

    componentDidMount() {
      Axios.get('/getstock')
      .then((res) => this.setState(
        {data: res.data}));
    }
    
    render () {
      return (
        <div className='lists-wrapper'>
          <p className='page-title'>Re Offers</p>
          <Sheep {...this.props} />
          <Cattle {...this.props} />
          <Goats {...this.props}/>
        </div>
      )
    }
  }