import config from '../../../config'
import socketService from '../../../utils/socketConnection'
import io from 'socket.io-client'

export default class SocketService {
  constructor () {
    this.socket = io(`${config.rootUrl}connectivity`, {
      jsonp: false,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionDelayMax: 2500,
      transports: ['websocket']
    })
  }

  connect () {
    const connect = function () {
      socketService.sockets['connectivity'].emit('login', {}, (err) => {
        if (err) {
          console.log('socket login error', err)
        } else {
          console.log(`connectivity: connected`)
        }
      })
    }

    this.socket.on('connect', connect)
    this.socket.on('reconnect', connect)

    return this.socket
  }

  disconnect () {
    this.socket.close()
  }
}
