import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import debounce from 'lodash/debounce'

export default class WeightField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: this.convertValue(this.props.value)
    }

    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.changeData = this.changeData.bind(this)
    this.debounced = null
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.value !== this.props.value && this.state.value !== this.props.value) {
      this.setState({ value: this.convertValue(this.props.value) })
    }
  }

  convertValue (value) {
    if (this.props.type === 'number' && value !== null) {
      const maxDecimalDigits = this.props.maxDecimalDigits !== undefined ? this.props.maxDecimalDigits : 0
      value = parseFloat(value).toFixed(maxDecimalDigits)
    }
    return value
  }

  onBlur ({ target: { value } }) {
    this.changeData(+value, 300)
  }

  onChange ({ target: { value } }) {
    this.changeData(value, 1000)
  }

  changeData (value, delay) {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(() => {
      this.props.onChange({
        value: +value,
        indexes: this.props.newIndexes,
        field: this.props.field
      })
    }, delay)
    this.setState({ value }, this.debounced)
  }

  render () {
    const { label } = this.props
    const { value } = this.state
    return (
      <>
        <TextField
          disabled={this.props.disabled}
          onChange={this.onChange}
          value={value === null ? '' : value}
          label={label}
          type='number'
          onBlur={this.onBlur}
        />
      </>
    )
  }
}
