import React, { Component } from 'react'
import { dataProvider } from '../../../App'
import { OwnPagination } from '../../OwnPagination'
import { GET_LIST, UPDATE, DELETE, Datagrid, TextField, DateField } from 'react-admin'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

function Transition (props) {
  return <Slide direction='up' {...props} />
}

export default class Bids extends Component {
  constructor (props) {
    super(props)
    this.state = {
      page: 1,
      perPage: 10,
      data: {},
      ids: [],
      total: 0,
      showEditModal: false,
      showDeleteModal: false,
      editValue: 0,
      workId: null
    }
    this.loadBids = this.loadBids.bind(this)
    this.setPerPage = this.setPerPage.bind(this)
    this.setPage = this.setPage.bind(this)
    this.setSort = this.setSort.bind(this)
    this.handleOpenEdit = this.handleOpenEdit.bind(this)
    this.handleOpenDelete = this.handleOpenDelete.bind(this)
    this.handleCloseEdit = this.handleCloseEdit.bind(this)
    this.handleCloseDelete = this.handleCloseDelete.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.loadBids()
  }

  loadBids () {
    dataProvider(GET_LIST, 'bids', {
      pagination: {
        page: this.state.page || 'createdAt',
        perPage: this.state.perPage
      },
      sort: {
        field: this.state.field || 'createdAt',
        order: this.state.order || 'DESC'
      },
      lotId: this.props.record._id
    })
      .then(({ data, total }) => {
        let newids = []
        let newdata = {}
        for (let i = 0; i < data.length; i++) {
          let id = data[i].id
          newids.push(id)
          newdata[id] = {
            ...data[i]
          }
        }
        this.setState({
          ids: newids,
          data: newdata,
          total
        })
      })
  }

  setPerPage (perPage) {
    this.setState({
      perPage
    }, this.loadBids)
  }

  setPage (page) {
    this.setState({
      page
    }, this.loadBids)
  }

  setSort (field) {
    let order = 'ASC'
    if (this.state.field === field) {
      order = this.state.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      field,
      order
    }, this.loadBids)
  }

  handleOpenEdit (record) {
    this.setState({
      showEditModal: true,
      workId: record._id,
      editValue: record.cents ? parseFloat(record.cents.slice(1)) : 0
    })
  }

  handleOpenDelete (record) {
    this.setState({
      showDeleteModal: true,
      workId: record._id
    })
  }

  handleCloseEdit () {
    this.setState({
      showEditModal: false
    })
  }

  handleCloseDelete () {
    this.setState({
      showDeleteModal: false
    })
  }

  handleUpdate () {
    dataProvider(UPDATE, 'bids', {
      id: this.state.workId,
      data: {
        bid: {
          cents: parseFloat((this.state.editValue * 100).toFixed(2)) // convert to cents
        }
      }
    })
      .then(this.loadBids)
      .catch((error) => {
        this.props.showNotification(error.message, 'warning')
      })
      .then(() => {
        this.setState({
          showEditModal: false
        })
      })
  }

  handleDelete () {
    dataProvider(DELETE, 'bids', {
      id: this.state.workId
    })
      .then(this.loadBids)
      .catch((error) => {
        this.props.showNotification(error.message, 'warning')
      })
      .then(() => {
        this.setState({
          showDeleteModal: false
        })
      })
  }

  render () {
    const { ids, data } = this.state
    const UserField = ({ record = {} }) => record.createdBy && <a href={`/#/users/${record.createdBy._id}/show`} >{record.createdBy.firstName} {record.createdBy.lastName}</a>
    const EditBidButton = ({ record = {} }) => <Button disabled = {record.status === 'withdrawn'? true : false}  onClick={() => this.handleOpenEdit(record)} >Edit</Button>
    const WithdrawBidButton = ({ record = {} }) => <Button disabled = {record.status === 'withdrawn'? true : false} onClick={() => this.handleOpenDelete(record)}  >Withdraw</Button>
    const { push, showNotification, ...elseProps } = this.props
    const bidRowStyle = (record, index) => ({
     "text-decoration" : record.status === 'withdrawn'  ? 'line-through' : '',
     "text-decoration-thickness" : "1.5px"
  });
    return ([
      <Dialog
        open={this.state.showEditModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        key='edit-dialog'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          Edit Bid
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description' />
          <Input
            value={this.state.editValue}
            placeholder='Bid Amount'
            name='Bid Amount'
            onChange={({ target: { value } }) => {
              this.setState({
                editValue: value
              })
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseEdit} color='secondary'>
            Close
          </Button>
          <Button onClick={this.handleUpdate} color='primary'>
            Update
          </Button>
        </DialogActions>
      </Dialog>,
      <Dialog
        open={this.state.showDeleteModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        key='delete-dialog'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          Delete Bid
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            Are you sure you want to delete bid?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseDelete} color='secondary'>
            No
          </Button>
          <Button onClick={this.handleDelete} color='primary'>
            Yes
          </Button>
        </DialogActions>
      </Dialog>,
      <div
        className='datagrid-wrapper datagrid-wrapper-bids'
        key='datagrid'
      >
        <Datagrid
          ids={ids}
          data={data}
          currentSort={{ field: this.state.field, order: this.state.order }}
          setSort={this.setSort}
          {...elseProps}
          rowStyle={bidRowStyle}
        >
          <UserField label='User' />
          <DateField source='createdAt' label='Time' showTime />
          <TextField source='cents' label='Bid Amount' />
          <TextField source='type' label='Bid Type' sortable={false} />
          <EditBidButton label='Edit' />
          <WithdrawBidButton label='Withdraw' />
        </Datagrid>
        <OwnPagination
          page={this.state.page}
          rowsPerPage={this.state.perPage}
          perPage={this.state.perPage}
          total={this.state.total}
          setPerPage={this.setPerPage}
          setPage={this.setPage}
        />
      </div>
    ])
  }
}
