import Toggle from 'react-toggle'
import React from 'react'
import { Form } from 'redux-form'
import debounce from 'lodash/debounce'
import './styles.scss'

export class BillForReoffered extends Form {
  constructor (props, context) {
    super(props, context)
    this.reduxForm = context._reduxForm
    let form = this.reduxForm.getValues()
    this.state = {
      state: form.state,
      takeFeeForReoffered: form.takeFeeForReoffered,
      reoffered: form.reoffered,
      defaultChecked: false
    }
    this.debounced = null
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.dataFromJson && nextProps.dataFromJson.hasOwnProperty('takeFeeForReoffered') && nextProps.dataFromJson.takeFeeForReoffered !== this.state.defaultChecked) {
      this.setState({
        defaultChecked: nextProps.dataFromJson.takeFeeForReoffered,
        takeFeeForReoffered: nextProps.dataFromJson.takeFeeForReoffered
      })
    }
  }

  onToggleChange = () => {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }

    this.debounced = debounce(this.changeToggleInReduxForm, 500)
    this.setState({
      takeFeeForReoffered: !this.state.takeFeeForReoffered
    }, this.debounced)
  }

  changeToggleInReduxForm = () => {
    this.reduxForm.dispatch(this.reduxForm.change('takeFeeForReoffered', this.state.takeFeeForReoffered))
  }

  ifDisabled = () => {
    if ((this.state.state === 'closed' && this.props.isChoosenAuction) || this.state.reoffered || this.props.create) {
      return false
    }
    return true
  }

  render () {
    const { state, takeFeeForReoffered, reoffered } = this.state
    return (
      <>{
        state === 'closed' || reoffered
          ? <div className='toggle-container'>
            <Toggle
              disabled={this.ifDisabled()}
              checked={takeFeeForReoffered || false}
              onChange={this.onToggleChange}
            />
            <span>Take listing fee for Re-offered</span>
          </div>
          : null
      }
      </>
    )
  }
}
