/* eslint camelcase: 0 */
import React, { Component } from 'react'
import debounce from 'lodash/debounce'
import Checkbox from '@material-ui/core/Checkbox'

export default class DebounceBooleanField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: props.checked
    }

    this.updateValue = this.updateValue.bind(this)
    this.UNSAFE_componentWillReceiveProps = this.UNSAFE_componentWillReceiveProps.bind(this)
    this.debounced = null
  }

  updateValue ({ target: { checked } }) {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(() => this.props.onChange({
      value: checked,
      indexes: this.props.newIndexes,
      field: this.props.field,
      lastArrayName: this.props.lastArrayName
    }), 300)
    this.setState({ value: checked }, this.debounced)
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.checked !== this.state.value) {
      this.setState({ value: nextProps.checked })
    }
  }

  render () {
    const { value } = this.state
    return (
      <Checkbox
        style={this.props.style}
        disabled={this.props.disabled}
        onChange={this.updateValue}
        checked={value === null ? false : value}
        field='value'
      />
    )
  }
}
