import { GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, UPDATE } from 'react-admin'
const queryString = require('query-string')
function _interopRequireDefault (obj) { return obj && obj.__esModule ? obj : { default: obj } }
const defineProperty2 = require('babel-runtime/helpers/defineProperty')
const defineProperty3 = _interopRequireDefault(defineProperty2)
const extends3 = require('babel-runtime/helpers/extends')
const extends4 = _interopRequireDefault(extends3)

const convertDataRequestToHTTP = (type, resource, params) => {
  let url = ''
  let options = {}
  switch (type) {
    case GET_LIST: {
      let { page, perPage } = params.pagination
      let { field, order } = params.sort
      let search = params.search
      let state = params.state
      if (params.filter && params.filter.q) {
        search = params.filter.q
      }
      let query = {
        page,
        limit: perPage,
        sortField: field,
        sortDirection: order === 'ASC' ? 1 : -1,
        state
      }
      if (params.isAdminSite) {
        query.isAdminSite = params.isAdminSite
      }
      if (params.fromXero) {
        query.fromXero = params.fromXero
      }
      if (params.auctionLots) {
        query.fromAdmin = params.fromAdmin
      }
      if (params.range) {
        query.rangeFrom = params.range.rangeFrom
        query.rangeTo = params.range.rangeTo
      }
      if (params.isAuctionBuyers) {
        query.onlineState = params.onlineState
      }
      if (params.viewedReport) {
        query.viewedReport = params.viewedReport
      }
       query.ViewMoreReport = params.getLotpageViewMoreReport ? params.getLotpageViewMoreReport : false
      if (search) {
        query.search = search
      }
      query.filters = JSON.stringify(params.filter)
      if (params.status && resource === 'invoices') {
        query.status = params.status
      }
      if (resource === 'connectivities') {
        query.userId = params.userId
      }
      if (params.userType && params.auction) {
        query.type = params.userType
      }
      if (params.user && resource === 'invoices') {
        resource = `invoices?user=${params.user}`
        // delete query.sortDirection
        url = resource + '&' + (0, queryString.stringify)(query)
      } else if (resource === 'bids' && params.lotId) {
        url = resource + `?lotId=${params.lotId}&` + (0, queryString.stringify)(query)
      } else if (resource === 'bids' && params.auctionId) {
        url = resource + `?auctionId=${params.auctionId}&` + (0, queryString.stringify)(query)
      } else if (params.lotsType) {
        url = resource + `?type=${params.lotsType}&` + (0, queryString.stringify)(query)
      } else if (params.bids && params.userId && params.enabled) {
        url = resource + `?userId=${params.userId}&enabled=true` + (0, queryString.stringify)(query)
      } else {
        url = resource + '?' + (0, queryString.stringify)(query)
      }
      break
    }
    case GET_ONE:
      if (params && params.id) {
        url = resource + '/' + params.id
      } else {
        url = resource
      }
      if (resource === 'lots' && params && params.id) {
        url = resource + '/' + params.id + '?mode=full'
      }
      break
    case GET_MANY:
      if (resource === 'users' && params.ids && params.ids.length === 1) {
        url = 'users/' + params.ids[0]
      } else {
        url = resource + '?' + (0, queryString.stringify)(params)
      }
      break
    case GET_MANY_REFERENCE: {
      let { page, perPage } = params.pagination
      let { field, order } = params.sort

      let query = {
        sortField: field,
        sortDirection: order === 'ASC' ? 1 : -1,
        page,
        limit: perPage,
        filters: JSON.stringify((0, extends4.default)({}, params.filter, (0, defineProperty3.default)({}, params.target, params.id)))
      }
      url = resource + '?' + (0, queryString.stringify)(query)
      break
    }
    case UPDATE:
      if (resource.indexOf('emails/config') !== -1) {
        url = resource
      } else {
        url = resource + '/' + params.id
      }
      options.method = 'PUT'
      options.body = JSON.stringify(params.data)
      break
    default:
      throw new Error('Unsupported fetch action type ' + type)
  }
  return { url, options }
}

export default convertDataRequestToHTTP
