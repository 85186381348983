import React, { Component } from 'react'
import './styles.css'
import update from 'immutability-helper'
import { Collapse } from 'react-collapse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { parseQueryString } from '../../utils/index'
import axios from 'axios'

export class TermsVersionsShowComponent extends Component {
//   render () {
//     return (
//       <div className='lists-wrapper'>
//         <p className='page-title'>Terms Version History</p>
//         <HomeLotsComponent {...this.props} />
//         <HomeUsersComponent {...this.props} />
//         <InvoiceList isHomePage {...this.props} />
//       </div>
//     )
//   }

constructor (props) {
    super(props)

    this.state = {
      ids: [],
      data: [],
      categoryName : "",
      indexNumber : null,
      total: 0,
      page: 1,
     // perPage: config.perPage,
      field: 'createdAt',
      order: 'DESC',
      timerEnabled: true,
      isOpened: false,
      search: ''
    }
    this.debounced = null

    // this.filter = { approved: false, draft: false }
    // this.loadLotData = this.loadLotData.bind(this)
    // this.setPage = this.setPage.bind(this)
    // this.setPerPage = this.setPerPage.bind(this)
    // this.setSort = this.setSort.bind(this)
    // this.renderLots = this.renderLots.bind(this)
    this.changeCollapse = this.changeCollapse.bind(this)
    // this.setSearchString = this.setSearchString.bind(this)

   // this.loadLotData()
  }
  async componentDidMount () {
    const params = parseQueryString(this.props.location.search)
    let url = `/terms/${params.id}`
    const res = await axios.get(url);

    this.setState({
        data: res.data.term.olderVersions,
        categoryName : res.data.term.categoryName
      })

   }
changeCollapse (name, state) {
    this.setState({
      isOpened: update(this.state.isOpened, {
        $set: !this.state.isOpened,
      }),
      indexNumber : name
    })
  }

collapsible ({ title, isOpened, changeCollapse, publicId, body }) {
    return (
      <div className='collapse-wrapper'>
        <div
          className='header'
          onClick={isOpened ? () => (changeCollapse(publicId, false)) : () => (changeCollapse(publicId, true))}
        >
          <p className='title'>
            {title}
          </p>
          <div
            className='button'
          >
            <FontAwesomeIcon icon={isOpened ? faAngleUp : faAngleDown} />
            <span>{isOpened ? 'Hide' : 'Show'}</span>
          </div>
        </div>
        <Collapse isOpened={isOpened}>
          <div className='body'>
            {body}
          </div>
        </Collapse>
      </div>
    )
  }

render () {
   let {data,categoryName,indexNumber} = this.state
  
    return  (
      <div>
        {/* <p className='page-title'>{categoryName.replace(/[0-9\.]/g, "") +" " + "Versions History"}</p> */}
        <p className='page-title'>{categoryName.replace(/[0-9.]/g, "")} Versions History</p>

        {
            data.map((ele, index) =>(
                <React.Fragment>
                {this.collapsible({
                  title: ele.version + ".0",
                  isOpened: this.state.isOpened && indexNumber === index,
                  changeCollapse: this.changeCollapse,
                  publicId: index,
                  body: ele.content
                })}
              </React.Fragment>
            ))
        }

      </div>
    )
  }
}