import React, { Component } from 'react'
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { Collapse } from 'react-collapse'
import 'react-table/react-table.css'
import { UserReportTableComponent } from '../../components/UserReportTable/UserReportTable'
import { KpiReportTable } from '../../components/KpiReportTable/KpiReportTable'
import { AuctionReportTable } from '../../components/AuctionReportTable/AuctionReportTable'
import { KpiIncWithdrawnReports } from '../../components/KpiIncWithdrawnReports/KpiIncWithdrawnReports'
import { KpiReportSalesTable } from '../../components/KpiReportSalesTable/KpiReportSalesTable'
import { LotReportTable } from '../../components/LotReportTable/LotReportTable'
import { AccountsInvoiceReportTable } from '../../components/AccountsInvoiceReportTable/AccountsInvoiceReportTable'
import { InvoicesSentReportTable } from '../../components/InvoicesSentReportTable/InvoicesSentReportTable'
import { LotWatchlist } from '../../components/LotsWatchlisTable/LotsWatchlisTable'

class ReportListComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openUserReport: false,
      openActiveUserReport: false,
      openKPIReport: false,
      openKPIWithdrawnReport: false,
      openKpiSales: false,
      openAuctionReport: false,
      openLotsViewedReport: false,
      openAccountsInvoiceReport: false,
      openInvoiceSendReport: false,
      openLotsAddedToWatchlistReport: false
    }
    this.userReportTableComponent = React.createRef()
    this.accountsInvoiceReportTable = React.createRef()
    this.activeUserReport = React.createRef()
    this.kPIReport = React.createRef()
    this.kPIWithdrawnReport = React.createRef()
    this.kpiSales = React.createRef()
    this.auctionReport = React.createRef()
    this.lotsViewedReport = React.createRef()
    this.invoiceSendReport = React.createRef()
    this.LotsAddedToWatchlistReport = React.createRef()
  }

  changeCollapse = (table) => {
    if (!this.state[table]) {
      switch (table) {
        case 'openUserReport':
          this.userReportTableComponent.current.loadData()
          break
        case 'openActiveUserReport':
          this.activeUserReport.current.loadData()
          break
        case 'openKPIReport':
          this.kPIReport.current.loadData()
          break
        case 'openKPIWithdrawnReport':
          this.kPIWithdrawnReport.current.loadData()
          break
        case 'openKpiSales' :
          this.kpiSales.current.loadData()
          break
        case 'openAuctionReport':
          this.auctionReport.current.loadData()
          break
        case 'openLotsViewedReport':
          this.lotsViewedReport.current.loadData()
          break
        case 'openAccountsInvoiceReport':
          this.accountsInvoiceReportTable.current.loadData()
          break
        case 'openInvoiceSendReport':
          this.invoiceSendReport.current.loadData()
          break
        case 'openLotsAddedToWatchlistReport':
          this.LotsAddedToWatchlistReport.current.loadData()
          break
        default :
          break
      }
    }

    this.setState({ [table]: !this.state[table] })
  }

  render () {
    return (
      <div className='lists-wrapper'>
        <p className='page-title'>Reports</p>
        <React.Fragment>
          <div className='collapse-wrapper reports'>
            <div
              className='header'
              onClick={() => {
                this.changeCollapse('openUserReport')
              }}>
              <p className='title'>{'User Report'}</p>
              <div className='button'>
                <FontAwesomeIcon icon={this.state.openUserReport ? faAngleUp : faAngleDown} />
                <span>{'Hide'}</span>
              </div>
            </div>
            <Collapse isOpened={this.state.openUserReport}>
              <div className='body'>
                <UserReportTableComponent
                  ref={this.userReportTableComponent}
                  isOpened={this.state.openUserReport}
                  onlyActive={false}
                  {...this.props}
                />
              </div>
            </Collapse>
          </div>
        </React.Fragment>
        <React.Fragment>
          <div className='collapse-wrapper reports'>
            <div
              className='header'
              onClick={() => {
                this.changeCollapse('openActiveUserReport')
              }}>
              <p className='title'>{'Active User Report'}</p>
              <div className='button'>
                <FontAwesomeIcon icon={this.state.openActiveUserReport ? faAngleUp : faAngleDown} />
                <span>{'Hide'}</span>
              </div>
            </div>
            <Collapse isOpened={this.state.openActiveUserReport}>
              <div className='body'>
                <UserReportTableComponent
                  ref={this.activeUserReport}
                  isOpened={this.state.openActiveUserReport}
                  {...this.props}
                  onlyActive
                />
              </div>
            </Collapse>
          </div>
        </React.Fragment>
        <React.Fragment>
          <div className='collapse-wrapper reports'>
            <div
              className='header'
              onClick={() => {
                this.changeCollapse('openKPIReport')
              }}>
              <p className='title'>{'KPI - Clearance Rate Report'}</p>
              <div className='button'>
                <FontAwesomeIcon icon={this.state.openKPIReport ? faAngleUp : faAngleDown} />
                <span>{'Hide'}</span>
              </div>
            </div>
            <Collapse isOpened={this.state.openKPIReport}>
              <div className='body'>
                <div className='reports-search-wrapper' />
                <KpiReportTable ref={this.kPIReport} />
              </div>
            </Collapse>
          </div>
        </React.Fragment>

        <React.Fragment>
          <div className='collapse-wrapper reports'>
            <div
              className='header'
              onClick={() => {
                this.changeCollapse('openKPIWithdrawnReport')
              }}>
              <p className='title'>{'KPI - FarmGate Listings Report (includes Withdrawn Lots and excludes Re-offered Lots)'}</p>
              <div className='button'>
                <FontAwesomeIcon icon={this.state.openKPIWithdrawnReport ? faAngleUp : faAngleDown} />
                <span>{'Hide'}</span>
              </div>
            </div>
            <Collapse isOpened={this.state.openKPIWithdrawnReport}>
              <div className='body'>
                <div className='reports-search-wrapper' />
                <KpiIncWithdrawnReports ref={this.kPIWithdrawnReport} />
              </div>
            </Collapse>
          </div>
        </React.Fragment>

        <React.Fragment>
          <div className='collapse-wrapper reports'>
            <div
              className='header'
              onClick={() => {
                this.changeCollapse('openKpiSales')
              }}>
              <p className='title'>{'KPI - Sales Turnover Report'}</p>
              <div className='button'>
                <FontAwesomeIcon icon={this.state.openKpiSales ? faAngleUp : faAngleDown} />
                <span>{'Hide'}</span>
              </div>
            </div>
            <Collapse isOpened={this.state.openKpiSales}>
              <div className='body'>
                <div className='reports-search-wrapper' />
                <KpiReportSalesTable ref={this.kpiSales} />
              </div>
            </Collapse>
          </div>
        </React.Fragment>

        <React.Fragment>
          <div className='collapse-wrapper reports'>
            <div
              className='header'
              onClick={() => {
                this.changeCollapse('openAuctionReport')
              }}>
              <p className='title'>{'Auction Results Report'}</p>
              <div className='button'>
                <FontAwesomeIcon icon={this.state.openAuctionReport ? faAngleUp : faAngleDown} />
                <span>{'Hide'}</span>
              </div>
            </div>
            <Collapse isOpened={this.state.openAuctionReport}>
              <div className='body'>
                <div className='reports-search-wrapper' />
                <AuctionReportTable ref={this.auctionReport} />
              </div>
            </Collapse>
          </div>
        </React.Fragment>

        <React.Fragment>
          <div className='collapse-wrapper reports'>
            <div
              className='header'
              onClick={() => {
                this.changeCollapse('openLotsViewedReport')
              }}>
              <p className='title'>{'Lots Viewed Report'}</p>
              <div className='button'>
                <FontAwesomeIcon icon={this.state.openLotsViewedReport ? faAngleUp : faAngleDown} />
                <span>{'Hide'}</span>
              </div>
            </div>
            <Collapse isOpened={this.state.openLotsViewedReport}>
              <div className='body'>
                <div className='reports-search-wrapper' />
                <LotReportTable ref={this.lotsViewedReport} />
              </div>
            </Collapse>
          </div>
        </React.Fragment>

        <React.Fragment>
          <div className='collapse-wrapper reports'>
            <div
              className='header'
              onClick={() => {
                this.changeCollapse('openLotsAddedToWatchlistReport')
              }}>
              <p className='title'>{'Lots Added To Watchlist Report'}</p>
              <div className='button'>
                <FontAwesomeIcon icon={this.state.openLotsAddedToWatchlistReport ? faAngleUp : faAngleDown} />
                <span>{'Hide'}</span>
              </div>
            </div>
            <Collapse isOpened={this.state.openLotsAddedToWatchlistReport}>
              <div className='body'>
                <div className='reports-search-wrapper' />
                <LotWatchlist ref={this.LotsAddedToWatchlistReport} />
              </div>
            </Collapse>
          </div>
        </React.Fragment>

        <React.Fragment>
          <div className='collapse-wrapper reports'>
            <div
              className='header'
              onClick={() => {
                this.changeCollapse('openAccountsInvoiceReport')
              }}>
              <p className='title'>{'Accounts - All Invoices Report'}</p>
              <div className='button'>
                <FontAwesomeIcon icon={this.state.openAccountsInvoiceReport ? faAngleUp : faAngleDown} />
                <span>{'Hide'}</span>
              </div>
            </div>
            <Collapse isOpened={this.state.openAccountsInvoiceReport}>
              <div className='body'>
                <div className='reports-search-wrapper' />
                <AccountsInvoiceReportTable
                  ref={this.accountsInvoiceReportTable}
                  {...this.props} />
              </div>
            </Collapse>
          </div>
        </React.Fragment>

        <React.Fragment>
          <div className='collapse-wrapper reports'>
            <div
              className='header'
              onClick={() => {
                this.changeCollapse('openInvoiceSendReport')
              }}>
              <p className='title'>{'XERO REPORT'}</p>
              <div className='button'>
                <FontAwesomeIcon icon={this.state.openInvoiceSendReport ? faAngleUp : faAngleDown} />
                <span>{'Hide'}</span>
              </div>
            </div>
            <Collapse isOpened={this.state.openInvoiceSendReport}>
              <div className='body'>
                <div className='reports-search-wrapper' />
                <InvoicesSentReportTable ref={this.invoiceSendReport} {...this.props} />
              </div>
            </Collapse>
          </div>
        </React.Fragment>
      </div>
    )
  }
}

export const ReportList = (props) => (
  <ReportListComponent {...props} />
)
