import { changeWindowModal } from '../../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { AuctionShowComponent } from './model'
import { showNotification, refreshView } from 'react-admin'

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({
      changeWindowModal,
      showNotification,
      refreshView
    }, dispatch)
  }
}

const AuctionShow = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuctionShowComponent)

export default AuctionShow
