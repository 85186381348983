import React, { Component } from 'react'
import {
  GET_ONE,
  UPDATE,
  Datagrid
} from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'

import { dataProvider } from '../../App'
import './styles.css'

const requiredTypes = [
  'forgotPasswordEmail',
  'catalogueSummary',
  'healthDeclarationCattle',
  'healthDeclarationSheep',
  'mySubmittedLotDetail',
  'forgotPasswordSuccessEmail',
  'myAdminAuctionsResults',
  'resultsFull',
  'resultsSummary',
  'catalogueFullLot',
  'arbitrationForm',
  'cattleAssessmentForm',
  'sheepAssessmentForm',
  'cattleWeightWorksheet',
  'sheepWeightWorksheet'
]

function Transition (props) {
  return <Slide direction='up' {...props} />
}

class EmailsListComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      data: {},
      ids: [],
      currentEmailType: '',
      currentEmailTypeStatus: ''
    }
    this.loadEmails = this.loadEmails.bind(this)
    this.updateEmailStatus = this.updateEmailStatus.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.loadEmails()
  }

  async loadEmails () {
    let emails = await dataProvider(GET_ONE, 'emails/config', {})
    if (emails) {
      let newids = []
      let newdata = {}
      for (let i = 0; i < emails.data.emails.length; i++) {
        let id = emails.data.emails[i]._id
        newids.push(id)
        newdata[id] = {
          ...emails.data.emails[i]
        }
      }
      this.setState({
        data: newdata,
        ids: newids
      })
    }
  }

  handleOpen (emailType, status) {
    this.setState({
      open: true,
      currentEmailType: emailType,
      currentEmailTypeStatus: status
    })
  }

  async updateEmailStatus () {
    await dataProvider(UPDATE, `emails/config/${this.state.currentEmailType}`, { data: { enable: !this.state.currentEmailTypeStatus } }).then(this.handleClose)
    this.loadEmails()
  }

  handleClose () {
    this.setState({
      open: false
    })
  }

  render () {
    const EmailType = ({ record }) => <span>{record.title}{requiredTypes.includes(record.type) ? <span className='asterisk'>*</span> : ''}</span>
    const EmailStatus = ({ record }) => <Button onClick={() => { this.handleOpen(record.type, record.enable) }}>{record.enable ? 'Enabled' : 'Disabled'}</Button>
    const LotEditButton = ({ record }) => <a rel='noopener noreferrer' href={`https://sendgrid.com/dynamic_templates/ui/${record.templateId}/versions/${record.activeVersion}/edit`} target='_blank'> Edit </a>
    return (
      <div className='email-config-table'>
        <Datagrid
          ids={this.state.ids}
          data={this.state.data}
          currentSort={{ field: this.state.field, order: this.state.order }}
          setSort={() => {}}
        >
          <EmailType label='Email Type' />
          <EmailStatus label='Email Status' />
          <LotEditButton label='Edit' />
        </Datagrid>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            {'Email status'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              Please confirm you want {!this.state.currentEmailTypeStatus ? 'Enable' : 'Disable'} this Email template?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.updateEmailStatus} color='primary'>
              Yes
            </Button>
            <Button onClick={this.handleClose} color='primary'>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export const EmailsList = (props) => (
  <EmailsListComponent {...props} />
)
