export default function compareArrays (arrayOne, arrayTwo) {
  if (arrayOne.length !== arrayTwo.length) {
    return true
  }
  for (let i = 0; i < arrayOne.length; i++) {
    if (arrayOne[i] !== arrayTwo[i]) {
      return true
    }
  }
  return false
}
