import React, { useState } from 'react'
import { connect } from 'react-redux'
import { UPDATE, SaveButton, Toolbar, showNotification } from 'react-admin'
import { DeleteButtonComponent } from './DeleteButtonComponent'
import { push } from 'react-router-redux'
import { dataProvider } from '../App'
import { professionOther } from '../constants'
import { getUserTypeByStatus } from '../utils'

const ToolbarWrapper = (props) => {
  const [isOnload, setIsOnload] = useState(false)
  const { basePath, handleSubmit, redirect, push, showNotification, user, auction, specificAgents, ...newprops } = props
  const handleSaveClick = (newprops) => {
    if (isOnload) {
      return null
    }
    return handleSubmit(data => {
      setIsOnload(true)
      if (newprops.resource === 'users' && data.hasOwnProperty('status') && data.status !== null) {
        data.type = getUserTypeByStatus(data.status, data.type ? data.type : newprops.record.type)
      }
      if (newprops.resource === 'users' && newprops.record.hasOwnProperty('anotherStatePicAvailable') && !newprops.record.anotherStatePicAvailable && newprops.record.anotherStatePicAvailable !== null) {
        data.anotherStatePIC = ''
      }
      if (newprops.resource === 'users' && newprops.record.hasOwnProperty('profession') && newprops.record.profession !== professionOther && newprops.record.otherProfession !== null) {
        data.otherProfession = ''
      }
      if (newprops.resource === 'users' && newprops.record.hasOwnProperty('registeredForGST') && newprops.record.registeredForGST !== null) {
        data.registeredForGST = newprops.record.registeredForGST
      }
      if (newprops.resource === 'users' && newprops.record.hasOwnProperty('areYouUsingAgent') && newprops.record.areYouUsingAgent !== null) {
        data.areYouUsingAgent = newprops.record.areYouUsingAgent
        if (!newprops.record.areYouUsingAgent) {
          data.agentName = ''
        }
      }


      if (newprops.resource === 'users' && data.hasOwnProperty('approvedStockTypes') && data.approvedStockTypes.length > 0) {
        data.stockTypes = data.stockTypes.filter(val => !data.approvedStockTypes.includes(val));
      }
      if (newprops.resource === 'users' && newprops.record.hasOwnProperty('isRequestedAsBuyer') && newprops.record.isRequestedAsBuyer !== null) {
        data.isRequestedAsBuyer = newprops.record.isRequestedAsBuyer
      }
      if (newprops.resource === 'users' && newprops.record.hasOwnProperty('isRequestedAsSeller') && newprops.record.isRequestedAsSeller !== null) {
        data.isRequestedAsSeller = newprops.record.isRequestedAsSeller
      }
      if (newprops.resource === 'auctions') {
        data.specificAgents = specificAgents
      }
      dataProvider(UPDATE, newprops.resource, { data, id: data._id })
        .then(() => {
          if ((newprops.resource === 'invoices' || newprops.resource === 'terms' || newprops.resource === 'faqs' || newprops.resource === 'how-to-videos' || newprops.resource === 'how-to-assess-steps') &&
            newprops.resource !== 'auctions' && newprops.resource !== 'users') {
            setIsOnload(false)
            push(`${basePath}`)
          } else if (newprops.resource === 'auctions' || newprops.resource === 'users') {
            setIsOnload(false)
            push(`${basePath}/${data._id}/show`)
          } else {
            setIsOnload(false)
            push(`${basePath}/${data._id}/${redirect}`)
          }
        })
        .catch(error => {
          setIsOnload(false)
          showNotification(error.message, 'warning')
        })
    })
  }

  return (
    <Toolbar {...newprops}>
      <SaveButton
        label='Save and Show'
        submitOnEnter={false}
        disabled={isOnload}
        handleSubmitWithRedirect={() => handleSaveClick(props)}
      />
      {(auction || user) &&
        <DeleteButtonComponent
          resource={props.resource}
          record={props.record}
        />
      }
    </Toolbar>
  )
}

export default connect(null, {
  push,
  showNotification
})((props) => <ToolbarWrapper {...props} />)
