import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './AssessmentFormStyles.css'
import formatTitleForType from '../../helpers/formatTitleForType'

export default class AssessmentFormView extends Component {
  constructor (props) {
    super(props)

    this.renderGroup = this.renderGroup.bind(this)
  }

  renderGroup (forms, depth = 0) {
    return (forms.map((form, index) => {
      if (form.type === 'group') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <h4>{formatTitleForType(form.type)}</h4>
            {this.renderGroup(form.forms, depth + 1)}
          </div>
        )
      } else if (form.type === 'repeatForNumber') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <h4>{formatTitleForType(form.type)}</h4>
            {this.renderGroup(form.forms, depth + 1)}
          </div>
        )
      } else if (form.type === 'repeatForBool') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <h4>{formatTitleForType(form.type)}</h4>
            {this.renderGroup(form.forms, depth + 1)}
          </div>
        )
      } else if (form.type === 'dropdown' || form.type === 'multiDropdown') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <FontAwesomeIcon className='is-public' icon={form.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', form.isRequired ? 'required' : 'not-required'].join(' ')} icon='asterisk' />
            <h4>{formatTitleForType(form.type)}</h4>
            {
              form.options.length && (
                <>
                  <h5>Options:</h5>
                  <ul className='dropdown-options'>
                    {form.options.map((option, ind) => {
                      return (
                        <li className={`depth-${(depth + 1) % 3}`} key={option + ind}>
                          {option}
                        </li>
                      )
                    })}
                  </ul>
                </>
              )
            }
          </div>
        )
      } else if (form.type === 'select') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <FontAwesomeIcon className='is-public' icon={form.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', form.isRequired ? 'required' : 'not-required'].join(' ')} icon='asterisk' />
            <h4>{formatTitleForType(form.type)}</h4>
            {
              form.options.length && (
                <>
                  <h5>Options:</h5>
                  <ul className='dropdown-options'>
                    {form.options.map((option, ind) => {
                      return (
                        <li className={`depth-${(depth + 1) % 3}`} key={option + ind}>
                          {option.label}
                        </li>
                      )
                    })}
                  </ul>
                </>
              )
            }
          </div>
        )
      } else if (form.type === 'bool') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <FontAwesomeIcon className='is-public' icon={form.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', form.isRequired ? 'required' : 'not-required'].join(' ')} icon='asterisk' />
            <h4>{formatTitleForType(form.type)}</h4>
          </div>
        )
      } else if (form.type === 'rating') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <FontAwesomeIcon className='is-public' icon={form.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', form.isRequired ? 'required' : 'not-required'].join(' ')} icon='asterisk' />
            <h4>{formatTitleForType(form.type)}</h4>
          </div>
        )
      } else if (form.type === 'weight') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <FontAwesomeIcon className='is-public' icon={form.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', form.isRequired ? 'required' : 'not-required'].join(' ')} icon='asterisk' />
            <h4>{formatTitleForType(form.type)}</h4>
          </div>
        )
      } else if (form.type === 'text') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <FontAwesomeIcon className='is-public' icon={form.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', form.isRequired ? 'required' : 'not-required'].join(' ')} icon='asterisk' />
            <h4>{formatTitleForType(form.type)}</h4>
          </div>
        )
      } else if (form.type === 'textarea') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <FontAwesomeIcon className='is-public' icon={form.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', form.isRequired ? 'required' : 'not-required'].join(' ')} icon='asterisk' />
            <h4>{formatTitleForType(form.type)}</h4>
          </div>
        )
      } else if (form.type === 'date') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <FontAwesomeIcon className='is-public' icon={form.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', form.isRequired ? 'required' : 'not-required'].join(' ')} icon='asterisk' />
            <h4>{formatTitleForType(form.type)}</h4>
          </div>
        )
      } else if (form.type === 'checkbox') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <FontAwesomeIcon className='is-public' icon={form.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', form.isRequired ? 'required' : 'not-required'].join(' ')} icon='asterisk' />
            <h4>{formatTitleForType(form.type)}</h4>
          </div>
        )
      } else if (form.type === 'number') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <FontAwesomeIcon className='is-public' icon={form.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', form.isRequired ? 'required' : 'not-required'].join(' ')} icon='asterisk' />
            <h4>{formatTitleForType(form.type)}</h4>
          </div>
        )
      } else if (form.type === 'user') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <FontAwesomeIcon className='is-public' icon={form.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', form.isRequired ? 'required' : 'not-required'].join(' ')} icon='asterisk' />
            <h4>{formatTitleForType(form.type)}</h4>
          </div>
        )
      } else if (form.type === 'phone') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <FontAwesomeIcon className='is-public' icon={form.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', form.isRequired ? 'required' : 'not-required'].join(' ')} icon='asterisk' />
            <h4>{formatTitleForType(form.type)}</h4>
          </div>
        )
      } else if (form.type === 'email') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <FontAwesomeIcon className='is-public' icon={form.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', form.isRequired ? 'required' : 'not-required'].join(' ')} icon='asterisk' />
            <h4>{formatTitleForType(form.type)}</h4>
          </div>
        )
      } else if (form.type === 'picNumber') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <FontAwesomeIcon className='is-public' icon={form.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', form.isRequired ? 'required' : 'not-required'].join(' ')} icon='asterisk' />
            <h4>{formatTitleForType(form.type)}</h4>
          </div>
        )
      } else if (form.type === 'tradingName') {
        return (
          <div className={`depth-${depth % 3}`} key={form.title + index}>
            <h3>{form.title}</h3>
            <FontAwesomeIcon className='is-public' icon={form.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', form.isRequired ? 'required' : 'not-required'].join(' ')} icon='asterisk' />
            <h4>{formatTitleForType(form.type)}</h4>
          </div>
        )
      } else {
        return null
      }
    }))
  }

  render () {
    let { form } = this.props.record
    return (
      <div className='assesstment-wrapper'>
        <h2>{form.title}</h2>
        {this.renderGroup(form.forms)}
      </div>
    )
  }
}
