import React, { Component } from 'react'
import classNames from 'classnames'
import { makePrice } from '../../../utils'
import { OwnPagination } from '../../OwnPagination'
import { GET_LIST, Datagrid, TextField, NumberField, BooleanField, ReferenceField } from 'react-admin'
import MaterialTextField from '@material-ui/core/TextField'
import config from '../../../config'
import { collapsible } from '../../../utils/auction/show/collapsible'
import update from 'immutability-helper'
import { dataProvider } from '../../../App'
import socketService from '../../../utils/socketConnection'
import debounce from 'lodash/debounce'
import './LotsTable.scss'
import ActionButton from '../../../components/ActionButton'
import { handleOpenLot } from '../../../models/lots/configurations/model'


export class LotsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      lots: {
        data: {},
        isOpen: false,
        total: 0,
        page: 1,
        perPage: 10,
        field: 'numericId',
        order: 'ASC'
      },
      search: null
    }
    this.auctionState = props._state
    this.debounced = null
    this.loadData()
  }

  loadData = async () => {
    socketService.sockets.emit('bids', 'join-auction', { _id: this.props.record.id }, (err) => {
      if (err) {
        console.warn(err)
      }
    })
    dataProvider(GET_LIST, `auctions/${this.props.record.id}/lots`, {
      filter: this.filter,
      pagination: {
        page: this.state.lots.page,
        perPage: this.state.lots.perPage
      },
      sort: {
        field: this.state.lots.field,
        order: this.state.lots.order
      },
      search: this.state.search,
      auction: true,
      auctionLots: true,
      fromAdmin: true
    })
      .then(({ data, total }) => {
        let newids = []
        let newdata = {}
        for (let i = 0; i < data.length; i++) {
          let id = data[i].id
          newids.push(id)
          newdata[id] = {
            ...data[i],
            leftTime: data[i].leftTime
          }
        }
        this.setState({
          lots: update(this.state.lots, {
            data: {
              $set: newdata
            }
          })
        })
        this.setState({
          lots: update(this.state.lots, {
            total: {
              $set: total
            }
          })
        })
      }).catch(err => {
        console.warn(err)
      })
  }

  updateLotsBySocket = (data) => {
    if (!data || !data.doc) {
      return
    }
    let isLotId = null
    Object.keys(this.state.lots.data).forEach((id) => {
      if (data && id === data.doc._id) {
        isLotId = id
      }
    })
    if (isLotId) {
      let newData = {}
      if (data.doc && data.doc.status) {
        newData.status = { $set: data.doc.status }
      }
      if (data.doc && data.doc.currentBidCents && data.doc.bidding) {
        newData._currentPrice = { $set: makePrice(data.doc.currentBidCents, { bidding: data.doc.bidding }) }
      }
      this.setState({
        lots: update(this.state.lots, {
          data: {
            [isLotId]: newData
          }
        })
      })
    }
  }

  setSearchString = ({ target: { value } }) => {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }

    this.debounced = debounce(this.loadData, 300)
    this.setState({
      search: value
    }, this.debounced)
  }

  lotsRender () {
    const LotClosedStatusField = ({ record }) => {
      const highlight = classNames('status-field', {
        sold: record.status === 'sold',
        passed: record.status === 'passed',
        noBid: record.status === 'noBid',
        withdrawn: record.status === 'withdrawn'
      })
      let status = record.status
      if (status === 'soldAfterAuction') {
        status = 'Sold AA'
      }
      return (
        <span className={highlight}>{status}</span>
      )
    }

    const ReserveStatusField = ({ source, record = {} }) => {
      let highlight
      let reserveStatus = record.reserveStatus
      highlight = classNames('status-field', {
        green: reserveStatus === 'onMarket',
        white: reserveStatus === 'notNear' || reserveStatus === 'awaitingBids',
        lime: reserveStatus === 'nearReserve'
      })
      if (record.withdrawn) {
        reserveStatus = 'withdrawn'
      }
      switch (reserveStatus) {
        case 'withdrawn':
          return (
            <span> Withdrawn </span>
          )
        case 'onMarket':
          return (
            <span className={highlight}> On Market </span>
          )
        case 'notNear':
          return (
            <span className={highlight}> Opening Bid </span>
          )
        case 'awaitingBids':
          return (
            <span className={highlight}> Awaiting Bids </span>
          )
        case 'nearReserve':
          return (
            <span className={highlight}> Nearing Reserve </span>
          )
        case 'upcoming':
          return (
            <span> Upcoming </span>
          )
        case 'live':
          return (
            <span className={highlight}> Live </span>
          )
        default:
          return null
      }
    }
    const LotId = ({ record = {} }) => <a href={`/#/lots/${record._id}/show`}
      className='self-link'>{record.searchNumericId}</a>
    const AuctionLinkField = ({ record = {} }) => <a href={`/#/auctions/${record.auction}/show`}
      className='vendor-link'>{record.auctionData.title}</a>
    const DescriptionLinkField = ({ record = {} }) => <a href={`/#/lots/${record._id}/show`}
      className='self-link'>{record.publicDetails.title}</a>
    const NumberLink = ({ record = {} }) => <a href={`/#/lots/${record._id}/show`}
      className='self-link'>{record.number}</a>
    const SellerField = ({ record = {} }) => <a href={`/#/users/${record.createdBy}/show`}
      className='vendor-link'>{record.creatorData.firstName} {record.creatorData.lastName}</a>
    const WinnerField = ({ record = {} }) => {
      return (record &&
        <a href={`/#/users/${record._id}/show`} className='vendor-link'>{`${record.firstName} ${record.lastName}`}</a>
      )
    }
    const ReOpenLot = ({ record = {} }) => {
    // <a href={`/#/lots/${record._id}/show`}
    // className='self-link'>Re-Open Lottttt
    // </a>
    return(
      <ActionButton 
      data={record} 
      onClick={handleOpenLot} 
      isComingfromLotTable = {true}
      variant='outlined' 
      disabled = {!(record.state === 'closed')}
      color='primary'>
      Re-Open Lot
      </ActionButton>
    )
    }

    const ReofferedField = ({ record = {} }) => {
      record._reoffered = record.reoffered
      return (
        <div className='reoffered-field'>
          <BooleanField record={record} source='_reoffered' sortable />
          { record._reoffered
            ? <>
              <span className='separator'>/</span>
              <BooleanField record={record} source='takeFeeForReoffered' sortable />
            </> : null
          }
        </div>
      )
    }
    const BidIncrementField = ({ record = {} }) => {
      return (
        <p>{makePrice(record.bidIncrementCents, { bidding: record.bidding })}</p>
      )
    }
    const StatusField = ({ record = {} }) => {
      if (record.auctionData.closedAt) {
        return <LotClosedStatusField record={record} />
      } else {
        return <ReserveStatusField record={record} />
      }
    }
    const BuyerField = ({ record = {} }) => {
      return (
        <ReferenceField record={record} source='winner' reference='users' label='Buyer/ Losing bidder(passed lots)'
          basePath='users' allowEmpty linkType={false} sortable={false}>
          <WinnerField />
        </ReferenceField>
      )
    }

    const DraftField = ({ record = {} }) => {
      record._draftReady = !record.draft
      return (
        <BooleanField record={record} source='_draftReady' sortable={false} />
      )
    }

    let { data } = this.state.lots
    let ids = Object.keys(data)
    const rowStyle = (record) => ({
      backgroundColor: record.removed ? '#e8553db8' : ''
    })
    return (
      <div className='info-table-div-wrapper'>
        <MaterialTextField
          label='Search'
          placeholder='Search'
          className='search-text'
          onChange={this.setSearchString}
        />
        <Datagrid
          ids={ids}
          data={data}
          currentSort={{}}
          rowStyle={rowStyle}
        >
          <AuctionLinkField source='auctionData.title' label='Auction' sortable={false} />
          <LotId source='_id' label='Lot ID' sortable={false} />
          <NumberLink source='number' label='Lot No' sortable={false} />
          <DescriptionLinkField source='description' label='Lot Description' sortable={false} />
          <TextField source='_startPrice' label='Start Price' sortable={false} />
          <TextField source='_reserveCents' label='Reserve Price' sortable={false} />
          <NumberField source='bidding' label='Bid Type' sortable={false} />
          <TextField source='allocatedSection' label='Allocated Section' sortable={false} />
          <BidIncrementField source='bidIncrementCents' label='Bid Increment' sortable={false} />
          <DraftField label='Draft Ready' />
          <BooleanField source='approved' label='Approved' sortable={false} />
          <ReofferedField label='Reoffered/Take Fee For Reoffered' sortable />
          <SellerField label='Seller' sortable={false} />
          <StatusField label='Status' />
          {this.auctionState !== 'closed' && <TextField source='_currentPrice' label='Current Price' sortable={false} />}
          <TextField source='_closedPrice' label='Closed Price' sortable={false} />
          <BuyerField label='Buyer/ Losing bidder(passed lots)' />
          <ReOpenLot label='Re-Open Lot' sortable={false} />

        </Datagrid>
        <OwnPagination
          page={this.state.lots.page}
          rowsPerPage={this.state.lots.perPage}
          perPage={this.state.lots.perPage}
          total={this.state.lots.total}
          setPerPage={this.setLotsPerPage}
          setPage={this.setLotsPage}
        />
      </div>
    )
  }

  setLotsPage = (page) => {
    this.setState({
      lots: update(this.state.lots, {
        page: { $set: page }
      })
    }, this.loadData)
  }

  setLotsPerPage = (perPage) => {
    this.setState({
      lots: update(this.state.lots, {
        perPage: { $set: perPage }
      })
    }, this.loadData)
  }

  changeCollapse = (state) => {
    this.setState({
      lots: update(this.state.lots, {
        isOpen: { $set: state }
      })
    })
  }

  render () {
    let { lots } = this.state
    return (
      <div id='collapse-content-lots'>
        {collapsible({
          title: `Lots, ${lots.total} lots`,
          exportLink: `${config.rootUrl}exports/auctions/${this.props.record.id}/lots?${config.adminAccess}=true`,
          isOpened: this.state.lots.isOpen,
          changeCollapse: this.changeCollapse,
          body: this.lotsRender()
        })}
      </div>
    )
  }
}
