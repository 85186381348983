import React, { Component } from 'react'
import {
  GET_LIST,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  EmailField,
  CreateButton
} from 'react-admin'
import MaterialTextField from '@material-ui/core/TextField'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { OwnPagination } from '../../components/OwnPagination'
import { generateUserId } from '../../utils'
import './styles.css'
import { dataProvider } from '../../App'
import config from '../../config'
import { professionOther } from '../../constants'

const UserId = (source) => {
  return (
    <div> {generateUserId(source.record.type, source.record.numericId)} </div>
  )
}

const DiscountField = ({ record = {} }) => {
  return (
    <div>
      {record.discount ? <span>{`${record.discount}%`}</span> : <span>{`Nil`}</span>}
    </div>
  )
}

const ProfessionField = ({ record = {} }) => {
  return (
    <div> <span>{`${record.profession ? (record.profession === professionOther ? (record.otherProfession ? record.otherProfession : '') : record.profession) : ''}`}</span>
    </div>
  )
}

const RequestedPermission = ({ record = {} }) => {
  return (
    <div> <span>{`${record.status === 'approved-buyer-seller' ? '' : record.isRequestedAsBuyer && record.isRequestedAsSeller ? 'Buyer & Seller' : record.isRequestedAsBuyer && record.status !== 'approved-buyer' ? 'Buyer' : record.isRequestedAsSeller ? 'Seller' : ''}`}</span>
    </div>
  )
}

const ApprovedSellingCategoriesField = ({ record = {} }) => {
  return (
    <div> <span>{`${record.approvedStockTypes && record.approvedStockTypes.length > 0 ? (record.approvedStockTypes.join(', ')) : ''}`}</span>
    </div>
  )
}

const RequestedSellingCategoriesField = ({ record = {} }) => {
  return (
    <div> <span>{`${record.stockTypes && record.stockTypes.length > 0 ? (record.stockTypes.join(', ')) : ''}`}</span>
    </div>
  )
}

/*const UserTypeField = ({ record = {} }) => {
  return (
    <span>
      {record.type === 'seller' ? 'Vendor' : 'Buyer'}
    </span>
  )
}*/

const DateFormat = ({ record, source }) => {
  let data
  let redColor = false
  if (source === 'connectivity.lastBidTime') {
    data = record.connectivity.lastBidTime ? moment(record.connectivity.lastBidTime).format('hh:mm:ss A') : '---'
  }
  if (source === 'connectivity.lastBidDate') {
    data = record.connectivity.lastBidTime ? moment(record.connectivity.lastBidTime).format('YYYY/MM/D') : '---'
  }
  if (source === 'connectivity.lastBoughtDate') {
    data = record.connectivity.lastBoughtTime ? moment(record.connectivity.lastBoughtTime).format('YYYY/MM/D') : '---'
  }
  if (source === 'connectivity.lastBoughtTime') {
    data = record.connectivity.lastBoughtTime ? moment(record.connectivity.lastBoughtTime).format('hh:mm:ss A') : '---'
  }
  if (source === 'createdAtDate') {
    data = record.createdAt ? moment(record.createdAt).format('YYYY/MM/D') : '---'
  }
  if (source === 'createdAtTime') {
    data = record.createdAt ? moment(record.createdAt).format('hh:mm:ss A') : '---'
  }
  if (source === 'connectivity.lastSoldTime') {
    data = record.connectivity.lastSoldTime ? moment(record.connectivity.lastSoldTime).format('hh:mm:ss A') : '---'
  }
  if (source === 'connectivity.lastSoldDate') {
    data = record.connectivity.lastSoldTime ? moment(record.connectivity.lastSoldTime).format('YYYY/MM/D') : '---'
  }
  // let createdAtTime = moment(record.createdAt).format('hh:mm:ss A')
  let lastLoginTime = moment(record.connectivity.lastLogin).format('hh:mm:ss A')
  let lastLoginDate = moment(record.connectivity.lastLogin).format('YYYY/MM/D')
  if (source === 'connectivity.lastLoginTime') {
    if (record.connectivity.lastLogin) {
      data = lastLoginTime
    } else {
      data = '---'
      redColor = true
    }
  }


  if (source === 'connectivity.lastLoginDate') {
    if (record.connectivity.lastLogin) {
      data = lastLoginDate
    } else {
      data = '---'
      redColor = true
    }
  }
  if (data === '---' && redColor) {
    return (
      <div style={{ backgroundColor: 'red', height: '60px', width: '60px' }} />
    )
  }
  return (
    <span>
      {data}
    </span>
  )
}

/*const SettingsField = ({ record, source }) => {
  return (
    <span>
      {record.settings.smsRemindersPermissions[source] ? 'On' : 'Off'}
    </span>
  )
}*/

export class UserList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ids: [],
      data: {},
      total: 0,
      page: 1,
      perPage: config.perPage,
      field: '_id',
      order: 'ASC',
      search: ''
    }
    this.debounced = null

    this.loadData = this.loadData.bind(this)
    this.setPerPage = this.setPerPage.bind(this)
    this.setPage = this.setPage.bind(this)
    this.setSort = this.setSort.bind(this)
    this.setSearchString = this.setSearchString.bind(this)

    this.loadData()
  }

  setPerPage (perPage) {
    this.setState({
      perPage
    }, this.loadData)
  }

  setPage (page) {
    this.setState({
      page
    }, this.loadData)
  }

  setSort (field) {
    let order = 'ASC'
    if (this.state.field === field) {
      order = this.state.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      field,
      order
    }, this.loadData)
  }

  convert (date) {
    const datearray = date.split('/')
    const newdate = datearray[2] + '/' + datearray[1] + '/' + datearray[0]
    return newdate
  }

  setSearchString ({ target: { value } }) {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.loadData, 300)
    this.setState({
      search: value
    }, this.debounced)
  }

  loadData () {
    let field
    if (this.state.field === '_lastBid') {
      field = 'connectivity.lastBidTime'
    } else if (this.state.field === '_lastBought') {
      field = 'connectivity.lastBoughtTime'
    } else if (this.state.field === '_lastSold') {
      field = 'connectivity.lastSoldTime'
    } else if (this.state.field[0] === '_') {
      field = this.state.field.substring(1)
    } else {
      field = this.state.field
    }
    dataProvider(GET_LIST, 'users', {
      filter: { ...this.filter },
      pagination: {
        page: this.state.page,
        perPage: this.state.perPage
      },
      sort: {
        field,
        order: this.state.order
      },
      search: this.state.search
    })
      .then(({ data, total }) => {
        let newids = []
        let newdata = {}
        for (let i = 0; i < data.length; i++) {
          let id = data[i].id
          newids.push(id)
          newdata[id] = {
            ...data[i],
            leftTime: data[i].leftTime
          }
        }
        /* for (let user in newdata) {
          newdata[user].createdAt = this.convert(newdata[user].createdAt.split(', ')[0]) + ': ' + newdata[user].createdAt.split(', ').pop()
        } */
        this.setState({
          ids: newids,
          data: newdata,
          total
        })
      })
  }

  render () {
    const {
      ids,
      data
    } = this.state
    let filteredProps = { ...this.props }
    delete filteredProps.hasList
    delete filteredProps.hasShow
    delete filteredProps.hasEdit
    delete filteredProps.hasCreate
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return (
      <div className='datagrid-wrapper users-wrapper'>
        <p className='page-title'>Users</p>
        <div className='actions'>
          <MaterialTextField
            label='Search'
            placeholder='Search'
            className='users-search-text'
            onChange={this.setSearchString}
          />
          <CreateButton to={'/users/create'} />
          <a href={`${config.rootUrl}exports/users?${config.adminAccess}=true&timezone=${timezone}`}>Export CSV</a>
        </div>
        <div className='data-grid-wrapper'>
          <Datagrid
            ids={ids}
            data={data}
            currentSort={{ field: this.state.field, order: this.state.order }}
            setSort={this.setSort}
            {...filteredProps}
            className='user-info-table'
          >
            <UserId source='_id' />
            <DiscountField source='discount' label='Discount%' />
            <TextField source='firstName' />
            <TextField source='lastName' />
            <TextField source='status' label='Current Permission' />
            <RequestedPermission label='Requested Permission' />
            <ProfessionField source='profession' label='Profession' />
            <ApprovedSellingCategoriesField source='approvedStockTypes' label='Approved Selling Categories' />
            <RequestedSellingCategoriesField source='stockTypes' label='Requested Selling Categories' />
            <TextField source='tradingName' />
            <EmailField source='email' />
            <TextField source='phone' />
            <TextField source='officeAddress.town' label='Town' />
            <TextField source='officeAddress.state' label='State' />
            <DateFormat source='createdAtDate' label='Date Registered' />
            <DateFormat source='createdAtTime' label='Time Registered' />
            <DateFormat source='connectivity.lastLoginDate' label='Date Last Online' />
            <DateFormat source='connectivity.lastLoginTime' label='Time Last Online' />
            <TextField source='iosVersionLastConnected' label='Last connected ios version' />
            {/* <TextField source='postalAddress.address1' label='Postal Address Line 1' />
            <TextField source='postalAddress.address2' label='Postal Address Line 2' />
            <TextField source='officeAddress.town' label='Town' />
            <TextField source='postalAddress.postcode' label='Postcode' />
            <TextField source='officeAddress.state' label='State' />
            <TextField source='status' />
            <UserTypeField source='type' label='Buyer/SSA' />
            <DateFormat source='connectivity.lastBidDate' label='Last Bid Date' />
            <DateFormat source='connectivity.lastBidTime' label='Last Bid Time' />
            <DateFormat source='connectivity.lastBoughtDate' label='Last Bought Date' />
            <DateFormat source='connectivity.lastBoughtTime' label='Last Bought Time' />
            <DateFormat source='connectivity.lastSoldDate' label='Last Sold Date' />
            <DateFormat source='connectivity.lastSoldTime' label='Last Sold Time' />
            <DateFormat source='createdAtDate' label='Date Added' />
            <DateFormat source='createdAtTime' label='Time Added' />
            <DateFormat source='connectivity.lastLoginDate' label='Date Last Online' />
            <DateFormat source='connectivity.lastLoginTime' label='Time Last Online' />
            <TextField source='connectivity.ip' label='IP Address' />
            <TextField source='iosVersionLastConnected' label='Last connected ios version' />
            <TextField source='connectivity.totalLotsBought' label='Lots Bought' />
            <TextField source='connectivity.totalLotsSold' label='Lots Sold' />
            <TextField source='agentName' label='Buyer Agent Name' />
            <TextField source='agentPhone' label='Buyer Agent Number' />
            <TextField source='agentTradingName' label='Buyer Agent Trading Name' />
            <SettingsField source='cattleAuctionOpenReminder' label='Cattle Auction is Live' />
            <SettingsField source='cattleOneDayReminder' label='Cattle Auto-Bids are Open' />
            <SettingsField source='cattleTenDaysReminder' label='Cattle Listings are Open' />
            <SettingsField source='sheepAuctionOpenReminder' label='Sheep Auction is Live' />
            <SettingsField source='sheepOneDayReminder' label='Sheep Auto-Bids are Open' />
            <SettingsField source='sheepTenDaysReminder' label='Sheep Listings are Open' /> */}
            <EditButton />
            <ShowButton />
          </Datagrid>
        </div>
        <OwnPagination
          className={'users-pagination'}
          page={this.state.page}
          rowsPerPage={this.state.perPage}
          perPage={this.state.perPage}
          total={this.state.total}
          setPerPage={this.setPerPage}
          setPage={this.setPage}
        />
      </div>
    )
  }
}
