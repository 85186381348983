import React from 'react'
import { Pagination, PaginationLimit } from 'react-admin'
import { sanitizeListRestProps } from 'ra-core'
import TablePagination from '@material-ui/core/TablePagination'
import { PaginationActions } from './PaginationActions'

export class OwnPagination extends Pagination {
  constructor (props) {
    super(props)

    this.getNbPages = this.getNbPages.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handlePerPageChange = this.handlePerPageChange.bind(this)
  }

  getNbPages () {
    return Math.ceil(this.props.total / this.props.perPage) || 1
  }

  componentDidUpdate () {
    if (this.props.page < 1 || isNaN(this.props.page)) {
      this.props.setPage(1)
    }
  }

  handlePageChange (event, page) {
    event && event.stopPropagation()
    if (page < 0 || page > this.getNbPages() - 1) {
      throw new Error(
        this.props.translate('ra.navigation.page_out_of_boundaries', {
          page: page + 1
        })
      )
    }
    this.props.setPage(page + 1)
  };

  handlePerPageChange (event) {
    this.props.setPerPage(event.target.value)
  };

  render () {
    const {
      isLoading,
      page,
      perPage,
      rowsPerPageOptions,
      total,
      translate,
      ...rest
    } = this.props

    if (!isLoading && total === 0) {
      return <PaginationLimit />
    }

    return (
      <TablePagination
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onChangePage={this.handlePageChange}
        onChangeRowsPerPage={this.handlePerPageChange}
        ActionsComponent={PaginationActions}
        rowsPerPageOptions={rowsPerPageOptions}
        component='span'
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
        {...sanitizeListRestProps(rest)}
      />
    )
  }
}
