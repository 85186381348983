import React, { Component } from 'react'
import './styles.css'
import { dataProvider } from '../../App'
import { GET_LIST } from 'react-admin'
import { OwnPagination } from '../OwnPagination'
import moment from 'moment'
import debounce from 'lodash/debounce'
import MaterialTextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import ReactTable from 'react-table'
import config from '../../config'
import * as Datetime from 'react-datetime'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import axios from 'axios'

export class KpiReportTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: [],
      defaultPageSize: 20,
      search: '',
      field: 'liveAt',
      order: 'DESC',
      total: 100,
      page: 1,
      perPage: config.perPage,
      totalData: {},
      dateFrom: '',
      dateTo: '',
      selectStockType: 'all'
    }

    this.loadData = this.loadData.bind(this)
  }

  loadData = async () => {
    let { page, perPage, field, order, search, dateFrom, dateTo, selectStockType } = this.state

    if (dateFrom) {
      dateFrom = moment(dateFrom).tz(config.timeZone)
    }
    if (dateTo) {
      dateTo = moment(dateTo).tz(config.timeZone)
    }
    if (selectStockType !== 'all') {
      this.filter = { 'kindData.type': selectStockType }
    } else {
      this.filter = null
    }

    try {
      let data = await dataProvider(GET_LIST, '/auctions/reports', {
        state: 'closed',
        filter: this.filter,
        pagination: {
          page,
          perPage
        },
        sort: {
          field,
          order
        },
        search,
        range: {
          rangeFrom: dateFrom,
          rangeTo: dateTo
        }
      })
      this.setState({ data: data.data, total: data.total, totalData: data.totalData })
    } catch (error) {
      console.log('error', error)
    }
  }

  setSearchString = ({ target: { value } }) => {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.loadData, 300)
    this.setState({
      search: value
    }, this.debounced)
  }

  setSort = (field) => {
    let order = 'ASC'
    if (this.state.field === field) {
      order = this.state.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      field,
      order
    }, this.loadData)
  }

  setPerPage = (perPage) => {
    this.setState({
      perPage
    }, this.loadData)
  }

  setPage = (page) => {
    this.setState({
      page
    }, this.loadData)
  }

  dateHandler = (date, field) => {
    this.setState({ [field]: date })
  }

  clearHandle = () => {
    this.setState({ dateFrom: '', dateTo: '', selectStockType: 'all' },
      () => {
        this.loadData()
      })
  }

  applyRangeHandle = () => {
    if (!this.state.dateFrom || !this.state.dateTo) {
      window.alert('Dates from and to should be filled')
    } else if (this.state.dateFrom > this.state.dateTo) {
      window.alert('Please select valid date')
    } else {
      this.loadData()
    }
  }

  handleChangeStockType = (e) => {
    this.setState({ selectStockType: e.target.value })
  }

  downloadCsv = async () => {
    let { field, order, search, dateFrom, dateTo, selectStockType } = this.state

    if (dateFrom) {
      dateFrom = moment(dateFrom).tz(config.timeZone)
    }
    if (dateTo) {
      dateTo = moment(dateTo).tz(config.timeZone)
    }
    if (selectStockType !== 'all') {
      this.filter = { 'kindData.type': selectStockType }
    } else {
      this.filter = ''
    }

    let response = await axios({
      url: `print/kpi-report?rangeFrom=${dateFrom}&rangeTo=${dateTo}&search=${search}&sortField=${field}&sortDirection=${order}&filters=${this.filter}`,
      method: 'GET',
      responseType: 'blob'
    })

    const blob = new window.Blob([response.data], { type: response.data.type })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    const contentDisposition = response.headers['content-disposition']
    let fileName = 'kpi_clearance_rate_report'
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length === 2) { fileName = fileNameMatch[1] }
    }
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
  }

  render () {
    let auctionClearanceRate = this.state.totalData ? (this.state.totalData.totalNoLotsSoldSum / this.state.totalData.totalNoLotsinAuctionSum).toFixed(2) : 0
    return (
      <div id='kpi-report-table'>
        <div className='reports-search-wrapper'>
          <Select
            className='select'
            value={this.state.selectStockType}
            onChange={this.handleChangeStockType}
          >
            <MenuItem value={'all'}> All </MenuItem>
            <MenuItem value={'cattle'}>Cattle</MenuItem>
            <MenuItem value={'sheep'}>Sheep</MenuItem>
          </Select>

          <Datetime
            timeFormat={0}
            dateFormat='DD/MM/YYYY'
            label={'label'}
            helperText={'some help text'}
            inputProps={{ placeholder: 'From' }}
            value={this.state.dateFrom}
            onChange={(date) => { this.dateHandler(date, 'dateFrom') }}
          />

          <Datetime
            timeFormat={false}
            dateFormat='DD/MM/YYYY'
            label={'label'}
            helperText={'some help text'}
            inputProps={{ placeholder: 'To' }}
            value={this.state.dateTo}
            onChange={(date) => { this.dateHandler(date, 'dateTo') }}
          />
          <Button className='range-button' onClick={this.applyRangeHandle}>apply range</Button>
          <Button className='range-button clearButton' onClick={this.clearHandle}>clear range</Button>

          <Button
            onClick={this.downloadCsv}
            color='primary'
            className='download-csv'
            variant='contained'>
            Download CSV
          </Button>
          <MaterialTextField
            label='Search'
            placeholder='Search'
            className='search-text'
            onChange={this.setSearchString}
          />
        </div>
        <ReactTable
          resizable
          minRows={0}
          data={this.state.data}
          showPagination={this.props.isOpened}
          sortable={false}
          PaginationComponent={() => {
            return (
              <OwnPagination
                page={this.state.page}
                rowsPerPage={this.state.perPage}
                perPage={this.state.perPage}
                total={this.state.total}
                setPerPage={this.setPerPage}
                setPage={this.setPage} />
            )
          }}
          columns={[
            {
              id: 'AuctionDate',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('liveAt')
                  }}>Auction Date</div>
                )
              },
              Cell: ({ original }) => {
                return <p>{moment(original.liveAt).tz(config.timeZone).format('DD/MM/YYYY')}</p>
              },
              width: 175
            },
            {
              id: 'AuctionNo',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('number')
                  }}>Auction No.</div>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.searchNumber}</p>
              }
            },
            {
              id: 'StockType',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('kindData.title')
                  }}>Stock Type</div>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.kindData.title}</p>
              }
            },
            {
              id: 'TotalNo',
              Header: () => {
                return (
                  <Tooltip title='Lots in Closed Auction exclude withdrawn'>
                    <div onClick={() => {
                      this.setSort('resultTable.total.numberOfLots.totalExclWithdrawn')
                    }}>Total No. <br /> Lots in Closed Auction
                    </div>
                  </Tooltip>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.resultTable.total.numberOfLots.totalExclWithdrawn}</p>
              },
              width: 140
            },
            {
              id: 'TotalNoSoldLots',
              Header: () => {
                return (
                  <Tooltip title='sold + soldAfterAuction'>
                    <div onClick={() => {
                      this.setSort('resultTable.total.numberOfLots.sold')
                    }}>Total No. <br /> Sold Lots</div>
                  </Tooltip>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.resultTable.total.numberOfLots.sold}</p>
              },
              width: 140
            },
            {
              id: 'TotalNoPassedinLots',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('resultTable.total.numberOfLots.passed')
                  }}>Total No. <br /> Passed in Lots</div>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.resultTable.total.numberOfLots.passed}</p>
              },
              width: 140
            },
            {
              id: 'TotalNoNoBidLots',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('resultTable.total.numberOfLots.noBid')
                  }}>Total No. <br /> No Bid Lots</div>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.resultTable.total.numberOfLots.noBid}</p>
              },
              width: 140
            },
            {
              id: 'TotalNoWithdrawnLots',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('resultTable.total.numberOfLots.withdrawn')
                  }}>Total No. <br /> Withdrawn Lots</div>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.resultTable.total.numberOfLots.withdrawn}</p>
              },
              width: 140
            },
            {
              id: 'TotalNoReofferedLots',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('resultTable.total.numberOfLots.reoffered.sold')
                  }}>Total No. <br /> Reoffered Lots Sold</div>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.resultTable.total.numberOfLots.reoffered.sold}</p>
              },
              width: 140
            },
            {
              id: 'Reoffered Lots NOT Sold',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('resultTable.total.numberOfLots.reoffered.notSold')
                  }}>Total No. <br /> Reoffered Lots NOT Sold</div>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.resultTable.total.numberOfLots.reoffered.notSold}</p>
              },
              width: 200
            },
            {
              id: 'TotalNoHeadinAuction',
              Header: () => {
                return (
                  <Tooltip title='(soldLots / totalExclWithdrawn) * 100'>
                    <div onClick={() => {
                      this.setSort('resultTable.total.numberOfLots.auctionClearanceRate')
                    }}>Auction Clearance Rate % Lots</div>
                  </Tooltip>
                )
              },
              Cell: ({ original }) => {
                return <p>{`${original.resultTable.total.numberOfLots.auctionClearanceRate.toFixed(0)} %`}</p>
              },
              width: 250
            },
            {
              id: 'TotalNoofHeadSold',
              Header: () => {
                return (
                  <Tooltip title='Total heads exclude withdrawn lots include reoffered lots'>
                    <div onClick={() => {
                      this.setSort('resultTable.total.heads.totalExclWithdrawenInclReoffered')
                    }}>Total No. <br /> Head in Closed Auction</div>
                  </Tooltip>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.resultTable.total.heads.totalExclWithdrawenInclReoffered}</p>
              },
              width: 225
            },
            {
              id: 'AuctionClearanceRate%Head',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('resultTable.total.heads.sold')
                  }}>Total No. <br /> Head Sold</div>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.resultTable.total.heads.sold}</p>
              },
              width: 150
            },
            {
              id: 'AuctionClearanceRate%Head',
              Header: () => {
                return (
                  <Tooltip title='headsInSoldLots / headsInTotalLotsExclWithdrawn) * 100'>
                    <div onClick={() => {
                      this.setSort('resultTable.total.heads.auctionClearanceRate')
                    }}>Auction Clearance Rate % Head</div>
                  </Tooltip>
                )
              },
              Cell: ({ original }) => {
                return <p>{`${original.resultTable.total.heads.auctionClearanceRate.toFixed(0)} %`}</p>
              },
              width: 300
            },
            {
              id: 'Auction Tags',
              Header: () => {
                return (
                  <div>Auction Tags</div>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.auctionTags ? original.auctionTags.map(e => e.title).join(', ') : ''}</p>
              },
              width: 200
            }
          ]}
          defaultPageSize={this.state.defaultPageSize}
        />
        <div className='total-container'>
          <div className='total-content'>
            <p>{`Total Auction Clearance Rate Lots: ${auctionClearanceRate}%`}</p>
            {this.state.totalData.auctionClearanceRateHead && this.state.data.length
              ? <p>{`Total Auction Clearance Rate Head: ${this.state.totalData.auctionClearanceRateHead}%`}</p> : ''}
          </div>
        </div>
      </div>
    )
  }
}
