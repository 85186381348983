import axios from 'axios'

const kindApiService = {
  getKinds: () => {
    return axios.get(`/kinds`)
      .then(res => {
        return res.data.kinds || []
      }).catch(e => {
        return []
      })
  }
}

export default kindApiService
