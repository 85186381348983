export default function generatePathForKind (indexes, field, lastArrayName = null) {
  let path = ''

  for (let i = 0; i < indexes.length; i++) {
    if (lastArrayName !== null && i === indexes.length - 1) {
      path += `.${lastArrayName}[${indexes[i]}]`
    } else {
      path += `.forms[${indexes[i]}]`
    }
  }
  path = path.slice(1) // remove dot
  if (field && !lastArrayName) {
    path += `['${field}']`
  }

  return path
}
