import React, { Component } from 'react'
import { dataProvider } from '../../App'
import { GET_LIST } from 'react-admin'
import { OwnPagination } from '../OwnPagination'
import debounce from 'lodash/debounce'
import MaterialTextField from '@material-ui/core/TextField'
import ReactTable from 'react-table'
import config from '../../config'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import './styles.css'
import { makePrice } from '../../utils'
import axios from 'axios'
import { generateLotStatusString } from '../../utils/fields'
import { gstTax } from '../../constants'

export class AccountsInvoiceReportTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: [],
      defaultPageSize: 20,
      search: '',
      field: '',
      order: 'ASC',
      total: 100,
      page: 1,
      perPage: config.perPage
    }

    this.loadData = this.loadData.bind(this)
  }

  loadData = async () => {
    let { page, perPage, field, order, search } = this.state
    try {
      let data = await dataProvider(GET_LIST, 'lot-invoice-report', {
        filter: this.filter,
        pagination: {
          page,
          perPage
        },
        sort: {
          field,
          order
        },
        search
      })
      let items = data.data.filter(item => item.invoice !== null)
      items.forEach(item => {
        if (item.invoice && item.invoice.data.auction.searchNumber) {
          item.searchNumber = item.invoice.data.auction.searchNumber
        } else {
          let letters = ''
          if (item.stockType) {
            letters = item.stockType.substring(0, 1)
          }
          if (item.invoice) {
            if (item.invoice.data.auction.privateSale) {
              letters = 'P'.concat(letters)
            } else {
              letters = 'A'.concat(letters)
            }
            if (item.invoice.data.auction.number) {
              letters = letters.concat(item.invoice.data.auction.number)
            }
          }
          item.searchNumber = letters
        }
      })
      this.setState({ data: items, total: data.total })
    } catch (error) {
      console.log(error)
    }
  }

  setSearchString = ({ target: { value } }) => {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.loadData, 300)
    this.setState({
      search: value
    }, this.debounced)
  }

  setSort = (field) => {
    let order = 'ASC'
    if (this.state.field === field) {
      order = this.state.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      field,
      order
    }, this.loadData)
  }

  getClearance (total, sold) {
    let clearance = 0
    if (total && sold) {
      clearance = sold / (total / 100)
    }
    return clearance.toFixed(0)
  }

  setPerPage = (perPage) => {
    this.setState({
      perPage
    }, this.loadData)
  }

  setPage = (page) => {
    this.setState({
      page
    }, this.loadData)
  }

  linkToUser = (userId) => {
    this.props.history.push(`users/${userId}/show`)
  }

  linkToAuction = (auctionId) => {
    this.props.history.push(`auctions/${auctionId}/show`)
  }

  async downloadCsv () {
    let response = await axios({
      url: `print/accounts-invoice-report`,
      method: 'GET',
      responseType: 'blob'
    })

    const blob = new window.Blob([response.data], { type: response.data.type })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    const contentDisposition = response.headers['content-disposition']
    let fileName = 'active-users-report'
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length === 2) { fileName = fileNameMatch[1] }
    }
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
  }

  makeUserId (userId, type) {
    let userType = ''
    if (type) {
      userType = 'U'
    }
    return `${userType}${userId}`
  }

  render () {
    return (
      <div id='invoice-report-table'>
        <div className='reports-search-wrapper'>
          <Button
            onClick={this.downloadCsv}
            color='primary'
            className='download-csv'
            variant='contained'>
            Download CSV
          </Button>
          <MaterialTextField
            label='Search'
            placeholder='Search'
            className='search-text'
            onChange={this.setSearchString}
          />
        </div>
        <ReactTable
          resizable
          minRows={0}
          data={this.state.data}
          showPagination={this.props.isOpened}
          sortable={false}
          PaginationComponent={() => {
            return (
              <OwnPagination
                page={this.state.page}
                rowsPerPage={this.state.perPage}
                perPage={this.state.perPage}
                total={this.state.total}
                setPerPage={this.setPerPage}
                setPage={this.setPage} />
            )
          }}
          columns={[
            {
              id: 'User ID',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('userId')
                  }}>User ID</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p className='link' onClick={() => { this.linkToUser(original.user) }}>{this.makeUserId(original.userId, original.userType)}</p>
                    </React.Fragment>
                  )
                }
                return <p className='link' onClick={() => { this.linkToUser(original.user) }}>{this.makeUserId(original.userId, original.userType)}</p>
              },
              width: 200
            },
            {
              id: 'User',
              Header: () => {
                return (
                  <div>User</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.invoice.data.user.firstName + ' ' + original.invoice.data.user.lastName}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.invoice.data.user.firstName + ' ' + original.invoice.data.user.lastName}</p>
              },
              width: 200
            },
            {
              id: 'Auction Number',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('searchNumber')
                  }}>Auction ID</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p className='link' onClick={() => { this.linkToAuction(original.invoice.auction) }}>{original.searchNumber}</p>
                    </React.Fragment>
                  )
                }
                return <p className='link' onClick={() => { this.linkToAuction(original.invoice.auction) }}>{original.searchNumber}</p>
              },
              width: 200
            },
            {
              id: 'Invoice No.',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('invoiceNo')
                  }}>Invoice No.</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{`FGI ${original.invoiceNo}`}</p>
                    </React.Fragment>
                  )
                }
                return (
                  <p>{`FGI ${original.invoiceNo}`}</p>
                )
              },
              width: 200
            },
            {
              id: 'Date of Issue',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('dateOfIssue')
                  }}>Invoice Date</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{moment(original.dateOfIssue).format('DD/MM/YYYY')}</p>
                    </React.Fragment>
                  )
                }
                return (
                  <p>{moment(original.dateOfIssue).format('DD/MM/YYYY')}</p>
                )
              },
              width: 200
            },
            {
              id: 'Payment Due Date',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('paymentDueDate')
                  }}>Payment Due Date</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.paymentDueDate ? moment(original.paymentDueDate).format('DD/MM/YYYY') : null}</p>
                    </React.Fragment>
                  )
                }
                return (
                  <p>{original.paymentDueDate ? moment(original.paymentDueDate).format('DD/MM/YYYY') : null}</p>
                )
              },
              width: 200
            },
            {
              id: 'Invoice Status',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('invoiceStatus')
                  }}>Payment Status</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.invoiceStatus}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.invoiceStatus}</p>
              },
              width: 200
            },
            {
              id: 'Email Status',
              Header: () => {
                return (
                  <div>Email Status</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.invoice.emailStatus}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.invoice.emailStatus}</p>
              },
              width: 200
            },
            {
              id: 'Vendor Name',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('vendorName')
                  }}>Vendor Name</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.vendorName}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.vendorName}</p>
              },
              width: 200
            },
            {
              id: 'Vendor PIC',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('vendorPIC')
                  }}>Vendor PIC</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.vendorPIC}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.vendorPIC}</p>
              },
              width: 200
            },
            {
              id: 'Lot No.',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('lotNo')
                  }}>Lot No.</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.lotNo}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.lotNo}</p>
              },
              width: 200
            },
            {
              id: 'Description (Breed, Sex, Stock Category)',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('description')
                  }}>Description (Breed, Sex, Stock Category)</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.description}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.description}</p>
              },
              width: 350
            },
            {
              id: 'Closed Status (Sold, Passed In, No Bid etc.)',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('lotStatus')
                  }}>Closed Status (Sold, Passed In, No Bid etc.)</div>
                )
              },
              Cell: ({ original, index }) => {
                let status = original.lotStatus
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{generateLotStatusString(status)}</p>
                    </React.Fragment>
                  )
                }
                return <p>{generateLotStatusString(status)}</p>
              },
              width: 350
            },
            {
              id: 'Reoffer',
              Header: () => {
                return (
                  <div onClick={() => { this.setSort('reoffer') }}>
                    Reoffered/Take Fee For Reoffered
                  </div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.reoffer ? 'Yes' : 'No'}</p>
                    </React.Fragment>
                  )
                }
                return (
                  <>
                    <span>{original.reoffer ? 'Yes' : 'No'}</span>
                    {original.reoffer
                      ? <>
                        <span className='separator'>/</span>
                        <span>{original.takeFeeForReoffered ? 'Yes' : 'No'}</span>
                      </>
                      : null
                    }
                  </>
                )
              },
              width: 200
            },
            {
              id: 'No Head.',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('lotNoHead')
                  }}>No Head.</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.lotNoHead}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.lotNoHead}</p>
              },
              width: 200
            },
            {
              id: 'Fee per Head',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('feeperHead')
                  }}>Listing Fee</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{makePrice(original.feeperHead)}</p>
                    </React.Fragment>
                  )
                }
                return <p>{makePrice(original.feeperHead)}</p>
              },
              width: 200
            },
            {
              id: 'Lot Fee',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('lotNoHead')
                  }}>Lot Fee</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.subTotal ? makePrice(original.subTotal) : makePrice(0)}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.subTotal ? makePrice(original.subTotal) : makePrice(0)}</p>
              },
              width: 200
            },
            {
              id: 'Lot Discount',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('lotNoHead')
                  }}>Lot Discount</div>
                )
              },
              Cell: ({ original, index }) => {
                let lotDiscount = original.lotDiscount ? original.lotDiscount : 0
                const lotNo = Number(original.lotNo) - 1
                if (original.invoice && original.invoice.data.lots[lotNo] && original.invoice.data.lots[lotNo].lotDiscount) {
                  lotDiscount = original.invoice.data.lots[lotNo].lotDiscount
                }
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.subTotal ? makePrice(original.subTotal * lotDiscount / 100) : makePrice(0)}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.subTotal ? makePrice(original.subTotal * lotDiscount / 100) : makePrice(0)}</p>
              },
              width: 200
            },
            {
              id: 'total',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('subTotal')
                  }}>Fee Total</div>
                )
              },
              Cell: ({ original, index }) => {
                let lotDiscount = original.lotDiscount ? original.lotDiscount : 0
                const lotNo = Number(original.lotNo) - 1
                if (original.invoice && original.invoice.data.lots[lotNo] && original.invoice.data.lots[lotNo].lotDiscount) {
                  lotDiscount = original.invoice.data.lots[lotNo].lotDiscount
                }
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{makePrice(original.subTotal * ((100 - lotDiscount) / 100))}</p>
                    </React.Fragment>
                  )
                }
                return <p>{makePrice(original.subTotal * ((100 - lotDiscount) / 100))}</p>
              },
              width: 200
            },
            {
              id: 'totalAmount',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('totalAmountOwing')
                  }}>Total</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{makePrice(original.totalAmountOwing)}</p>
                    </React.Fragment>
                  )
                }
                return <p>{makePrice(original.totalAmountOwing)}</p>
              },
              width: 200
            },
            {
              id: 'Set Up Fee',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('auctionId')
                  }}>Set up Fee</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{makePrice(original.invoice.data.auction.auctionSetUpFee)}</p>
                    </React.Fragment>
                  )
                }
                return <p>{makePrice(original.invoice.data.auction.auctionSetUpFee)}</p>
              },
              width: 200
            },
            {
              id: 'Auction Discount',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('auctionDiscount')
                  }}>Less Auction Discount %</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.invoice.data.auction.auctionDiscount}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.invoice.data.auction.auctionDiscount}</p>
              },
              width: 200
            },
            {
              id: 'Total Invoice excl GST',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('totalInvoiceAmountexclGST')
                  }}>Total excl GST - (Discount)</div>
                )
              },
              Cell: ({ original, index }) => {
                let userDiscount = 0
                if (original.invoice) {
                  userDiscount = (!original.invoice.data.auction.privateSale && original.invoice.data.user.discount) ? original.invoice.data.user.discount : 0
                }
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{makePrice(original.totalInvoiceAmountexclGST * (100 - userDiscount) / 100)}</p>
                    </React.Fragment>
                  )
                }
                return (
                  <p>{makePrice(original.totalInvoiceAmountexclGST * (100 - userDiscount) / 100)}</p>
                )
              },
              width: 250
            },
            {
              id: 'GST amount',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('amountGST')
                  }}>GST</div>
                )
              },
              Cell: ({ original, index }) => {
                let userDiscount = 0
                if (original.invoice) {
                  userDiscount = (!original.invoice.data.auction.privateSale && original.invoice.data.user.discount) ? original.invoice.data.user.discount : 0
                }
                let gst = gstTax
                if (original.invoice.hasOwnProperty(gst)) {
                  gst = original.invoice.gst
                }
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{makePrice(original.totalInvoiceAmountexclGST * ((100 - userDiscount) / 100) * (gst / 100))}</p>
                    </React.Fragment>
                  )
                }
                return <p>{makePrice(original.totalInvoiceAmountexclGST * ((100 - userDiscount) / 100) * (gst / 100))}</p>
              },
              width: 200
            },
            {
              id: 'Total Owing',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('subTotalMinusDiscount')
                  }}>Total Amount Owing</div>
                )
              },
              Cell: ({ original, index }) => {
                let userDiscount = 0
                if (original.invoice) {
                  userDiscount = (!original.invoice.data.auction.privateSale && original.invoice.data.user.discount) ? original.invoice.data.user.discount : 0
                }
                let gst = gstTax
                if (original.invoice.hasOwnProperty(gst)) {
                  gst = original.invoice.gst
                }
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{makePrice(original.totalInvoiceAmountexclGST * (100 - userDiscount) / 100 * (100 + gst) / 100)}</p>
                    </React.Fragment>
                  )
                }
                return <p>{makePrice(original.totalInvoiceAmountexclGST * (100 - userDiscount) / 100 * (100 + gst) / 100)}</p>
              },
              width: 200
            },
            {
              id: 'Auction Tag',
              Header: () => {
                return (
                  <div>Auction Tag</div>
                )
              },
              Cell: ({ original }) => {
                return <p>{original.invoice.data.auction.auctionTags ? original.invoice.data.auction.auctionTags.map(e => e.title).join(', ') : ''}</p>
              },
              width: 200
            }
          ]}
          defaultPageSize={this.state.defaultPageSize}
        />
      </div>
    )
  }
}
