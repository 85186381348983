import { WINDOW_MODOAL } from '../constants'

const defaultState = {
  windowPopup: { show: false, auction: {} }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case WINDOW_MODOAL:
      return {
        ...state,
        windowPopup: action.value
      }
    default:
      return state
  }
}
