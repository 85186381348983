import React, { Component } from 'react'
import pure from 'recompose/pure'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import compose from 'recompose/compose'

const styles = theme => ({
  actions: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: 20
  },
  hellip: { padding: '1.2em' }
})

export class PaginationActions extends Component {
  /**
   * Warning: material-ui's page is 0-based
   */
  constructor (props) {
    super(props)

    this.getNbPages = this.getNbPages.bind(this)
    this.prevPage = this.prevPage.bind(this)
    this.nextPage = this.nextPage.bind(this)
    this.gotoPage = this.gotoPage.bind(this)
    this.renderPageNums = this.renderPageNums.bind(this)
  }
  range () {
    const { page, rowsPerPage, count } = this.props
    const nbPages = Math.ceil(count / rowsPerPage) || 1
    if (isNaN(page) || nbPages === 1) {
      return []
    }
    let input = []
    if (nbPages < 5) {
      for (let i = 0; i < nbPages; i++) {
        input.push(i + 1)
      }
      return input
    }
    if (page >= nbPages - 4) {
      input.push(1, '.')
      for (let i = nbPages - 4; i < nbPages; i++) {
        input.push(i)
      }
    } else {
      if (page > 3) {
        input.push(1, '.', page, page + 1, page + 2, '.')
      } else {
        input.push(1, 2, 3, 4, 5, '.')
      }
    }
    input.push(nbPages)
    if (nbPages === 5) {
      input = [1, 2, 3, 4, 5]
    }
    return input
  }

  getNbPages () {
    return Math.ceil(this.props.count / this.props.rowsPerPage) || 1
  }

  prevPage (event) {
    if (this.props.page === 0) {
      throw new Error()
    }
    this.props.onChangePage(event, this.props.page - 1)
  }

  nextPage (event) {
    if (this.props.page > this.getNbPages() - 1) {
      throw new Error()
    }
    this.props.onChangePage(event, this.props.page + 1)
  }

  gotoPage (event) {
    const page = parseInt(event.currentTarget.dataset.page, 10)
    if (page < 0 || page > this.getNbPages() - 1) {
      throw new Error()
    }
    this.props.onChangePage(event, page)
  }

  renderPageNums () {
    const { classes = {} } = this.props

    return this.range().map(
      (pageNum, index) =>
        pageNum === '.' ? (
          <span key={`hyphen_${index}`} className={classes.hellip}>
            &hellip;
          </span>
        ) : (
          <Button
            className='page-number'
            color={
              pageNum === this.props.page + 1
                ? 'default'
                : 'primary'
            }
            key={pageNum}
            data-page={pageNum - 1}
            onClick={this.gotoPage}
            size='small'
          >
            {pageNum}
          </Button>
        )
    )
  }

  render () {
    const { classes = {}, page } = this.props
    const nbPages = this.getNbPages()
    if (nbPages === 1) return <div className={classes.actions} />
    return (
      <div className='pagination-pages-wrapper' >
        {page > 0 && (
          <Button
            color='primary'
            key='prev'
            onClick={this.prevPage}
            className='previous-page'
            size='small'
          >
            <ChevronLeft />
            Prev
          </Button>
        )}
        {this.renderPageNums()}
        {page !== nbPages - 1 && (
          <Button
            color='primary'
            key='next'
            onClick={this.nextPage}
            className='next-page'
            size='small'
          >
            Next
            <ChevronRight />
          </Button>
        )}
      </div>
    )
  }
}

const enhance = compose(
  pure,
  withStyles(styles)
)

export default enhance(PaginationActions)
