export default function generatePathForLot (indexes, field) {
  let path = ''

  for (let i = 0; i < indexes.length; i++) {
    if (field === 'array' && i === indexes.length - 1) {
      path += `[${indexes[i]}].value`
    } else {
      path += `.values[${indexes[i]}]`
    }
  }
  path = path.slice(1) // remove dot
  if (field && field !== 'array') {
    path += `['${field}']`
  }

  return path
}
