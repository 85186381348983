import React, { Component } from 'react'
import { dataProvider } from '../../../App'
import { OwnPagination } from '../../OwnPagination'
import { GET_LIST, Datagrid, TextField } from 'react-admin'
import { generateUserId } from '../../../utils'

export default class WatchList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      page: 1,
      perPage: 10,
      data: {},
      ids: [],
      total: 0,
      showEditModal: false,
      showDeleteModal: false,
      editValue: 0,
      workId: null
    }
    this.loadWatchList = this.loadWatchList.bind(this)
    this.setPerPage = this.setPerPage.bind(this)
    this.setPage = this.setPage.bind(this)
    this.setSort = this.setSort.bind(this)
    this.handleOpenEdit = this.handleOpenEdit.bind(this)
    this.loadWatchList()
  }

  loadWatchList () {
    dataProvider(GET_LIST, `/lots/${this.props.record._id}/watchlist`, {
      pagination: {
        page: this.state.page || 'createdAt',
        perPage: this.state.perPage
      },
      sort: {
        field: this.state.field || 'createdAt',
        order: this.state.order || 'DESC'
      }
    })
      .then(({ data, total }) => {
        let newids = []
        let newdata = {}
        for (let i = 0; i < data.length; i++) {
          let id = data[i]._id
          newids.push(id)
          newdata[id] = {
            ...data[i]
          }
        }
        this.setState({
          ids: newids,
          data: newdata,
          total
        })
      })
  }

  setPerPage (perPage) {
    this.setState({
      perPage
    }, this.loadWatchList)
  }

  setPage (page) {
    this.setState({
      page
    }, this.loadWatchList)
  }

  setSort (field) {
    let order = 'ASC'
    if (this.state.field === field) {
      order = this.state.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      field,
      order
    }, this.loadWatchList)
  }

  handleOpenEdit (record) {
    this.setState({
      showEditModal: true,
      workId: record._id,
      editValue: record.cents ? parseFloat(record.cents.slice(1)) : 0
    })
  }

  render () {
    const { ids, data } = this.state

    const IdField = ({ record = {} }) => {
      if (!record) {
        return null
      }
      return (
        <a href={`/#/users/${record._id}/show`} className='vendor-link'>
          {generateUserId(record.type, record.numericId)}
        </a>
      )
    }

    return (
      <div
        className='datagrid-wrapper datagrid-wrapper-bids'
        key='datagrid'
      >
        <Datagrid
          ids={ids}
          data={data}
          currentSort={{ field: this.state.field, order: this.state.order }}
          setSort={this.setSort}
          {...this.props}
        >
          <IdField label='Id' />
          <TextField source='firstName' />
          <TextField source='lastName' />
          <TextField source='officeAddress.town' label='Town' />
          <TextField source='officeAddress.state' label='State' />
          <TextField source='phone' />
        </Datagrid>
        <OwnPagination
          page={this.state.page}
          rowsPerPage={this.state.perPage}
          perPage={this.state.perPage}
          total={this.state.total}
          setPerPage={this.setPerPage}
          setPage={this.setPage}
        />
      </div>
    )
  }
}
