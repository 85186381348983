import React, { Component, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './AssessmentFormStyles.css'
import formatTitleForType from '../../helpers/formatTitleForType'
import Button from '@material-ui/core/Button'
import { lotHiddenFields } from '../../constants'
import moment from 'moment'

function generatePaginator (workingObject, details, indexes = []) {
  details.values.forEach((detail, index) => {
    let newIndexes = indexes.concat(index)
    if (detail.length > -1) {
      generatePaginator(workingObject, detail, newIndexes)
    }
    if (detail.type === 'group') {
      generatePaginator(workingObject, detail, newIndexes)
    } else if (detail.type === 'repeatForNumber') {
      workingObject[newIndexes] = 0
    }
  })
}

export default class LotAssessmentFormView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 1,
      paginator: {}
    }
    generatePaginator(this.state.paginator, this.props.record.details)
    this.renderGroup = this.renderGroup.bind(this)
  }

  /**
   * We should hide assessment form in case lot type = goat and countWeighed = 0
   * @param {Object} detail
   * @param {Object} kindData lot kind data
   * @param {int} countWeighed
   * @returns {boolean}
   */
  isAssessmentFormShown (detail, kindData, countWeighed) {
    return !(detail.publicId === 'assessmentGroup' && kindData.type === 'goat' && !countWeighed)
  }

  renderAdjustWeightSection (depth) {
    let { record } = this.props
    let assessedWt = record.publicDetails.weight.average.toFixed(2)
    let adjustWeight = record.adjustWeight
    let weightGain = record.weightGain
    let curfewWt = record.publicDetails.weight.curfew
    let totalWG = record.weightGainTotal || 0
    let newWeight = record.publicDetails.weight.average + totalWG
    let optiweighAllowed = !!record.optiweighAllowed

    curfewWt = curfewWt.toFixed(2)
    return (
      <Fragment key='adjust-weight-section'>
        <div className={`depth-${depth % 3}`}>
          <h3 className='dropdown-title'>{optiweighAllowed ? 'Full Weight' : 'Assessment Weight'}</h3>
          <h3 className='table-value'>{assessedWt} Kg</h3>
        </div>
        <div className={`depth-${depth % 3}`}>
          <h3 className='dropdown-title'>Adjustment (+ or -)</h3>
          <h3 className='table-value'>{adjustWeight}%</h3>
        </div>
        <div className={`depth-${depth % 3}`}>
          <h3 className='dropdown-title'>Weight Gain Adjustment</h3>
          <h3 className='table-value'>{weightGain} Kg</h3>
        </div>
        {!optiweighAllowed && <div className={`depth-${depth % 3}`}>
          <h3 className='dropdown-title'>New Assessment Weight</h3>
          <h3 className='table-value'>{newWeight.toFixed(2)} Kg</h3>
        </div>}
        <div className={`depth-${depth % 3}`}>
          <h3 className='dropdown-title'>Curfew Weight</h3>
          <h3 className='table-value'>{curfewWt} Kg</h3>
        </div>
      </Fragment>
    )
  }

  renderGroup ({ details, depth = 0, indexes = [], count, countWeighed, kindData }) {
    return (details.map((detail, index) => {
      let nodes = []
      let isHidden = lotHiddenFields.indexOf(detail.publicId) !== -1
      if (isHidden) {
        return nodes
      }
      let newIndexes = indexes.concat(index)
      if (detail.length > -1) {
        nodes.push(
          this.renderGroup({ details: detail, depth: depth + 1, indexes: newIndexes, count, countWeighed, kindData })
        )
      } else if (detail.type === 'group') {
        if (!(this.props.record.optiweighAllowed && detail.publicId === 'assessmentGroup')) {
          if (this.isAssessmentFormShown(detail, kindData, countWeighed)) {
            nodes.push(
              <div className={`depth-${depth % 3}`} key={detail.title + index}>
                <h3>{detail.title}</h3>
                <h4>{formatTitleForType(detail.type)}</h4>
                {this.renderGroup({
                  details: detail.values,
                  depth: depth + 1,
                  indexes: newIndexes,
                  count,
                  countWeighed,
                  kindData
                })}
              </div>
            )
          }
        }
      } else if (detail.type === 'repeatForNumber') {
        let page = '' + newIndexes
        if (!(kindData && kindData.type === 'goat' && !countWeighed)) {
          nodes.push(
            <div className={`depth-${depth % 3}`} key={detail.title + index}>
              <h3>{detail.title}</h3>
              <h4>{formatTitleForType(detail.type)}</h4>
              {detail.values[this.state.paginator[page]].map((row, ind) => {
                return <div className={`depth-0`} key={this.state.paginator[page] + ind}>
                  <h3 className='dropdown-title'>{row.title}</h3>
                  <FontAwesomeIcon className='is-public' icon={row.isPublic ? 'eye' : 'eye-slash'} />
                  <FontAwesomeIcon className={['is-required', row.isRequired ? 'required' : 'not-required'].join(' ')}
                    icon='asterisk' />
                  <h4 className='table-type'>{formatTitleForType(row.type)}</h4>
                  <h4 className='row-value'>{row.value}</h4>
                </div>
              })}
              <div className='pagination'>
                <h5 className='total-heads'>Total heads: {this.state.paginator[page] + 1} / {detail.values.length} </h5>
                <Button
                  variant='outlined'
                  color='primary'
                  disabled={!this.state.paginator[page] > 0}
                  onClick={() => {
                    if (this.state.paginator[page] > 0) {
                      const newPaginator = Object.assign({}, this.state.paginator)
                      newPaginator[page] = 0
                      this.setState({ paginator: newPaginator })
                    }
                  }}>
                  First Assessment
                </Button>
                <Button variant='outlined' color='primary' disabled={!this.state.paginator[page] > 0} onClick={() => {
                  if (this.state.paginator[page] > 0) {
                    this.setState({ paginator: { [page]: this.state.paginator[[page]] - 1 } })
                  }
                }}>
                  Prev
                </Button>
                <Button variant='outlined' color='primary' disabled={!detail.values[this.state.paginator[page] + 1]}
                  onClick={() => {
                    if (detail.values[this.state.paginator[page] + 1]) {
                      this.setState({ paginator: { [page]: this.state.paginator[page] + 1 } })
                    }
                  }}>
                  Next
                </Button>
                <Button
                  variant='outlined'
                  color='primary'
                  disabled={this.state.paginator[page] + 1 === detail.values.length}
                  onClick={() => {
                    if (detail.values[this.state.paginator[page] + 1]) {
                      const newPaginator = Object.assign({}, this.state.paginator)
                      newPaginator[page] = detail.values.length - 1
                      this.setState({ paginator: newPaginator })
                    }
                  }}>
                  Last Assessment
                </Button>
              </div>
            </div>
          )
        }
      } else if (detail.type === 'repeatForBool') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3>{detail.title}</h3>
            <h4>{formatTitleForType(detail.type)}</h4>
            {detail.value && this.renderGroup({
              details: detail.values,
              depth: depth + 1,
              indexes: newIndexes,
              count,
              countWeighed,
              kindData
            })}
            <h3 className='table-value'>{detail.value}</h3>
          </div>
        )
      } else if (detail.type === 'dropdown') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <h3 className='table-value'>{detail.value}</h3>
          </div>
        )
      } else if (detail.type === 'multiDropdown') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <h3 className='table-value'>{detail.value ? detail.value.join('/') : ''}</h3>
          </div>
        )
      } else if (detail.type === 'select') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <h3
              className='table-value'>{detail.value ? detail.options.find(option => option.value === detail.value).label : ''}</h3>
          </div>
        )
      } else if (detail.type === 'bool') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <h4 className='row-value'>{detail.value ? 'Yes' : 'No'}</h4>
          </div>
        )
      } else if (detail.type === 'rating') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
          </div>
        )
      } else if (detail.type === 'weight') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
          </div>
        )
      } else if (detail.type === 'text') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <h3 className='table-value'>{detail.value}</h3>
          </div>
        )
      } else if (detail.type === 'textarea') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <h3 className='table-value'>{detail.value}</h3>
          </div>
        )
      } else if (detail.type === 'date') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <h3 className='table-value'>{detail.value ? moment.utc(detail.value).format('DD/MM/YYYY') : ''}</h3>
          </div>
        )
      } else if (detail.type === 'checkbox') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <h4 className='row-value'>{detail.value ? 'Yes' : 'No'}</h4>
          </div>
        )
      } else if (detail.type === 'number') {
        if (detail.value === 'NaN') {
          return ''
        }
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <h3 className='table-value'>{detail.value}</h3>
          </div>
        )
      } else if (detail.type === 'user') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
          </div>
        )
      } else if (detail.type === 'phone') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <h3 className='table-value'>{detail.value}</h3>
          </div>
        )
      } else if (detail.type === 'email') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <h3 className='table-value'>{detail.value}</h3>
          </div>
        )
      } else if (detail.type === 'picNumber') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3 className='dropdown-title'>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
            <h3 className='table-value'>{detail.value}</h3>
          </div>
        )
      } else if (detail.type === 'tradingName') {
        nodes.push(
          <div className={`depth-${depth % 3}`} key={detail.title + index}>
            <h3>{detail.title}</h3>
            <FontAwesomeIcon className='is-public' icon={detail.isPublic ? 'eye' : 'eye-slash'} />
            <FontAwesomeIcon className={['is-required', detail.isRequired ? 'required' : 'not-required'].join(' ')}
              icon='asterisk' />
            <h4>{formatTitleForType(detail.type)}</h4>
          </div>
        )
      }

      if (detail.publicId === 'assessmentDate') {
        nodes.push(
          this.renderAdjustWeightSection(depth)
        )
      }
      return nodes
    }))
  }

  render () {
    const { record } = this.props
    const { count, countWeighed, kindData } = record

    return (
      <div className='assesstment-wrapper lot-assessment'>
        <h2>{record.details.title}</h2>
        {this.renderGroup({ details: record.details.values, count, countWeighed, kindData })}
      </div>
    )
  }
}
