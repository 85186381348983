/* global btoa Promise sessionStorage */

import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin'
import axios from 'axios'

import config from '../config'
import socketService from '../utils/socketConnection'

axios.defaults.baseURL = config.rootUrl
axios.defaults.withCredentials = true
axios.defaults.headers.common[config.adminAccess] = true

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params
    let authOptions = {
      method: 'GET',
      url: `users/me?role=admin`,
      headers: {
        'Authorization': 'Basic ' + btoa(username + ':' + password)
      }
    }
    return axios(authOptions)
      .then(function (response) {
        socketService.connection()
        return Promise.resolve()
      })
  } if (type === AUTH_LOGOUT) {
    let requestOptions = {
      method: 'GET',
      url: `logout`
    }
    return axios(requestOptions)
      .then(function (response) {
        sessionStorage.clear()
        return Promise.resolve()
      })
  } if (type === AUTH_ERROR) {
    const status = params.status || (params.response && params.response.status)
    if (status === 401 || status === 403) {
      return Promise.reject(params)
    }
    return Promise.resolve()
  } if (type === AUTH_CHECK) {
    let authOptions = {
      method: 'GET',
      url: `users/me?role=admin`
    }
    return axios(authOptions)
      .then(function (response) {
        sessionStorage.setItem('userFullName', `${response.data.user.firstName} ${response.data.user.lastName}`)
        sessionStorage.setItem('userId', `${response.data.user['_id']}`)
        return Promise.resolve()
      })
  }
  return Promise.reject(new Error('Unkown method'))
}
