import React, { Component } from 'react'
import { Circle } from 'better-react-spinkit'
import './styles.css'

class Spinner extends Component {
  render () {
    return (
      <div className='spinner-wrapper'>
        <div className='spinner'>
          <Circle size={100} />
        </div>
      </div>
    )
  }
}

export default Spinner
