import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { Collapse } from 'react-collapse'
import update from 'immutability-helper'
import {
    EditButton,
    List,
    Datagrid,
    TextField
   // ShowButton,
    //CardActions,
   // CreateButton,

} from 'react-admin'

export class Goats extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpened: false,
        }

        this.renderGoat = this.renderGoat.bind(this)
        this.changeCollapse = this.changeCollapse.bind(this)
    }

    

    renderGoat() {
        const {  ...props  } = this.props;
        return (
            <div className='datagrid-wrapper home-datagrid-wrapper'>
                <List title='Stock Category' {...props}  >
                    <Datagrid>
                        <TextField source='record.name' label='name' />
                        <TextField source='days' label='days'  />
                        <EditButton />
                    </Datagrid>
                </List>

            </div>
        )
    }


    collapsible({ title, isOpened, changeCollapse, publicId, body }) {
        return (
            <div className='collapse-wrapper'>
                <div
                    className='header'
                    onClick={isOpened ? () => (changeCollapse(publicId, false)) : () => (changeCollapse(publicId, true))}
                >
                    <p className='title'>
                        {title}
                    </p>
                    <div
                        className='button'
                    >
                        <FontAwesomeIcon icon={isOpened ? faAngleUp : faAngleDown} />
                        <span>{isOpened ? 'Hide' : 'Show'}</span>
                    </div>
                </div>
                <Collapse isOpened={isOpened}>
                    <div className='body'>
                        {body}
                    </div>
                </Collapse>
            </div>
        )
    }

    changeCollapse() {
        this.setState({
            isOpened: update(this.state.isOpened, {
                $set: !this.state.isOpened
            })
        })
    }

    render() {
        return (
            <div>
                <React.Fragment>
                    {this.collapsible({
                        title: 'Goats',
                        isOpened: this.state.isOpened,
                        changeCollapse: this.changeCollapse,
                        body: this.renderGoat()
                    })}
                </React.Fragment>
            </div>
        )
    }
}