import { GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, UPDATE } from 'react-admin'
import moment from 'moment'
import { generateUserId, makePrice, msToTime, transformBidType } from '../utils'

const convertHTTPResponse = (response, type, resource, params) => {
  let headers = response.headers
  let data = response.data
  switch (type) {
    case GET_ONE: {
      if (resource === 'auctions') {
        if (data.auction.listingFee || data.auction.listingFee === 0) {
          data.auction.listingFeeInDollars = data.auction.listingFee / 100
        }
        if (data.auction.auctionSetUpFee || data.auction.auctionSetUpFee === 0) {
          data.auction.auctionSetUpFeeInDollars = data.auction.auctionSetUpFee / 100
        }
        if (data.auction.specialPriceFee || data.auction.specialPriceFee === 0) {
          data.auction.specialPriceFeeInDollars = data.auction.specialPriceFee / 100
        }
      }
    }
    // eslint-disable-next-line no-fallthrough
    case UPDATE: {
      if (resource === 'lots') {
        let lot = data.lot
        data.lot.media = lot.media.map(media => {
          return media
        })
        data.lot._reoffered = data.lot.reofferAttempts > 0
      }
      if (resource === 'auctions' && data.auction.liveAt) {
        data.auction.localLiveAtISO = moment(new Date(data.auction.localLiveAt || data.auction.liveAt)).format('YYYY-MM-DDTHH:mm')
        if (data.auction.cancelled) {
          data.auction._state = 'Cancelled'
        } else {
          data.auction._state = data.auction.state
        }
      }
      if (data.bids) {
        return data.bids
      }
      if (data.lots) {
        return data.lots
      }
      if (params.user && resource === 'invoices') {
        data[resource] = data[resource].map(obj => {
          obj.userName = `${obj.user.firstName} ${obj.user.lastName}`
          obj.amount = obj.lots.map(lot => (lot.currentBidCents)).reduce((a, b) => a + b, 0)
          obj.amount = makePrice(obj.amount)
          obj.kind.listingPriceCents = makePrice(obj.kind.listingPriceCents)
          obj.lots = obj.lots.map(lot => {
            lot.price = obj.kind.listingPriceCents
            return lot
          })
          return obj
        })
        let response = {
          data: data.invoices,
          total: headers['x-total-count'] ? parseInt(headers['x-total-count'], 10) : data[resource].length
        }
        return response
      }
      if (resource === 'users') {
        /*
        if (!data.user.sellerType) {
          data.user.sellerType = 'independent'
        }
        */
        if (data.user.tradingName) {
          data.user.tradingName = data.user.tradingName.replace(/ +/g, " ")
        }
      }
      if (resource === 'contacts/main') {
        resource = 'contacts'
      }
      if (resource === 'invoice/template') {
        return { data }
      }
      if (resource.indexOf('emails/config') !== -1) {
        return { data }
      }
      const one = data[resource.slice(0, resource.length - 1)]
      if (one) {
        one.id = one['_id']
        if (one.officeAddress && one.postalAddress) {
          if (JSON.stringify(one.officeAddress) === JSON.stringify(one.postalAddress)) {
            one.postalAddress.sameAsOffice = true
          } else {
            one.postalAddress.sameAsOffice = false
          }
        }
      }
      return {
        data: !one ? data : one
      }
    }
    case GET_LIST:
    case GET_MANY:
      if (resource === 'users') {
        if (data.user) {
          data.user.id = data.user._id
          return { data: [data.user] }
        }
      }
    // eslint-disable-next-line no-fallthrough
    case GET_MANY_REFERENCE:
      if (params.auction && !params.userType) {
        resource = 'lots'
      }
      if (resource === 'auctions') {
        data.auctions = data.auctions.map(auction => {
          if (new Date() - new Date(auction.liveAt) > 0) {
            auction.liveTime = msToTime(new Date() - new Date(auction.liveAt))
          } else if (new Date() - new Date(auction.liveAt) <= 0) {
            auction.liveTime = '00:00'
          }
          if (new Date() - new Date(auction.openAt) > 0) {
            auction.openTime = msToTime(new Date() - new Date(auction.openAt))
          } else if (new Date() - new Date(auction.openAt) <= 0) {
            auction.openTime = '00:00'
          }
          if (auction.lotInfo) {
            auction.lotsOpen = auction.lotInfo.state.open.length
            auction.lotsClosed = auction.lotInfo.state.closed.length
          }
          if (auction.state) {
            if (auction.cancelled) {
              auction._state = 'Cancelled'
            } else {
              auction._state = auction.state.toUpperCase()
            }
          }
          return auction
        })
      }
      if (resource === 'lots' && !params.isAuctionBuyers && !params.isAuctionVendors && !params.isAuctionBids) {
        data.lots = data.lots.map((lot) => {
          lot._currentPrice = makePrice(lot.currentBidCents, { bidding: lot.bidding })
          lot._closedPrice = makePrice(lot.closedPrice, { bidding: lot.bidding })
          lot._startPrice = makePrice(lot.startPrice, { bidding: lot.bidding })
          lot._reserveCents = makePrice(lot.reserveCents, { bidding: lot.bidding })
          return lot
        })
      }
      if (resource === 'admin-lots' || params.isUserLots) {
        resource = 'lots'
        data[resource].map(el => {
          el._pending = !el.approved && !el.draft
          if (!el.startPrice) {
            el._startPrice = 0
          } else {
            el._startPrice = makePrice(el.startPrice, { bidding: el.bidding })
          }
          if (!el.closedPrice) {
            el._closedPrice = 0
          } else {
            el._closedPrice = makePrice(el.closedPrice, { bidding: el.bidding })
          }
          el._bidIncrementCents = makePrice(el.bidIncrementCents, { bidding: el.bidding })
          el._approved = !el.approved
          el._reserveCents = makePrice(el.reserveCents, { bidding: el.bidding })
          el._currentBid = makePrice(el.currentBidCents, { bidding: el.bidding })
          el._leftTime = moment(el.finishAt).diff()
          el._reoffered = el.reofferAttempts > 0
          el.createdAt = moment(el.createdAt).format('D/MM/YYYY, hh:mm:ss A')
          el._auctionDate = moment(el.auctionData.liveAt).format('D/MM/YYYY, hh:mm:ss A')
          return el
        })
      }
      if (resource === 'bids') {
        data[resource] = data[resource].map(el => {
          el.cents = makePrice(el.cents, { bidding: el.bidding })
          el.type = transformBidType(el)
          return el
        })
      }
      if (resource === 'invoices') {
        data[resource] = data[resource].map(obj => {
          if (obj.data.user) {
            obj.userName = `${obj.data.user.firstName} ${obj.data.user.lastName}`
          }
          obj.lots = obj.data.lots
          obj.number = `FGI${obj.number}`
          obj.status = obj.status.toUpperCase()
          obj.lots = obj.lots.map(lot => {
            lot.price = makePrice(obj.priceRate)
            lot.customStatus = lot.status
            return lot
          })
          obj.lots.unshift({
            count: obj.lotTotalCount,
            price: makePrice(obj.priceRate),
            totalCount: true
          })
          return obj
        })
      }
      if (resource === 'users') {
        data[resource] = data[resource].map(el => {
          el._newId = generateUserId(el.type, el.numericId)
          // el.createdAt = moment(el.createdAt).format('D/MM/YYYY, hh:mm:ss A')
          if (!el.connectivity) {
            el.connectivity = {}
          }
          if (el.connectivity.createdAt) {
            el.createdAt = moment(el.createdAt).format('D/MM/YYYY, hh:mm:ss A')
          }
          return el
        })
      }
      if (resource === 'connectivities') {
        data.connectivities = data.connectivities.map(el => {
          return el
        })
      }
      if (params.auction && params.userType) {
        resource = 'users'
      }
      if (params.isAuctionBuyers) {
        resource = 'buyers'
        data[resource] = data[resource].map(el => {
          if (el.lastBid) {
            el._price = makePrice(el.lastBid.cents, { bidding: el.lastBid.bidding })
          } else {
            el._price = ''
          }

          if (el.user && el.user.connectivity && el.user.connectivity.platforms) {
            let _platforms = []

            for (let platform in el.user.connectivity.platforms) {  // eslint-disable-line
              _platforms.push({
                platformName: platform,
                state: el.user.connectivity.platforms[platform].state,
                timeConnected: el.user.connectivity.platforms[platform].timeConnected,
                timeDisconnected: el.user.connectivity.platforms[platform].timeDisconnected
              })
            }
            el._platforms = _platforms
          }

          return el
        })
      }
      if (params.isAuctionVendors) {
        resource = 'lots'
        data[resource] = data[resource].map(el => {
          el._reservePrice = makePrice(el.reserveCents, { bidding: el.bidding })
          el._startPrice = makePrice(el.startPrice, { bidding: el.bidding })
          return el
        })
      }
      if (params.lotsType) {
        resource = 'lots'
      }
      if (params.isAuctionBuyers) {
        return {
          data: data[resource].map(element => ({
            ...element,
            id: resource === 'tags' ? element.title : element._id
          })),
          total: headers['x-total-count'] ? parseInt(headers['x-total-count'], 10) : data[resource].length,
          totalAnonymous: data.anonymousUsers ? data.anonymousUsers : 0
        }
      }
      if (resource === 'lots' && params.isAuctionBids) {
        data.bids = data.bids.map(el => {
          el.type = transformBidType(el)
          return el
        })
        return {
          data: data.bids.map(element => ({ ...element, id: resource === 'tags' ? element.title : element._id })),
          total: headers['x-total-count'] ? parseInt(headers['x-total-count'], 10) : data[resource].length
        }
      } else if (resource.indexOf('report=true') !== -1) {
        return {
          data: data.users.map(element => ({ ...element, id: resource === 'tags' ? element.title : element._id })),
          total: headers['x-total-count'] ? parseInt(headers['x-total-count'], 10) : data.users.length,
          distincted: data.distincted || 0
        }
      } else if (resource === '/lots/reports') {
        return {
          data: data.lots,
          total: headers['x-total-count'] ? parseInt(headers['x-total-count'], 10) : data.lots.length,
          distincted: data.distincted || 0
        }
      } else if (resource.indexOf('/auctions') !== -1) {
        return {
          data: data.auctions.map(element => ({
            ...element,
            id: resource === 'tags' ? element.title : element._id
          })),
          total: headers['x-total-count'] ? parseInt(headers['x-total-count'], 10) : data.auctions.length,
          totalData: data.total
        }
      } else if (resource.includes('/view-lot-report')) {
        return {
          data: data.views.map(element => ({ ...element, id: resource === 'tags' ? element.title : element._id })),
          total: headers['x-total-count'] ? parseInt(headers['x-total-count'], 10) : data.auctions.length
        }
      } else if (resource.includes('lot-invoice-report')) {
        return {
          data: data.views.map(element => ({ ...element, id: resource === 'tags' ? element.title : element._id })),
          total: headers['x-total-count'] ? parseInt(headers['x-total-count'], 10) : data.auctions.length
        }
      } else if (resource.match(/(lots.).{24}(.watchlist)/mg)) {
        return {
          data: data.users.map(element => ({ ...element, id: resource === 'tags' ? element.title : element._id })),
          total: headers['x-total-count'] ? parseInt(headers['x-total-count'], 10) : data.users.length
        }
      } else if (resource.match(/(auctions.).{24}(.preBid)/mg)) {
        return {
          data: data.prebids.map(element => ({
            ...element,
            id: resource === 'tags' ? element.title : element._id
          })),
          total: headers['x-total-count'] ? parseInt(headers['x-total-count'], 10) : data.prebids.length
        }
      } else {
        return {
          data: data[resource].map(element => ({
            ...element,
            id: resource === 'auction-tags' ? element.title : element._id
          })),
          total: headers['x-total-count'] ? parseInt(headers['x-total-count'], 10) : data[resource].length,
          distincted: data.distincted || 0
        }
      }

    default:
      return { data }
  }
}

export default convertHTTPResponse
