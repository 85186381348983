/* global sessionStorage */
import React from 'react'
import { Form } from 'redux-form'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import debounce from 'lodash/debounce'
import DeleteIcon from '@material-ui/icons/Delete'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './AdminComments.scss'

export default class AdminComments extends Form {
  constructor (props, context) {
    super(props, context)
    this.state = {
      adminComments: [],
      isDelete: false,
      changedComment: false
    }
    this.debounced = null
    this.reduxForm = context._reduxForm
  }

  componentDidMount () {
    if (this.props) {
      this.setState({
        adminComments: this.props.record.adminComments
      })
    }
  }

  componentWillReceiveProps (nextProps, prevProps) {
    let adminComments
    if (this.state.changedComment) {
      adminComments = prevProps._reduxForm.values.adminComments
    } else if (this.state.isDelete) {
      adminComments = prevProps._reduxForm.values.adminComments
    } else {
      adminComments = nextProps.record.adminComments
    }
    this.setState({
      adminComments
    })
  }

  updateAdminComments = () => {
    const { adminComments } = this.state
    this.reduxForm.dispatch(this.reduxForm.change('adminComments', adminComments))
  }

  handleEditComment = (value, i) => {
    let { adminComments } = this.state
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.updateAdminComments, 300)
    adminComments[i].updatedAt = new Date()
    adminComments[i].comment = value
    this.setState({
      adminComments,
      isDelete: false,
      changedComment: true
    }, this.debounced)
  }

  handleDeleteComment = (i) => {
    let { adminComments } = this.state
    adminComments = adminComments.filter((comment, index) => {
      return index !== i
    })
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.updateAdminComments, 300)
    this.setState({
      adminComments,
      isDelete: true,
      changedComment: false
    }, this.debounced)
  }

  handleAddComment = () => {
    const { adminComments } = this.state
    const userId = sessionStorage.getItem('userId')
    this.debounced = debounce(this.updateAdminComments, 300)
    adminComments.push({
      author: {
        firstName: '',
        lastName: '',
        _id: userId
      },
      comment: '',
      createdAt: new Date(),
      updatedAt: new Date()
    })
    this.setState({
      adminComments,
      isDelete: false,
      changedComment: true
    }, this.debounced)
  }

  render () {
    const { adminComments } = this.state
    return (
      <div className='admin-notes'>
        <p className='admin-notes-title'>Admin Notes:</p>
        {adminComments && adminComments.map((comment, i) => {
          return (
            <div key={i} className='admin-notes-comment'>
              <TextField value={comment.comment} label={`Add New Note`} onChange={(e) => {
                this.handleEditComment(e.target.value, i)
              }} />

              <Button className='admin-note-delete'
                onClick={() => { this.handleDeleteComment(i) }}>
                <DeleteIcon />
                Delete
              </Button>
            </div>
          )
        })}
        <Button className='admin-note-add' onClick={this.handleAddComment}>
          <FontAwesomeIcon style={{
            paddingRight: '2px'
          }} icon={faPlusCircle} />
          Add
        </Button>
      </div>
    )
  }
}
