export const approvedOptions = [
  { id: false, name: 'Not Approved' },
  { id: true, name: 'Approved' }
]

export const draftOptions = [
  { id: false, name: 'Not in draft' },
  { id: true, name: 'In draft' }
]

export const removedOptions = [
  { id: false, name: 'No' },
  { id: true, name: 'Yes' }
]

export const soldAfterAuctionOptions = [
  { id: false, name: 'No' },
  { id: true, name: 'Yes' }
]

export const withdrawnOptions = [
  { id: false, name: 'No' },
  { id: true, name: 'Yes' }
]

export const acceptenceOnTerms = [
  { id: false, name: 'Not accepted' },
  { id: true, name: 'Accepted' }
]

export const biddingOptions = [
  { id: 'head', name: 'Head' },
  { id: 'kg', name: 'Kg' }
]
