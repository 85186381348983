import React, { Component } from 'react'

import DebounceField from './DebounceField'
import compareArrays from '../../../helpers/compareArrays'

export default class FormOptionsField extends Component {
  constructor (props) {
    super(props)

    this.onChange = props.onChange
  }

  shouldComponentUpdate (nextProps) {
    return this.props.option._shortId !== nextProps.option._shortId || compareArrays(this.props.newIndexes, nextProps.newIndexes)
  }

  render () {
    return (
      <DebounceField
        onChange={this.onChange}
        value={this.props.option.label}
        newIndexes={this.props.newIndexes}
        lastArrayName='options'
        passObject={this.props.option}
        field={this.props.newIndexes[this.props.newIndexes.length - 1]}
        type='text'
      />
    )
  }
}
