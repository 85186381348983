import React from 'react'
import ReactDropzone from 'react-dropzone'
import { Form } from 'redux-form'
import '../../DocumentsInputField/DocumentsInputField.scss'
import './UsefulLinks.scss'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextField from '@material-ui/core/TextField'
import debounce from 'lodash/debounce'
import adobeReaderIcon from '../../../assets/img/adobe-reader-icon.jpg'

const ImagePreview = ({ document }) => {
  if (document.rawFile) {
    return (<img className='document-image' alt='pdf' src={adobeReaderIcon} style={{ paddingLeft: '10px', width: '50px', display: 'block' }} />)
  } else {
    return (<img className='document-image' alt='pdf' src={adobeReaderIcon} style={{ paddingLeft: '10px', width: '50px', display: 'block' }} />)
  }
}

export default class PdfField extends Form {
  constructor (props, context) {
    super(props, context)
    this.reduxForm = context._reduxForm
    let documents = []
    let showImagePlaseHolder = false
    this.field = 'usefulPdfs'
    this.isOne = props.isOne || false

    this.state = { documents, showImagePlaseHolder, isDeleted: false }
    this.debounced = null

    this.deleteItem = this.deleteItem.bind(this)
    this.onDrop = this.onDrop.bind(this)
  }

  deleteItem (newDocument) {
    let documents = Object.assign([], this.state.documents)
    documents.forEach((document, index) => {
      if (document.name === newDocument.name) {
        documents.splice(index, 1)
      }
    })
    if (this.isOne) {
      this.reduxForm.dispatch(this.reduxForm.change(this.field, null))
    } else {
      this.reduxForm.dispatch(this.reduxForm.change(this.field, documents))
    }
    this.setState({ documents })
  }

  onDrop (files) {
    if (files.length) {
      let documents = Object.assign([], this.state.documents)
      let isSameName = false
      documents.forEach((document) => {
        if (files[0].name === document.name) {
          isSameName = true
        }
      })
      if (!isSameName) {
        let file = files[0]
        if (!file.type) {
          let mime = 'application/pdf'
          var newFilef = new window.File([file], file.name, { type: mime, lastModified: new Date() })
          documents.push({ rawFile: newFilef, name: newFilef.name })
          if (this.isOne) {
            documents[0] = { rawFile: newFilef, name: newFilef.name }
            this.reduxForm.dispatch(this.reduxForm.change(this.field, documents[0]))
          } else {
            documents.push({ rawFile: newFilef, name: newFilef.name })
            this.reduxForm.dispatch(this.reduxForm.change(this.field, documents))
          }
          this.setState({ documents })
        } else {
          if (this.isOne) {
            documents[0] = { rawFile: file, name: file.name }
            this.reduxForm.dispatch(this.reduxForm.change(this.field, documents[0]))
          } else {
            documents.push({ rawFile: file, name: file.name })
            this.reduxForm.dispatch(this.reduxForm.change(this.field, documents))
          }
          this.setState({ documents })
        }
      }
    }
  }

  onChangeDescription (event, index) {
    let value = event.target.value
    let documents = Object.assign([], this.state.documents)
    documents[index].description = value
    this.setState({ documents })
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(() => {
      this.reduxForm.dispatch(this.reduxForm.change('documents', documents))
    }, 300)
  }

  render () {
    return (
      <div className='useful-link-upload-pdf'>
        <ReactDropzone
          disabled={this.props.record.state === 'closed'}
          accept={'.pdf'}
          multiple={false}
          onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => {
            return (
              <div {...getRootProps()}
                className='drop-zone'>
                <input {...getInputProps()} />
                <p>Drop your PDF file here</p>
              </div>
            )
          }}
        </ReactDropzone>
        {this.state.documents && this.state.documents.length
          ? this.state.documents.map((document, index) => {
            return (
              <React.Fragment key={index}>
                <div className='item'>
                  <div className='leftSide'>
                    <FontAwesomeIcon
                      className='delete-btn'
                      onClick={() => { this.deleteItem(document) }}
                      icon={faMinusCircle} />

                    <p>{document.name}</p>
                    <ImagePreview document={document} />
                  </div>
                  {
                    this.state.showImagePlaseHolder
                      ? null
                      : <TextField
                        className='useful-link-text-description'
                        name='minutes'
                        style={{ width: '85px' }}
                        onChange={(event) => { this.onChangeDescription(event, index) }}
                        onBlur={this.onBlur}
                        label={'Description'}
                        value={document.description}
                        type='text'
                        InputProps={{ inputProps: { min: 0, max: 59 } }}
                      />
                  }
                </div>
                <hr />
              </React.Fragment>
            )
          })
          : null}
      </div>
    )
  }
}
