export const selectableTypes = [
  'bool',
  'text',
  'weight',
  'rating',
  'textarea',
  'date',
  'checkbox',
  'number',
  'dropdown',
  'multiDropdown',
  'select',
  'email',
  'phone',
  'picNumber',
  'tradingName',
  'repeatForBool',
  'group'
]

export default function formatTitleForType (type) {
  switch (type) {
    case 'bool':
      return 'Yes / No'
    case 'text':
      return 'Text'
    case 'weight':
      return 'Weight'
    case 'rating':
      return 'Rating'
    case 'textarea':
      return 'Textarea'
    case 'date':
      return 'Date'
    case 'checkbox':
      return 'Checkbox'
    case 'number':
      return 'Number'
    case 'user':
      return 'User'
    case 'dropdown':
      return 'Dropdown'
    case 'multiDropdown':
      return 'Dropdown (multi)'
    case 'select':
      return 'Select'
    case 'phone':
      return 'Phone Number'
    case 'email':
      return 'Email Address'
    case 'picNumber':
      return 'PIC Number'
    case 'tradingName':
      return 'Trading Name'
    case 'repeatForBool':
      return 'Accordion'
    case 'repeatForNumber':
      return 'Individual Assessment'
    case 'group':
      return 'Group'
    default:
      return type
  }
}
