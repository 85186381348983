import { WINDOW_MODOAL } from '../constants'

export function changeWindowModal (data) {
  return dispatch => {
    dispatch({
      type: WINDOW_MODOAL,
      value: data
    })
  }
}
