/* eslint camelcase: 0 */
import React, { Component } from 'react'
import DebounceBooleanField from './DebounceBooleanField'

export default class CheckboxField extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: this.props.value
    }
    this.onChange = props.onChange
    this.UNSAFE_componentWillReceiveProps = this.UNSAFE_componentWillReceiveProps.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value })
    }
  }

  render () {
    return (
      <DebounceBooleanField
        style={this.props.style}
        disabled={this.props.disabled}
        onChange={this.onChange}
        checked={this.state.value}
        newIndexes={this.props.newIndexes}
        field='value'
      />
    )
  }
}
