import React from 'react'
import { Form } from 'redux-form'
import IconPlus from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import MultipleSelect from '../../MultipleSelectComponent/MultipleSelect'
import { TextInput } from 'react-admin'
import axios from 'axios'
import './AuctionTags.scss'

export class AuctionTags extends Form {
  constructor (props, context) {
    super(props, context)

    this.state = {
      showSelect: true,
      newTag: '',
      auctionTags: []
    }
  }

  componentDidMount () {
    axios.get('/auction-tags').then((response) => {
      this.setState({ auctionTags: response.data['auction-tags'] })
    })
  }

  addTag = (event) => {
    this.setState({ newTag: event.currentTarget.value })
  }

  onClickAdd = () => {
    if (this.state.newTag !== '') {
      this.setState({ auctionTags: [...this.state.auctionTags, { title: this.state.newTag }] })
    }
  }

  render () {
    return (
      <div className='auction-tag-container'>
        <label className='tag-main-label' htmlFor='auctionTags-select'>Select Tag</label>
        <MultipleSelect id='auctionTags-select' className='auctionTags-select' record={this.props.record} options={this.state.auctionTags} />
        <TextInput className='new-tag-input' label='New Tag' onChange={this.addTag} />

        <label className='plus-icon-wrapper'>
          <IconButton onClick={this.onClickAdd} color='primary' aria-label='upload picture' component='span'>
            <IconPlus />
          </IconButton>
        </label>
      </div>
    )
  }
}
