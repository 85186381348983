import React from 'react'

import { msToTime } from './index'

export const TimeField = ({ record = {}, source }) => {
  if (record[source]) {
    return (<div style={{ width: '60px' }}>{msToTime(record[source])}</div>)
  } else {
    return null
  }
}

export const generateStatusString = (string = '') => {
  if (string === 'soldAfterAuction') {
    return 'Sold AA'
  }
  return string.match(/[A-Z]*[^A-Z]+/g).map(word => word[0].toUpperCase() + word.slice(1)).join(' ')
}

export const generateLotStatusString = (string = '') => {
  if (string === 'soldAfterAuction') {
    return 'Sold AA'
  }
  if (string === 'sold') {
    return 'Sold'
  }
  if (string === 'passed') {
    return 'Passed In'
  }
  if (string === 'withdrawn') {
    return 'Withdrawn'
  }
  if (string === 'noBid') {
    return 'No Bid'
  }
  return string.match(/[A-Z]*[^A-Z]+/g).map(word => word[0].toUpperCase() + word.slice(1)).join(' ')
}

export const StatusField = ({ record = {}, source }) => {
  if (record[source]) {
    return (<span>{generateStatusString(record[source])}</span>)
  } else {
    return null
  }
}

export const FullNameField = ({ record = {} }) => {
  if (record.firstName) {
    return (<span>{record.firstName} {record.lastName}</span>)
  } else {
    return null
  }
}
