import React from 'react'
import ActionButton from '../../../../components/ActionButton'
import {
  addMinutesToActiveLots,
  editAuctionLotMaxSecond,
  freezeAuction,
  pauseAuction
} from '../../configurations/model'

export const CustomPauseButton = ({ record = {}, props }) => {
  return (
    <ActionButton
      onClick={pauseAuction}
      data={props}
      variant={record.pausedTime ? 'contained' : 'outlined'}
      color='primary'
    >
      Pause Auction
    </ActionButton>
  )
}

export const CustomFreezeButton = ({ record = {}, props }) => {
  return (
    <ActionButton
      onClick={freezeAuction}
      data={props}
      variant={record.frozenTime ? 'contained' : 'outlined'}
      color='primary'
    >
      Freeze Auction
    </ActionButton>
  )
}

export const CustomAddMinuteToActiveLots = ({ record = {}, props, minutes }) => {
  return (
    <ActionButton
      onClick={(props) => { return addMinutesToActiveLots(props, minutes) }}
      data={props}
      variant='outlined'
      color='primary'
    >
      
      {minutes >= 0 ? 'Add' : 'Minus'} {Math.abs(minutes)}  Min to all OPEN Lot Timers
    </ActionButton>
  )
}

export const CustomEditMinuteToAuctionLotMaxTime = ({ record = {}, props, minutes }) => {
  return (
    <ActionButton
      onClick={(props) => { return editAuctionLotMaxSecond(props, minutes) }}
      data={props}
      variant='outlined'
      color='primary'
    >
      {minutes >= 0 ? 'Add Lot Max' : 'Minus Lot Max'} {Math.abs(minutes)} Min
    </ActionButton>
  )
}
