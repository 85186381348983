import React, { Component, Fragment } from 'react'
import { generateUserId, makePrice } from '../../../../utils'
import classNames from 'classnames'
import moment from 'moment'
import { OwnPagination } from '../../../OwnPagination'
import { dataProvider } from '../../../../App'
import update from 'immutability-helper'
import { GET_LIST, Datagrid, TextField, UPDATE } from 'react-admin'
import MaterialTextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import config from '../../../../config'
import { bidTypes } from '../../../../constants'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Input from '@material-ui/core/Input'
import debounce from 'lodash/debounce'
import { collapsible } from '../../../../utils/auction/show/collapsible'

export class Bids extends Component {
  constructor (props) {
    super(props)
    this.state = {
      bids: {
        data: {},
        isOpened: false,
        page: 1,
        perPage: 10,
        total: 0,
        field: 'createdAtAndType',
        order: 'DESC'
      },
      editValue: { biddingType: '', value: 0 },
      bidUpdateId: null,
      showEditModal: false
    }
    this.loadBids()
  }

  loadBids = async () => {
    dataProvider(GET_LIST, `bids`, {
      filter: this.filter,
      pagination: {
        page: this.state.bids.page,
        perPage: this.state.bids.perPage
      },
      sort: {
        field: this.state.bids.field,
        order: this.state.bids.order
      },
      auctionId: this.props.auctionId,
      isAuctionBids: true,
      search: this.state.searchBids,
      auction: true
    })
      .then(({ data, total }) => {
        let newids = []
        let newdata = {}
        for (let i = 0; i < data.length; i++) {
          let id = data[i]._id
          newids.push(id)
          newdata[id] = {
            ...data[i]
          }
        }
        this.setState({
          bids: update(this.state.bids, {
            data: {
              $set: newdata
            },
            total: {
              $set: total
            }
          })
        })
      })
  }

  setPerPageBids = (perPage) => {
    this.setState({
      bids: update(this.state.bids, {
        perPage: { $set: perPage }
      })
    }, this.loadBids)
  }

  setPageBids = (page) => {
    this.setState({
      bids: update(this.state.bids, {
        page: { $set: page }
      })
    }, this.loadBids)
  }

  changeCollapse = (state) => {
    this.setState({
      bids: update(this.state.bids, {
        isOpened: { $set: state }
      })
    })
  }

  setSearchBidsString = ({ target: { value } }) => {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.loadBids, 300)
    this.setState({
      searchBids: value
    }, this.debounced)
  }

  renderBids () {
    const IdField = ({ record = {} }) => {
      if (!record.createdBy) {
        return null
      }
      return (
        <a
          href={`/#/users/${record.createdBy._id}/show`}
          className='vendor-link'
        >
          {generateUserId(record.createdBy.type, record.createdBy.numericId)}
        </a>
      )
    }
    const UserField = ({ record = {} }) => {
      if (!record.createdBy) {
        return null
      }
      return (
        <a
          href={`/#/users/${record.createdBy._id}/show`}
          className='vendor-link'
        >
          {record.createdBy.firstName} {record.createdBy.lastName}
        </a>
      )
    }
    const LotField = ({ record = {} }) => {
      return (
        <span>
          {record.lot &&
          <a href={`/#/lots/${record.lot._id}/show`} className='vendor-link'>{record.lot.publicDetails.title}</a>
          }
        </span>
      )
    }
    const LotNumberField = ({ record = {} }) => {
      return (
        <span>
          {record.lot &&
          <a href={`/#/lots/${record.lot._id}/show`} className='vendor-link'>{record.lot.number}</a>
          }
        </span>
      )
    }
    const LotStatusField = ({ record }) => {
      let highlight
      let reserveStatus = record.reserveStatus
      highlight = classNames('status-field', {
        green: reserveStatus === 'onMarket',
        white: reserveStatus === 'notNear' || reserveStatus === 'awaitingBids',
        lime: reserveStatus === 'nearReserve'
      })
      if (record.withdrawn) {
        reserveStatus = 'withdrawn'
      }
      switch (reserveStatus) {
        case 'onMarket':
          return (
            <span className={highlight}> On Market </span>
          )
        case 'notNear':
          return (
            <span className={highlight}> Opening Bid </span>
          )
        case 'awaitingBids':
          return (
            <span className={highlight}> Awaiting Bids </span>
          )
        case 'nearReserve':
          return (
            <span className={highlight}> Nearing Reserve </span>
          )
        case 'upcoming':
          return (
            <span> Upcoming </span>
          )
        case 'live':
          return (
            <span className={highlight}> Live </span>
          )
        default:
          return null
      }
    }
    const LotSearchNumberField = ({ record = {} }) => {
      return (
        <span>
          {record.lot && record.lot.searchNumericId &&
          <a href={`/#/lots/${record.lot._id}/show`} className='vendor-link'>{record.lot.searchNumericId}</a>
          }
        </span>
      )
    }
    // const WithDrawTextField = ({ record = {} }) =>{
    //   return (
    //     <TextField record={record} source='status'  sortable={false}  />
    //   )
    // } 
    const EditBidButton = ({ record = {} }) => <Button disabled = {record.status === 'withdrawn'? true : false}  onClick={() => { this.handleOpenEdit(record) }}> Edit </Button>
    const DateFiled = (data) => <span>{moment(data.record.createdAt).format('YYYY/MM/DD hh:mm:ss')}</span>
    const PriceField = ({ record = {} }) => <span> {makePrice(record.cents, { bidding: record.bidding })} </span>
    const bidRowStyle = (record, index) => ({
      "text-decoration" : record.status === 'withdrawn'  ? 'line-through' : '',
      "text-decoration-thickness" : "1.5px"
   });
    let { data } = this.state.bids
    let ids = Object.keys(data)
    return (
      <div className='info-table-div-wrapper'>
        <MaterialTextField
          label='Search'
          placeholder='Search'
          className='search-text'
          onChange={this.setSearchBidsString}
        />
        <Datagrid
          ids={ids}
          data={data}
          currentSort={{}}
          rowStyle={bidRowStyle}
        >
          <IdField label='Id' sortable={false} />
          <UserField label='Name' sortable={false} />
          <LotSearchNumberField label='Lot ID' sortable />
          <LotNumberField label='Lot No' sortable />
          <LotField label='Lot Title' sortable={false} />
          <LotStatusField label='Lot Status' sortable={false} />
          <DateFiled source='Bid created at' />
          <PriceField source='cents' label='Bid Amount' sortable={false} />
          <TextField source='type' label='Type' sortable={false} />
          <EditBidButton label='Edit' />
          {/* <WithDrawTextField label='Bid Status' sortable={false}/> */}
        </Datagrid>
        <OwnPagination
          page={this.state.bids.page}
          perPage={this.state.bids.perPage}
          total={this.state.bids.total}
          setPerPage={this.setPerPageBids}
          setPage={this.setPageBids}
        />
      </div>
    )
  }

  handleOpenEdit = (record) => {
    this.setState({
      showEditModal: true,
      bidUpdateId: record._id,
      editValue: { value: record.cents, biddingType: record.bidding }
    })
  }

  handleCloseEdit = () => {
    this.setState({
      showEditModal: false
    })
  }

  handleUpdate = () => {
    dataProvider(UPDATE, 'bids', {
      id: this.state.bidUpdateId,
      data: {
        bid: {
          cents: parseFloat((this.state.editValue.value)) // convert to cents
        }
      }
    })
      .then(this.loadBids)
      .catch((error) => {
        this.props.showNotification(error.message, 'warning')
      })
      .then(() => {
        this.setState({
          showEditModal: false
        })
      })
  }

  render () {
    let value = this.state.editValue.biddingType === bidTypes.KG ? this.state.editValue.value : this.state.editValue.value / 100

    return (
      <Fragment>
        <Dialog
          open={this.state.showEditModal}
          keepMounted
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
          key='edit-dialog'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            Edit Bid
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description' />
            {this.state.editValue.biddingType === bidTypes.KG ? '¢' : '$'}
            <Input
              value={value}
              placeholder='Bid Amount'
              name='Bid Amount'
              onChange={({ target: { value } }) => {
                let newValue = this.state.editValue.biddingType === bidTypes.KG ? value : value * 100

                console.log({
                  editValue: { ...this.state.editValue, value: newValue }
                })
                this.setState({
                  editValue: { ...this.state.editValue, value: newValue }
                })
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseEdit} color='secondary'>
              Close
            </Button>
            <Button onClick={this.handleUpdate} color='primary'>
              Update
            </Button>
          </DialogActions>
        </Dialog>
        <div id='collapse-content-bids'>
          {collapsible({
            title: 'Bids',
            exportLink: `${config.rootUrl}exports/bids?${config.adminAccess}=true&auctionId=${this.props.auctionId}`,
            isOpened: this.state.bids.isOpened,
            changeCollapse: this.changeCollapse,
            body: this.renderBids()
          })}
        </div>
      </Fragment>
    )
  }
}
