import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import compareArrays from '../../../helpers/compareArrays'

export default class FormAddButton extends Component {
  constructor (props) {
    super(props)

    this.onClick = props.addSection
  }

  shouldComponentUpdate (nextProps) {
    return compareArrays(this.props.indexes, nextProps.indexes)
  }

  render () {
    return (
      <FontAwesomeIcon
        className='add-button'
        icon='plus-square'
        onClick={() => this.onClick({ indexes: this.props.indexes, field: this.props.field })}
      />
    )
  }
}
