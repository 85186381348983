/* global Promise */
import axios from 'axios'
import config from '../config'

const { maxDocumentLimit, maxMediaLimit } = config.lot

export default async function (resource, params) {
  let data = []
  let basePath
  if (resource.indexOf('emails/config') !== -1) {
    basePath = resource
  } else {
    basePath = `${resource}/${params.id}`
  }
  try {
    if (resource === 'kinds') {
      if (params.icon && params.icon.rawFile) {
        data.push({
          data: params.data.icon.rawFile,
          url: `${basePath}/icon`,
          method: 'POST'
        })
      }
    }
    if (resource === 'auctions') {
      if (params.image && params.image.rawFile) {
        data.push({
          data: params.image.rawFile,
          url: `${basePath}/image`,
          method: 'POST'
        })
      }
      if (params.logo && params.logo.rawFile) {
        data.push({
          data: params.logo.rawFile,
          url: `${basePath}/logo`,
          method: 'POST'
        })
      }
      if (params.logoIos && params.logoIos.rawFile) {
        data.push({
          data: params.logoIos.rawFile,
          url: `${basePath}/logo-ios`,
          method: 'POST'
        })
      }

      if (!params.logo || !params.image) {
        let oldAuction = await axios.get(`/auctions/${params.id}`)
        if (oldAuction.data.auction.logo && !params.logo) {
          data.push({
            url: `/auctions/${params.id}/logo`,
            method: 'DELETE'
          })
        }
        if (oldAuction.data.auction.image && !params.image) {
          data.push({
            url: `/auctions/${params.id}/image`,
            method: 'DELETE'
          })
        }
      }
      if (!params.logoIos) {
        let oldAuction = await axios.get(`/auctions/${params.id}`)
        if (oldAuction.data.auction.logoIos && !params.logoIos) {
          data.push({
            url: `/auctions/${params.id}/logo-ios`,
            method: 'DELETE'
          })
        }
      }

      if (params.data.usefulPdfs && params.data.usefulPdfs.length > maxDocumentLimit) {
        return new Promise((resolve, reject) => {
          reject(new Error(`maximum number of documents = ${maxDocumentLimit}`))
        })
      }

      if (params.data.usefulPdfs && params.data.usefulPdfs.length <= maxDocumentLimit) {
        let getNextOrder = () => {
          let maxOrder = Math.max(...params.data.usefulPdfs.map(doc => {
            if (doc.hasOwnProperty('order')) {
              return doc.order
            } else {
              return 0
            }
          }))
          return maxOrder >= 0 ? maxOrder + 1 : 0
        }
        params.data.usefulPdfs.forEach((newDocument) => {
          if (newDocument.rawFile) {
            data.push({
              data: newDocument.rawFile,
              fileOrder: getNextOrder(),
              url: `/auctions/${params.id}/usefulPdfs`,
              fileDescription: newDocument.description || '',
              method: 'POST'
            })
          }
        })
      }
    }

    if (resource.match(/(auctions.).{24}(.lots)/mg)) {
      let oldLot
      if (params.data.usefulPdfs && params.data.usefulPdfs.length <= maxDocumentLimit) {
        let getNextOrder = () => {
          let maxOrder = Math.max(...params.data.usefulPdfs.map(doc => {
            if (doc.hasOwnProperty('order')) {
              return doc.order
            } else {
              return 0
            }
          }))
          return maxOrder >= 0 ? maxOrder + 1 : 0
        }
        params.data.usefulPdfs.forEach((newDocument) => {
          if (newDocument.rawFile) {
            data.push({
              data: newDocument.rawFile,
              fileOrder: getNextOrder(),
              url: `/lots/${params.id}/usefulPdfs`,
              fileDescription: newDocument.description || '',
              method: 'POST'
            })
          }
        })
      }

      if (params.documents || params.media || params.adminAttachments) {
        oldLot = await axios.get(`/lots/${params.id}`)
      }
      if (params.documents && params.documents.length > maxDocumentLimit) {
        return new Promise((resolve, reject) => {
          reject(new Error(`maximum number of documents = ${maxDocumentLimit}`))
        })
      }
      if (params.documents && params.documents.length <= maxDocumentLimit) {
        // add new document
        params.documents.forEach((newDocument) => {
          if (newDocument.rawFile) {
            let getNextOrder = () => {
              let maxOrder = Math.max(...params.documents.map(doc => {
                if (doc.hasOwnProperty('order')) {
                  return doc.order
                } else {
                  return 0
                }
              }))
              return maxOrder >= 0 ? maxOrder + 1 : 0
            }
            data.push({
              data: newDocument.rawFile,
              url: `/lots/${params.id}/documents`,
              fileOrder: getNextOrder(),
              fileDescription: newDocument.description || '',
              method: 'POST'
            })
          }
        })

        // delete old document
        oldLot.data.lot.documents.forEach((oldDocuments) => {
          let isSame = false
          params.documents.forEach((newDocument) => {
            if (oldDocuments.url === newDocument.url) {
              isSame = true
              if (oldDocuments.description !== newDocument.description) {
                data.push({
                  data: { document: { description: newDocument.description } },
                  url: `/lots/${params.id}/documents/${newDocument.key}`,
                  method: 'PUT'
                })
              }
            }
          })

          if (!isSame) {
            data.push({
              url: `/lots/${params.id}/documents/${oldDocuments.key}`,
              method: 'DELETE'
            })
          }
        })
      }

      if (params.adminAttachments && params.adminAttachments.length > maxDocumentLimit) {
        return new Promise((resolve, reject) => {
          reject(new Error(`maximum number of admin attachments = ${maxDocumentLimit}`))
        })
      }
      if (params.adminAttachments && params.adminAttachments.length <= maxDocumentLimit) {
        // add new document
        params.adminAttachments.forEach((newDocument) => {
          if (newDocument.rawFile) {
            let getNextOrder = () => {
              let maxOrder = Math.max(...params.adminAttachments.map(doc => {
                if (doc.hasOwnProperty('order')) {
                  return doc.order
                } else {
                  return 0
                }
              }))
              return maxOrder >= 0 ? maxOrder + 1 : 0
            }
            data.push({
              data: newDocument.rawFile,
              url: `/lots/${params.id}/adminAttachments`,
              fileOrder: getNextOrder(),
              fileDescription: newDocument.description || '',
              method: 'POST'
            })
          }
        })

        // delete old admin attachment
        oldLot.data.lot.adminAttachments.forEach((oldDocuments) => {
          let isSame = false
          params.adminAttachments.forEach((newDocument) => {
            if (oldDocuments.url === newDocument.url) {
              isSame = true
              if (oldDocuments.description !== newDocument.description) {
                data.push({
                  data: { document: { description: newDocument.description } },
                  url: `/lots/${params.id}/adminAttachments/${newDocument.key}`,
                  method: 'PUT'
                })
              }
            }
          })

          if (!isSame) {
            data.push({
              url: `/lots/${params.id}/adminAttachments/${oldDocuments.key}`,
              method: 'DELETE'
            })
          }
        })
      }

      if (params.media && params.media.length > maxMediaLimit) {
        return new Promise((resolve, reject) => {
          reject(new Error(`maximum number of pictures = ${maxMediaLimit}`))
        })
      }
      let getNextOrder = () => {
        let maxOrder = Math.max(...params.media.map(doc => {
          if (doc.hasOwnProperty('order')) {
            return doc.order
          } else {
            return 0
          }
        }))
        return maxOrder >= 0 ? maxOrder + 1 : 0
      }
      if (params.media && params.media.length <= maxMediaLimit) {
        // add new files
        params.media.forEach((newMedia) => {
          if (newMedia.rawFile && !newMedia.editMode) {
            data.push({
              data: newMedia.rawFile,
              url: `/lots/${params.id}/media`,
              fileOrder: newMedia.hasOwnProperty('order') ? newMedia.order : getNextOrder(),
              method: 'POST',
              main: newMedia.main,
              thumbnailData: newMedia.rawThumbnail
            })
          }
        })

        // delete old files
        oldLot.data.lot.media.forEach((oldMedia, index) => {
          let isSame = false
          params.media.forEach((newMedia) => {
            if ((oldMedia.url === newMedia.url) || newMedia.editMode) {
              isSame = true
            }
          })
          if (!isSame) {
            data.push({
              url: `/lots/${params.id}/media/${oldMedia.key}`,
              method: 'DELETE'
            })
          }
        })
        // update old files
        oldLot.data.lot.media.forEach((oldMedia) => {
          params.media.forEach(async (newMedia) => {
            if (oldMedia.key === newMedia.key || (newMedia.rawFile && newMedia.rawFile.key === oldMedia.key)) {
              if (newMedia.order !== oldMedia.order || newMedia.editMode) {
                if (newMedia.rawFile) {
                  newMedia.rawFile.type = newMedia.rawFile?.mimeType || oldMedia.mimeType
                }
                data.push({
                  data: newMedia.rawFile || newMedia,
                  url: `/lots/${params.id}/media/${newMedia.key || newMedia.rawFile.key}`,
                  fileOrder: newMedia.order || getNextOrder(),
                  method: 'PUT',
                  thumbnailData: newMedia.rawThumbnail
                })
              }
            }
          })
        })
      }
    }

    if (resource === 'how-to-videos') {
      let oldHowToVideos = await axios.get(`/how-to-videos/${params.id}`)

      if (params.media) {
        params.media.forEach((newMedia) => {
          if (newMedia.rawFile) {
            data.push({
              data: newMedia.rawFile,
              url: `/how-to-videos/${params.id}/videos`,
              method: 'POST'
            })
          }
        })
        // delete old videos
        oldHowToVideos.data['how-to-video'].videos.forEach((oldMedia, index) => {
          let isSame = false
          params.media.forEach((newMedia) => {
            if (oldMedia.url === newMedia.url) {
              isSame = true
            }
          })
          if (!isSame) {
            data.push({
              url: `/how-to-videos/${params.id}/medias/${oldMedia._id}/key/${oldMedia.key}`,
              method: 'DELETE'
            })
          }
        })
      }
    }

    if (resource === 'users' && params.type === 'UPDATE') {
      let oldUser = await axios.get(`/users/${params.id}`)
      /*
      if (!params.licenseFile && oldUser.data.user.licenseFile) {
        data.push({
          url: `/users/${params.id}/license-file`,
          method: 'DELETE'
        })
      }
      if (!params.policyFile && oldUser.data.user.policyFile) {
        data.push({
          url: `/users/${params.id}/policy-file`,
          method: 'DELETE'
        })
      }
      */
      if (!params.logo && oldUser.data.user.logo) {
        data.push({
          url: `/users/${params.id}/logo`,
          method: 'DELETE'
        })
      }
    }

    if (resource === 'users') {
      if (params.logo && params.logo.rawFile) {
        data.push({
          data: params.logo.rawFile,
          url: `${basePath}/logo`,
          method: 'POST'
        })
      }
      /*
      if (params.licenseFile && params.licenseFile.rawFile) {
        data.push({
          data: params.licenseFile.rawFile,
          url: `${basePath}/license-file`,
          method: 'POST'
        })
      }
      if (params.policyFile && params.policyFile.rawFile) {
        data.push({
          data: params.policyFile.rawFile,
          url: `${basePath}/policy-file`,
          method: 'POST'
        })
      }
      */
    }
  } catch (e) {
    data = null
  }
  return Promise.all(data.map((object) => {
    let headers = {}
    if (object.hasOwnProperty('data')) {
      headers = {
        'content-type': object.data ? object.data.type : '',
        'file-name': object.data ? encodeURIComponent(object.data.name) : ''
      }
    }
    if (object.hasOwnProperty('fileOrder')) {
      headers['file-order'] = `${object.fileOrder}`
    }
    if (object.hasOwnProperty('main')) {
      headers['file-main'] = `${object.main}`
    }
    if (object.hasOwnProperty('fileDescription')) {
      headers['file-description'] = `${object.fileDescription}`
    }
    let requestOptions = {
      method: object.method,
      url: object.url,
      data: object.data,
      headers: headers
    }
    return axios(requestOptions).then((response) => {
      if (response.data.media && (response.data.media.mimeType.includes('image') || response.data.media.mimeType.includes('video')) && object.thumbnailData) {
        return axios.post(
          `lots/${params.id}/media/${response.data.media.key}/thumbnail`,
          object.thumbnailData,
          {
            headers: {
              'content-type': response.data.media.mimeType
            }
          })
      }
      return response
    })
  }))
}
