import config from '../../config'
import { dataProvider } from '../../App'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { Collapse } from 'react-collapse'
import update from 'immutability-helper'
import debounce from 'lodash/debounce'
import { OwnPagination } from '../OwnPagination'
import React, { Component } from 'react'
import { GET_LIST, Datagrid, TextField, ShowButton, EditButton,DeleteButton} from 'react-admin'
import MaterialTextField from '@material-ui/core/TextField'
import moment from 'moment'

const UserIdLink = ({ record = {} }) => <a href={`/#/users/${record._id}/show`} className='self-link'>{record._newId}</a>

export class HomeUsersComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ids: [],
      data: {},
      total: 0,
      page: 1,
      perPage: config.perPage,
      field: 'dateBuySellRequested',
      order: 'DESC',
      timerEnabled: true,
      isOpened: false,
      search: ''
    }
    this.debounced = null

    this.filter = {
      $or: [
        { status: { $in: ["registered-updated", "approved-buyer-updated"] } },
        {
          status: "approved-buyer-seller",
          $expr: {
            $gt: [{ $size: "$stockTypes" }, 1],
          },
        },
      ],
    };
  
    this.loadUserData = this.loadUserData.bind(this)
    this.setPage = this.setPage.bind(this)
    this.setPerPage = this.setPerPage.bind(this)
    this.setSort = this.setSort.bind(this)
    this.changeCollapse = this.changeCollapse.bind(this)
    this.setSearchString = this.setSearchString.bind(this)
    this.renderUsers = this.renderUsers.bind(this)

    this.loadUserData()
  }

  loadUserData () {
    dataProvider(GET_LIST, 'users', {
      filter: { ...this.filter },
      pagination: {
        page: this.state.page,
        perPage: this.state.perPage,
      },
      sort: {
        field: this.state.field,
        order: this.state.order
      },
      search: this.state.search
    })
      .then(({ data, total }) => {
        let newids = []
        let newdata = {}
        for (let i = 0; i < data.length; i++) {
          let id = data[i].id
          newids.push(id)
          newdata[id] = {
            ...data[i],
            leftTime: data[i].leftTime
          }
        }
        this.setState({
          ids: newids,
          data: newdata,
          total,
          timerEnabled: true
        })
      })
  }

  setPerPage (perPage) {
    this.setState({
      perPage
    }, this.loadUserData)
  }

  setPage (page) {
    this.setState({
      page
    }, this.loadUserData)
  }

  setSort (field) {
    if (field[0] === '_' && field !== '_id') {
      field = field.substring(1)
    }
    let order = 'ASC'
    if (this.state.field === field) {
      order = this.state.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      field,
      order
    }, this.loadUserData)
  }

  collapsible ({ title, isOpened, changeCollapse, publicId, body }) {
    return (
      <div className='collapse-wrapper'>
        <div
          className='header'
          onClick={isOpened ? () => (changeCollapse(publicId, false)) : () => (changeCollapse(publicId, true))}
        >
          <p className='title'>
            {title}
          </p>
          <div
            className='button'
          >
            <FontAwesomeIcon icon={isOpened ? faAngleUp : faAngleDown} />
            <span>{isOpened ? 'Hide' : 'Show'}</span>
          </div>
        </div>
        <Collapse isOpened={isOpened}>
          <div className='body'>
            {body}
          </div>
        </Collapse>
      </div>
    )
  }

  changeCollapse () {
    this.setState({
      isOpened: update(this.state.isOpened, {
        $set: !this.state.isOpened
      })
    })
  }

  setSearchString ({ target: { value } }) {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.loadUserData, 300)
    this.setState({
      search: value
    }, this.debounced)
  }

  renderUsers () {
    const {
      ids,
      data
    } = this.state
    let filteredProps = { ...this.props }
    delete filteredProps.hasList
    delete filteredProps.hasShow
    delete filteredProps.hasEdit
    
    const UserEditButton = ({ record }) => <EditButton to={`/users/${record._id}`} />
    const UserShowButton = ({ record }) => <ShowButton to={`/users/${record._id}/show`} />
    const FirstName = ({ record = {} }) => <a href={`/#/users/${record._id}/show`} className='vendor-link'>{record.firstName}</a>
    const LastName = ({ record = {} }) => <a href={`/#/users/${record._id}/show`} className='vendor-link'>{record.lastName}</a>
    const RequestedPermission = ({ record = {} }) => {
      return (
        <div> <span>{`${record.isRequestedAsBuyer && record.isRequestedAsSeller ? 'Buyer & Seller' : record.isRequestedAsBuyer ? 'Buyer' : record.isRequestedAsSeller ? 'Seller' : ''}`}</span>
        </div>
      )
    }    
    const DateFormat = ({ record, source }) => {
      let data
      if (source === 'createdAtDate') {
        data = record.createdAt ? moment(record.createdAt).format('YYYY/MM/DD, h:mm A') : ''
      } else if (source === 'dateBuySellRequested') {
        data = record.dateBuySellRequested ? moment(record.dateBuySellRequested).format('YYYY/MM/DD, h:mm A') : ''
      }
      return (
        <span>
          {data}
        </span>
      )
    }

    const ApprovedSellingCategoriesField = ({ record = {} }) => {
      return (
        <div> <span>{`${record.approvedStockTypes && record.approvedStockTypes.length > 0 ? (record.approvedStockTypes.join(', ')) : ''}`}</span>
        </div>
      )
    }
    
    const RequestedSellingCategoriesField = ({ record = {} }) => {
      return (
        <div> <span>{`${record.stockTypes && record.stockTypes.length > 0 ? (record.stockTypes.join(', ')) : ''}`}</span>
        </div>
      )
    }

    return (
      <div className='datagrid-wrapper home-datagrid-wrapper'>
        <b className='table-header'>New Users, {this.state.total} users</b>
        <MaterialTextField
          label='Search'
          placeholder='Search'
          className='home-search-text'
          onChange={this.setSearchString}
        />
        <Datagrid
          ids={ids}
          data={data}
          currentSort={{ field: this.state.field, order: this.state.order }}
          setSort={this.setSort}
          {...filteredProps}
        >
          <UserIdLink source='_id' label='ID' />
          <DateFormat source='createdAtDate' label='Date Registered' />
          <FirstName source='firstName' label='First Name' />
          <LastName source='lastName' label='Last Name' />
          <TextField source='status' label='Current Permission' />
          <RequestedPermission label='Requested Permission' />
          <DateFormat source='dateBuySellRequested' label='Date Buy/Sell Requested' />
          <ApprovedSellingCategoriesField source='approvedStockTypes' label='Approved Selling Categories' />
          <RequestedSellingCategoriesField source='stockTypes' label='Requested Selling Categories' />
          <TextField source='tradingName' label='Trading Name' />
          <TextField source='officeAddress.town' label='Town' />
          <TextField source='officeAddress.state' label='State' />
          <TextField source='email' label='Email' />
          <TextField source='phone' label='Phone' />
          <UserEditButton />
          <UserShowButton />
          <DeleteButton label='Delete'/>
        </Datagrid>
        <OwnPagination
          page={this.state.page}
          rowsPerPage={this.state.perPage}
          perPage={this.state.perPage}
          total={this.state.total}
          setPerPage={this.setPerPage}
          setPage={this.setPage}
        />
      </div>
    )
  }

  render () {
    return (
      <div>
        <React.Fragment>
          {this.collapsible({
            title: 'New Buy - Buy/Sell requests',
            isOpened: this.state.isOpened,
            changeCollapse: this.changeCollapse,
            body: this.renderUsers()
          })}
        </React.Fragment>
      </div>
    )
  }
}
