import React from 'react'
import { Form } from 'redux-form'
import './FaqEdit.css'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import TextField from '@material-ui/core/TextField'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, ContentState } from 'draft-js'
import update from 'immutability-helper'
import htmlToDraft from 'html-to-draftjs'
import uuid from 'uuid'
import { pick } from 'lodash'
import { faqInterface } from './faqInterface'
import Button from '@material-ui/core/Button'
import {
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DeleteIcon from '@material-ui/icons/Delete'

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  display: 'flex',
  alignItems: 'start',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 1400
})

const getFileBase64 = (file, callback) => {
  let reader = new window.FileReader()
  reader.readAsDataURL(file)
  // Since FileReader is asynchronous,
  // we need to pass data back.
  reader.onload = () => callback(reader.result)
  reader.onerror = error => {
    console.log(error)
  }
}

const imageUploadCallback = file => new Promise(
  (resolve, reject) => getFileBase64(
    file,
    data => resolve({ data: { link: data } })
  )
)

const createDragabeId = questions => {
  return questions.map((question, index) => {
    const contentBlock = htmlToDraft(question.answer)
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    let order = question.order || index

    return {
      answer: EditorState.createWithContent(contentState),
      question: question.question,
      order: order,
      _id: uuid()
    }
  })
}

export default class FaqEditComponent extends Form {
  constructor (props, context) {
    super(props, context)
    this.reduxForm = context._reduxForm
    let formValues = this.reduxForm.getValues()
    let newQuestions = createDragabeId(formValues.questions)
    this.editTimeout = null
    this.editTitleTimeout = null
    this.state = {
      questions: newQuestions
    }

    this.onDragEnd = this.onDragEnd.bind(this)
    this.handleOnChangeTitle = this.handleOnChangeTitle.bind(this)
  }

  onDragEnd (result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const questions = Array.from(this.state.questions)
    const [removed] = questions.splice(result.source.index, 1)
    questions.splice(result.destination.index, 0, removed)
    questions.forEach((question, index) => {
      question.order = index
    })
    this.setState({ questions }, () => {
      this.reduxForm.dispatch(this.reduxForm.change('questions', questions.map(q => pick(q, faqInterface))))
    })
  }

  onEditorStateChange = (editorState, localQuestion) => {
    let questionIndex = 0
    clearTimeout(this.editTimeout)
    this.state.questions.forEach((question, index) => {
      if (question._id === localQuestion._id) {
        questionIndex = index
      }
    })
    this.setState({
      questions: update(this.state.questions, {
        [questionIndex]: {
          answer: { $set: editorState }
        }
      })
    }, () => {
      this.editTimeout = setTimeout(() => {
        this.reduxForm.dispatch(this.reduxForm.change('questions', this.state.questions.map(q => pick(q, faqInterface))))
      }, 500)
    })
  }

  addQuestionHandler = (e) => {
    e.preventDefault()
    let order = Math.max.apply(null, this.state.questions.map(question => question.order))
    if (order) {
      ++order
    } else {
      order = 1
    }

    let newQuestion = {
      _id: uuid.v4(),
      question: '',
      answer: '',
      order: order
    }

    this.setState({ questions: update(this.state.questions, { $push: [newQuestion] }) }, () => {
      this.reduxForm.dispatch(this.reduxForm.change('questions', this.state.questions.map(q => pick(q, faqInterface))))
    })
  }

  handleRemoveButton (itemId) {
    // this.reduxForm.dispatch(this.reduxForm.change('questions', questions.map(q => pick(q, faqInterface))))
    this.setState({ questions: update(this.state.questions, arr => arr.filter(item => item._id !== itemId)) }, () => {
      this.reduxForm.dispatch(this.reduxForm.change('questions', this.state.questions.map(q => pick(q, faqInterface))))
    })
  }

  handleOnChangeTitle (event, itemId) {
    clearTimeout(this.editTitleTimeout)
    let newTitleText = event.target.value
    let questionIndex = null
    this.state.questions.forEach((question, index) => {
      if (question._id === itemId) {
        questionIndex = index
      }
    })

    this.setState({
      questions: update(this.state.questions, {
        [questionIndex]: {
          question: { $set: newTitleText }
        }
      })
    }, () => {
      this.editTitleTimeout = setTimeout(() => {
        this.reduxForm.dispatch(this.reduxForm.change('questions', this.state.questions.map(q => pick(q, faqInterface))))
      }, 500)
    })
  }

  render () {
    return (
      <div className='question-editor-container'>
        <h4 className='question-title'>Questions</h4>

        <DragDropContext id='faq-draganddrop-context' onDragEnd={this.onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided, snapshot) => {
              return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {this.state.questions.map((item, index) => {
                    return (
                      <Draggable key={item._id} draggableId={item._id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div style={{
                              maxWidth: '1250px'
                            }}>
                              <h5>question</h5>
                              <TextField onChange={(e) => { this.handleOnChangeTitle(e, item._id) }} className='question-input' value={item.question} type='text' />
                              <Editor
                                editorState={item.answer}
                                toolbarClassName='toolbarClassName'
                                wrapperClassName='wrapperClassName'
                                editorClassName='editorClassName'
                                onEditorStateChange={(editorState) => { this.onEditorStateChange(editorState, item) }}
                                toolbar={{
                                  image: {
                                    uploadCallback: imageUploadCallback,
                                    previewImage: true
                                  }
                                }}
                              />
                            </div>
                            <Button onClick={() => { this.handleRemoveButton(item._id) }}>
                              <DeleteIcon />
                              Remove
                            </Button>
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )
            }}
          </Droppable>
        </DragDropContext>
        <Button onClick={this.addQuestionHandler}>
          <FontAwesomeIcon style={{
            paddingRight: '2px'
          }} icon={faPlusCircle} />
          Add
        </Button>
      </div>
    )
  }
}
