import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  EditButton,
  DeleteButton
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

import { toolbarOptions } from '../../utils/reachTextEditor'
import config from '../../config'
import EditToolbar from '../../components/EditToolbar'

const EmptyPagination = props => <span />

export const TermList = (props) =>{
  const StateLink = ({ record = {} }) => {
    return (<div>
       <p>{record.version + ".0"}</p>
       <a href={`/#/terms-version?id=${record.id}`} className='self-link' target='_blank' rel="noopener noreferrer" >Want to see previous versions?</a> 
      
    </div>)
  
  }
  return (
    <div>
      <p className='page-title'>Terms</p>
      <List title='All Terms' bulkActions={false} {...props} pagination={<EmptyPagination />} perPage={config.perPage}>
        <Datagrid>
          <TextField source='_id' />
          <TextField source='categoryName' />
          <StateLink source='state' label='Active Version'  />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    </div>
  
  )
} 

export const TermEdit = (props) => (
  <Edit title='Edit Term' {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <DisabledInput label='Id' source='_id' />
      <TextInput source='categoryName' />
      <RichTextInput source='content' addLabel={false} toolbar={toolbarOptions} />
    </SimpleForm>
  </Edit>
)

export const TermCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='categoryName' />
      <RichTextInput source='content' addLabel={false} toolbar={toolbarOptions} />
    </SimpleForm>
  </Create>
)
