import React, { Component } from 'react'

import DebounceField from './DebounceField'

// TODO useless component (delete if it possible)
export default class FormTextField extends Component {
  constructor (props) {
    super(props)
    this.onChange = props.onChange
  }
  render () {
    return (
      <DebounceField
        disabled={this.props.disabled}
        multiline={this.props.multiline}
        onChange={this.onChange}
        value={this.props.value}
        newIndexes={this.props.newIndexes}
        field={this.props.field}
        label={this.props.label}
        publicId={this.props.publicId}
        type='text'
      />
    )
  }
}
