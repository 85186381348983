import React, { Component } from 'react'

import DebounceField from './DebounceField'
import compareArrays from '../../../helpers/compareArrays'

export default class FormTextField extends Component {
  constructor (props) {
    super(props)

    this.onChange = props.onChange
  }

  shouldComponentUpdate (nextProps) {
    return compareArrays(this.props.newIndexes, nextProps.newIndexes)
  }

  render () {
    return (
      <DebounceField
        onChange={this.onChange}
        value={this.props.title}
        newIndexes={this.props.newIndexes}
        field='title'
        type='text'
      />
    )
  }
}
