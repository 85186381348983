import React, { Component } from 'react'
import './styles.css'
import { dataProvider } from '../../App'
import { GET_LIST } from 'react-admin'
import { OwnPagination } from '../OwnPagination'
import debounce from 'lodash/debounce'
import MaterialTextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ReactTable from 'react-table'
import config from '../../config'
import moment from 'moment'
import { makePrice } from '../../utils'
import * as Datetime from 'react-datetime'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import axios from 'axios'

export class KpiIncWithdrawnReports extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: [],
      defaultPageSize: 20,
      search: '',
      field: 'liveAt',
      order: 'DESC',
      total: 100,
      page: 1,
      perPage: config.perPage,
      totalData: {},
      dateFrom: '',
      dateTo: '',
      selectStockType: 'all'
    }

    this.loadData = this.loadData.bind(this)
  }

  loadData = async () => {
    let { page, perPage, field, order, search, dateFrom, dateTo, selectStockType } = this.state

    if (dateFrom) {
      dateFrom = moment(dateFrom).tz(config.timeZone)
    }
    if (dateTo) {
      dateTo = moment(dateTo).tz(config.timeZone)
    }
    if (selectStockType !== 'all') {
      this.filter = { 'kindData.type': selectStockType }
    } else {
      this.filter = null
    }

    try {
      let data = await dataProvider(GET_LIST, '/auctions/reports', {
        state: 'closed',
        filter: this.filter,
        pagination: {
          page, perPage
        },
        sort: {
          field, order
        },
        search,
        range: {
          rangeFrom: dateFrom, rangeTo: dateTo
        }
      })
      this.setState({ data: data.data, total: data.total, totalData: data.totalData })
    } catch (error) {
      console.log('error', error)
    }
  }

  setSearchString = ({ target: { value } }) => {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.loadData, 300)
    this.setState({
      search: value
    }, this.debounced)
  }

  setSort = (field) => {
    let order = 'ASC'
    if (this.state.field === field) {
      order = this.state.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      field, order
    }, this.loadData)
  }

  setPerPage = (perPage) => {
    this.setState({
      perPage
    }, this.loadData)
  }

  setPage = (page) => {
    this.setState({
      page
    }, this.loadData)
  }

  makeTaxPrice (heads, kideType) {
    let price = 0
    if (kideType === 'sheep') {
      price = heads * config.taxPerHead.sheep / 100
    }
    if (kideType === 'cattle') {
      price = heads * config.taxPerHead.cattle / 100
    }
    return `${price} $`
  }

  dateHandler = (date, field) => {
    this.setState({ [field]: date })
  }

  clearHandle = () => {
    this.setState({ dateFrom: '', dateTo: '', selectStockType: 'all' }, () => {
      this.loadData()
    })
  }

  applyRangeHandle = () => {
    if (!this.state.dateFrom || !this.state.dateTo) {
      window.alert('Dates from and to should be filled')
    } else if (this.state.dateFrom > this.state.dateTo) {
      window.alert('Please select valid date')
    } else {
      this.loadData()
    }
  }

  handleChangeStockType = (e) => {
    this.setState({ selectStockType: e.target.value })
  }

  downloadCsv = async () => {
    let { field, order, search, dateFrom, dateTo, selectStockType } = this.state
    if (dateFrom) {
      dateFrom = moment(dateFrom).tz(config.timeZone)
    }
    if (dateTo) {
      dateTo = moment(dateTo).tz(config.timeZone)
    }
    if (selectStockType !== 'all') {
      this.filter = { 'kindData.type': selectStockType }
    } else {
      this.filter = ''
    }

    let response = await axios({
      url: `print/kpiWithdrawn-report?rangeFrom=${dateFrom}&rangeTo=${dateTo}&search=${search}&sortField=${field}&sortDirection=${order}&filters=${this.filter}`,
      method: 'GET',
      responseType: 'blob'
    })

    const blob = new window.Blob([response.data], { type: response.data.type })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    const contentDisposition = response.headers['content-disposition']
    let fileName = 'kpi_listings_report'
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length === 2) { fileName = fileNameMatch[1] }
    }
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
  }

  render () {
    return (<div id='kpi-report-withdrawn-table'>
      <div className='reports-search-wrapper'>
        <Select
          className='select'
          value={this.state.selectStockType}
          onChange={this.handleChangeStockType}
        >
          <MenuItem value={'all'}> All </MenuItem>
          <MenuItem value={'cattle'}>Cattle</MenuItem>
          <MenuItem value={'sheep'}>Sheep</MenuItem>
        </Select>

        <Datetime
          timeFormat={false}
          dateFormat='DD/MM/YYYY'
          label={'label'}
          inputProps={{ placeholder: 'From' }}
          value={this.state.dateFrom}
          onChange={(date) => { this.dateHandler(date, 'dateFrom') }}
        />

        <Datetime
          timeFormat={false}
          dateFormat='DD/MM/YYYY'
          label={'label'}
          inputProps={{ placeholder: 'To' }}
          value={this.state.dateTo}
          onChange={(date) => { this.dateHandler(date, 'dateTo') }}
        />
        <Button className='range-button' onClick={this.applyRangeHandle}>apply range</Button>
        <Button className='range-button clearButton' onClick={this.clearHandle}>clear range</Button>

        <Button
          onClick={this.downloadCsv}
          color='primary'
          className='download-csv'
          variant='contained'>
          Download CSV
        </Button>

        <MaterialTextField
          label='Search'
          placeholder='Search'
          className='search-text'
          onChange={this.setSearchString}
        />
      </div>
      <ReactTable
        resizable
        minRows={0}
        data={this.state.data}
        showPagination={this.props.isOpened}
        sortable={false}
        PaginationComponent={() => {
          return (<OwnPagination
            page={this.state.page}
            rowsPerPage={this.state.perPage}
            perPage={this.state.perPage}
            total={this.state.total}
            setPerPage={this.setPerPage}
            setPage={this.setPage} />)
        }}
        columns={[{
          id: 'AuctionDate',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('liveAt')
            }}>Auction Date</div>)
          },
          Cell: ({ original }) => {
            return <p>{moment(original.liveAt).tz(config.timeZone).format('DD/MM/YYYY')}</p>
          },
          width: 175
        }, {
          id: 'AuctionNo',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('number')
            }}>Auction No.</div>)
          },
          Cell: ({ original }) => {
            return <p>{original.searchNumber}</p>
          }
        }, {
          id: 'StockType',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('kindData.title')
            }}>Stock Type</div>)
          },
          Cell: ({ original }) => {
            return <p>{original.kindData.title}</p>
          }
        }, {
          id: 'TotalNoLotsCatalogue',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('resultTable.total.numberOfLots.totalExclReoffered')
            }}>Total No. <br /> Lots in Catalogue <br />(incl withdrawn excl re-offered)</div>)
          },
          Cell: ({ original }) => {
            if (original.resultTable.total) {
              return <p>{original.resultTable.total.numberOfLots.totalExclReoffered}</p>
            }
            return null
          },
          width: 200
        }, {
          id: 'TotalNOHeadsINCatalogue',
          Header: () => {
            return (<div onClick={() => {
              this.setSort('resultTable.total.heads.totalExclReoffered')
            }}>Total No. <br /> Head in Catalogue <br />(incl withdrawn excl re-offered)</div>)
          },
          Cell: ({ original }) => {
            if (original.resultTable.total) {
              return <p>{original.resultTable.total.heads.totalExclReoffered}</p>
            }
            return null
          },
          width: 200
        }, {
          id: 'TotalFarmGatelistings',
          Header: () => {
            return (
              <Tooltip title='lot.count * listingFee'>
                <div onClick={() => { this.setSort('resultTable.total.money.totalFeeCents') }}>
                  Total $ Value of FG Listings excl GST (before dis)
                </div>
              </Tooltip>
            )
          },
          Cell: ({ original }) => {
            if (original.resultTable.total) {
              return <p>{makePrice(original.resultTable.total.money.totalFeeCents)}</p>
            }
            return null
          },
          width: 400
        }, {
          id: 'Total Discount Amount excl GST',
          Header: () => {
            return (
              <Tooltip title='If auction is private (totalFeeCentsByCategory - lotDiscount after - auction discount)
                  if auction is not private (totalFeeCentsByCategory - lotDiscount after - user discount)'>
                <div onClick={() => { this.setSort('resultTable.total.totalDiscountAmount') }}>
              Total Discount Amount excl GST
                </div>
              </Tooltip>
            )
          },
          Cell: ({ original }) => {
            if (original.resultTable.total) {
              return <p>{makePrice(original.resultTable.total.totalDiscountAmount)}</p>
            }
            return null
          },
          width: 400
        }, {
          id: 'Total $ Value of FG Listing Fee excl GST (after discount)',
          Header: () => <Tooltip title='TotalFee - discount'><div>Total $ Value of FG Listing Fee excl GST (after discount)</div></Tooltip>,
          Cell: ({ original }) => {
            if (original.resultTable.total) {
              return <p>{makePrice(original.resultTable.total.money.totalFeeCents - original.resultTable.total.totalDiscountAmount)}</p>
            }
            return null
          },
          width: 400
        }, {
          id: 'auctionTags',
          Header: () => {
            return (
              <div>Auction Tags</div>
            )
          },
          Cell: ({ original }) => {
            return <p>{original.auctionTags ? original.auctionTags.map(e => e.title).join(', ') : ''}</p>
          },
          width: 200
        }]}
        defaultPageSize={this.state.defaultPageSize}
      />
      <div className='total-container'>
        <div className='total-content'>
          {this.state.totalData.totalExclReofferedSum
            ? <p>{`Total No. Lots in Catalogue (incl withdrawn excl re-offered): ${this.state.totalData.totalExclReofferedSum}`}</p> : ''}
          {this.state.totalData.totalExclReofferedHeadSum
            ? <p>{`Total No. Head in Catalogue (incl withdrawn excl re-offered): ${this.state.totalData.totalExclReofferedHeadSum}`}</p> : ''}
          {this.state.totalData.totalTaxPriceSum
            ? <p>{`Total $ Value of FG Listings exc GST (before dis): ${makePrice(this.state.totalData.totalTaxPriceSum)}`}</p> : ''}
          {this.state.totalData.totalDiscountSum
            ? <p>{`Total Discount Amount exl GST: ${makePrice(this.state.totalData.totalDiscountSum)}`}</p> : ''}
          {this.state.totalData.totalDiscountSum && this.state.totalData.totalTaxPriceSum
            ? <p>{`Total $ Value of FG Listing Fee excl GST (after discount): ${makePrice(this.state.totalData.totalTaxPriceSum - this.state.totalData.totalDiscountSum)}`}</p> : ''}
        </div>
      </div>
    </div>)
  }
}
