import React, { Component } from 'react'
import ReactTable from 'react-table'
import './styles.css'
import moment from 'moment'
import { OwnPagination } from '../../components/OwnPagination'
import debounce from 'lodash/debounce'
import config from '../../config'
import { GET_LIST } from 'react-admin'
import { dataProvider } from '../../App'
import MaterialTextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import { makePrice, numberFormatter } from '../../utils'
import { gstTax } from '../../constants'
import axios from 'axios'

export class UserReportTableComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: [],
      defaultPageSize: 20,
      search: '',
      field: 'connectivity.lastLogin',
      order: 'DESC',
      total: 100,
      page: 1,
      perPage: config.perPage
    }
  }

  loadData = async () => {
    try {
      let { page, perPage, field, order, search } = this.state
      let userData = await dataProvider(GET_LIST, `/users?report=true&onlyActive=${this.props.onlyActive}&page=${this.state.page}&limit=${this.state.perPage}`, {
        filter: this.filter,
        pagination: {
          page,
          perPage
        },
        sort: {
          field,
          order
        },
        search
      })
      this.setState({ data: userData.data, total: userData.total })
    } catch (error) {
      console.log(error)
    }
  }

  plusGst (cents) {
    let price = cents + (cents / 100 * gstTax)
    return makePrice(price)
  }

  setSearchString = ({ target: { value } }) => {
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(this.loadData, 300)
    this.setState({
      page: 1,
      search: value
    }, this.debounced)
  }

  setSort = (field) => {
    let order = 'ASC'
    if (this.state.field === field) {
      order = this.state.order === 'ASC' ? 'DESC' : 'ASC'
    }
    this.setState({
      field,
      order
    }, this.loadData)
  }

  setPerPage = (perPage) => {
    this.setState({
      perPage
    }, this.loadData)
  }

  setPage = (page) => {
    this.setState({
      page
    }, this.loadData)
  }

  makeUserId (userId, type) {
    let userType = ''
    if (type) {
      userType = 'U'
    }
    return `${userType}${userId}`
  }

  linkToUser = (userId) => {
    this.props.history.push(`users/${userId}/show`)
  }

  stringLimit (string) {
    let modificString = ''
    if (string && string.length === 100) {
      modificString = string + '...'
    } else if (string) {
      modificString = string
    }
    if (string && string.length > 50) {
      return { firstLine: modificString.slice(0, 50), secondLine: modificString.slice(50) }
    }
    return { firstLine: modificString, secondLine: '' }
  }

  async downloadCsv () {
    let response = await axios({
      url: `print/active-users-report`,
      method: 'GET',
      responseType: 'blob'
    })

    const blob = new window.Blob([response.data], { type: response.data.type })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    const contentDisposition = response.headers['content-disposition']
    let fileName = 'active-users-report'
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length === 2) { fileName = fileNameMatch[1] }
    }
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
  }

  render () {
    return (
      <div id='user-report-table'>
        <div className='reports-search-wrapper'>
          {
            this.props.onlyActive ? <Button
              onClick={this.downloadCsv}
              color='primary'
              className='download-csv'
              variant='contained'>
              Download CSV
            </Button> : null
          }

          <MaterialTextField
            label='Search'
            placeholder='Search'
            className='search-text'
            onChange={this.setSearchString}
          />
        </div>
        <ReactTable
          resizable
          minRows={0}
          data={this.state.data}
          showPagination={this.props.isOpened}
          sortable={false}
          PaginationComponent={() => {
            return (
              <OwnPagination
                page={this.state.page}
                rowsPerPage={this.state.perPage}
                perPage={this.state.perPage}
                total={this.state.total}
                setPerPage={this.setPerPage}
                setPage={this.setPage} />
            )
          }}
          columns={[
            {
              id: 'userID',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('_id')
                  }}>
                    User ID
                  </div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p className='link'
                        onClick={() => { this.linkToUser(original._id) }}>{this.makeUserId(original.numericId, original.type)}</p>
                    </React.Fragment>
                  )
                }
                return <p className='link'
                  onClick={() => { this.linkToUser(original._id) }}>{this.makeUserId(original.numericId, original.type)}</p>
              }
            },
            {
              id: 'status',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('type')
                  }}>
                    User Type
                  </div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.type}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.type}</p>
              }
            },
            {
              id: 'disputeStrikes',
              Header: () => {
                return (
                  <div onClick={() => { this.setSort('disputeStrikes') }}>
                    Dispute Strikes
                  </div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.disputeStrikes && original.disputeStrikes.length ? original.disputeStrikes.length : ''}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.disputeStrikes && original.disputeStrikes.length ? original.disputeStrikes.length : ''}</p>
              }
            },
            {
              id: 'firstName',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('firstName')
                  }}>First Name</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.firstName}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.firstName}</p>
              }
            },
            {
              id: 'lastName',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('lastName')
                  }}>Last Name</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.lastName}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.lastName}</p>
              }
            },
            {
              id: 'Trading Name',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('tradingName')
                  }}>Trading Name</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.tradingName}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.tradingName}</p>
              },
              width: 125
            },
            {
              id: 'Town',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('officeAddress.town')
                  }}>Town</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.officeAddress.town}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.officeAddress.town}</p>
              }
            },
            {
              id: 'State',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('officeAddress.state')
                  }}>State</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.officeAddress.state}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.officeAddress.state}</p>
              }
            },
            {
              id: 'Email',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('email')
                  }}>Email</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.email}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.email}</p>
              }
            },
            {
              id: 'Email',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('email')
                  }}>IP Address</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.connectivity.ip}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.connectivity.ip}</p>
              }
            },
            {
              id: 'Last Login',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('connectivity.lastLogin')
                  }}>Last Login</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.connectivity.lastLogin ? moment(original.connectivity.lastLogin).format('DD/MM/YYYY, HH:mm:ss') : null}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.connectivity.lastLogin ? moment(original.connectivity.lastLogin).format('DD/MM/YYYY, HH:mm:ss') : null}</p>
              }
            },
            {
              id: 'Last Live Auction',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('connectivity.lastLiveAuctionDate')
                  }}>Last Live Auction</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.connectivity.lastLiveAuctionDate ? moment(original.connectivity.lastLiveAuctionDate).format('DD/MM/YYYY, HH:mm:ss') : null}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.connectivity.lastLiveAuctionDate ? moment(original.connectivity.lastLiveAuctionDate).format('DD/MM/YYYY, HH:mm:ss') : null}</p>
              },
              width: 150
            },
            {
              id: 'Date last Lot Submitted',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('connectivity.dateLastLotSubmitted')
                  }}>Date last Lot Submitted</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.connectivity.dateLastLotSubmitted ? moment(original.connectivity.dateLastLotSubmitted).format('DD/MM/YYYY, HH:mm:ss') : null}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.connectivity.dateLastLotSubmitted ? moment(original.connectivity.dateLastLotSubmitted).format('DD/MM/YYYY, HH:mm:ss') : null}</p>
              },
              width: 200
            },
            {
              id: 'Personal Lot Clearance Rate %',
              Header: () => {
                return (
                  <Tooltip title='Personal Lot Clearance Rate % = soledLots / (totalLots / 100)
                  soldLots = (All closed lots with status sold or soldAfterAuction)
                  totalLots = (All closed lots)'>
                    <div onClick={() => {
                      this.setSort('connectivity.lotClearanceRate')
                    }}>Personal Lot Clearance Rate %
                    </div>
                  </Tooltip>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{`${original.connectivity.lotClearanceRate} %`}</p>
                    </React.Fragment>
                  )
                }
                return <p>{`${original.connectivity.lotClearanceRate} %`}</p>
              },
              width: 250
            },
            {
              id: 'Personal Head Clearance Rate %',
              Header: () => {
                return (
                  <Tooltip title='Personal Head Clearance Rate % = soledHeads / (totalHead / 100)
                  soledHeads = (All heads in closed lots with status sold or soldAfterAuction)
                  totalHead = (All heads in closed lots)'>
                    <div onClick={() => {
                      this.setSort('connectivity.headClearanceRate')
                    }}>
                      Personal Head Clearance Rate %
                    </div>
                  </Tooltip>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{`${original.connectivity.headClearanceRate} %`}</p>
                    </React.Fragment>
                  )
                }
                return <p>{`${original.connectivity.headClearanceRate} %`}</p>
              },
              width: 200
            },
            {
              id: 'Total No. Lots in Catalogue',
              Header: () => {
                return (
                  <Tooltip title='Total No.Lots in Catalogue (exclude withdrawn) will be count after lot approve'>
                    <div>
                      Total No.Lots in Catalogue
                    </div>
                  </Tooltip>
                )
              },
              minWidth: 200,
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='halfСell threeCell left'>
                        <div onClick={() => { this.setSort('connectivity.totalLotSubmitted.cattle') }}
                          className='subHeader'>
                          Cattle
                        </div>
                        <p>
                          {original.connectivity.totalLotSubmitted &&
                          numberFormatter.format(original.connectivity.totalLotSubmitted.cattle)}
                        </p>
                      </div>
                      <div className='halfСell threeCell middle'>
                        <div onClick={() => { this.setSort('connectivity.totalLotSubmitted.sheep') }}
                          className='subHeader'>
                          Sheep
                        </div>
                        <p>{
                          original.connectivity.totalLotsSoldByCategory &&
                          numberFormatter.format(original.connectivity.totalLotSubmitted.sheep)}
                        </p>
                      </div>
                      <div className='halfСell threeCell right'>
                        <div onClick={() => { this.setSort('connectivity.totalLotSubmitted.goat') }}
                          className='subHeader'>
                          Goat
                        </div>
                        <p>{
                          original.connectivity.totalLotsSoldByCategory &&
                          numberFormatter.format(original.connectivity.totalLotSubmitted.goat)}
                        </p>
                      </div>
                    </React.Fragment>
                  )
                }
                return (
                  <div>
                    <div className='halfСell threeCell left'>
                      <p>{
                        original.connectivity.totalLotSubmitted &&
                        numberFormatter.format(original.connectivity.totalLotSubmitted.cattle)}
                      </p>
                    </div>
                    <div className='halfСell threeCell middle'>
                      <p>{original.connectivity.totalLotSubmitted &&
                      numberFormatter.format(original.connectivity.totalLotSubmitted.sheep)}</p>
                    </div>
                    <div className='halfСell threeCell right'>
                      <p>{original.connectivity.totalLotSubmitted &&
                      numberFormatter.format(original.connectivity.totalLotSubmitted.goat)}</p>
                    </div>
                  </div>
                )
              },
              width: 250
            },
            {
              id: 'Total No. Head Listed in Catalogue',
              Header: () => {
                return (
                  <Tooltip title='Total No. Head Listed in Catalogue will be count after lot approve'>
                    <div>
                      Total No. Head Listed in Catalogue
                    </div>
                  </Tooltip>
                )
              },
              minWidth: 200,
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='halfСell threeCell left'>
                        <div onClick={() => { this.setSort('connectivity.totalHeadListed.cattle') }}
                          className='subHeader'>Cattle
                        </div>
                        <p>{original.connectivity.totalHeadListed && numberFormatter.format(original.connectivity.totalHeadListed.cattle)}</p>
                      </div>
                      <div className='halfСell threeCell middle'>
                        <div onClick={() => { this.setSort('connectivity.totalHeadListed.sheep') }}
                          className='subHeader'>Sheep
                        </div>
                        <p>{original.connectivity.totalHeadListed && numberFormatter.format(original.connectivity.totalHeadListed.sheep)}</p>
                      </div>
                      <div className='halfСell threeCell right'>
                        <div
                          onClick={() => { this.setSort('connectivity.totalHeadListed.goat') }}
                          className='subHeader'>
                          Goat
                        </div>
                        <p>{original.connectivity.totalHeadListed && numberFormatter.format(original.connectivity.totalHeadListed.goat)}</p>
                      </div>
                    </React.Fragment>
                  )
                }
                return (
                  <React.Fragment>
                    <div className='halfСell threeCell left'>
                      <p>{original.connectivity.totalHeadListed && numberFormatter.format(original.connectivity.totalHeadListed.cattle)}</p>
                    </div>
                    <div className='halfСell threeCell middle'>
                      <p>{original.connectivity.totalHeadListed && numberFormatter.format(original.connectivity.totalHeadListed.sheep)}</p>
                    </div>
                    <div className='halfСell threeCell right'>
                      <p>{original.connectivity.totalHeadListed && numberFormatter.format(original.connectivity.totalHeadListed.goat)}</p>
                    </div>
                  </React.Fragment>
                )
              },
              width: 275
            },
            {
              id: 'Total No. Head Sold / Sold AA',
              Header: () => {
                return (
                  <Tooltip
                    title='Total No. Head Sold / Sold AA will be recount after auction close or change status to (soldAfterAuction)'>
                    <div onClick={() => { this.setSort('connectivity.totalHeadSold') }}>
                      Total No. Head Sold / Sold AA
                    </div>
                  </Tooltip>
                )
              },
              minWidth: 200,
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='halfСell threeCell left'>
                        <div onClick={() => { this.setSort('connectivity.totalHeadSold.cattle') }}
                          className='subHeader'>Cattle
                        </div>
                        <p>{original.connectivity.totalHeadSold && numberFormatter.format(original.connectivity.totalHeadSold.cattle)}</p>
                      </div>
                      <div className='halfСell threeCell middle'>
                        <div onClick={() => { this.setSort('connectivity.totalHeadSold.sheep') }}
                          className='subHeader'>Sheep
                        </div>
                        <p>{original.connectivity.totalHeadSold && numberFormatter.format(original.connectivity.totalHeadSold.sheep)}</p>
                      </div>
                      <div className='halfСell threeCell right'>
                        <div onClick={() => { this.setSort('connectivity.totalHeadSold.goat') }}
                          className='subHeader'>
                          Goat
                        </div>
                        <p>{original.connectivity.totalHeadSold && numberFormatter.format(original.connectivity.totalHeadSold.goat)}</p>
                      </div>
                    </React.Fragment>
                  )
                }
                return (
                  <React.Fragment>
                    <div className='halfСell threeCell left'>
                      <p>{original.connectivity.totalHeadSold && numberFormatter.format(original.connectivity.totalHeadSold.cattle)}</p>
                    </div>
                    <div className='halfСell threeCell middle'>
                      <p>{original.connectivity.totalHeadSold && numberFormatter.format(original.connectivity.totalHeadSold.sheep)}</p>
                    </div>
                    <div className='halfСell threeCell right'>
                      <p>{original.connectivity.totalHeadSold && numberFormatter.format(original.connectivity.totalHeadSold.goat)}</p>
                    </div>
                  </React.Fragment>
                )
              },
              width: 275
            },
            {
              id: 'No. Lots Sold / Sold AA',
              Header: () => {
                return (
                  <Tooltip
                    title='No. Lots Sold / Sold AA will be recount after auction close or change status to (soldAfterAuction)'>
                    <div onClick={() => { this.setSort('connectivity.totalHeadSold') }}>No. Lots Sold / Sold AA</div>
                  </Tooltip>
                )
              },
              minWidth: 200,
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='halfСell threeCell left'>
                        <div onClick={() => { this.setSort('connectivity.totalLotsSoldByCategory.cattle') }}
                          className='subHeader'>Cattle
                        </div>
                        <p>{original.connectivity.totalLotsSoldByCategory && numberFormatter.format(original.connectivity.totalLotsSoldByCategory.cattle)}</p>
                      </div>
                      <div className='halfСell threeCell middle'>
                        <div onClick={() => { this.setSort('connectivity.totalLotsSoldByCategory.sheep') }}
                          className='subHeader'>Sheep
                        </div>
                        <p>{original.connectivity.totalLotsSoldByCategory && numberFormatter.format(original.connectivity.totalLotsSoldByCategory.sheep)}</p>
                      </div>
                      <div className='halfСell threeCell right'>
                        <div onClick={() => { this.setSort('connectivity.totalLotsSoldByCategory.goat') }}
                          className='subHeader'>
                          Goat
                        </div>
                        <p>{original.connectivity.totalLotsSoldByCategory && numberFormatter.format(original.connectivity.totalLotsSoldByCategory.goat)}</p>
                      </div>
                    </React.Fragment>
                  )
                }
                return (
                  <React.Fragment>
                    <div className='halfСell threeCell left'>
                      <p>{original.connectivity.totalLotsSoldByCategory && numberFormatter.format(original.connectivity.totalLotsSoldByCategory.cattle)}</p>
                    </div>
                    <div className='halfСell threeCell middle'>
                      <p>{original.connectivity.totalLotsSoldByCategory && numberFormatter.format(original.connectivity.totalLotsSoldByCategory.sheep)}</p>
                    </div>
                    <div className='halfСell threeCell right'>
                      <p>{original.connectivity.totalLotsSoldByCategory && numberFormatter.format(original.connectivity.totalLotsSoldByCategory.goat)}</p>
                    </div>
                  </React.Fragment>
                )
              },
              width: 275
            },
            {
              id: 'Total $ Value of Lots Sold excl GST.',
              Header: () => {
                return (
                  <Tooltip
                    title=' Total $ Value of Lots Sold excl GST. (lot.currentBidCents * lot.count) after auction close'>
                    <div onClick={() => { this.setSort('connectivity.totalCentsSoldByCategory') }}>
                      Total $ Value of Lots Sold excl GST.
                    </div>
                  </Tooltip>
                )
              },
              minWidth: 200,
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='halfСell threeCell left'>
                        <div onClick={() => { this.setSort('connectivity.totalCentsSoldByCategory.cattle') }}
                          className='subHeader'>Cattle
                        </div>
                        <p>{`${makePrice(original.connectivity.totalCentsSoldByCategory.cattle)}`}</p>
                      </div>
                      <div className='halfСell threeCell middle'>
                        <div onClick={() => { this.setSort('connectivity.totalCentsSoldByCategory.sheep') }}
                          className='subHeader'>Sheep
                        </div>
                        <p>{`${makePrice(original.connectivity.totalCentsSoldByCategory.sheep)}`}</p>
                      </div>
                      <div className='halfСell threeCell right'>
                        <div onClick={() => { this.setSort('connectivity.totalCentsSoldByCategory.goat') }}
                          className='subHeader'>
                          Goat
                        </div>
                        <p>{`${makePrice(original.connectivity.totalCentsSoldByCategory.goat)}`}</p>
                      </div>
                    </React.Fragment>
                  )
                }
                return (
                  <React.Fragment>
                    <div className='halfСell threeCell left'>
                      <p>{`${makePrice(original.connectivity.totalCentsSoldByCategory.cattle)}`}</p>
                    </div>
                    <div className='halfСell threeCell middle'>
                      <p>{`${makePrice(original.connectivity.totalCentsSoldByCategory.sheep)}`}</p>
                    </div>
                    <div className='halfСell threeCell right'>
                      <p>{`${makePrice(original.connectivity.totalCentsSoldByCategory.goat)}`}</p>
                    </div>
                  </React.Fragment>
                )
              },
              width: 250
            },
            {
              id: 'Total $ Value of FG Listings exl GST (before dis)',
              Header: () => {
                return (
                  <Tooltip title='listingFee * lot.count'>
                    <div>
                      Total $ Value of FG Listings excl GST (before dis)
                    </div>
                  </Tooltip>
                )
              },
              minWidth: 200,
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='halfСell threeCell left'>
                        <div onClick={() => { this.setSort('connectivity.totalFeeCentsByCategory.cattle') }}
                          className='subHeader'>
                          Cattle
                        </div>
                        <p>{original.connectivity.totalFeeCentsByCategory && makePrice(original.connectivity.totalFeeCentsByCategory.cattle)}</p>
                      </div>
                      <div className='halfСell threeCell middle'>
                        <div onClick={() => { this.setSort('connectivity.totalFeeCentsByCategory.sheep') }}
                          className='subHeader'>
                          Sheep
                        </div>
                        <p>{original.connectivity.totalFeeCentsByCategory && makePrice(original.connectivity.totalFeeCentsByCategory.sheep)}</p>
                      </div>
                      <div className='halfСell threeCell right'>
                        <div onClick={() => { this.setSort('connectivity.totalFeeCentsByCategory.goat') }}
                          className='subHeader'>
                          Goat
                        </div>
                        <p>{original.connectivity.totalFeeCentsByCategory && makePrice(original.connectivity.totalFeeCentsByCategory.goat)}</p>
                      </div>
                    </React.Fragment>
                  )
                }
                return (
                  <React.Fragment>
                    <div className='halfСell threeCell left'>
                      <p>{original.connectivity.totalFeeCentsByCategory && makePrice(original.connectivity.totalFeeCentsByCategory.cattle)}</p>
                    </div>
                    <div className='halfСell threeCell middle'>
                      <p>{original.connectivity.totalFeeCentsByCategory && makePrice(original.connectivity.totalFeeCentsByCategory.sheep)}</p>
                    </div>
                    <div className='halfСell threeCell right'>
                      <p>{original.connectivity.totalFeeCentsByCategory && makePrice(original.connectivity.totalFeeCentsByCategory.goat)}</p>
                    </div>
                  </React.Fragment>
                )
              },
              width: 275
            },
            {
              id: 'Less Discount $',
              Header: () => {
                return (
                  <Tooltip title=' Less Discount %
                  if auction is private (totalFeeCentsByCategory - lotDiscount after - auction discount)
                  if auction is not private (totalFeeCentsByCategory - lotDiscount after - user discount)'>
                    <div onClick={() => { this.setSort('discount') }}>
                      Less Discount %
                    </div>
                  </Tooltip>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='halfСell threeCell left'>
                        <div onClick={() => { this.setSort('connectivity.totalUserDiscountCents.cattle') }}
                          className='subHeader'>Cattle
                        </div>
                        <p>{original.connectivity.totalUserDiscountCents && makePrice(original.connectivity.totalUserDiscountCents.cattle)}</p>
                      </div>
                      <div className='halfСell threeCell middle'>
                        <div onClick={() => { this.setSort('connectivity.totalUserDiscountCents.sheep') }}
                          className='subHeader'>Sheep
                        </div>
                        <p>{original.connectivity.totalUserDiscountCents && makePrice(original.connectivity.totalUserDiscountCents.sheep)}</p>
                      </div>
                      <div className='halfСell threeCell right'>
                        <div onClick={() => { this.setSort('connectivity.totalUserDiscountCents.goat') }}
                          className='subHeader'>
                          Goat
                        </div>
                        <p>{original.connectivity.totalUserDiscountCents && makePrice(original.connectivity.totalUserDiscountCents.goat)}</p>
                      </div>
                    </React.Fragment>
                  )
                }
                return (
                  <React.Fragment>
                    <div className='halfСell threeCell left'>
                      <p>{original.connectivity.totalUserDiscountCents && makePrice(original.connectivity.totalUserDiscountCents.cattle)}</p>
                    </div>
                    <div className='halfСell threeCell middle'>
                      <p>{original.connectivity.totalUserDiscountCents && makePrice(original.connectivity.totalUserDiscountCents.sheep)}</p>
                    </div>
                    <div className='halfСell threeCell right'>
                      <p>{original.connectivity.totalUserDiscountCents && makePrice(original.connectivity.totalUserDiscountCents.goat)}</p>
                    </div>
                  </React.Fragment>
                )
              },
              width: 200
            },
            {
              id: 'Total $ Value of FG Listings exl GST (After dis)',
              Header: () => {
                return (
                  <Tooltip title='totalFeeCentsByCategory - all discounts'>
                    <div onClick={() => { this.setSort('connectivity.totalHeadListed') }}>
                      Total $ Value of FG Listings excl GST (After dis)
                    </div>
                  </Tooltip>
                )
              },
              minWidth: 200,
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='halfСell threeCell left'>
                        <div
                          onClick={() => { this.setSort('connectivity.totalFeeCentsByCategoryAfterDiscounts.cattle') }}
                          className='subHeader'>
                          Cattle
                        </div>
                        <p>{original.connectivity.totalFeeCentsByCategoryAfterDiscount && makePrice(original.connectivity.totalFeeCentsByCategoryAfterDiscount.cattle)}</p>
                      </div>
                      <div className='halfСell threeCell middle'>
                        <div
                          onClick={() => { this.setSort('connectivity.totalFeeCentsByCategoryAfterDiscounts.sheep') }}
                          className='subHeader'>
                          Sheep
                        </div>
                        <p>{original.connectivity.totalFeeCentsByCategoryAfterDiscount && makePrice(original.connectivity.totalFeeCentsByCategoryAfterDiscount.sheep)}</p>
                      </div>
                      <div className='halfСell threeCell right'>
                        <div
                          onClick={() => { this.setSort('connectivity.totalFeeCentsByCategoryAfterDiscounts.goat') }}
                          className='subHeader'>
                          Goat
                        </div>
                        <p>{original.connectivity.totalFeeCentsByCategoryAfterDiscount && makePrice(original.connectivity.totalFeeCentsByCategoryAfterDiscount.goat)}</p>
                      </div>
                    </React.Fragment>
                  )
                }
                return (
                  <React.Fragment>
                    <div className='halfСell threeCell left'>
                      <p>{original.connectivity.totalFeeCentsByCategoryAfterDiscount && makePrice(original.connectivity.totalFeeCentsByCategoryAfterDiscount.cattle)}</p>
                    </div>
                    <div className='halfСell threeCell middle'>
                      <p>{original.connectivity.totalFeeCentsByCategoryAfterDiscount && makePrice(original.connectivity.totalFeeCentsByCategoryAfterDiscount.sheep)}</p>
                    </div>
                    <div className='halfСell threeCell right'>
                      <p>{original.connectivity.totalFeeCentsByCategoryAfterDiscount && makePrice(original.connectivity.totalFeeCentsByCategoryAfterDiscount.goat)}</p>
                    </div>
                  </React.Fragment>
                )
              },
              width: 275
            },
            {
              id: 'Date last Lots Bought',
              Header: () => {
                return (
                  <div onClick={() => {
                    this.setSort('connectivity.lastBoughtTime')
                  }}>Date last Lots Bought</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.connectivity.lastBoughtTime ? moment(original.connectivity.lastBoughtTime).format('DD/MM/YYYY, HH:mm:ss') : null}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.connectivity.lastBoughtTime ? moment(original.connectivity.lastBoughtTime).format('DD/MM/YYYY, HH:mm:ss') : null}</p>
              },
              width: 200
            },
            {
              id: 'No. Head Bought by User in Total',
              Header: () => {
                return (
                  <div onClick={() => { this.setSort('connectivity.totalHeadBought') }}>
                    No. Head Bought by User in Total
                  </div>
                )
              },
              minWidth: 200,
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='halfСell threeCell left'>
                        <div onClick={() => { this.setSort('connectivity.totalHeadBought.cattle') }}
                          className='subHeader'>Cattle
                        </div>
                        <p>{original.connectivity.totalHeadBought.cattle}</p>
                      </div>
                      <div className='halfСell threeCell middle'>
                        <div onClick={() => { this.setSort('connectivity.totalHeadBought.sheep') }}
                          className='subHeader'>Sheep
                        </div>
                        <p>{original.connectivity.totalHeadBought.sheep}</p>
                      </div>
                      <div className='halfСell threeCell right'>
                        <div onClick={() => { this.setSort('connectivity.totalHeadBought.goat') }}
                          className='subHeader'>
                          Goat
                        </div>
                        <p>{original.connectivity.totalHeadBought.goat}</p>
                      </div>
                    </React.Fragment>
                  )
                }
                return (
                  <React.Fragment>
                    <div className='halfСell threeCell left'>
                      <p>{original.connectivity.totalHeadBought.cattle}</p>
                    </div>
                    <div className='halfСell threeCell middle'>
                      <p>{original.connectivity.totalHeadBought.sheep}</p>
                    </div>
                    <div className='halfСell threeCell right'>
                      <p>{original.connectivity.totalHeadBought.goat}</p>
                    </div>
                  </React.Fragment>
                )
              },
              width: 250
            },
            {
              id: 'No. Lots Bought by User in Total',
              Header: () => {
                return (
                  <div onClick={() => { this.setSort('connectivity.totalLotsBoughtByCategory') }}>
                    No. Lots Bought by User in Total
                  </div>
                )
              },
              minWidth: 200,
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='halfСell threeCell left'>
                        <div onClick={() => { this.setSort('connectivity.totalLotsBoughtByCategory.cattle') }}
                          className='subHeader'>Cattle
                        </div>
                        <p>{original.connectivity.totalLotsBoughtByCategory.cattle}</p>
                      </div>
                      <div className='halfСell threeCell middle'>
                        <div onClick={() => { this.setSort('connectivity.totalLotsBoughtByCategory.sheep') }}
                          className='subHeader'>Sheep
                        </div>
                        <p>{original.connectivity.totalLotsBoughtByCategory.sheep}</p>
                      </div>
                      <div className='halfСell threeCell right'>
                        <div onClick={() => { this.setSort('connectivity.totalLotsBoughtByCategory.goat') }}
                          className='subHeader'>
                          Goat
                        </div>
                        <p>{original.connectivity.totalLotsBoughtByCategory.goat}</p>
                      </div>
                    </React.Fragment>
                  )
                }
                return (
                  <React.Fragment>
                    <div className='halfСell threeCell left'>
                      <p>{original.connectivity.totalLotsBoughtByCategory.cattle}</p>
                    </div>
                    <div className='halfСell threeCell middle'>
                      <p>{original.connectivity.totalLotsBoughtByCategory.sheep}</p>
                    </div>
                    <div className='halfСell threeCell right'>
                      <p>{original.connectivity.totalLotsBoughtByCategory.sheep}</p>
                    </div>
                  </React.Fragment>
                )
              },
              width: 250
            },
            {
              id: 'Total $ Value of Lots Bought',
              Header: () => {
                return (
                  <div onClick={() => { this.setSort('connectivity.totalCentsBoughtByCategory') }}>
                    Total $ Value of Lots Bought
                  </div>
                )
              },
              minWidth: 200,
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='halfСell threeCell left'>
                        <div onClick={() => { this.setSort('connectivity.totalCentsBoughtByCategory.cattle') }}
                          className='subHeader'>Cattle
                        </div>
                        <p>{makePrice(original.connectivity.totalCentsBoughtByCategory.cattle)}</p>
                      </div>
                      <div className='halfСell threeCell middle'>
                        <div onClick={() => { this.setSort('connectivity.totalCentsBoughtByCategory.sheep') }}
                          className='subHeader'>Sheep
                        </div>
                        <p>{makePrice(original.connectivity.totalCentsBoughtByCategory.sheep)}</p>
                      </div>
                      <div className='halfСell threeCell right'>
                        <div onClick={() => { this.setSort('connectivity.totalCentsBoughtByCategory.goat') }}
                          className='subHeader'>
                          Goat
                        </div>
                        <p>{makePrice(original.connectivity.totalCentsBoughtByCategory.goat)}</p>
                      </div>
                    </React.Fragment>
                  )
                }
                return (
                  <React.Fragment>
                    <div className='halfСell threeCell left'>
                      <p>{makePrice(original.connectivity.totalCentsBoughtByCategory.cattle)}</p>
                    </div>
                    <div className='halfСell threeCell middle'>
                      <p>{makePrice(original.connectivity.totalCentsBoughtByCategory.sheep)}</p>
                    </div>
                    <div className='halfСell threeCell right'>
                      <p>{makePrice(original.connectivity.totalCentsBoughtByCategory.goat)}</p>
                    </div>
                  </React.Fragment>
                )
              },
              width: 250
            },
            {
              id: 'No. Lots Passed In in Total',
              Header: () => {
                return (
                  <div onClick={() => { this.setSort('connectivity.totalLotsPassedIn') }}>
                    No. Lots Passed In in Total
                  </div>
                )
              },
              minWidth: 200,
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='halfСell threeCell left'>
                        <div onClick={() => { this.setSort('connectivity.totalLotsPassedIn.cattle') }}
                          className='subHeader'>Cattle
                        </div>
                        <p>{original.connectivity.totalLotsPassedIn.cattle}</p>
                      </div>
                      <div className='halfСell threeCell middle'>
                        <div onClick={() => { this.setSort('connectivity.totalLotsPassedIn.sheep') }}
                          className='subHeader'>Sheep
                        </div>
                        <p>{original.connectivity.totalLotsPassedIn.sheep}</p>
                      </div>
                      <div className='halfСell threeCell right'>
                        <div onClick={() => { this.setSort('connectivity.totalLotsPassedIn.goat') }}
                          className='subHeader'>
                          Goat
                        </div>
                        <p>{original.connectivity.totalLotsPassedIn.goat}</p>
                      </div>
                    </React.Fragment>
                  )
                }
                return (
                  <React.Fragment>
                    <div className='halfСell threeCell left'>
                      <p>{original.connectivity.totalLotsPassedIn.cattle}</p>
                    </div>
                    <div className='halfСell threeCell middle'>
                      <p>{original.connectivity.totalLotsPassedIn.sheep}</p>
                    </div>
                    <div className='halfСell threeCell right'>
                      <p>{original.connectivity.totalLotsPassedIn.goat}</p>
                    </div>
                  </React.Fragment>
                )
              },
              width: 250
            },
            {
              id: 'Total $ Value of Lots Passed In',
              Header: () => {
                return (
                  <div onClick={() => { this.setSort('connectivity.totalCentsPassedIn') }}>
                    Total $ Value of Lots Passed In
                  </div>
                )
              },
              minWidth: 200,
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='halfСell threeCell left'>
                        <div onClick={() => { this.setSort('connectivity.totalCentsPassedIn.cattle') }}
                          className='subHeader'>Cattle
                        </div>
                        <p>{makePrice(original.connectivity.totalCentsPassedIn.cattle)}</p>
                      </div>
                      <div className='halfСell threeCell middle'>
                        <div onClick={() => { this.setSort('connectivity.totalCentsPassedIn.sheep') }}
                          className='subHeader'>Sheep
                        </div>
                        <p>{makePrice(original.connectivity.totalCentsPassedIn.sheep)}</p>
                      </div>
                      <div className='halfСell threeCell right'>
                        <div onClick={() => { this.setSort('connectivity.totalCentsPassedIn.goat') }}
                          className='subHeader'>
                          Goat
                        </div>
                        <p>{makePrice(original.connectivity.totalCentsPassedIn.goat)}</p>
                      </div>
                    </React.Fragment>
                  )
                }
                return (
                  <React.Fragment>
                    <div className='halfСell threeCell left'>
                      <p>{makePrice(original.connectivity.totalCentsPassedIn.cattle)}</p>
                    </div>
                    <div className='halfСell threeCell middle'>
                      <p>{makePrice(original.connectivity.totalCentsPassedIn.sheep)}</p>
                    </div>
                    <div className='halfСell threeCell right'>
                      <p>{makePrice(original.connectivity.totalCentsPassedIn.goat)}</p>
                    </div>
                  </React.Fragment>
                )
              },
              width: 250
            },
            {
              id: 'No. Lots No Bid in Total',
              Header: () => {
                return (
                  <div onClick={() => { this.setSort('connectivity.totalLotsNoBid') }}>No. Lots No Bid in Total</div>
                )
              },
              minWidth: 200,
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='halfСell threeCell left'>
                        <div onClick={() => { this.setSort('connectivity.totalLotsNoBid.cattle') }}
                          className='subHeader'>Cattle
                        </div>
                        <p>{original.connectivity.totalLotsNoBid.cattle}</p>
                      </div>
                      <div className='halfСell threeCell middle'>
                        <div onClick={() => { this.setSort('connectivity.totalLotsNoBid.sheep') }}
                          className='subHeader'>Sheep
                        </div>
                        <p>{original.connectivity.totalLotsNoBid.sheep}</p>
                      </div>
                      <div className='halfСell threeCell right'>
                        <div onClick={() => { this.setSort('connectivity.totalLotsNoBid.goat') }}
                          className='subHeader'>
                          Goat
                        </div>
                        <p>{original.connectivity.totalLotsNoBid.goat}</p>
                      </div>
                    </React.Fragment>
                  )
                }
                return (
                  <React.Fragment>
                    <div className='halfСell threeCell left'>
                      <p>{original.connectivity.totalLotsNoBid.cattle}</p>
                    </div>
                    <div className='halfСell threeCell middle'>
                      <p>{original.connectivity.totalLotsNoBid.sheep}</p>
                    </div>
                    <div className='halfСell threeCell right'>
                      <p>{original.connectivity.totalLotsNoBid.goat}</p>
                    </div>
                  </React.Fragment>
                )
              },
              width: 250
            },
            {
              id: 'Total $ Value of Lots No Bid',
              Header: () => {
                return (
                  <div onClick={() => { this.setSort('connectivity.totalCentsNoBid') }}>
                    Total $ Value of Lots No Bid
                  </div>
                )
              },
              minWidth: 200,
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='halfСell threeCell left'>
                        <div onClick={() => { this.setSort('connectivity.totalCentsNoBid.cattle') }}
                          className='subHeader'>Cattle
                        </div>
                        <p>{makePrice(original.connectivity.totalCentsNoBid.cattle)}</p>
                      </div>
                      <div className='halfСell threeCell middle'>
                        <div onClick={() => { this.setSort('connectivity.totalCentsNoBid.sheep') }}
                          className='subHeader'>Sheep
                        </div>
                        <p>{makePrice(original.connectivity.totalCentsNoBid.sheep)}</p>
                      </div>
                      <div className='halfСell threeCell right'>
                        <div onClick={() => { this.setSort('connectivity.totalCentsNoBid.goat') }}
                          className='subHeader'>Goat
                        </div>
                        <p>{makePrice(original.connectivity.totalCentsNoBid.goat)}</p>
                      </div>
                    </React.Fragment>
                  )
                }
                return (
                  <React.Fragment>
                    <div className='halfСell threeCell left'>
                      <p>{makePrice(original.connectivity.totalCentsNoBid.cattle)}</p>
                    </div>
                    <div className='halfСell threeCell middle'>
                      <p>{makePrice(original.connectivity.totalCentsNoBid.sheep)}</p>
                    </div>
                    <div className='halfСell threeCell right'>
                      <p>{makePrice(original.connectivity.totalCentsNoBid.goat)}</p>
                    </div>
                  </React.Fragment>
                )
              },
              width: 250
            },
            {
              id: 'Total No. Withdrawn Lots',
              Header: () => {
                return (
                  <div onClick={() => { this.setSort('connectivity.totalLotsWithdrawn') }}>
                    Total No. Withdrawn Lots
                  </div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.connectivity.totalLotsWithdrawn}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.connectivity.totalLotsWithdrawn}</p>
              },
              width: 200
            },
            {
              id: 'Total No. Re-offered Lots',
              Header: () => {
                return (
                  <div onClick={() => { this.setSort('connectivity.totalLotsReOffered') }}>
                    Total No. Re-offered Lots
                  </div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.connectivity.totalLotsReOffered}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.connectivity.totalLotsReOffered}</p>
              },
              width: 200
            },
            {
              id: 'Date Of Last Note',
              Header: () => {
                return (
                  <div onClick={() => { this.setSort('updatedData.updatedAt') }}>Last Note</div>
                )
              },
              Cell: ({ original, index }) => {
                if (original.adminComments && !original.adminComments.length) {
                  if (!index) {
                    return (
                      <React.Fragment>
                        <div className='subHeader' />
                        <p />
                      </React.Fragment>
                    )
                  } else {
                    return (
                      <React.Fragment>
                        <p />
                      </React.Fragment>
                    )
                  }
                }
                if (!index) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>{original.adminComments && original.adminComments.length && moment(original.adminComments[original.adminComments.length - 1].updatedAt).format('DD/MM/YYYY, HH:mm:ss')}</p>
                    </React.Fragment>
                  )
                }
                return <p>{original.adminComments && original.adminComments.length && moment(original.adminComments[original.adminComments.length - 1].updatedAt).format('DD/MM/YYYY, HH:mm:ss')}</p>
              },
              width: 150
            },
            {
              id: 'Notes',
              Header: () => {
                return (
                  <div onClick={() => { this.setSort('updatedData.updatedAt') }}>Notes</div>
                )
              },
              Cell: ({ original, index }) => {
                if (!index) {
                  if (original.adminComments && original.adminComments.length) {
                    return (
                      <React.Fragment>
                        <div className='subHeader' />
                        <p>
                          <span>{this.stringLimit(original.adminComments[original.adminComments.length - 1].comment).firstLine}</span><br />
                          <span>{this.stringLimit(original.adminComments[original.adminComments.length - 1].comment).secondLine}</span>
                        </p>

                      </React.Fragment>
                    )
                  } else {
                    return (
                      <React.Fragment>
                        <div className='subHeader' />
                        <p />
                      </React.Fragment>
                    )
                  }
                }
                if (original.adminComments && original.adminComments.length) {
                  return (
                    <p>
                      <span>{this.stringLimit(original.adminComments[original.adminComments.length - 1].comment).firstLine}</span><br />
                      <span>{original.adminComments && original.adminComments.length && this.stringLimit(original.adminComments[original.adminComments.length - 1].comment).secondLine}</span>
                    </p>
                  )
                } else {
                  return (
                    <p />
                  )
                }
              },
              width: 400
            },
            {
              id: 'Tags',
              Header: () => {
                return (
                  <div>Tags</div>
                )
              },
              Cell: ({ original, index }) => {
                if (original.userTag) {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p>
                        <span>{original.userTag}</span><br />
                      </p>

                    </React.Fragment>
                  )
                } else {
                  return (
                    <React.Fragment>
                      <div className='subHeader' />
                      <p />
                    </React.Fragment>
                  )
                }
              },
              width: 200
            }
          ]}
          defaultPageSize={this.state.defaultPageSize}
        />
      </div>
    )
  }
}
