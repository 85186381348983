import axios from 'axios'

import config from '../../../config'

// handles for ActionButton

export const pauseAuction = async (props) => {
  await axios(`auctions/${props.data.id}/pause`, {
    method: 'put'
  })
  props.showNotification('Auction was successfully paused', 'success')
  props.data.refreshView()
}

export const addMinutesToActiveLots = async (props, min) => {
  const seconds = min * 60
  await axios(`auctions/${props.data.id}/addTimeToActiveLots?seconds=${seconds}`, {
    method: 'put'
  })
  props.showNotification('Live lots was successfully updated', 'success')
  props.data.refreshView()
}

export const editAuctionLotMaxSecond = async (props, min) => {
  const seconds = min * 60
  await axios(`auctions/${props.data.id}/edit-lot-max-seconds?seconds=${seconds}`, {
    method: 'put'
  })
  props.showNotification('Live auction was successfully updated', 'success')
  props.data.refreshView()
}

export const freezeAuction = async (props) => {
  await axios(`auctions/${props.data.id}/freeze`, {
    method: 'put'
  })
  props.showNotification('Auction was successfully frozen', 'success')
  props.data.refreshView()
}

export const resumeAuction = async (props) => {
  await axios(`auctions/${props.data.id}/resume`, {
    method: 'put'
  })
  props.showNotification('Auction was successfully resumed', 'success')
  props.data.refreshView()
}

export const addMinute = async (props) => {
  await axios(`auctions/${props.data.id}/add-max-time?seconds=60`, {
    method: 'put'
  })
  props.showNotification('Time was successfully added', 'success')
  props.data.refreshView()
}

export const addFiveMinutes = async (props) => {
  await axios(`auctions/${props.data.id}/add-max-time?seconds=300`, {
    method: 'put'
  })
  props.showNotification('Time was successfully added', 'success')
  props.data.refreshView()
}


export const saveAndExport = async (props) => {
  window.open(config.rootUrl + `print/auctions/${props.data.id}/full-details`, '_blank')
}

export const publishNotification = async (props) => {
  if (!props.notification) {
    props.showNotification('Enter notification text', 'warning')
    return
  }
  await axios(`auctions/${props.data.id}/publish-notification`, {
    method: 'put',
    data: {
      notification: props.notification
    }
  })
  props.showNotification('Notification was successfully published', 'success')
  props.data.refreshView()
}

export const stopNotification = async (props) => {
  await axios(`auctions/${props.data.id}/stop-notification`, {
    method: 'put'
  })
  props.showNotification('Notification was successfully stopped', 'success')
  props.data.refreshView()
}

export const cancelAuction = async (props) => {
  if (!props.record.cancelled) {
    try {
      await axios(`auctions/${props.data.id}/cancel`, {
        method: 'put'
      })
      props.showNotification('Auction was cancelled', 'success')
      props.data.refreshView()
    } catch (err) {
      props.showNotification(err.message, 'warning')
    }
  }
}

export const closeAuction = async (props) => {
  try {
    await axios.post(`/auctions/${props.data.id}/close-all-lots`)
    props.showNotification('Auction was closed', 'success')
    props.data.refreshView()
  } catch (err) {
    props.showNotification(err.message, 'warning')
  }
}

export const runNextLotInSequentialAuction = async (props) => {
  if (props.record.auctionType === 'sequential' && props.record.state === 'live') {
    await axios(`auctions/${props.data.id}/next-lot`, {
      method: 'post'
    })
    props.showNotification('The lot was successfully launched', 'success')
    props.data.refreshView()
  } else {
    props.showNotification('Auction is not Live', 'warning')
    props.data.refreshView()
  }
}
