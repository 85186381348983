import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { showNotification } from 'react-admin'
import { Bids } from './Bids'

export default connect(
  null,
  { push, showNotification },
  null,
  { withRef: true }
)(Bids)
