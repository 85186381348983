export let LotDetailsModify = (detail) => {
    let getNewFields = () => (

        {
            'options': [
                'Agent Assessed',
                'Vendor Assessed',
            ],
            'isPublic': true,
            'isRequired': true,
            'title': 'Assessor Type',
            'type': 'dropdown',
            'publicId': 'assessorType',
            'value': null
        }
    )
    // const agentGroupIndex = detail.values.findIndex(item => item.title === 'Vendor Agent Details')
    if (detail.title === 'Vendor Agent Details') {
        detail.title = 'Assessor Details';
        const agentDetailsNameIndex = detail.values.findIndex(x => x.publicId === 'agentDetailsName');
        const agencyNameIndex = detail.values.findIndex(x => (x.publicId === 'agencyName' || x.publicId === 'agencyname'))
        const agentCommentIndex = detail.values.findIndex(x => x.publicId === 'agentComment')
        if (agentDetailsNameIndex !== -1) {
            detail.values[agentDetailsNameIndex].title = 'Assessor Name'
            detail.values[agentDetailsNameIndex].value = null
        }
        if (agentCommentIndex !== -1) {
            detail.values[agentCommentIndex].title = 'Assessor Comments'
             detail.values[agentCommentIndex].value = null
        }

        if (agencyNameIndex !== -1) {
            detail.values[agencyNameIndex] = getNewFields();
        } else {
            detail.values.splice(1, 0, getNewFields())
        }

    }

    if(detail.title === 'Delivery'){
        const agentNameIndex  = detail.values.findIndex(x => x.publicId === 'agentName')
        const agentPhoneIndex = detail.values.findIndex(x => x.publicId === 'agentPhone')
        const agentEmailIndex = detail.values.findIndex(x => x.publicId === 'agentEmail')

        if(agentNameIndex !== -1){
            detail.values[agentNameIndex].title = "Delivery Assessor Name"
          }
          if(agentPhoneIndex !== -1){
            detail.values[agentPhoneIndex].title = 'Delivery Assessor Phone'
          }
          if(agentEmailIndex !== -1){
            detail.values[agentEmailIndex].title = 'Delivery Assessor Email'
          }

    }

}