import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  BooleanInput,
  BooleanField,
  ArrayInput,
  SimpleFormIterator,
  EditButton,
  DeleteButton
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import FaqEditComponent from '../../components/Faq/FaqEdit'
import './styles.css'

import { toolbarOptions } from '../../utils/reachTextEditor'
import config from '../../config'
import EditToolbar from '../../components/EditToolbar'

export const FaqList = (props) => (
  <div>
    <p className='page-title'>FAQs</p>
    <List title='All FAQs' bulkActions={false} {...props} perPage={config.perPage}>
      <Datagrid>
        <TextField source='_id' />
        <TextField source='categoryName' />
        <BooleanField source='forBuyer' />
        <BooleanField source='forSeller' />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  </div>
)

export const FaqEdit = (props) => (
  <Edit title='Edit FAQ' {...props}>
    <SimpleForm className='edit-faq' toolbar={<EditToolbar />}>
      <DisabledInput label='Id' source='_id' />
      <TextInput source='categoryName' />
      <BooleanInput label='For Buyer' source='forBuyer' />
      <BooleanInput label='For Seller' source='forSeller' />
      <FaqEditComponent />
    </SimpleForm>
  </Edit>
)

export const FaqCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect='show'>
      <TextInput source='categoryName' />
      <BooleanInput label='For Buyer' source='forBuyer' />
      <ArrayInput id='create-faq' source='questions'>
        <SimpleFormIterator>
          <TextInput source='question' />
          <RichTextInput source='answer' addLabel={false} toolbar={toolbarOptions} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
)
