import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  EditButton,
  LongTextInput,
  DeleteButton,
  BooleanInput
} from 'react-admin'

import config from '../../config'
import EditToolbar from '../../components/EditToolbar'
import MediaInputField from '../../components/MediaInputField/MediaInputField'

export const VideoEdit = (props) => (
  <Edit title='Edit Video' {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <DisabledInput label='Id' source='_id' />
      <TextInput source='name' />
      <TextInput source='title' />
      <BooleanInput label='For Buyer' source='forBuyer' />
      <BooleanInput label='For Seller' source='forSeller' />
      <TextInput source='description' />
      <LongTextInput source='subDescription' />
      <MediaInputField location='howToVideo' onSubmit={() => { /* mock inheritance */ }} />
    </SimpleForm>
  </Edit>
)

export const VideoList = (props) => (
  <div>
    <p className='page-title'>How to Videos</p>
    <List title='All Videos' {...props} perPage={config.perPage}>
      <Datagrid>
        <TextField source='_id' />
        <TextField source='name' />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  </div>
)

export const VideoCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect='show'>
      <TextInput source='name' />
      <TextInput source='title' />
      <BooleanInput label='For Buyer' source='forBuyer' />
      <BooleanInput label='For Seller' source='forSeller' />
      <TextInput source='description' />
      <LongTextInput source='subDescription' />
    </SimpleForm>
  </Create>
)
