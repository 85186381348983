import React, { Component } from 'react'
import { OwnPagination } from '../../OwnPagination'
import { dataProvider } from '../../../App'
import { GET_LIST, Datagrid } from 'react-admin'
import update from 'immutability-helper'
import { generateUserId, makePrice } from '../../../utils'
import classNames from 'classnames'
import moment from 'moment'
import { collapsible } from '../../../utils/auction/show/collapsible'

export class PreBid extends Component {
  constructor (props) {
    super(props)

    this.state = {
      prebids: {
        data: {},
        total: 0,
        isOpen: false
      },
      page: 1,
      perPage: 10,
      field: '_id',
      order: 'ASC',
      distincted: 0
    }
    this.getDate()
  }

  getDate = async () => {
    let newids = []
    let newdata = {}

    try {
      let { data, total } = await dataProvider(GET_LIST, `auctions/${this.props.auctionId}/preBid`, {
        filter: this.filter,
        pagination: {
          page: this.state.page,
          perPage: this.state.perPage
        },
        sort: {
          field: 'type',
          order: 'AST'
        },
        search: ''
      })

      for (let i = 0; i < data.length; i++) {
        let id = data[i].id
        newids.push(id)
        newdata[id] = {
          ...data[i]
        }
      }

      this.setState({ prebids: update(this.state.prebids, { data: { $set: newdata }, total: { $set: total } }) })
    } catch (e) {
      console.log('new error log', e)
    }
  }

  setPerPage = (perPage) => {
    this.setState({ perPage }, this.getDate)
  }

  setPage = (page) => {
    this.setState({ page }, this.getDate)
  }

  changeCollapse = (state) => {
    this.setState({
      prebids: update(this.state.prebids, {
        isOpen: { $set: state }
      })
    })
  }

  preBidRender () {
    let { data } = this.state.prebids

    const IdField = ({ record = {} }) => {
      if (!record.createdBy) {
        return null
      }
      return (
        <a href={`/#/users/${record.createdBy._id}/show`} className='vendor-link'>
          {generateUserId(record.createdBy.type, record.createdBy.numericId)}
        </a>
      )
    }

    const UserField = ({ record = {} }) => {
      if (!record.createdBy) {
        return null
      }
      return (
        <a
          href={`/#/users/${record.createdBy._id}/show`}
          className='vendor-link'
        >
          {record.createdBy.firstName} {record.createdBy.lastName}
        </a>
      )
    }

    const LotSearchNumberField = ({ record = {} }) => {
      return (
        <span>
          {record.lot && record.lot.searchNumericId &&
          <a href={`/#/lots/${record.lot._id}/show`} className='vendor-link'>{record.lot.searchNumericId}</a>
          }
        </span>
      )
    }

    const LotNumberField = ({ record = {} }) => {
      return (
        <span>
          {record.lot &&
          <a href={`/#/lots/${record.lot._id}/show`} className='vendor-link'>{record.lot.number}</a>
          }
        </span>
      )
    }

    const LotField = ({ record = {} }) => {
      return (
        <span>
          {record.lot &&
          <a href={`/#/lots/${record.lot._id}/show`} className='vendor-link'>{record.lot.publicDetails.title}</a>
          }
        </span>
      )
    }

    const LotStatusField = ({ record }) => {
      let highlight
      let reserveStatus = record.lot.reserveStatus
      highlight = classNames('status-field', {
        green: reserveStatus === 'onMarket',
        white: reserveStatus === 'notNear' || reserveStatus === 'awaitingBids',
        lime: reserveStatus === 'nearReserve'
      })
      if (record.withdrawn) {
        reserveStatus = 'withdrawn'
      }
      switch (reserveStatus) {
        case 'onMarket':
          return (
            <span className={highlight}> On Market </span>
          )
        case 'notNear':
          return (
            <span className={highlight}> Opening Bid </span>
          )
        case 'awaitingBids':
          return (
            <span className={highlight}> Awaiting Bids </span>
          )
        case 'nearReserve':
          return (
            <span className={highlight}> Nearing Reserve </span>
          )
        case 'upcoming':
          return (
            <span> Upcoming </span>
          )
        case 'live':
          return (
            <span className={highlight}> Live </span>
          )
        default:
          return null
      }
    }

    const DateFiled = ({ record }) => {
      return <span>{moment(record.createdAt).format('YYYY/MM/DD hh:mm:ss')}</span>
    }

    const PriceField = ({ record = {} }) => {
      return <span> {makePrice(record.cents, { bidding: record.lot.bidding })} </span>
    }

    const StartPrice = ({ record = {} }) =>
      <span> {makePrice(record.lot.startPrice)} </span>

    const ReservePrice = ({ record = {} }) =>
      <span> {makePrice(record.lot.reserveCents)} </span>

    return (
      <div className='info-table-div-wrapper'>
        <Datagrid ids={Object.keys(data)} data={data} currentSort={{}}>
          <IdField label='Id' sortable={false} />
          <UserField label='Name' sortable={false} />
          <LotSearchNumberField label='Lot ID' sortable />
          <LotNumberField label='Lot No' sortable />
          <LotField label='Lot Title' sortable={false} />
          <LotStatusField label='Lot Status' sortable={false} />
          <DateFiled source='Pre-Bid created at' sortable={false} />
          <PriceField source='cents' label='Bid Amount' sortable={false} />
          <StartPrice source='lot.startPrice' label='Start Price' sortable={false} />
          <ReservePrice source='lot.reserveCents' label='Reserve Price' sortable={false} />
        </Datagrid>
        <OwnPagination
          page={this.state.page}
          perPage={this.state.perPage}
          total={this.state.prebids.total}
          setPerPage={this.setPerPage}
          setPage={this.setPage}
        />
      </div>
    )
  }

  render () {
    let { auction } = this.props
    let { isOpen } = this.state.prebids

    if (auction && auction.state === 'open') {
      return (
        <div id='collapse-content-bids'>
          {collapsible({
            title: 'Auto-Bids Set on Open Lots',
            isOpened: isOpen,
            changeCollapse: this.changeCollapse,
            publicId: 'prebids',
            body: this.preBidRender()
          })}
        </div>
      )
    } else {
      return null
    }
  }
}
