import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  SaveButton,
  DeleteButton,
  UPDATE,
  showNotification
} from 'react-admin'
import { push } from 'react-router-redux'
import { cloneDeep } from 'lodash'

import { dataProvider } from '../../App'
import filterKindFormReference from '../../helpers/filterKindFormReference'

class KindEditActions extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isRequest: false
    }
    this.isUnmounted = false
  }

  componentWillUnmount () {
    this.isUnmounted = true
  }

  render () {
    let {
      reduxForm,
      push,
      showNotification,
      form
    } = this.props
    let initialData = reduxForm.getValues()
    return (
      <div className='custom-kind-actions'>
        <SaveButton
          label='Save And Show'
          submitOnEnter={false}
          className='save-button'
          disabled={this.state.isRequest}
          onClick={() => {
            if (this.state.isRequest) {
              return
            }

            this.setState({
              isRequest: true
            }, () => {
              let data = reduxForm.getValues()
              data.form = cloneDeep(form)
              filterKindFormReference(data.form)
              return dataProvider(UPDATE, 'kinds', { data, id: data._id })
                .then(() => {
                  reduxForm.dispatch(reduxForm.change('form', data.form))
                  push(`/kinds/${data._id}/show`)
                })
                .catch(error => {
                  showNotification(error.message, 'warning')
                })
                .then(() => {
                  if (!this.isUnmounted) {
                    this.setState({
                      isRequest: false
                    })
                  }
                })
            })
          }}
        />
        <DeleteButton
          label='Delete'
          className='delete-button'
          resource='kinds'
          record={initialData}
        />
      </div>
    )
  }
}

export default connect(null, {
  push,
  showNotification
})(KindEditActions)
