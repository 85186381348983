import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Show,
  SimpleShowLayout,
  SaveButton,
  Toolbar,
  ImageField,
  ReferenceManyField,
  DateField,
  NumberField,
  NumberInput,
  CardActions,
  FileInput
} from 'react-admin'

import './styles.css'
import config from '../../config'
import AssessmentFormView from '../../components/AssessmentForm/AssessmentFormView'
import AssessmentFormEdit from '../../components/AssessmentForm/AssessmentFormEdit'

const KindTitle = ({ record = {} }) => {
  return <span>Assessment Form {record ? `'${record.title}'` : ''}</span>
}

/* *** */

const KindListHiddenActions = () => (
  <CardActions className='hidden' />
)

const FullCountField = ({ record = { auctionInfo: { state: {} } } }) => {
  let sum = Object.values(record.auctionInfo.state).reduce((sum, acc) => sum + acc)
  return (
    <span>{sum}</span>
  )
}

export const KindList = (props) => (
  <div>
    <p className='page-title'>Assessment Forms</p>
    <List title='Assessment Forms' {...props} perPage={config.perPage} bulkActionButtons={false} actions={<KindListHiddenActions />}>
      <Datagrid>
        <TextField source='_id' label='Id' sortable={false} />
        <TextField source='title' />
        <FullCountField source='auctionInfo' label='Auction Count' />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  </div>

)

/* *** */

export const KindShow = (props) => {
  const SpecialPriceField = ({ record }) => {
    switch (record.type) {
      case 'cattle':
        return (
          <div className='number-field'>
            <label htmlFor='specialPriceCents-text' >Listing Price for Bull (cents)</label>
            <p id='specialPriceCents-text'>{record.specialPriceCents}</p>
          </div>
        )
      case 'sheep':
        return (
          <div className='number-field'>
            <label htmlFor='specialPriceCents-text' >Listing Price for Ram (cents)</label>
            <p id='specialPriceCents-text'>{record.specialPriceCents}</p>
          </div>
        )
      case 'goat':
        return (
          <div className='number-field'>
            <label htmlFor='specialPriceCents-text' >Listing Price for Buck (cents)</label>
            <p id='specialPriceCents-text'>{record.specialPriceCents}</p>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <Show {...props} title={<KindTitle />}>
      <SimpleShowLayout>
        <TextField label='Id' source='_id' />
        <TextField source='title' />
        <NumberField source='listingPriceCents' label='Listing Price (cents)' title='Listing Price (cents)' />
        <SpecialPriceField />
        <TextField source='loadedAuctionCount' label='Auction Count' />
        <ImageField source='icon.url' title='Icon' />
        <ReferenceManyField
          label='Auctions'
          reference='auctions'
          target='kind'
          sort={{ field: 'liveAt', order: 'DESC' }}
        >
          <Datagrid>
            <TextField source='title' title='Title' />
            <DateField source='liveAt' title='Live At' />
            <TextField source='lotCount' title='Lot Count' sortable={false} />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
        <AssessmentFormView />
      </SimpleShowLayout>
    </Show>
  )
}

/* *** */

const EmptyToolbar = props => (
  <Toolbar {...props} className='hidden' >
    <SaveButton label='' submitOnEnter={false} />
  </Toolbar>
)

export const KindEdit = props => {
  const SpecialPriceField = ({ record }) => {
    switch (record.type) {
      case 'cattle':
        return <NumberInput source='specialPriceCents' label='Listing Price for Bull (cents)' title='Listing Price for Bull (cents)' />
      case 'sheep':
        return <NumberInput source='specialPriceCents' label='Listing Price for Ram (cents)' title='Listing Price for Ram (cents)' />
      case 'goat':
        return <NumberInput source='specialPriceCents' label='Listing Price for Buck (cents)' title='Listing Price for Buck (cents)' />
      default:
        return null
    }
  }

  return (
    <Edit {...props} title={<KindTitle />}>
      <SimpleForm toolbar={<EmptyToolbar />}>
        <TextInput source='title' className='wide-text-input' />
        <NumberInput source='listingPriceCents' title='Listing Price (cents)' step={1} />
        <SpecialPriceField />
        <FileInput source='icon' label='Icon' accept='image/*' placeholder={<p>Drop your file here</p>}>
          <ImageField source='url' />
        </FileInput>
        <AssessmentFormEdit onSubmit={() => { /* mock inheritance */ }} />
      </SimpleForm>
    </Edit>
  )
}
