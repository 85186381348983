import React, { Component } from 'react'
import debounce from 'lodash/debounce'
import * as Datetime from 'react-datetime'
import moment from 'moment'

export default class DebounceField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: props.value
    }

    this.onChange = this.onChange.bind(this)
    this.onReset = this.onReset.bind(this)
    this.debounced = null
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.value !== this.props.value && this.state.value !== this.props.value) {
      this.setState({ value: this.props.value })
    }
  }

  onChange (value) {
    this.updateValue(value)
  }

  onReset () {
    this.updateValue(null)
  }

  onBlur = (value) => {
    if (this.props.required && (!value || typeof value === 'string')) {
      value = moment()
    }
    if (value && typeof value !== 'string') {
      value = value.add(value.utcOffset(), 'minutes').utc().format()
    }
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(() => this.props.onChange({
      value: value, indexes: this.props.newIndexes, field: this.props.field
    }), 300)
    this.setState({ value }, this.debounced)
  }

  updateValue (value) {
    if (typeof value === 'string') {
      return
    }

    if (value) {
      value = value.add(value.utcOffset(), 'minutes').utc().format()
    }
    if (this.debounced && this.debounced.cancel) {
      this.debounced.cancel()
    }
    this.debounced = debounce(() => this.props.onChange({
      value: value, indexes: this.props.newIndexes, field: this.props.field
    }), 300)
    this.setState({ value }, this.debounced)
  }

  renderInput (props) {
    return (<div className='date-selector'>
      <input {...props} />
    </div>)
  }

  render () {
    const { value } = this.state
    return (<>
      {!this.props.disabled && <>
        <Datetime
          timeFormat={false}
          dateFormat='DD/MM/YYYY'
          onChange={this.onChange}
          onBlur={this.onBlur}
          inputProps={{ placeholder: 'Date' }}
          value={value === null ? '' : moment.utc(value).format('DD/MM/YYYY')}
          renderInput={this.renderInput}
        />
        <span className='fg-field-reset' onClick={this.onReset}>X</span>
      </>}
      {this.props.disabled && <span>{value === null ? '' : moment.utc(value).format('DD/MM/YYYY')}</span>}
    </>)
  }
}
