import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { SaveButton } from 'react-admin'
import { pick } from 'lodash'

import './styles.css'
import axios from 'axios'
import { Circle } from 'better-react-spinkit'

const notificationRequestInterface = ['comment', 'createdAt']

export const NotificationsConfig = ({ record }) => {
  const [adminNotifications, setAdminNotifications] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    axios.get(`/notification/config`)
      .then((dataFromServer) => {
        if (mounted.current) {
          setAdminNotifications(dataFromServer.data.notifications)
        }
      })
    return () => {
      mounted.current = false
    }
  }, [])

  const handleAddComment = () => {
    const newNotifications = [...adminNotifications]
    newNotifications.push({ comment: '', createdAt: new Date() })
    setAdminNotifications(newNotifications)
  }

  const submitNotifications = async () => {
    const validNotifications = adminNotifications
      .filter(not => not.comment)
      .map(not => pick(not, notificationRequestInterface))
    setIsLoading(true)
    axios.post(`/notification/config`, { notifications: validNotifications })
      .then(() => {
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  const handleEditNotification = (value, i) => {
    const notifications = [...adminNotifications]
    notifications[i].comment = value
    setAdminNotifications(notifications)
  }

  const handleDeleteNotification = (i) => {
    const newNotifications = adminNotifications.filter((comment, index) => index !== i)
    setAdminNotifications(newNotifications)
  }

  return (
    <div className='admin-notification-page'>
      <p className='page-title'>Admin Notifications:</p>
      <div className='main-container'>
        {!adminNotifications.length && <p>No messages saved yet</p>}
        {adminNotifications?.map((comment, i) => {
          return (
            <div key={i} className='admin-notes-comment'>
              <TextField
                multiline
                value={comment.comment}
                label={'Add New Notifications'}
                onChange={(e) => { handleEditNotification(e.target.value, i) }}
              />
              <Button className='admin-note-delete'
                onClick={() => { handleDeleteNotification(i) }}>
                <DeleteIcon />
                Delete
              </Button>
            </div>
          )
        })}
        <Button className='admin-note-add' onClick={handleAddComment}>
          <FontAwesomeIcon
            style={{ paddingRight: '2px' }}
            icon={faPlusCircle} />
          Add
        </Button>
        {adminNotifications.length
          ? <div className='save-button-group'>
            <SaveButton
              disabled={!adminNotifications.filter(not => not.comment).length}
              className='save-button'
              onClick={submitNotifications}>
              Save
            </SaveButton>
            {isLoading &&
            <div className='spinner-local'>
              <Circle size={35} />
            </div>
            }
          </div>
          : null
        }
      </div>
    </div>
  )
}
