/* global Promise */

export default function (error) {
  console.error(error.message)
  let message
  try {
    if (error.response.data.error.errors.length > 1) {
      message = error.response.data.error.errors.map(error => error.message)
    } else {
      message = error.response.data.error.errors[0].message || 'Unknown Error'
    }
  } catch (e) {
    try {
      message = error.response.data.replace(/<[^>]+>/g, '') || 'Unknown Error'
    } catch (e) {
      try {
        message = error.message || 'Unknown Error'
      } catch (e) {
        message = 'Server Error'
      }
    }
  }
  return Promise.reject({ message: `Error: ${message}` }) // eslint-disable-line prefer-promise-reject-errors
}
