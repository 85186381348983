/* eslint camelcase: 0 */
import React from 'react'
import { BaseListComponent } from '../../../components/BaseList'
import { dataProvider } from '../../../App'
import ReactHtmlParser from 'react-html-parser'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'
import moment from 'moment-timezone'
import './styles.css'
import {
  GET_ONE
} from 'react-admin'
import { gstTax } from '../../../constants'
import { Circle } from 'better-react-spinkit'
import PdfModel from '../pdf/model'

function Transition (props) {
  return <Slide direction='up' {...props} />
}

export class InvoiceTemplateComponent extends BaseListComponent {
  constructor (props) {
    super(props)
    this.state = {
      html: '',
      amount: 0,
      open: false,
      spinner: true
    }
    this.handleClose = this.handleClose.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (this.state.amount !== (Math.floor(nextProps.count) / 100).toFixed(2) && nextProps.count !== null) {
      this.setState({ amount: (Math.floor(nextProps.count) / 100).toFixed(2) }, () => {
        if (document.getElementById('amount')) {
          document.getElementById('amount').innerHTML = `<span id="amountValue">${(this.state.amount)}</span>`
        }
      })
    }
  }

  componentDidMount () {
    let amount = (Math.floor(this.calculateTotalOwing(this.props.record)) / 100).toFixed(2)
    dataProvider(GET_ONE, `invoice/template`, { invoicesTemplate: true })
      .then(response => {
        this.setState({
          html: response.data.html,
          spinner: false,
          amount
        }, () => {
          let auction = this.props.record.data.auction
          document.getElementById('amount').innerHTML = `<span id="amountValue">${(this.state.amount)}</span>`
          document.getElementById('auctionInfo').innerText = `${auction.title}, Auction No. ${auction.number || ''}, ${moment(auction.liveAt).tz('Australia/Sydney').format('dddd MMMM Do YYYY')}, ${moment(auction.liveAt).tz('Australia/Sydney').format('h:mm A')}`
          document.getElementById('userName').innerText = `Hi ${this.props.record.data.user.firstName}`
          document.getElementById('due-on').innerText = `${moment(this.props.record.dueOn).format('DD/MM/YYYY')}`
        })
      })
  }

  calculateTotalOwing (record) {
    let totalExcGST = 0
    record.data.lots.forEach(lot => {
      lot.lotDiscount = lot.lotDiscount ? lot.lotDiscount : 0
      if (!lot.reoffered || lot.takeFeeForReoffered) {
        totalExcGST += lot.lotFee * ((100 - lot.lotDiscount) / 100)
      }
    })
    if (record.data.auction.privateSale) {
      if (record.data.auction.auctionSetUpFee) {
        totalExcGST += record.data.auction.auctionSetUpFee
      }
      const auctionDiscount = record.data.auction.auctionDiscount ? record.data.auction.auctionDiscount : 0
      totalExcGST = totalExcGST * (100 - auctionDiscount) / 100
    } else {
      const userDiscount = record.data.user.discount ? record.data.user.discount : 0
      totalExcGST = totalExcGST * (100 - userDiscount) / 100
    }
    let gstRate = gstTax
    if (this.props.record.hasOwnProperty('gst')) {
      gstRate = this.props.record.gst
    }
    return ((totalExcGST * gstRate) / 100) + totalExcGST
  }

  handleClose () {
    this.setState({
      open: false
    })
  }

  render () {
    return (
      <div className='invoice-template-page'>
        {this.state.spinner &&
        <div className='spinner'>
          <Circle size={100} />
        </div>
        }
        {ReactHtmlParser(this.state.html)}
        <PdfModel disabled={this.state.spinner} {...this.props} setCount={this.props.setCount} />
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            {'Invoice email status'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              Your email has been sent
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
