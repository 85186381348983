import React from 'react'
import { Form } from 'redux-form'
import TextField from '@material-ui/core/TextField'
import IconPlus from '@material-ui/icons/Add'
import IconRemove from '@material-ui/icons/RemoveCircle'
import IconButton from '@material-ui/core/IconButton'
import './UsefulLinks.scss'
import PdfField from './PdfField'

export class UsefulLinks extends Form {
  constructor (props, context) {
    super(props, context)

    this.state = {
      links: [{ url: '', description: '', isPdf: false, name: '' }]
    }

    this.reduxForm = context._reduxForm
    this.formValues = this.reduxForm.getValues()
    this.usefulValues = this.formValues.usefulLinks
  }

  componentDidMount = async () => {
    if (this.usefulValues && this.usefulValues.length) {
      this.setState({ links: this.usefulValues })
    }
  }

  onAddClick = () => {
    this.setState({ links: [...this.state.links, { url: '', description: '', isPdf: false, name: '' }] })
  }

  onRemoveClick = (index) => {
    if (this.state.links.length <= 1) {
      this.setState({ links: [{ url: '', description: '' }] }, this.updateReduxForm)
    } else {
      const copyOfLinks = Object.assign([], this.state.links)
      copyOfLinks.splice(index, 1)
      this.setState({ links: copyOfLinks }, this.updateReduxForm)
    }
  }

  onChangeUrl = (index, value) => {
    const copyOfLinks = Object.assign([], this.state.links)
    copyOfLinks[index].url = value
    this.setState({ links: copyOfLinks }, this.updateReduxForm)
  }

  onChangeDesc = (index, value) => {
    const copyOfLinks = Object.assign([], this.state.links)
    copyOfLinks[index].description = value
    this.setState({ links: copyOfLinks }, this.updateReduxForm)
  }

  updateReduxForm = () => {
    this.reduxForm.dispatch(this.reduxForm.change('usefulLinks', this.state.links))
  }

  render () {
    return (
      <div className='useful-link-container' style={{ marginTop: '10px' }}>
        <label className={'useful-main-label'}>
          Useful links
        </label>
        {
          this.state.links.map((link, index) => {
            return (
              <>
                <TextField
                  className='useful-link-text-url'
                  name='hours'
                  style={{ width: '85px' }}
                  onChange={({ target: { value } }) => { this.onChangeUrl(index, value) }}
                  label={'Url'}
                  value={link.url}
                  type='text'
                  InputProps={{ inputProps: { min: 0 } }}
                />
                <TextField
                  className='useful-link-text-description'
                  name='minutes'
                  style={{ width: '85px' }}
                  onChange={({ target: { value } }) => { this.onChangeDesc(index, value) }}
                  onBlur={this.onBlur}
                  label={'Description'}
                  value={link.description}
                  type='text'
                  InputProps={{ inputProps: { min: 0, max: 59 } }}
                />
                <label className='icon-button-file-remove' htmlFor='icon-button-file-remove'>
                  <IconButton id={'icon-button-file-remove'} onClick={() => { this.onRemoveClick(index) }}
                    color='primary'
                    aria-label='upload picture' component='span'>
                    <IconRemove />
                  </IconButton>
                </label>
                {
                  index === this.state.links.length - 1 &&
                  <label className='icon-button-file-plus' htmlFor='icon-button-file-plus'>
                    <IconButton id={'icon-button-file-remove'} onClick={this.onAddClick} color='primary'
                      aria-label='upload picture' component='span'>
                      <IconPlus />
                    </IconButton>
                  </label>
                }
              </>
            )
          })
        }
        <PdfField record={this.formValues} />
      </div>
    )
  }
}
