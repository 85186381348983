import React, { Component } from 'react'

import DebounceDateField from './DebounceDateField'

export default class FormDateField extends Component {
  constructor (props) {
    super(props)
    this.onChange = props.onChange
  }
  render () {
    return (
      <DebounceDateField
        disabled={this.props.disabled}
        onChange={this.onChange}
        value={this.props.value}
        newIndexes={this.props.newIndexes}
        field={this.props.field}
        label={this.props.label}
        type='text'
        required={this.props.required}
      />
    )
  }
}
