import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  BooleanInput,
  BooleanField,
  EditButton,
  DeleteButton
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

import { toolbarOptions } from '../../utils/reachTextEditor'
import config from '../../config'
import EditToolbar from '../../components/EditToolbar'

export const ToolList = (props) => (
  <div>
    <p className='page-title'>Getting Started</p>
    <List title='All Tools' {...props} perPage={config.perPage}>
      <Datagrid>
        <TextField source='_id' />
        <TextField source='categoryName' />
        <BooleanField source='sellerOnly' />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  </div>

)

export const ToolEdit = (props) => (
  <Edit title='Edit Tool' {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <DisabledInput label='Id' source='_id' />
      <TextInput source='categoryName' />
      <BooleanInput label='For Sellers Only' source='sellerOnly' />
      <RichTextInput source='content' addLabel={false} toolbar={toolbarOptions} />
    </SimpleForm>
  </Edit>
)

export const ToolCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect='show'>
      <TextInput source='categoryName' />
      <BooleanInput label='For Sellers Only' source='sellerOnly' />
      <RichTextInput source='content' addLabel={false} toolbar={toolbarOptions} />
    </SimpleForm>
  </Create>
)
